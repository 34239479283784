import React, {useState} from 'react';

import {store} from '../../../stores/store';
import {clientDb} from '../../../shared/firebase';
import {makeAudit} from '../../../shared/helpers';
import PinIconBlackPNG from '../../../assets/png/pin_black.png';
import PinIconOrangePNG from '../../../assets/png/pin_orange.png';

interface iProps {
  property: string;
  isPinnedProp: boolean;
}

const menuPinStyles: React.CSSProperties = {
  padding: '0px 5px 0px 5px',
  height: '20px',
  cursor: 'pointer'
}

export const MenuPin = ({property, isPinnedProp}: iProps) => {
  const user = store.getState().auth.user;
  const [isPinned, setIsPinned] = useState<boolean>(isPinnedProp);
  const [image, setImage] = useState(() => {
    return isPinnedProp ? PinIconOrangePNG : PinIconBlackPNG;
  });

  const handleClick = async (event) => {
    event.stopPropagation();

    await clientDb().update(makeAudit(user, {
      [`people/people-details/${user.uid}/menuPin/${property}`]: !isPinned,
    }));

    setImage(() => {
      return !isPinned ? PinIconOrangePNG : PinIconBlackPNG;
    });

    setIsPinned(!isPinned);
  }

  return (
    <img src={image} onClick={handleClick} alt='pin' style={menuPinStyles}/>
  )
}
