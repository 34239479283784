import React, {useState} from 'react';
import styles from './DeviceHeader.module.scss';
import PinIcon from '../../../../assets/svg/place_24px.svg';
import ModalImage from '../../../ModalImage';

const FormatDeviceAddress = ({address}) => {
  const {
    street = '',
    city = '',
    state = '',
    zip = '',
  } = address;

  return (
    <div className={styles.DeviceFormattedAddress}>
      <span style={{color: '#333'}}>{`${street.replace(/US/gi, '')}`}</span>
      <span style={{color: '#333'}}>{`${city} ${state}, ${zip}`.replace(/US/gi, '')}</span>
    </div>
  )
}

const DeviceHeader = ({name, address, clickHandler, iconUrl}) => {
  const [isFullImage, setIsFullImage] = useState<boolean>(false);

  return (
    <div className={styles.DeviceHeader}>
      <div className={styles.DeviceHeaderImage}>
        <img src={iconUrl !== '' ? iconUrl : 'images/no_car_image.jpg'} onClick={() => setIsFullImage(true)}/>
      </div>
      <div className={styles.DeviceHeaderInfo}>
        <div className={styles.DeviceHeaderInfoLocation}>
          <div className={styles.DeviceHeaderLocationBtn}>
            <img src={PinIcon}/>
            <span>Location</span>
          </div>
          <FormatDeviceAddress address={address}/>
        </div>
        <button className={styles.DeviceHeaderViewOnMap} onClick={clickHandler}>View On Map</button>
      </div>
      {isFullImage && <ModalImage>
        <div style={{padding: '25px', backgroundColor: '#fff', borderRadius: '10px'}}>
          <img style={{maxWidth: '80vw', maxHeight: '80vh'}}
               src={iconUrl !== '' ? iconUrl : 'images/no_car_image.jpg'}/>
          <button onClick={() => setIsFullImage(false)} style={{position: 'absolute', top: '0'}}>&#10006;</button>
        </div>
      </ModalImage>}
    </div>
  )
}

export default DeviceHeader;