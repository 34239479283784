import React from "react";
import styles from './styles.module.scss';

const DvirFormInput = ({name, description, onChangeName, onChangeDescription, nameIsEmpty}) => {
  return (
    <div className={styles.DvirFormInput}>
        <label htmlFor='form-name'>Name</label>
        <input
          type='text'
          id='form-name'
          placeholder='Enter form name'
          value={name}
          onChange={onChangeName}
        />
      {nameIsEmpty && <p className={styles.NameErrorMsg}>Name can't be empty</p>}
        <label>Description</label>
        <textarea
          id='form-description'
          placeholder='Enter description'
          value={description}
          onChange={onChangeDescription}
        />
    </div>
  )
}

export default DvirFormInput;
