import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {connect, DispatchProp} from 'react-redux';
import { path, keys, values } from 'ramda';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {iFenceDetails, iFullStoreState, iList, iTag, ItemType, UserAuth} from '../../../shared/interfaces';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import {FenceBodyLayout} from '../../fence/tab-bodies/BodyLayout';
import DashboardBlock from '../../DashboardBlock';
import ActionRow from '../../fence/tab-bodies/ActionRow';
import ModalHeader from '../../menus/modal/modal-header';
import { vals } from '../../../shared/helpers';
import {Actions} from '../../../stores/reducers/tag-item';
import * as tagsDb from '../../../shared/db/tags-db';
import {fenceHistory, history} from '../../../stores/store';
import { groupItemsIntoPages } from '../../../utils';
import useItemsList, { ToggleSelectionParams } from '../../../hooks/useItemsList';
import SearchInput from '../../core/SearchInput';
import CheckboxList from '../../core/CheckboxList';
import SelectedLabelsList from '../../core/SelectedLabelsList';
import { SelectedLabel } from '../../core/SelectedLabelsList/interfaces';
import styles from './TagItem.module.scss';

type IProps = RouteComponentProps<{
  type: ItemType;
  itemId: string;
  isFence?: string;
}>

type IPropsFromStore = {
  authUser?: UserAuth;
  tagFilter: string;
  tags: iList<iTag>;
  itemTags: iList<iTag>;
  fence?: iFenceDetails;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState, props: IProps): IPropsFromStore => {
  const {itemId, type} = props.match.params;

  return {
    authUser: state.auth.user!,
    tagFilter: state.tagItem.tagFilter || '',
    tags: state.general.tags,
    itemTags: tagSelectors.getItemTagsSelector(state, {itemId, itemType: type}),
    fence: (state.general.fences[itemId] || {} as any).details,
  };
}

const TagItem = (props: IFullProps) => {
  const [showWarn, setShowWarn] = useState<boolean>(false);
  const [alertChanged, setAlertChanged] = useState<boolean>(false);

  const timeoutForHide = useRef<ReturnType<typeof setTimeout>>();
  const hideWarn = () => {
    clearTimeout(timeoutForHide.current);
    setShowWarn(false);
  }

  const showWarnHandler = () => {
    setShowWarn(true);
    timeoutForHide.current = setTimeout(hideWarn, 5000);
  };

  const {tags, tagFilter, fence} = props;
  const {type, itemId, isFence} = props.match.params;
  const DynamicLayout = isFence ? FenceBodyLayout : DashboardBlock as any;
  const DynamicHeader = isFence
    ? <ActionRow title={(fence || {} as any).name} canBack actions={[]} />
    : <ModalHeader title="Add Tags" style={{ marginBottom: 0, height: 33 }} />

  // Add condition for checking if tagFilter exist to filter items by search logic (+ make tag's name required in this case)
  const filteredTags = useMemo(() => (
    vals(tags)
      .filter(t => tagFilter
        ? !!t.details.name && t.details.name.toLowerCase().indexOf(tagFilter.toLowerCase()) !== -1
        : true)
      .filter(t => type === ItemType.device || !t.details.isAlertType)
  ), [tags, tagFilter, type]);

  useEffect(() => {
    return () => {
      props.dispatch({type: Actions.RESET_PAGE});
    }
  }, []);

  // CHANGING TAGS LOGIC
  const allTagsIds = useMemo(() => filteredTags.map(tag => tag.details.id), [filteredTags]);

  const canTagBeSelected = (tagId: string, allSelected: string[]) => {
    const curTag = tags[tagId];
    const visHasAlert = allSelected.find((id) => tags[id].details.isAlertType);
    const isItemDisallowedToSelect = curTag.details.isAlertType && visHasAlert && !allSelected.includes(tagId);

    return !isItemDisallowedToSelect;
  }

  const initSelectedTags = keys(props.itemTags);
  const {
    selected: visTags,
    toggleSelection: toggleTagSelection,
    isAllSelected: isAllTagsSelected,
    toggleSelectAll,
    resetAllSelection,
  } = useItemsList({
    initialSelected: initSelectedTags,
    allItemsIds: allTagsIds,
    canItemBeSelected: canTagBeSelected,
    onCannotSelect: showWarnHandler,
  });

  const selectedTagsCount = visTags.length;

  useEffect(() => {
    const { tags, itemTags } = props;
    const pre = values(itemTags).find(t => t.details.isAlertType);
    const post = visTags.find((id) => tags[id].details.isAlertType);
    const preId = path(['details', 'id'])(pre);
    const postId = path(['id'])(post);

    setAlertChanged(preId !== postId);
  }, [visTags]);

  const filterChange = (filter) => {
    resetAllSelection();

    props.dispatch({type: Actions.CHANGE_FILTER, filter})
  }

  const changeTagSelection = useCallback(({
    id: tagId,
    isSelected,
  }: ToggleSelectionParams): void => {
    hideWarn();

    // toggle item value. If canTagBeSelected (used as prop in hook) returns 'false':
    // - it will not be selected
    // - warning will be shown (showWarnHandler will be call)
    toggleTagSelection({ id: tagId, isSelected });
  }, [toggleTagSelection]);

  const goBack = () => {
    const { isFence} = props.match.params;

    isFence ? fenceHistory.goBack() : history.goBack();
  }

  const changeAllTags = (isSelected: boolean) => {
    hideWarn();

    // update tags values
    toggleSelectAll(isSelected);
  };

  const tagSections = useMemo(() => {
    const tagsInSection = 6;
    const allTags = filteredTags.map((tag) => ({
      id: tag.details.id,
      name: tag.details.name,
      onChange: () => changeTagSelection({ id: tag.details.id }),
    }));

    return groupItemsIntoPages(allTags, tagsInSection)
      .map((v, i) => ({id: `tag-section-${i}`, value: v}));
  }, [filteredTags, changeTagSelection]);

  const updateTags = async () => {
    const {authUser} = props;
    const { type, itemId} = props.match.params;

    try {
      const isComeFromDevicePage = true;
      await tagsDb.setItemTags(authUser)(isComeFromDevicePage, type, itemId, visTags);
    } catch (e) {
      console.log(e)
    }

    goBack();
  }

  const selectedLabels: SelectedLabel[] = useMemo(() => (
    visTags.map((tagId) => ({
      id: tagId,
      name: tags[tagId].details.name,
      style: { order: tags[tagId].details.name?.length ?? 0 },
    }))
  ), [visTags, tags]);

  if (!tags) return null;

  return (
    <DynamicLayout mapOnly={!itemId || !isFence}>
      {DynamicHeader}

      {/* selected tags count */}
      <div className={styles.selectedTagsContainer}>
        <p>Select Tags:</p>
        <p className={styles.selectedCount}>{selectedTagsCount} Tags Selected</p>
      </div>

      <SearchInput
        value={tagFilter}
        onChange={filterChange}
        placeholder='Filter Tags'
        className={styles.searchInput}
      />

      <CheckboxList
        sections={tagSections}
        selected={visTags}
        changeAllItems={changeAllTags}
        isAllSelected={isAllTagsSelected}
        containerClassName={styles.checkboxesList}
      />

      {!!selectedTagsCount && (
        <SelectedLabelsList
          title='Selected Tags:'
          selected={selectedLabels}
          onClose={(id) => changeTagSelection({ id, isSelected: false })}
          className={styles.selectedTagsSection}
        />
      )}

      <div className={styles.infoMessagesSection}>
        {!!alertChanged && (
          <div className='alert alert-info'>
            Saving these changes will cause device <span style={{ whiteSpace: 'nowrap' }}>settings / alerts</span> to
            change.
          </div>
        )}

        {!!showWarn && (
          <div className='alert alert-warning'>
            <span className='close btn-link' onClick={hideWarn} aria-label='close'>&times;</span>
            Multiple alert type tags are not permitted.
          </div>
        )}
      </div>

      <div className={styles.actionButtons}>
        <button type='button' className={styles.cancelButton} onClick={goBack}>Cancel</button>
        <button type='button' className={styles.saveButton} onClick={updateTags}>Save Tags</button>
      </div>
    </DynamicLayout>
  )
}

export default connect(mapStateToProps)(TagItem);
