import { display } from "spacetime-informal";

export const optionsAddress = [
    { value: 'city', label: 'City', name: 'option' },
    { value: 'state', label: 'State', name: 'option' },
    { value: 'street', label: 'Street', name: 'option' },
    { value: 'zip', label: 'Zip Code', name: 'option' },
]

export const optionsMetrics = [
    { value: 'fuel', label: 'Fuel', name: 'option' },
    { value: 'mpg', label: 'Mpg', name: 'option' },
    { value: 'rpm', label: 'Rpm', name: 'option' },
    { value: 'speed', label: 'Speed', name: 'option' },
    { value: 'volts', label: 'Volts', name: 'option' },
]

export const groupedOptions = [
    { label: 'Address', options: optionsAddress },
    { label: 'Metrics', options: optionsMetrics },
];

export const address = ['city', 'state', 'street', 'zip'];

export const compareOperations = {
    'more': (a, b) => a > b,
    'less': (a, b) => a < b,
    'equalMore': (a, b) => a >= b,
    'equalLess': (a, b) => a <= b,
    'equal': (a, b) => a === b,
    'noEqual': (a, b) => a !== b,
}

export const optionsCompareForMetrics = [
    { value: 'more', label: '>', name: 'compare' },
    { value: 'less', label: '<', name: 'compare' },
    { value: 'equalMore', label: '>=', name: 'compare' },
    { value: 'equalLess', label: '<=', name: 'compare' },
    { value: 'equal', label: '=', name: 'compare' },
    { value: 'noEqual', label: '!=', name: 'compare' },
]

export const optionsCompareForAddress = [
    { value: 'equal', label: '=', name: 'compare' },
    { value: 'noEqual', label: '!=', name: 'compare' },
]

export const getLabelByValue = (value) => {
    const option = optionsCompareForMetrics.find(option => option.value === value);

    return option ? option.label : null;
}

export const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgba(255, 124, 2, 0.64)' : state.isFocused ? 'rgba(255, 124, 2, 0.10)' : '#fff',
        color: '#111',
        padding: '8px 12px',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgba(255, 124, 2, 0.15)',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid transparent',
        boxShadow: state.isFocused ? '0 0 0 1px #FF7C02' : 'none',
        borderRadius: 2,
        minHeight: '32px',
        height: '32px',
        padding: '4px 12px',
        transition: 'border 0.2s ease, box-shadow 0.2s ease',
        backgroundColor: '#F7F7F7',
        outline: 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'transparent' : 'hsl(0, 0%, 80%)',
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#111111',
        transform: 'rotate(180deg)',
        padding: '0px 8px 0px 0px',
        '&:hover': {
            color: '#111111',
        },
        'svg': {
            width: '16px',
            height: '16px',
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        lineHeight: '16px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: '12px',
        fontWeight: '400',
        padding: '0px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 2,
        boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.12)',
        marginTop: '2px',
        maxHeight: '320px',
        overflowY: 'auto',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        overflowX: 'hidden',
        wordWrap: 'break-word',
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#ECECEC',
        },
        '&::-webkit-scrollbar': {
            width: '2px',
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111',
            borderRadius: '2px',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (provided) => ({
        ...provided,
        color: '#878889',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
    }),
};

export const sortBySelectStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgba(255, 124, 2, 0.64)' : state.isFocused ? 'rgba(255, 124, 2, 0.10)' : '#fff',
        color: '#111',
        padding: '8px 12px',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgba(255, 124, 2, 0.15)',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: state.isFocused  ? '#111' : '#B9B9B9 !important',
        boxShadow: 'transparent',
        borderRadius: 8,
        minHeight: '24px',
        height: '24px',
        cursor: 'pointer',
        width: '96px',
        padding: '4px 8px',
        transition: 'border 0.2s ease',
        backgroundColor: 'transparent',
        outline: 'none',
        '&:hover': {
            backgroundColor: state.isFocused  ? 'transparent' : '#ECECEC',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: '12px',
        fontWeight: '400',
        padding: '0px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 2,
        boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.12)',
        marginTop: '2px',
        maxHeight: '320px',
        minWidth: '200px',
        maxWidth: '249px',
        overflowY: 'auto',
        left: 'auto',
        right: 0,
        top: '100%',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        overflowX: 'hidden',
        wordWrap: 'break-word',
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#ECECEC',
        },
        '&::-webkit-scrollbar': {
            width: '2px',
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111',
            borderRadius: '2px',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (provided) => ({
        ...provided,
        color: '#878889',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
    }),
};