import React, {useEffect, useState} from 'react';
import {faChevronDown, faChevronRight} from '@fortawesome/fontawesome-free-solid';

import {MenuPinType} from '../../../../shared/interfaces';
import {Fa} from '../../../elements/fa';
import {MenuPin} from "../../../elements/MenuPin";
import AssignedDriverIcon from '../../../../assets/svg/driver_24px.svg';
import styles from './AssignedDriver.module.scss';
import {friendlyDiff} from "../../../../shared/helpers";
import * as devicedb from "../../../../shared/db/devices-db";
import {Link} from "react-router-dom";
import moment from 'moment';

export const DeviceAssignedDriver = ({deviceId, isPinned, isDriverDevice, isDriver, assignedPerson, authUser, assignedPersonId, device, isReadOnly, title, cssOrder}) => {
  const [collapsed, setCollapsed] = useState<boolean>(isPinned);

  useEffect(() => {
    setCollapsed(isPinned);
  }, [isPinned]);

  return (
    <div className={styles.AssignedDriver} style={{order: cssOrder}}>
      <div className={styles.AssignedDriverHeader}>
        <div className={styles.AssignedDriverHeaderTitle}>
          <img src={AssignedDriverIcon} alt='Assigned Driver'/>
          <span>{title}</span>
        </div>
        <div className={styles.AssignedDriverHeaderBtns}>
          {deviceId && collapsed && <MenuPin property={MenuPinType.ASSIGNED_DRIVER} isPinnedProp={isPinned}/>}
          <Fa style={{cursor: 'pointer', width: '20px', height: '20px', color: '#111', opacity: 0.5}} onClick={() => setCollapsed(!collapsed)} icon={!collapsed ? faChevronRight : faChevronDown}/>
        </div>
      </div>
      {collapsed && <div>
        {assignedPerson ?
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span>{assignedPerson.displayName} test</span>
            <span>{friendlyDiff(moment(), (device as any).assignedTo.time)}</span>
            <button
              className="btn btn-primary btn-xs"
              onClick={(_) => devicedb.unAssignDevice(authUser)(deviceId, assignedPersonId)}
              disabled={!isDriverDevice && isDriver || isReadOnly}
            >
              Tap Out
            </button>
          </div> :
          isDriver ?
            <button
              disabled={isReadOnly}
              className="btn btn-primary"
              onClick={(_) => devicedb.assignDevice(authUser)(deviceId, authUser.uid)}
            >
              Tap in
            </button> :
            isReadOnly
              ?<p>You dont have permission to assing a driver</p>
              : <Link to={`/device/${deviceId}/assign`} className="btn btn-xs btn-primary">Assign to Person</Link>
        }
      </div>}
    </div>
  )
};
