import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { extractDevice } from '../../../../shared/helpers';
import {
  iFullStoreState,
  ItemType,
  iDeviceDetails,
  iDevicePing,
} from '../../../../shared/interfaces';
import { FaDevice, FaFav } from '../../../elements/fa';
import UserImage from '../../../general/UserImage';
import { FormatAddress } from '../../devices/device-list';
import { formatWhenLast } from '../../devices/device-page';
import * as favoritesdb from '../../../../shared/db/favorites-db';
import C from '../../../../shared/constants';
import { ITileProps } from './PersonTile.types';
import HistoryIcon from '../../../../assets/svg/history_24px.svg';

import styles from './styles.module.scss';
import { DashboardSize } from '../../../../stores/reducers/dashboardInfo';

export const PersonTile = ({ person, style = {} }: ITileProps) => {
  const isFavorite = useSelector<iFullStoreState, boolean>(
    (state) => !!state.general.favorites?.[ItemType.person]?.[person.id]
  );
  const assignedDevice = useSelector<
    iFullStoreState,
    iDeviceDetails | undefined
  >((state) =>
    extractDevice(state.devicesData.devicesDetails, person.hasDevice?.deviceId)
  );
  const assignedDeviceLastPing = useSelector<
    iFullStoreState,
    iDevicePing | undefined
  >((state) =>
    state.devicesData.devicesLastPing.get(person.hasDevice?.deviceId)
  );
  const uid = useSelector<iFullStoreState, string>(
    (state) => state.auth.user?.uid ?? ''
  );


  const ago = formatWhenLast(
    assignedDevice ? assignedDeviceLastPing?.time : undefined
  );

  const toggleFav = (e) => {
    e.preventDefault()
    e.stopPropagation()
    favoritesdb.toggleFavorite(ItemType.person, uid, person.id, !isFavorite)
  }

  const size = useSelector<iFullStoreState, DashboardSize>(
    (s) => s.dashboardInfo.size
  );

  const isNotFullSizeDashboard = !(size === DashboardSize.OPEN_FULL);

  return (
    <Link
      className={classNames(styles.PersonTile, {
        [styles.PersonTile_showFullCardWidthOnSmallScreens]: isNotFullSizeDashboard,
      })}
      key={person.id}
      to={`/person/${person.id}`}
    >
      <div className={styles.PersonIcons}>
        <FaDevice active={!!person.hasDevice} />
        <UserImage key={person.id} person={person} size={50} />
        <FaFav
          isFav={isFavorite}
          style={{ color: C.yellow }}
          onClick={toggleFav}
        />
      </div>

      <div className={styles.PersonName}>
        {person.displayName}
      </div>

      {ago && (
        <span className={styles.PersonAgo} title={ago.toString()}>
            <img src={HistoryIcon} />
            <span>{ago}</span>
        </span>
      )}

      <div className={styles.PersonStatus}>
        Status: {assignedDeviceLastPing?.msg ?? 'N/A'}
      </div>

      {assignedDevice && (
        <>
          <FormatAddress
            style={{
              alignSelf: 'stretch',
              paddingTop: 12,
              textAlign: 'center',
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 10, 
              color: '#878889', 
              fontWeight: 400, 
              lineHeight: '14px',
            }}
            address={assignedDeviceLastPing?.address}
          />
          <div style={{ fontSize: 10, color: '#878889', fontWeight: 400, lineHeight: '14px', padding: 12 }}>
            {assignedDevice.name}
          </div>
        </>
      )}
    </Link>
  )
}