import React from "react";

import {useLocalStorage} from "../../../../stores/reducers/general-reducers";
import {iHiddenReportData} from "../vis-report-data";
import {LabelOrPersonRow} from "./cells/person-label-row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    calcTotalTime,
    countDateWithUserAndTimezoneOffset,
    friendlyMilesPer,
    friendlySpeed,
    speedWholeNumber,
    userUnitSpeedStr,
    userUnitStr,
} from "../../../../shared/helpers";
import {
    iDevicePing,
    Units,
    AlertTypes as AlertsType, ItemType,
} from "../../../../shared/interfaces";
import {useRedux} from "../../../../states/redux-state";
import {store} from "../../../../stores/store";

const ReportPointRow = ({
    prevCur: {previous, current: row},
    deviceUnits,
    condensed,
    timezone,
    isTimeClock,
}: {
    deviceUnits: Units;
    condensed: boolean;
    prevCur: { current: iDevicePing ; previous: null | iDevicePing };
    timezone: string;
    isTimeClock?: boolean;
}) => {
    const state = store.getState();
    const deviceOrPerson = useRedux(item => item.report.details.devicePerson);
    const devicesDetails = state.devicesData.devicesDetails;
    const people = useRedux(item => item.general.people);

    const [showCondensed, setShowCondensed] = React.useState(false);
    if (!row.time) console.log("row no time", row);

    const newDate = isTimeClock ? (
            row?.startDate?.format("MMM-D") !== previous?.startDate?.format("MMM-D")
        ) : (
            row?.time?.format("MMM-D") !== previous?.time?.format("MMM-D")
    );
    const [hiddenFields] = useLocalStorage<iHiddenReportData>(
        "hidden-report-data:static",
        {}
    );

    const date = countDateWithUserAndTimezoneOffset(row?.time, timezone);

    const setDataStyle = (prop) => {
        return !hiddenFields[prop]
            ? {
                display: "",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
            : {display: "none"};
    };

    return (isTimeClock ? (
            <>
                {!newDate ? null : (
                    <div className="trip-static-header-wrapper">
                        <div className="trip-time-clock-date">{row.startDate.format("MM/DD/YYYY")}</div>
                        <div key={`${row.pointId}-0`} className="trip-time-clock-header">
                            <div>Device Name</div>
                            <div>Start Date</div>
                            <div>Start Time</div>
                            <div>End Time</div>
                            <div>Total Time</div>
                        </div>
                    </div>
                )}
                <div key={`${row.pointId}-1`} className="trip-time-clock-row">
                    <div
                        className="trip-time-clock-row-item"
                    >
                        {row?.deviceName}
                    </div>
                    <div
                        className="trip-time-clock-row-item"
                    >
                        {row?.startDate
                            ? ` ${row.startDate?.format("MM/DD/YY")} `
                            : '-'
                        }
                    </div>
                    <div
                        className="trip-time-clock-row-item"
                    >
                        {row?.startDate
                            ? row.startDate?.format("hh:mmA")
                            : '-'
                        }
                    </div>
                    <div
                        className="trip-time-clock-row-item"
                        style={{display: !hiddenFields["event"] ? "" : "none"}}
                    >
                        {row?.endDate
                            ? ` ${row.endDate?.format("hh:mmA")} `
                            : '-'
                        }
                    </div>
                    <div
                        className="trip-time-clock-row-item"
                    >
                        {row?.endDate && row?.startDate && calcTotalTime(row.endDate, row.startDate)}
                    </div>
                </div>
            </>
        ) : (
            <>
                {!newDate ? null : (
                    <div className="trip-static-header-wrapper">
                        <div className="trip-static-date">{date.format("MM/DD/YYYY")}</div>
                        <div key={`${row.pointId}-0`} className="trip-static-header">
                            <div>Time</div>
                            <div style={{display: !hiddenFields["fuel"] ? "" : "none"}}>
                                Fuel
                            </div>
                            <div style={{display: !hiddenFields["voltage"] ? "" : "none"}}>
                                Voltage
                            </div>
                            <div style={{display: !hiddenFields["speed"] ? "" : "none"}}>
                                {userUnitSpeedStr(deviceUnits)}
                            </div>
                            <div style={{display: !hiddenFields["posted"] ? "" : "none"}}>
                                Posted
                            </div>
                            <div
                                style={{display: !hiddenFields["consumption"] ? "" : "none"}}
                            >
                                {userUnitStr(deviceUnits)}
                            </div>
                            <div style={{display: !hiddenFields["event"] ? "" : "none"}}>
                                Event
                            </div>
                            <div style={setDataStyle("location")}>Location</div>
                            <div
                                style={{textAlign: "center", ...setDataStyle("coordinates")}}
                            >
                                Coordinates
                            </div>
                            {condensed ? (
                                <div>...</div>
                            ) : (
                                <>
                                    <div style={{display: !hiddenFields["label"] ? "" : "none"}}>
                                        Label
                                    </div>
                                    <div style={{display: !hiddenFields["person"] ? "" : "none"}}>
                                        Person
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div key={`${row.pointId}-1`} className="trip-static-row">
                    <div className="trip-static-row-item">{date.format("h:mmA")}</div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["fuel"] ? "" : "none"}}
                    >
                        {row.fuel}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["voltage"] ? "" : "none"}}
                    >
                        {row.volt}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["speed"] ? "" : "none"}}
                    >
                        {(row.speed as any) === ""
                            ? "-"
                            : speedWholeNumber(row.speed, deviceUnits)}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["posted"] ? "" : "none"}}
                    >
                        {row.posted_speed
                            ? friendlySpeed(row.posted_speed, deviceUnits)
                            : "-"}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["consumption"] ? "" : "none"}}
                    >
                        {row.mpg ? friendlyMilesPer(row.mpg, deviceUnits) : "-"}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["event"] ? "" : "none"}}
                    >
                        {row.msg ? row.msg : "-"}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={{display: !hiddenFields["location"] ? "" : "none"}}
                    >
                        {row.address.street}
                        <br/>
                        {`${row.address.city} ${row.address.state} ${row.address.zip}`.replace(/US/gi, '')}
                    </div>
                    <div
                        className="trip-static-row-item"
                        style={setDataStyle("coordinates")}
                    >
                        {row.coordinates.location.lat}, {row.coordinates.location.lng}
                    </div>
                    {condensed ? (
                        <div>
                            <div onClick={() => setShowCondensed(!showCondensed)}>
                                <FontAwesomeIcon icon={showCondensed ? "minus" : "plus"}/>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="trip-static-row-item"
                                style={{display: !hiddenFields["label"] ? "" : "none"}}
                            >
                                <LabelOrPersonRow row={row} type="point" field="label"/>
                            </div>
                            <div
                                className="trip-static-row-item"
                                style={{display: !hiddenFields["person"] ? "" : "none"}}
                            >
                                <LabelOrPersonRow row={row} type="point" field="personId"/>
                            </div>
                        </>
                    )}
                </div>
                {!showCondensed ? null : (
                    <>
                        <div key={`${row.pointId}-condensed-1`}>
                            <div style={{paddingLeft: 10}}>Label:</div>
                            <div style={{display: !hiddenFields["label"] ? "" : "none"}}>
                                <LabelOrPersonRow row={row} type="point" field="label"/>
                            </div>
                        </div>
                        <div key={`${row.pointId}-condensed-2`}>
                            <div style={{paddingLeft: 10}}>Person:</div>
                            <div style={{display: !hiddenFields["person"] ? "" : "none"}}>
                                <LabelOrPersonRow row={row} type="point" field="personId"/>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    );
};

export default ReportPointRow;

