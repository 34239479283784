import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronLeft, faChevronRight, faTimes, faSpinner } from '@fortawesome/fontawesome-free-solid';

import { ReactComponent as AddIcon } from '../../assets/svg/add.svg';
import { ReactComponent as CancelIcon } from '../../assets/svg/cancel.svg';
import { ReactComponent as CloseIcon } from "../../assets/svg/close_24px.svg";

import styles from './BookmarksBar.module.scss';

import Modal from '../Modal';
import { Fa } from '../elements/fa';

import { Actions as ReportActions, iReportDetails, ReportAC } from '../../stores/reducers/report-reducers';
import { GMapModalAC, GMapModalContent } from '../../stores/reducers/gMapModal/AC';
import { iFullStoreState } from '../../shared/interfaces';

import { BOOKMARK_ICONS } from '../../shared/constants';
import { clientDb, keyGen } from '../../shared/firebase';
import { makeAudit } from '../../shared/helpers';
import { removeReportTrips } from "../../stores/reducers/devicesTripsPoints/AC";
import { getStartAndEndOfDate } from "../../utils";
import SearchIcon from '../SVG-components/search';
import { useHistory, useLocation } from "react-router-dom";
import { Actions as GeneralActions } from '../../stores/reducers/general-reducers';


interface iBookmark {
  id: string;
  name: string;
  url: string;
  icon: string;
  isReport: boolean;
  details?: string;
}

const BookmarksBar = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState<string>(window.location.href);
  const [bookmarkName, setBookmarkName] = useState<string>('');
  const [hostname] = useState<string>(window.location.hostname);
  const [errors, setErrors] = useState<string[]>([]);
  const [pickedIcon, setIcon] = useState<string>('');
  const [bookmarks, setBookmarks] = useState<iBookmark[]>([]);
  const [deletingBookmarkId, setDeletingBookmarkId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCustomUrl, setIsCustomUrl] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: any) => state.auth.user);
  const details: iReportDetails = useSelector<iFullStoreState>((state) => state.report.details) as iReportDetails;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const fetchBookmarks = async () => {
    try {
      const bookmarksSnapshot = await clientDb()
        .child(`/people/people-bookmarks/${user.uid}`)
        .once('value');
      const bookmarksData = bookmarksSnapshot.val() || {};

      setBookmarks(Object.values(bookmarksData));
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
    }
  };

  useEffect(() => {
    fetchBookmarks();
  }, [user.uid]);

  useEffect(() => {
    if (!isFormOpen) {
      setErrors([]);
      setBookmarkName('');
      setCurrentUrl(window.location.href);
    }
  }, [isFormOpen]);

  const validateForm = (): string[] => {
    const validationErrors = [];

    if (!currentUrl.trim()) {
      validationErrors.push("Bookmark URL can't be empty");
    }
    else {
      try {
        const url = new URL(currentUrl);

        if (url.hostname !== hostname) {
          validationErrors.push("Invalid hostname");
        }
      } catch (error) {
        validationErrors.push("Invalid URL format");
      }
    }

    if (!bookmarkName.trim()) {
      validationErrors.push("Bookmark name can't be empty");
    }

    return validationErrors;
  };

  const addBookmarkToFirebase = async (isReport: boolean, url: string, details: string) => {
    const bookmarkKey = keyGen();
    const newBookmark = {
      id: bookmarkKey,
      name: bookmarkName,
      url: url,
      icon: pickedIcon || 'settings.svg',
      isReport: isReport,
      details: details
    };

    try {
      await clientDb().update(makeAudit(user, {
        [`people/people-bookmarks/${user.uid}/${bookmarkKey}`]: newBookmark
      }));

      fetchBookmarks();
    } catch (error) {
      console.error('Error adding bookmark:', error);
    }
  };

  const removeBookmarkFromFirebase = async (id: string) => {
    setDeletingBookmarkId(id);

    try {
      await clientDb().update(makeAudit(user, {
        [`people/people-bookmarks/${user.uid}/${id}`]: null
      }));

      fetchBookmarks();
    } catch (error) {
      console.error('Error removing bookmark:', error);
    } finally {
      setTimeout(() => {
        setDeletingBookmarkId(null);
      }, 500);
    }
  };

  const handleAddBookmark = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const isReport = currentUrl.includes('reports/new');
      const bookmarkDetails = isReport ? JSON.stringify(details) : '';
      await addBookmarkToFirebase(isReport, new URL(currentUrl).hash, bookmarkDetails);
      setIsFormOpen(false);
    } catch (error) {
      console.error('Error adding bookmark:', error);
    }
  };

  const scrollContainer = (direction: string) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleBookmarkClick = (bookmark: iBookmark) => {
    if (!bookmark.isReport) {
      window.location.hash = bookmark.url;
      return;
    }

    const details: iReportDetails = bookmark.details ? JSON.parse(bookmark.details) : {};
    const newSearchKey = `bookmark_report_${Date.now()}`;

    updateReportDetails(details, newSearchKey);
    openReportModal();
  };

  const handleTodaysHistory = () => {
    dispatch(removeReportTrips());
    dispatch({ type: 'REPORT_RESET_ALL_FILTERS' });

    const [startDate, endDate] = getStartAndEndOfDate();
    const deviceRegex = /^\/device\/([^\/]+)$/;
    const match = location.pathname.match(deviceRegex);
    const id = match ? match[1] : null;

    if (id) {
      dispatch(ReportAC.TOGGLE_ITEM_FILTER('device', id));
    }
    dispatch(ReportAC.UPSERT_DATE_GROUP_IN_DATES({ startDate, endDate }));

    history.push('/reports');
  }

  const updateReportDetails = (details: iReportDetails, newSearchKey: string) => {
    details.searchKey = newSearchKey;
    details.dates = [{
      startDate: moment(details.dates?.[0]?.startDate ?? Date.now()),
      endDate: moment(details.dates?.[0]?.endDate ?? Date.now())
    }];

    dispatch(ReportActions.SET_SEARCH_KEY(newSearchKey));
    dispatch(ReportActions.SET_DETAILS(details));
  };

  const openReportModal = () => {
    dispatch(
      GMapModalAC.showModal({
        contentType: GMapModalContent.REPORT_PAGE_FILTERS,
      })
    );
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    if (isModalOpen) {
      dispatch(GMapModalAC.hideModal());
    }

    setIsModalOpen(false);
    setIsFormOpen(false);
  };

  const getIconSrc = (icon) => {
    try {
      return require(`../../assets/svg/bookmark/${icon}`);
    } catch (e) {
      try {
        return require(`../../assets/svg/${icon}`);
      } catch (e) {
        return null;
      }
    }
  };

  const searchTerm = useSelector<iFullStoreState, string>(state => state.general.searchTerm);
  const isBookmarksSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showBookmarksSearch);

  const handleChange = (e) => {
    dispatch(GeneralActions.CHANGE_TERM(e.target.value));
  }

  const showSearch = () => {
    if (!isBookmarksSearchVis) {
      dispatch(GeneralActions.SHOW_BOOKMARKS_SEARCH);
    }
  }

  const hideSearch = () => {
    if (isBookmarksSearchVis) {
      dispatch(GeneralActions.HIDE_BOOKMARKS_SEARCH)
      dispatch(GeneralActions.HIDE_SEARCH); 
      dispatch(GeneralActions.CHANGE_TERM(''))
    }
  }


  return (
    <div className={styles.BookmarksBarWrapper}>
      {(
        <>
          {isBookmarksSearchVis ? (
            <>
              <div className={styles.BookmarksSearchPart}>
                <div className="searchContainer">
                  <SearchIcon className='searchIcon' />
                  <input
                    className={`searchInput ${isBookmarksSearchVis ? "isOpen" : ""}`}
                    type="text"
                    id="search-input"
                    value={searchTerm}
                    onChange={handleChange}
                    onFocus={showSearch}
                    placeholder='Search'
                    autoFocus
                  />
                </div>
              </div>
              <div className={styles.searchPart}>
                <button
                  className={styles.searchButton}
                  onClick={hideSearch}
                >
                  <CloseIcon style={{ fill: '#111111' }} className={styles.searchButton__searchIcon} />
                </button>

              </div>
            </>
          ) : (
            <>
              <button className={styles.ScrollButton} onClick={() => scrollContainer('left')}>
                <Fa icon={faChevronLeft} />
              </button>

              <div className={styles.BookmarksBar} ref={scrollContainerRef}>
                <div className={styles.Bookmark} style={{ width: '36px', padding: '0' }} onClick={() => setIsFormOpen(true)}>
                  <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                    <AddIcon style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '2px'
                    }} />
                  </div>
                </div>

                <div className={styles.Bookmark} onClick={() => handleTodaysHistory()}>
                  <p>Today's History</p>
                </div>

                {bookmarks.map((bookmark) => (
                  <div key={bookmark.id} className={styles.Bookmark} onClick={() => handleBookmarkClick(bookmark)}>
                    {deletingBookmarkId === bookmark.id ? (
                      <div className={styles.BookmarkLoader}>
                        <Fa icon={faSpinner} spin />
                      </div>
                    ) : (
                      <>
                        <img
                          src={getIconSrc(bookmark.icon)}
                          alt={bookmark.icon}
                          style={{ width: '20px', height: '20px' }}
                        />
                        <p>{bookmark.name}</p>
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            minHeight: '16px'
                          }}
                        >
                          <CancelIcon
                            className={styles.BookmarkDelete}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeBookmarkFromFirebase(bookmark.id);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <button className={styles.ScrollButton} onClick={() => scrollContainer('right')}>
                <Fa icon={faChevronRight} />
              </button>
              <div className={styles.searchPart}>
                <button
                  className={styles.searchButton}
                  onClick={showSearch}
                >
                  <SearchIcon className={styles.searchButton__searchIcon} />
                </button>
              </div>
            </>
          )}
        </>
      )}

      {isFormOpen && (
        <Modal>
          <div className={styles.AddBookmarkModal}>
            <span className={styles.AddBookmarkTitle}>Add New Bookmark</span>

            <div className={styles.AddBookmarkInputs}>
              <div className={styles.ToggleContainer}>
                <span>Set for:</span>
                <div className={styles.ToggleInputsContainer}>
                  <div className={styles.ToggleInputContainer}>
                    <input
                      className={styles.ToggleInput}
                      type='radio'
                      id='current'
                      name='current'
                      checked={!isCustomUrl}
                      onClick={() => setIsCustomUrl(!isCustomUrl)}
                    />
                    <label style={!isCustomUrl ? { fontWeight: 600 } : {}}>Current Page</label>
                  </div>
                  <div className={styles.ToggleInputContainer}>
                    <input
                      className={styles.ToggleInput}
                      type='radio'
                      id='custom'
                      name='custom'
                      checked={isCustomUrl}
                      onClick={() => setIsCustomUrl(!isCustomUrl)}
                    />
                    <label style={isCustomUrl ? { fontWeight: 600 } : {}}>Custom Link</label>
                  </div>
                </div>
              </div>

              <div className={styles.AddBookmarkInputContainer}>
                <label htmlFor="bookmarkName">Name</label>
                <input
                  type="text"
                  id="bookmarkName"
                  value={bookmarkName}
                  onChange={(e) => setBookmarkName(e.target.value)}
                  placeholder="Enter bookmark name"
                />
              </div>

              {isCustomUrl && <div className={styles.AddBookmarkInputContainer}>
                <label htmlFor="bookmarkURL">URL</label>
                <input
                  type="text"
                  id="bookmarkURL"
                  value={currentUrl}
                  onChange={(e) => setCurrentUrl(e.target.value)}
                  placeholder="Enter URL"
                />
              </div>}

              <div className={styles.AddBookmarkIconsContainer}>
                <label>Select Icon</label>
                <div className={styles.AddBookmarkIcons}>
                  {BOOKMARK_ICONS.map((icon) => (
                    <img
                      key={icon}
                      className={styles.AddBookmarkIcon}
                      style={pickedIcon === icon ? { border: '1px solid #ff7c02' } : {}}
                      src={require(`../../assets/svg/bookmark/${icon}`)}
                      alt={icon}
                      onClick={() => setIcon(icon)}
                    />
                  ))}
                </div>
              </div>
            </div>

            {errors.length > 0 && (
              <div className={styles.AddBookmarkErrors}>
                {errors.map((error, index) => (
                  <span key={index} className={styles.AddBookmarkError}>
                    {error}
                  </span>
                ))}
              </div>
            )}

            <div className={styles.AddBookmarkBtns}>
              <hr className={styles.HorizontalLine} />
              <button className={styles.CancelBtn} type='button' onClick={handleModalClose}>Cancel</button>
              <button onClick={handleAddBookmark} className={styles.AddBtn} type="submit">Add</button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BookmarksBar;
