import React from 'react';
import {connect, useSelector} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {always, assoc, assocPath, dissocPath, equals, isEmpty, keys, map, omit, pathOr, pipe, prop, toPairs, values, when} from 'ramda';
import {faPlusCircle} from '@fortawesome/fontawesome-free-solid';
import {faTimesCircle} from '@fortawesome/fontawesome-free-regular';

import {history, store} from '../../../stores/store';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {idValArr, makeAudit, targetVal} from '../../../shared/helpers';
import MinimalTile from '../../general/MinimalTile';
import C, {ACL, UserCan} from '../../../shared/constants';
import ModalActions from '../../menus/modal/modal-actions';
import {FenceBodyLayout} from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import {iExtraInfo, iFenceDetails, iFullStoreState, iList, ItemType, UserAuth} from '../../../shared/interfaces';
import * as selectors from '../../../shared/db/tags-labels-selectors';
import * as labelsDb from '../../../shared/db/extra-info-db';
import {BaseComponent} from '../../../shared/BaseComponent';
import {Fa} from '../../elements/fa';
import {Col, Row} from '../../elements/flex';
import {Overlay} from '../../menus/modal/modal';
import {localStorage} from '../../../shared/storage';
import {clientDb, clientStorage} from '../../../shared/firebase';
import { ReactComponent as Camera } from '../../../assets/svg/camera.svg';
import { ReactComponent as Pdf } from '../../../assets/svg/pdf.svg';

// todo. sort out the type differences a bit.
// the type is the thing we are tagging.
// the itemtype is optionally added for fences as the fence type -> fences/{type}/ so type will be fence but itemType might be tag or device

type ILabelRowProps = {
    name: string;
    value: string;
    readOnly?: boolean;
    remove: () => void;
    onChange: (...any) => void;
    idx?: number;
}
const LabelRow = ({name, value, onChange, remove, readOnly, idx}: ILabelRowProps) => (
    <Row style={{borderBottom: `1px solid ${C.mediumGray}`}}>
        <span style={{width: '50%'}}>{name}</span>
        <input type="text" disabled={readOnly} className="mas-input"
            style={{...C.inputCss, margin: '5px 8px', width: '50%'}}
            onChange={pipe(targetVal, onChange)}
            value={value}
        />
        {readOnly ? null : <Fa icon={faTimesCircle} onClick={remove} />}
    </Row>
);

type IProps = {itemType?: ItemType} & RouteComponentProps<{ itemId: string; isFence: string; type: ItemType}>;

type IPropsFromStore = {
    item: {'extra-info': iList<string>};
    userCanDo?: Array<string>;
    fence: iFenceDetails | false;
    fenceId?: string|false;
    allLabels: iList<iExtraInfo>;
    authUser: UserAuth;
};

type IFullProps = IProps & IPropsFromStore
interface iState {
    toSave: iList<string>;
    newOverlay?: boolean;
    currentItemIdx?: string;
}

const mapStateToProps = (state: iFullStoreState, ownProps): IPropsFromStore => ({
    item: selectors.getItemSelector(state, ownProps.match.params),
    fenceId: !!ownProps.match.params.isFence ? ownProps.match.params.itemId : false,
    fence: pathOr(false,['general', 'fences', ownProps.itemType, ownProps.match.params.itemId, 'details'], state),
    userCanDo: state.auth.user!.acl?.can,
    allLabels: state.general.extraInfo,
    authUser: state.auth.user!,
});

class LabelItemComponent extends BaseComponent<IFullProps, iState> {
    imageInput;
    pdfInput;

    constructor(props) {
        super(props);

        this.state = {
            toSave: this.props.item['extra-info'] || {},
        };

        this.imageInput = React.createRef();
        this.pdfInput = React.createRef();
    }

    _saveChanges = async () => {
        const {/*item,*/ itemType, fenceId} = this.props;
        const {isFence, type, itemId} = this.props.match.params;
        console.log('this.state.toSave', type, this.state.toSave);

        // todo: this his hacky - look into replacing this somehow!!!!!!!!!!!!!!!
        if (!!isFence) {
            await labelsDb.updateItemInfoValue(this.props.authUser)(type, `info/${fenceId}`, this.state.toSave);
        } else {
            await labelsDb.updateItemInfoValue(this.props.authUser)(type, itemId, this.state.toSave);
        }

    }

    saveItemAndGetLink = async (device, user, file) => {
        await clientStorage().child(`devices/device-details/${device}/extra-info/${this.state.currentItemIdx}`).put(file);
        const url = await clientStorage().child(`devices/device-details/${device}/extra-info/${this.state.currentItemIdx}`).getDownloadURL();

        return url;
    }

    saveImage = async ({ target }) => {
        const device = this.props.match.params.itemId;
        const user = store.getState().auth.user;

        const url = await this.saveItemAndGetLink(device, user, target.files[0]);

        await clientDb().update(makeAudit(user, {
            [`devices/device-details/${device}/extra-info/${this.state.currentItemIdx}`]: {
                'url': url,
                'isPhoto': true
            }
        }));

        this.setState(assocPath(['toSave', this.state.currentItemIdx], {
            'url': url,
            'isPhoto': true
        }), this._saveChanges.bind(this));
    };

    savePdf = async ({ target }) => {
        const device = this.props.match.params.itemId;
        const user = store.getState().auth.user;

        const url = await this.saveItemAndGetLink(device, user, target.files[0]);

        await clientDb().update(makeAudit(user, {
            [`devices/device-details/${device}/extra-info/${this.state.currentItemIdx}`]: {
                'url': url,
                'isPdf': true
            }
        }));

        this.setState(assocPath(['toSave', this.state.currentItemIdx], {
            'url': url,
            'isPdf': true
        }), this._saveChanges.bind(this));
    };


    _newRow = () => this.setState(assoc('newOverlay', true))

    _setLabel = (idx, value: string) => this.setState(assocPath(['toSave', idx], value))

    cameraClick = (idx) => {
        this.setState(prevState => {
            return {
                ...prevState,
                currentItemIdx: idx
            }
        })
        this.imageInput.current.click();
    }

    pdfClick = (idx) => {
        this.setState(prevState => {
            return {
                ...prevState,
                currentItemIdx: idx
            }
        })
        this.pdfInput.current.click();
    }

    removeElement = async (idx) => {
        this.setState(dissocPath(['toSave', idx]))

        const device = this.props.match.params.itemId;
        const user = store.getState().auth.user;

        await clientDb().update(makeAudit(user, {
            [`devices/device-details/${device}/extra-info/${idx}`]: null
        }));
    }

    debugRender = () => {
        const {fence, allLabels, userCanDo, item} = this.props;
        const {type, itemId, isFence} = this.props.match.params;
        const {newOverlay = false, toSave} = this.state;
        const isSubAdmin = localStorage.get('login-init-be-token')[UserCan.SUB_ADMIN];
        const subUsers = isSubAdmin ? localStorage.get('login-init-user')['subUsers'] : [];

        const DynamicLayout: any = !!isFence ? FenceBodyLayout : DashboardBlock;
        const DynamicHeader = !!isFence ? <div><ActionRow title={(fence || {} as any).name} canBack actions={[]} /></div> : <ModalHeader title="Add Extra Info" />;

        const needsSave = !equals(this.state.toSave, item['extra-info'] || {});

        const overlay = !newOverlay ?
            null :
            <LabelSelectorOverlay
                onSelected={(key) => this.setState(pipe(assocPath(['toSave', key], ''), assoc('newOverlay', false)))}
                possible={omit(keys(toSave), allLabels)}
                close={this.stateSetter({newOverlay: false})}
            />;

        return (
            <DynamicLayout
                mapOnly={!itemId || !type}
                overlay={overlay}
                modalActions={!needsSave ? false : <ModalActions isFence hidden={!needsSave} buttons={[
                    {click: history.goBack, title: 'Cancel'},
                    {click: this._saveChanges, title: 'Update', primary: true},
                ]}/>}
            >
                {DynamicHeader}

                <div style={!!isFence ? {padding: 8} : { } }>
                    {!isFence ? null :
                        <MinimalTile>
                            Change Labels
                        </MinimalTile>
                    }

                    <MinimalTile>
                        {toPairs(toSave).filter(([idx]) => idx in allLabels).map(([idx, value]) => {
                              const isPhoto = typeof (value) === 'object' && value.hasOwnProperty('isPhoto') && value['isPhoto'];
                              const isPdf = typeof (value) === 'object' && value.hasOwnProperty('isPdf') && value['isPdf'];
                              const readOnly = allLabels[idx]?.details?.readOnly;
                              const displayValue = (isPhoto || isPdf) ? '' : value;

                              const onChangeHandler = (v) => {
                                  this.setState(assocPath(['toSave', idx], v), this._saveChanges.bind(this));
                              }

                              return (
                                <Row style={{borderBottom: `1px solid ${C.mediumGray}`}}>
                                    <span style={{width: '50%'}}>{allLabels[idx].details.name}</span>
                                    <input
                                      type='text'
                                      disabled={readOnly}
                                      className='mas-input'
                                      style={{...C.inputCss, margin: '5px 8px', width: '50%'}}
                                      onChange={pipe(targetVal, onChangeHandler)}
                                      value={displayValue}
                                    />
                                    {!readOnly && <Camera
                                      style={{height: '20px', cursor: 'pointer', marginRight: '10px'}}
                                      onClick={() => this.cameraClick(idx)}
                                    />}
                                    {!readOnly && <Pdf
                                      style={{height: '20px', cursor: 'pointer', marginRight: '10px'}}
                                      onClick={() => this.pdfClick(idx)}
                                    />}
                                    {!readOnly && <Fa icon={faTimesCircle} onClick={() => this.removeElement(idx)}/>}
                                </Row>
                              )
                          }
                        )}
                        <input
                          name='image'
                          type='file'
                          accept='image/*'
                          onChange={this.saveImage}
                          style={{display: 'none'}}
                          ref={this.imageInput}
                        />
                        <input
                          name='document'
                          type='file'
                          accept='.pdf'
                          onChange={this.savePdf}
                          style={{display: 'none'}}
                          ref={this.pdfInput}
                        />
                        <Row style={{paddingTop: 8}}>
                            {(ACL.check(UserCan.LABEL_THINGS, userCanDo) || (isSubAdmin && subUsers.includes(itemId))) ?
                              <Fa icon={faPlusCircle} style={{color: C.primaryColor, fontSize: 17}}
                                  onClick={this._newRow}/> : null
                            }
                            {(ACL.check(UserCan.LABEL_THINGS, userCanDo) || (isSubAdmin && subUsers.includes(itemId))) ?
                              (needsSave ?
                                  <button disabled style={{cursor: 'not-allowed'}}
                                          title="Save changes before adding new label"
                                          className="btn btn-primary btn-xs">Manage Labels</button> :
                                  <Link className="btn btn-primary btn-xs" to="/extra-info">New Label</Link>
                              ) : null
                            }
                        </Row>
                    </MinimalTile>
                </div>

                <div style={{paddingBottom: needsSave ? 35 : 0}}>&nbsp;</div>

            </DynamicLayout>
        );
    }
}

export default connect(mapStateToProps)(LabelItemComponent);

interface iLabelSelectorOverlayProps {
    possible: iList<iExtraInfo>;
    onSelected: (id: string) => any;
    close: () => any;
}

const LabelSelectorOverlay = ({possible, onSelected, close}: iLabelSelectorOverlayProps) => {
    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user);
    const isSuperAdmin = authUser.beToken['do-anything'];

    return (
      <Overlay close={close} header="Choose a label">
          <Col>
              {pipe(
                (x) => x,
                map(prop('details')),
                map((l: {id; name; isHidden; author}) => {
                    const userHasPermission = l.isHidden ? (isSuperAdmin || l.author === authUser.uid) : true;

                    if (!userHasPermission) {
                        return null;
                    }

                    return (
                      <button
                        key={l.id}
                        className="btn btn-outline-primary"
                        style={{marginBottom: 8}}
                        onClick={pipe(always(l.id), onSelected)
                        }>{l.name}
                      </button>
                    )
                  }
                ) as any,
                values,
                when(isEmpty, always(<p>No more labels found</p>)) as any
              )(possible)}
          </Col>
      </Overlay>
    )
};

