// TODO
//  THIS IS A DUPLICATED VERSION OF TAGS PAGE,
//  AS WE NEED TO IMPLEMENT SOME CHANGES IN THE TAGS PAGE, BUT KEEP 'EXTRA INFO' PAGE WITH SAME LOGIC
import React, { useEffect, useState } from 'react';

import "./extra-info.scss";

import * as extraInfoDb from '../../../shared/db/extra-info-db';
import * as tagsDb from '../../../shared/db/tags-db';

import { ALL_DEVICES_TAG, getAllDevicesTag, idValArr, vals, handleSearch} from '../../../shared/helpers';
import C, { ACL, UserCan } from '../../../shared/constants';
import { ItemType, UserAuth, iFullStoreState, iList, iTagInfoBase } from '../../../shared/interfaces';
import { Set, is } from 'immutable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { hideAllTags, tagShowToggle } from '../../../stores/reducers/tagsDevicesMap/AC';

import { BaseComponent } from '../../../shared/BaseComponent';
import DashboardBlock from '../../DashboardBlock';
import FavoriteBLL from "../../BLL/FavoriteBLL";
import {faPlusCircle} from '@fortawesome/fontawesome-free-solid';
import ModalHeader from '../../menus/modal/modal-header';
import {Overlay} from '../../menus/modal/modal';
import {StandardItem} from '../../general/index';
import {keyGen} from '../../../shared/firebase';
import { selectDevices } from "../../../stores/reducers/devicesData/selector";
import { updateFilter } from '../../../shared/db/filters-db';
import { useRedux } from '../../../states/redux-state';
import GoogleMap from "react-google-maps/lib/components/GoogleMap";
import moment from 'moment';
import {get} from 'lodash';
import {equals} from 'ramda';

const ONE_DAY = 86_400_000; // 24 hours in milliseconds

interface iProps {
  authUser: UserAuth;
  userCanDo;
  items: iList<iTagInfoBase<any>>;
  isTagType: boolean;
}

const AllTagsInfoPage = (props: iProps) => <AllTagsInfo {...props} />

const AllTagsInfo = (props: iProps) => {
  const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user, (l, r) => equals(l, r));
  const filters = useSelector<iFullStoreState, object>((state) => state.general.filters);
  const dispatch = useDispatch();
  const { items, isTagType, userCanDo } = props;
  const [searchParam] = useState(['name']);
  const [searchQueryValue, setSearchQueryValue] = useState('');
  const [createTag, setCreateTag] = React.useState(false);
  const [activeLetter, setActiveLetter] = React.useState('');
  const devices = useSelector(selectDevices);
  const devicesIds = [...devices.keys()];
  const [filter, setFilter] = React.useState(false);
  const pageTitle = process.env.REACT_APP_TAGS_TAB_NAME || 'Tags';
  const showedTagsIds = useSelector<iFullStoreState, Set<string>>(
    (state) => state.tagsDevicesMap.showedTagsIds,
    (left, right) => is(left, right),
  );

  const mapRef = useSelector<
    iFullStoreState,
    React.RefObject<GoogleMap> | null
  >((state) => state.gmap.mapRef());

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);

  const [tagsActualState, setTagsActualState] = useState({});

  const filteredTags = Object.keys(items).reduce((acc, rec) => {
    if (get(items[rec], `instances.allowed-see.person.${authUser.uid}`)) {
      return { ...acc, [rec]: items[rec] };
    }

    return acc;
  }, {});

  const getFilters = () => {
    return ACL.check(UserCan.DO_ANYTHING, userCanDo) || ACL.check(UserCan.SUB_USER, userCanDo) ? items : filteredTags;
  };

  const devicesLastPing = useRedux((s) => s.devicesData.devicesLastPing).valueSeq().toArray()
    .filter((it) => it !== null && moment().diff(it.time) < ONE_DAY).map((it) => it.device);

  const newItems = { ...getFilters(), ...getAllDevicesTag(devicesIds) };

  const toggleTag = (tagId: string) => {
    if (filter) {
      dispatch(tagShowToggle(tagId, devicesLastPing, filter));
    } else if (tagId === ALL_DEVICES_TAG) {
      dispatch(tagShowToggle(tagId, devicesIds));
    } else {
      let markerBounds = new google.maps.LatLngBounds();

      devicesMarker.forEach((marker) => {
        markerBounds.extend(
          new google.maps.LatLng(
            marker.coordinates.location.lat,
            marker.coordinates.location.lng
          )
        );
      });

      mapRef.current.fitBounds(markerBounds);
      dispatch(tagShowToggle(tagId));
    }
  };

  useEffect(() => {
    setTagsActualState(handleSearch(newItems, searchParam, searchQueryValue));
  }, [searchQueryValue, items])

  const removeAllTags = () => {
    dispatch(hideAllTags());
  };

  useEffect(() => removeAllTags(), []);

  useEffect(() => {
    if (filters) {
      Object.keys(filters).forEach((filterId) => {
        updateFilter(authUser)(filterId, { ...filters[filterId], selected: false });
      });
    }
  }, [showedTagsIds]);

  return (
    <DashboardBlock
      overlay={
        !createTag ?
          undefined :
          <NewTagOverlay
            isTagType={isTagType}
            close={() => setCreateTag(false)}
          />
      }>
      <div style={{ height: '100%' }}>
        <ModalHeader
          title={isTagType ? pageTitle : 'Extra Info'}
          action={
            showedTagsIds.size ?
              null :
              {
                disabled:
                  ACL.check(UserCan.CREATE_TAGS, userCanDo) ?
                    false :
                    'Insufficient Privileges',
                click: () => setCreateTag(true),
                fa: faPlusCircle, title: 'Add new tag'
              }
          }
        />

        <div style={{ textAlign: 'center' }}>
          <input className="search-input" type="input" placeholder="Search" onChange={(e) => setSearchQueryValue(e.target.value) } value={searchQueryValue}/>
        </div>

        {/* Letters of alphabet */}
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {C.alphanums.concat('').map((letter) => (
            <AtoZ
              letter={letter === '' ? '(all)' : letter}
              key={letter}
              active={activeLetter == letter}
              disabled={letter !== '' && !tagsHasLetter(getFilters(), letter)}
              click={(_) => {
                if (letter !== '' && !tagsHasLetter(getFilters(), letter)) return;

                { setActiveLetter(letter); }
              }}
            />
          ))}
        </div>

        <div style={{ flexShrink: 1, overflow: 'auto' }}>
          {C.alphanums
            .filter((letter) => !activeLetter || activeLetter == letter)
            .map((letter) =>
              <ListTagsForLetter
                showedTagsIds={showedTagsIds}
                toggleTag={toggleTag}
                key={letter}
                user={props.authUser}
                letter={letter}
                tags={tagsActualState}
                isTagType={isTagType}
              />)}
        </div>

        {<span style={{ marginTop: 20, paddingBottom: 40 }}>
                    <input
                      type='checkbox'
                      name='Filter'
                      checked={filter}
                      onChange={() => setFilter(!filter)}
                      style={{ marginRight: 10 }}
                    />
                    Only devices active in the last 24 hours
                </span >}

        {!showedTagsIds.size ? null :
          <div>
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
              <span />
              <span>Shown on map</span>
              <span
                style={{
                  color: 'darkred',
                  cursor: 'pointer',
                  marginBottom: -3
                }}
                onClick={removeAllTags}
              >
                                <i className="fas fa-times" />
                            </span>
            </div>
            <div style={{ padding: '10px 0', borderTop: '1px dotted lightgray', backgroundColor: 'white' }}>
              {showedTagsIds.map((id) => <StandardItem
                style={{ marginLeft: 10, display: 'inline-block' }}
                view={() => toggleTag(id)}
                isViewed
                remove={() => toggleTag(id)}
                key={id}
                itemId={id}
                displayName={newItems[id].details.name}
              />)}
            </div>
          </div>}
      </div>
    </DashboardBlock>
  );
};

@connect((s: iFullStoreState) => ({
  authUser: s.auth.user!,
}))
class NewTagOverlay extends BaseComponent<{ close; isTagType }, { value, isHidden }, { authUser: UserAuth }> {
  state = {
    value: '',
    isHidden: false
  }

  __submit = async (e) => {
    const { value, isHidden } = this.state;
    const { dispatch, close, isTagType } = this.props as any;

    if (value.length) await (isTagType ? tagsDb : extraInfoDb).update(this.fullProps.authUser)(keyGen(), { name: value, icon: {svgIconName: 'micro', type: 1}, color: '#FFF', isHidden, owner: this.fullProps.authUser.uid });

    close();
  }

  debugRender = () => {
    const { close, isTagType } = this.props as any;
    const { value } = this.state;

    return (
      <Overlay close={close} >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Please enter a {isTagType ? 'tag' : 'label'} name</p>

          <input
            type="text"
            autoFocus
            className="mas-input"
            style={C.inputCss}
            onChange={({ target: { value } }) => this.setState({ value })}
            value={value}
          />

          {!isTagType && <div style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column'}}>
            <label htmlFor="isHidden">Is label hidden?</label>
            <input
              type="checkbox"
              name="isHidden"
              checked={this.state.isHidden}
              onChange={(e) => this.setState({isHidden: e.target.checked})}
            />
          </div>}

          <button
            disabled={!value.length}
            style={{marginTop: 5}}
            className="btn btn-sm btn-primary"
            onClick={this.__submit}
          >
            Submit
          </button>
        </div>
      </Overlay>
    );
  }
}

const ListTagsForLetter = ({ letter, tags, isTagType, user, showedTagsIds, toggleTag }) => {
  const authUser = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
  const isSuperAdmin = authUser.beToken['do-anything'];

  const filtered = idValArr(tags as iList<iTagInfoBase<any>>)
    .filter((tag) => {
      const letterFilter = tag.val.details.name && tag.val.details.name[0].toUpperCase() == letter;
      const userHasRule = tag.val.details.isHidden ? isSuperAdmin || tag.val.details.author === authUser.uid : true;
      return letterFilter && userHasRule;
    });

  const deleteItem = (id: string) => {
    if (showedTagsIds.has(id)) toggleTag(id);

    isTagType ?
      tagsDb.deleteTags(user)(tags[id]) :
      extraInfoDb.deleteLabels(user)([id]);
  };

  if (!filtered.length) return null;

  return (
    <div style={{ display: 'flex', marginBottom: 5 }}>
      <div style={{ textAlign: 'center', width: 25 }}>{letter}</div>
      <div className="tags-list" style={{ display: 'flex', flexDirection: 'column' }}>
        {filtered.map(({ val: tag, id }) =>
          <div key={id} className="tags-list__item">
            <FavoriteBLL favoriteId={id} favoriteType={ItemType.tag} />
            <StandardItem
              view={() => toggleTag(id)}
              isViewed={!!showedTagsIds.size && showedTagsIds.has(id)}
              showBell={tag.details.isAlertType}
              link={isTagType ? `/tag/${id}` : false}
              key={id}
              remove={!!showedTagsIds.size ? false : (id) => deleteItem(id)}
              itemId={id}
              displayName={tag.details.name}
              userId={user.uid}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const tagsHasLetter = (items: iList<iTagInfoBase<any>>, letter: string) =>
  vals(items)
    .some((tag) => {/* debugger; */return !!tag.details.name && tag.details.name[0].toUpperCase() === letter.toUpperCase();});

const AtoZ = ({ letter, active = false, disabled = false, click }) => {
  let color = 'inherit';

  if (active) color = C.primaryColor.toString();

  if (disabled) color = C.mediumGray.toString();

  return (
    <span
      className="btn-link"
      onClick={click}
      style={{
        textDecoration: 'none',
        margin: 2,
        fontWeight:
          active ?
            'bold' :
            disabled ?
              'lighter' :
              'normal',
        fontSize: 10,
        color: color,
        cursor: disabled ?
          'default' :
          'pointer'
      }}
    >
            {letter}
        </span>
  );
};

const mapStateToProps = (state: iFullStoreState, ownProps) => {
  const authUser = state.auth.user;
  const userCanDo = state.auth?.user?.acl?.can ?? [];
  let items = {};
  let isTagType;

  if (ownProps.match.path.indexOf('/tags') === 0) {
    isTagType = true;
    items = (state.general.tags || {});
  }

  if (ownProps.match.path.indexOf('/extra-info') === 0) {
    isTagType = false;
    items = (state.general.extraInfo || {});
  }

  return {
    authUser,
    items,
    isTagType,
    userCanDo,
  };
};

export default connect(mapStateToProps)(AllTagsInfoPage);