import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { friendlyDiff, minutesToFriendly } from '../../../../../shared/helpers';
import { iTrip } from '../../../../../shared/interfaces';
import { TripTravelRow } from '../trip-travel-row';
import { LabelOrPersonRow } from './person-label-row';
import { friendlySpeed, friendlyDist, friendlyMilesPer } from '../../../../../shared/helpers';
import { getTravelReportFieldVisibility } from '../../../../../shared/db/extra-info-db';

interface iProps { row: iTrip, hiddenFields?: any, device?: any }

enum TravelReportField {
    Duration = 'duration',
    Idle = 'idle',
    Stopped = 'stopped',
    Safety = 'safety',
    Person = 'person',
    MaxSpeed = 'max_speed',
    AvgSpeed = 'avg_speed',
    Distance = 'distance',
    Consumption = 'consumption',
    Label = 'label'
}

export const TripAndTravelCell = ({ row, hiddenFields, device }: iProps,) => {
    let counter = 0;

    const defaultFieldVisibility: Record<TravelReportField, boolean> = {
        [TravelReportField.Duration]: false,
        [TravelReportField.Idle]: false,
        [TravelReportField.Stopped]: false,
        [TravelReportField.Safety]: false,
        [TravelReportField.Person]: false,
        [TravelReportField.MaxSpeed]: false,
        [TravelReportField.AvgSpeed]: false,
        [TravelReportField.Distance]: false,
        [TravelReportField.Consumption]: false,
        [TravelReportField.Label]: false,
    };

    const [fieldVisibility, setFieldVisibility] = useState<Record<TravelReportField, boolean>>(defaultFieldVisibility);

    const fetchTravelReportFieldVisibility = async () => {
        const value = await getTravelReportFieldVisibility();

        setFieldVisibility(prev => ({
            ...defaultFieldVisibility,
            ...value
        }));
    }

    useEffect(() => {
        fetchTravelReportFieldVisibility()
    }, [])

    const isVisible = (field: TravelReportField) => fieldVisibility[field] !== true;

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TripTravelRow
            visible={!hiddenFields['duration'] && isVisible(TravelReportField.Duration)}
            label="Duration"
            val={friendlyDiff(moment(row.endDate), moment(row.startDate))}
            odd={(hiddenFields['duration'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['idle'] && isVisible(TravelReportField.Idle)}
            label="Idle"
            val={minutesToFriendly(row.idleMinutes)}
            odd={(hiddenFields['idle'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['stopped'] && isVisible(TravelReportField.Stopped)}
            label="Stopped"
            val={minutesToFriendly(row.stopMinutes)}
            odd={(hiddenFields['stopped'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['safety'] && isVisible(TravelReportField.Safety)}
            label="Safety"
            val={row.safetyPercent ? row.safetyPercent + '%' : 'NA'}
            odd={(hiddenFields['safety'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['person'] && isVisible(TravelReportField.Person)}
            label="Person"
            val={<LabelOrPersonRow row={row} type="trip" field="personId" />}
            odd={(hiddenFields['person'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['maxSpeed'] && isVisible(TravelReportField.MaxSpeed)}
            label="Max Speed"
            val={friendlySpeed(row.maxSpeed, device?.units)}
            odd={(hiddenFields['maxSpeed'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['avgSpeed'] && isVisible(TravelReportField.AvgSpeed)}
            label="Avg. Speed"
            val={`${row.averageSpeed ? friendlySpeed(row.averageSpeed, device?.units) : 'NA'}`}
            odd={(hiddenFields['avgSpeed'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['distance'] && isVisible(TravelReportField.Distance)}
            label="Distance"
            val={friendlyDist(row.miles, device?.units)}
            odd={(hiddenFields['distance'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['consumption'] && isVisible(TravelReportField.Consumption)}
            label="Consumption"
            val={friendlyMilesPer(row.mpg, device?.units)}
            odd={(hiddenFields['consumption'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow visible={!hiddenFields['label'] && isVisible(TravelReportField.Label)}
            label="Label"
            val={<LabelOrPersonRow
                row={row} type="trip" field="label" />}
            odd={(hiddenFields['label'] ? 0 : counter++) % 2 === 0}
        />
    </div>
}
