import { toast } from "react-toastify";
import instance from "./instance";
import {IS_MOBILE_APP} from "../shared/constants";
import moment from "moment";
import {HttpStatus} from "../shared/interfaces";

interface IStatusCamera {
  serial?: string,
  firebaseDeviceId?: string
}
declare let window: any;

export const checkStatusCamera = async ({ serial, firebaseDeviceId }: IStatusCamera) => {
    try {
        const deviceQuery = firebaseDeviceId ? `firebaseDeviceId=${firebaseDeviceId}` : `uniqueIds=${serial}`;
        const response = await instance.get(`/api/device/cameras-info?${deviceQuery}`);

        if(response.status === HttpStatus.OK){
            const { data: { data } } = response;
            return { onlineStatus: data[0].onlineState, channels: data[0]?.channels, cameraTime: data[1] };
        }else {
            return { onlineStatus: false, channels: [] };
        }
    } catch ({ message }) {
        console.error(`ERROR: online status ${ message }`);
        return { onlineStatus: false, channels: [] };
    }
};

export const renameChannel = async (body) => {
    const URL = `/api/device/rename-channel`;

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({ message }){
        console.error(message);
    }
};

export const getChannels = async ({serial = undefined, extraChannelsIds, firebaseDeviceId = undefined}) => {
    // Media type. 1-flv; 2-hls. The default value is flv
    // Stream type. 1-main; 2-sub stream
    const deviceQuery = firebaseDeviceId ? `firebaseDeviceId=${firebaseDeviceId}` : `uniqueId=${serial}`;
    const isIosSafari = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // @ts-ignore
    const mediaType = (IS_MOBILE_APP && window.cordova.platformId === 'ios') || isIosSafari ? 2 : 1;
    const hasExtraChannels = extraChannelsIds?.length;
    const formmatedChannelsIds = extraChannelsIds.length && ',' + extraChannelsIds.join(',');
    const URL = `/api/device/camera-stream-info?${deviceQuery}&channels=1,2${hasExtraChannels ? formmatedChannelsIds : ''}&mediaType=${mediaType}`;

    try{
        const { data } = await instance.get(URL);
        return data
    }catch ({ message }){
        toast.error(message)
        return Promise.reject(message)
    }
};

export const getChannelsMore = async ({firebaseTagId, orgKey}) => {
    const isIosSafari = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // @ts-ignore
    const mediaType = (IS_MOBILE_APP && window.cordova.platformId === 'ios') || isIosSafari ? 2 : 1;

    const URL = `/api/device/camera-stream-infos?channels=1,2&mediaType=${mediaType}`;

    const body = {
        firebaseTagId,
        orgKey
    };

    try {
        return await instance.post(URL, body);
    } catch (error) {
        if (error.response.status !== HttpStatus.NOT_FOUND) {
            toast.error(error.message);
        }
        throw error;
    }
};

export const getAvailableTime = async ({serial, firebaseDeviceId}: IStatusCamera) => {
    const deviceQuery = firebaseDeviceId ? `firebaseDeviceId=${firebaseDeviceId}` : `uniqueId=${serial}`;

    const URL = `/api/device/get-time-info?${deviceQuery}`;

    try{
        const { data } = await instance.get(URL);
        return data;
    }catch ({message}){
        console.error(message)
    }
}

export const updateAvailableTime = async ({serial = undefined, playedSeconds, firebaseDeviceId = undefined}) => {
    const URL = `/api/device/time-update`;

    const body: {
        used_time_in_seconds: number,
        firebaseDeviceId?: string,
        uniqueId?: string,
    } = {
        used_time_in_seconds: playedSeconds,
    }

    if(firebaseDeviceId){
        body.firebaseDeviceId = firebaseDeviceId;
    }
    else{
        body.uniqueId = serial;
    }

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({message}){
        console.error(message)
    }
}

export const buyTime = async ({serial = undefined, tariff, cardId, firebaseDeviceId = undefined}) => {
    const URL = `/api/device/buy-time`;

    const body: {
        uniqueId?: string,
        firebaseDeviceId?: string,
        tariff: any,
        payment_method_id: any
    } = {
        tariff,
        payment_method_id: cardId
    }

    if(firebaseDeviceId){
        body.firebaseDeviceId = firebaseDeviceId;
    }
    else{
        body.uniqueId = serial;
    }

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({message}){
        toast.error(message)
        console.error(message)
    }
}

export const existingHistoryDate = async ({serial = undefined, currentMonth, firebaseDeviceId = undefined}) => {
    try {
        const deviceQuery = firebaseDeviceId ? `firebaseDeviceId=${firebaseDeviceId}` : `uniqueId=${serial}`;

        const { data } = await instance.get(`api/device/calendar-month?${deviceQuery}&month=${currentMonth}`);
        return data
    } catch({message}) {
        console.error("ERR => takeExistingHistoryDate", message);
    }
}

export const dayChooseByTimestamp = async (props) => {
    const deviceQuery = props.firebaseDeviceId ? `firebaseDeviceId=${props.firebaseDeviceId}` : `uniqueId=${props.serial}`;

    const isIosSafari = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // @ts-ignore
    const isCordovaIosDevice = (IS_MOBILE_APP && window?.cordova?.platformId === 'ios') || isIosSafari;

    const selectedDate = `${props.convertDate}${props.alertTime !== null ? ` ${props.alertTime}` : ''}`;
    const alertTimestamp = isCordovaIosDevice ? new Date(selectedDate.replace(/-/g, "/")).getTime() : new Date(selectedDate).getTime();

    const timeZone = new Date().getTimezoneOffset() * 60000;
    const timestampDate = new Date(props.convertDate).getTime() + timeZone;

    try {
        const response = await instance.get(`api/device/calendar-day-by-timestamp?${deviceQuery}&startOfDay=${timestampDate}${props.alertTime == null ? "" :`&startEvent=${alertTimestamp}`}`);
        const {  data, code, message } = response.data;

        if((code && code == HttpStatus.OK) || data.length) {
            return data
        } else {
            toast.error(message || 'Something went wrong', {position: toast.POSITION.TOP_RIGHT});
        }
    } catch ({message}) {
        console.error("ERR => dayChooseByTimestamp", message);
    }
};

// export const dayChoose = async ({ serial, convertDate, alertTime }) => {
//     const selectedDate = `${convertDate}, ${alertTime}`;
//     const timestampDate = new Date(selectedDate).getTime();

//     try {
//         const { data } = await instance.get(`api/device/calendar-day?uniqueId=${serial}&day=${convertDate}${alertTime && `&timestamp=${timestampDate}`}`);
//         return data
//     } catch ({message}) {
//         console.error("ERR => dayChoose", message);
//     }
// };

export const getHistoryVideoApi = async ({ selectedHistoryChannel, uniqueId = undefined, firebaseDeviceId = undefined, playedSeconds, alertTimestamp }) => {
    const {
        beginTime,
        endTime,
        storageType,
        streamType,
        timeZoneOffset,
        vodFileType,
        channel,
    } = selectedHistoryChannel;
    const compareTimestamps = beginTime == alertTimestamp;

    const settingBeginTime = () => {
        let time;

        if(playedSeconds === 0 && compareTimestamps) {
            time = moment(beginTime).unix() * 1000;
        } else {
            const startTime = moment(beginTime).add(playedSeconds, 'seconds');
            const continueTime = moment(startTime).unix() * 1000;
            // const alertStartEventTimestamp = (alertTimestamp - beginTime) + beginTime;
            const timestamp = alertTimestamp > 0 ? alertTimestamp : continueTime;
            time = timestamp;
        }

        return time;
    }
    const setStartTime = settingBeginTime();
    const isIosSafari = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // @ts-ignore
    const mediaProtocolType = (IS_MOBILE_APP && window.cordova.platformId === 'ios') || isIosSafari ? 'HLS' : 'FLV';

    try {
        const deviceQuery = firebaseDeviceId ? `firebaseDeviceId=${firebaseDeviceId}` : `uniqueId=${uniqueId}`;

        const { data } = await instance.get(
          `api/device/video-record?${deviceQuery}&channels=${channel}&startTime=${setStartTime}&endTime=${endTime}&streamType=${streamType}&storeType=${storageType}&mediaProtocol=${mediaProtocolType}`
        );
        return data
    } catch ({message}) {
        console.error("ERR => getHistoryVideo", message);
        toast.error('No files are available. null response', { position: toast.POSITION.TOP_RIGHT });
    }
};

export const closeHistorySession = async ({ session }) => {
    try {
        const { data } = await instance.post(`api/device/close-video-record`, {
          opSession: session,
        });
        return data
    } catch ({message}) {
        console.log("ERR => closeHistorySession", message);
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
    }
}

export const getBillingCards = () => {
        return instance
        .get(`api/billing/cards`)
        .then((res) => res.data)
        .catch((err) => {
            console.log("ERROR: plaid/braintree token or cards =>", err);
            const { message } = err;
            return Promise.reject(message);
        });
}

export const getDownloadAlert = async (alarmId) => {
    try {
      const res = await instance.get(`api/device/get-downloading-link-from-storage?alarmId=${alarmId}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

export const getDownloadPoint = async (pointId) => {
    try {
        const res = await instance.get(`api/device/get-point-link?firebase-point-id=${pointId}`);

        return res.data;
    } catch (error) {
        console.error(error);
    }
};

export const postNewAlarmId = async (formData) => {
    const {alarmId, channel, file_type, stream_type, before, after} = formData;
    try {
        const res = await instance.post(`api/device/add-alarm`, {
            "alarm_id": alarmId,
            channel,
            file_type,
            stream_type,
            before,
            after
        });
        return res.data;
    } catch ({ message }) {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        return Promise.reject(message);
    }
}

export const postNewPointId = async (formData) => {
    const {pointId, deviceId, time, channel, stream_type, before, after} = formData;

    try {
        const res = await instance.post(`api/device/add-adplus-point-task`, {
            "firebase_device_id": deviceId,
            "firebase_point_id": pointId,
            "created_at": (time.unix()).toString(),
            before: before.toString() || '0',
            after: after.toString() || '0',
            channel,
            stream_type
        });

        return res.data;
    } catch ({ message }) {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        return Promise.reject(message);
    }
}
