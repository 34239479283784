import Portal from '../Portal';
import React from 'react';
import styles from './ModalImage.module.scss';
import {classnames} from "@bem-react/classnames";

export default function ModalImage(props) {
  const {children, onClickHandler} = props

  return (
    <Portal>
      <div className={styles.modal__overlay} onClick={onClickHandler}>
        <div className={classnames(styles.modal__block, props?.className)} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </Portal>
  )
}
