import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { is } from 'immutable';
import { Link } from 'react-router-dom';

import DashboardBlock from '../../DashboardBlock';
import { iFullStoreState } from '../../../shared/interfaces';
import { DevicesDetailsContainer } from "../../../stores/reducers/devicesData";
import { useRedux } from '../../../states/redux-state';
import { withSentry } from '../../../shared/hoc/withSentry';
import { setShowedDevices } from '../../../shared/helpers';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import NewDeviceItem from "./DeviceItem";
import DeviceListItem from "./DeviceListItem";
import { clientDb } from '../../../shared/firebase';
import { localStorage } from '../../../shared/storage';
import styles from './device-page.module.scss';
import classNames from 'classnames';
import { DashboardSize } from '../../../stores/reducers/dashboardInfo';
import ModalHeaderComponent from '../../menus/modal/modal-header';

enum ViewMode {
  GRID = 'grid',
  LIST = 'list',
}

const DevicesPage = () => {
  enum SortOptions {
    ASC = 'asc',
    DESC = 'desc',
    LAST_REPORT_ASC = 'last_report_asc',
    LAST_REPORT_DESC = 'last_report_desc',
  }
  const sortOptions = [
    { value: SortOptions.ASC, label: 'Device Name ASC' },
    { value: SortOptions.DESC, label: 'Device Name DESC' },
    { value: SortOptions.LAST_REPORT_ASC, label: 'Last Report ASC' },
    { value: SortOptions.LAST_REPORT_DESC, label: 'Last Report DESC' },
  ];
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  const handleSortChange = (selectedOption) => setSortOption(selectedOption);

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );

  const devicesArray = devices.valueSeq().toArray();

  const devicesLastPingsRaw = useRedux((s) => s.devicesData.devicesLastPing);

  const pageTitle = process.env.REACT_APP_DEVICES_TAB_NAME || 'Devices';

  const naturalSort = (a, b, order = "asc") => {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
  
    const cleanedA = a.name?.trim().replace(/\s+/g, " ") || "";
    const cleanedB = b.name?.trim().replace(/\s+/g, " ") || "";
  
    const comparison = collator.compare(cleanedA, cleanedB);
  
    return order === "asc" ? comparison : -comparison;
  };

  const compareByNameAscending = (a, b) => naturalSort(a, b, "asc");

  const compareByNameDescending = (a, b) => naturalSort(a, b, "desc");

  const compareByLastReport = (a, b) => {
    const aLastPing = devicesLastPingsRaw.get(a.id);
    const bLastPing = devicesLastPingsRaw.get(b.id);
    const aTime = aLastPing?.time?.unix() ?? null;
    const bTime = bLastPing?.time?.unix() ?? null;

    if (sortOption.value === SortOptions.LAST_REPORT_DESC) {
      if (aTime !== null && bTime !== null) return aTime - bTime;
    } else {
      if (aTime !== null && bTime !== null) return bTime - aTime;
    }

    if (aTime !== null) return -1;
    if (bTime !== null) return 1;
    return 0;
  };

  const sortArray = () => {
    switch (sortOption.value) {
      case SortOptions.DESC:
        return devicesArray.sort(compareByNameDescending).map(({ id }) => id);
      case SortOptions.ASC:
        return devicesArray.sort(compareByNameAscending).map(({ id }) => id);
      case SortOptions.LAST_REPORT_ASC:
      case SortOptions.LAST_REPORT_DESC:
        return devicesArray.sort(compareByLastReport).map(({ id }) => id);
      default:
        return devicesArray.map(({ id }) => id);
    }
  }

  const sortedArray = sortArray();

  const dispatch = useDispatch();

  const devicesLastPings = devicesLastPingsRaw
    .valueSeq()
    .toArray();

  const mapRef = useSelector<
    iFullStoreState,
    React.RefObject<GoogleMap> | null
  >((state) => state.gmap.mapRef());

  let isRenderedMap = useSelector<iFullStoreState, boolean>(
    (state) => state.gmap.isRendered
  );

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.GRID);

  const user = localStorage.get('login-init-user');

  useEffect(() => {
    let markerBounds = new google.maps.LatLngBounds();
    devicesMarker.forEach((marker) => {
      markerBounds.extend(
        new google.maps.LatLng(
          marker.coordinates.location.lat,
          marker.coordinates.location.lng
        )
      );
    });
    mapRef?.current?.fitBounds(markerBounds);

    const fetchViewMode = async () => {
      const userFirebaseViewMode = (await clientDb().child(`people/people-details/${user.uid}/viewMode`).once('value')).val();
      if (userFirebaseViewMode) {
        setViewMode(userFirebaseViewMode);
      }
    };

    fetchViewMode();
  }, []);

  useEffect(() => {
    if (!isRenderedMap) {
      let markerBounds = new google.maps.LatLngBounds();
      devicesMarker.forEach((marker) => {
        markerBounds.extend(
          new google.maps.LatLng(
            marker.coordinates.location.lat,
            marker.coordinates.location.lng
          )
        );
      });
      mapRef?.current?.fitBounds(markerBounds);
    }
    setShowedDevices(devicesLastPings, dispatch);
  }, [devicesLastPings, isRenderedMap]);

  console.log('rendering devices')


  const size = useSelector<iFullStoreState, DashboardSize>(
    (s) => s.dashboardInfo.size
  );

  const isNotFullSizeDashboard = !(size === DashboardSize.OPEN_FULL);

  return (
    <DashboardBlock>
      <ModalHeaderComponent
        title={pageTitle}
        showSelect={true}
        options={sortOptions}
        option={sortOption}
        handleChange={handleSortChange}
      />
      <div className={viewMode === ViewMode.GRID ? styles.deviceGrid : styles.deviceList}>
        {sortedArray.map((deviceId) => {
          return viewMode === ViewMode.GRID ? (<Link key={deviceId} className={classNames({ [styles.showFullCardWidthOnSmallScreensOn1505pxScreen]: isNotFullSizeDashboard })} to={`/device/${deviceId}`}>
            <NewDeviceItem deviceId={deviceId} />
          </Link>) : (
            <Link key={deviceId} to={`/device/${deviceId}`}>
              <DeviceListItem deviceId={deviceId} />
            </Link>
          )
        })}
      </div>
    </DashboardBlock>
  );
};

export default withSentry(DevicesPage);


// todo: move this to shared path
export const formatWhenLast = (time?: moment.Moment) => {
  if (!time) return false;

  const now = moment();

  const lastActiveDays = now.diff(time, 'days');
  const lastActiveHours = Math.floor(now.diff(time, 'hours'));
  const lastActiveMinutes = now.diff(time, 'minutes');

  let ago = '';

  if (lastActiveDays)
    ago = `${lastActiveDays} Day${lastActiveDays === 1 ? '' : 's'} ago`;
  else if (lastActiveHours)
    ago = `${lastActiveHours} Hour${lastActiveHours === 1 ? '' : 's'} ago`;
  else if (lastActiveMinutes)
    ago = `${lastActiveMinutes} Minute${lastActiveMinutes === 1 ? '' : 's'}`;

  return ago;
};
