import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import {pipe, tap, when} from 'ramda';
import moment from 'moment';

import {allWatch as dbTagsWatcher} from '../../shared/db/tags-db';
import {allWatch as dbFencesWatcher} from '../../shared/db/fences-db';
import {allWatch as dbFavoriteWatcher} from '../../shared/db/favorites-db';
import {allWatch as dbExtraInfoWatcher} from '../../shared/db/extra-info-db';
import {watchLabels, incidentIcons, watchPersonCan} from '../../shared/db/general-db';
import {isMobile} from '../../shared/helpers';
import {iConfig, iDeviceEvent, iIcon, iList} from '../../shared/interfaces';
import {localStorage} from '../../shared/storage';
import {allWatch as dbPeopleWatcher} from '../../shared/db/people-db';
import {useRedux} from '../../states/redux-state';
import {useFirebase} from '../../states/firebase-state';
import {fbConnection, globaldb} from '../../shared/firebase';
import {dbFiltersWatcher} from '../../shared/db/filters-db';

class ActionsClass {
    SET_DEVICE_EVENTS = (events: iList<iDeviceEvent>) => ({type: 'SET_DEVICE_EVENTS', events})
    SET_INVIS_CAPTCHA = (pass: boolean) => ({type: 'SET_INVIS_CAPTCHA', pass})

    SHOW_SEARCH = ({type: 'SEARCH_SHOW_SEARCH'})
    HIDE_SEARCH = ({type: 'SEARCH_HIDE_SEARCH'})

    SHOW_BOOKMARKS_SEARCH = ({type: 'SEARCH_SHOW_BOOKMARKS_SEARCH'})
    HIDE_BOOKMARKS_SEARCH = ({type: 'SEARCH_HIDE_BOOKMARKS_SEARCH'})

    CHANGE_TERM = (newTerm) => ({type: 'CHANGE_TERM', newTerm})
    SET_LABELS = (labels) => ({type: 'SET_LABELS', labels})

    TOGGLE_SIDEBARS = ({type: 'TOGGLE_SIDEBARS'})
    TOGGLE_NAV_MENU = () => ({type: 'TOGGLE_NAV_MENU'})

    SET_INCIDENT_ICONS = (icons: {[incidentname: string]: iIcon}) => ({type: 'GENERAL_SET_INCIDENT_ICONS', incidentIcons})
    SET_TAGS_INITIALIZED = () => ({type: 'SET_TAGS_INITIALIZED'})

    SET_CLIENT_CONFIG = (config: iConfig) => ({type: 'SET_CLIENT_CONFIG', config})
    TOGGLE_LIVE_TRAIL = () => ({type: 'TOGGLE_LIVE_TRAIL'})
}

export const Actions = new ActionsClass();

export const tagsWatcher = (s) => dbTagsWatcher(
    (tags) => s.dispatch({type: 'SET_ALL_TAGS', tags}),
    (tagId, extraInfoIdVals) => s.dispatch({type: 'SET_TAG_EXTRA_INFO', tagId, extraInfoIdVals}),
    pipe(tap((x) => console.log('here tap')), Actions.SET_TAGS_INITIALIZED, s.dispatch)
);
export const fencesWatcher = (s) => dbFencesWatcher((fences) => s.dispatch({type: 'SET_ALL_FENCES', fences}));
export const peopleWatcher = (s) => dbPeopleWatcher((people) => s.dispatch({type: 'SET_ALL_PEOPLE', people}));
export const filtersWatcher = (s) => dbFiltersWatcher((filters) => s.dispatch({type: 'SET_ALL_FILTERS', filters}));
export const favoritesWatcher = (s,uid) => dbFavoriteWatcher(uid, (favorites) => s.dispatch({type: 'SET_ALL_FAVORITES', favorites}));
export const infoWatcher = (s) => dbExtraInfoWatcher((extraInfo) => s.dispatch({type: 'SET_ALL_EXTRA_INFO', extraInfo}));
export const labelWatcher = (s) => watchLabels((labels) => s.dispatch(Actions.SET_LABELS(labels)));
export const incidentIconsGetter = async (s) => {
    const icons = await incidentIcons();

    s.dispatch(Actions.SET_INCIDENT_ICONS(icons || {}));
}
export const permisionWather = (s, uid) => watchPersonCan(uid, can => s.dispatch({type: 'SET_PERMISIONS', can}));

interface iUseIsLocatingActions {
    locate: () => any;
    clear: () => any;
}
export enum LOCATION_STATUS {
    INACTIVE = 'INACTIVE',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    ACTIVE = 'ACTIVE',
}

const locationTryForMs = 1000*60*2;
export const useIsLocating = (deviceId): [LOCATION_STATUS, iUseIsLocatingActions] => {
    const [locatingUntil, locateTimeSetter] = useFirebase<moment.Moment>(
        `/devices/device-details/${deviceId}/locating-time`,
        when(Boolean, moment),
        (m) => m.toISOString()
    );

    // helper so that we know that we were locating but are done
    const [wasLocating, setWasLocating] = useState(false);
    const [bump,setBump] = useState(1);

    useEffect(() => {
        // only set was locating to true if we saw it while active
        if (locatingUntil && locatingUntil.isSameOrAfter(moment())) {
            setWasLocating(true);
        } else {
            setWasLocating(false);
        }
    }, [locatingUntil, bump]);

    // need to reprocess after 2 minutes so we can go to failed
    useEffect(() => {
        if (wasLocating && !!locatingUntil) {
            // bump after time is in past to force ui update
            setTimeout(() => setBump(bump + 1), locatingUntil.diff(moment(), 'ms'));
        }
    }, [wasLocating, locatingUntil]);

    return [
        deriveLocatingStatus(locatingUntil, wasLocating),
        {
            locate: () => {
                if (locatingUntil.isSameOrBefore(moment())) locateTimeSetter(undefined);
                locateTimeSetter((date) => date || moment().add(locationTryForMs, 'ms'))
            },
            clear: () => { locateTimeSetter(undefined); }
        }
    ];
};

const deriveLocatingStatus = (locatingUntil: moment.Moment|undefined, wasLocating) => {
    let status = LOCATION_STATUS.INACTIVE;

    if (locatingUntil && locatingUntil.isSameOrAfter(moment())) {
        status = LOCATION_STATUS.ACTIVE;
    } else if (!locatingUntil && wasLocating) {
        // this means that we saw that it was locating and mastrack removed
        // which means we found a message
        status = LOCATION_STATUS.SUCCESS;
    } else if (wasLocating && !!locatingUntil && locatingUntil.isBefore(moment())) {
        // we are locating but if we made it this far we have gone beyone the
        // supported locate time so notify that this failed
        status = LOCATION_STATUS.FAIL;
    }

    return status;
};

export const useConnected = (): [boolean, () => any] => {
    const [silentConWaiting, setWaiter] = React.useState(true);
    const [isConnected, setIsConnected] = React.useState(false);

    React.useEffect(() => {
        const timerId = setTimeout(() => {
            if (silentConWaiting) setWaiter(false);
        }, 5000);

        return () => clearTimeout(timerId);
    }, [silentConWaiting]);

    React.useEffect(() => {
        fbConnection.on('value', (snap) => {
            const connected = snap.val() === true;
            setIsConnected(connected);
            setWaiter(!connected);

        });
    }, []);

    return [silentConWaiting || isConnected, () => {}];
};

export const useReconnecting = () => {
    const [isConnected] = useConnected();
    const [lastCon, setLastCon] = React.useState(isConnected);
    const [reconnecting, setReconnecting] = React.useState(false);

    React.useEffect(() => {
        setLastCon(isConnected);
    }, [isConnected]);

    React.useEffect(() => {
        setReconnecting(isConnected && lastCon);
    }, [isConnected, lastCon]);

    return [reconnecting];
};

export const useLocalStorage = <T>(plainKey, defaultVal = null): [T|null, ((newVal: T) => any)] => {
    const storageKey = `storage-key:${plainKey}`;
    const value = useRedux((s) => s.general.misc[storageKey]);
    const dispatch = useDispatch();
    const changer = (newVal: any) => {
        localStorage.set(storageKey, newVal);
        dispatch({type: 'SET_MISC_VALUE', key: storageKey, value: {...newVal}});
    };

    React.useEffect(() => {
        if (!value) changer(localStorage.get(storageKey, defaultVal));
    }, []);

    return [value || {}, changer];
};
