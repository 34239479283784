import React, { useEffect, useState } from 'react';
import DashboardBlock from "../../../DashboardBlock";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { iDvirForm, iFullStoreState } from "../../../../shared/interfaces";
import { fetchDvirFormById } from "../utils";
import styles from "./styles.module.scss";
import { dvirCloseDashboard, dvirOpenDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import DvirFormInput from "../DvirFormInput";
import { localStorage } from "../../../../shared/storage";
import DvirFormHeadingInput from "../DvirFormHeadingInput";
import DvirFormHeadings from "../DvirFormHeadings";
import instance from "../../../../api/instance";
import { ReactComponent as ArrowBackIcon } from '../../../../assets/svg/arrow-back.svg';
import classNames from "classnames";

interface DvirFormEditProps extends RouteComponentProps<{ id: string }> { }

const mapStateToProps = (state: iFullStoreState, ownProps: DvirFormEditProps) => ({
  dvirId: ownProps.match.params.id,
});

const DvirFormEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = props.dvirId;
  const [form, setForm] = useState<iDvirForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>('');
  const [editDescription, setEditDescription] = useState<string>('');
  const [editHeadingName, setEditHeadingName] = useState<string>('');
  const [editHeadingDescription, setEditHeadingDescription] = useState<string>('');
  const [editHeadings, setEditHeadings] = useState<{ header: string, description: string, sort_order: number }[]>([]);
  const [nameIsEmpty, setNameIsEmpty] = useState<boolean>(false);
  const [isHeaderNameError, setIsHeaderNameError] = useState<boolean>(false);
  const [headerNameErrorMsg, setHeaderNameErrorMsg] = useState<string>('');
  const [headingsAreEmpty, setHeadingsAreEmpty] = useState<boolean>(false);
  const clientId = localStorage.get('active-client');

  const fetchDvirForm = async () => {
    try {
      const data = await fetchDvirFormById(id);

      const dvirForm = data.data['dvir_form'];
      const headings = data.data['header_items'];

      setForm({
        name: dvirForm.name,
        description: dvirForm.brief_description,
        id: dvirForm.firebase_key,
        headings
      });

      setEditName(dvirForm.name);
      setEditDescription(dvirForm.brief_description);
      setEditHeadings(headings);
    }
    catch (e) {
      console.log(e)
    }
  }

  const addHeading = async () => {
    if (editHeadingName.trim() === '') {
      setIsHeaderNameError(true);
      setHeaderNameErrorMsg('Heading Name can\'t be empty');
    }
    else if (!editHeadings.every(heading => heading.header != editHeadingName)) {
      setIsHeaderNameError(true);
      setHeaderNameErrorMsg('Duplicate');
      setEditHeadingName('');
    }
    else {
      setEditHeadings([...editHeadings, {
        header: editHeadingName,
        description: editHeadingDescription,
        sort_order: editHeadings.length + 1
      }]);

      setEditHeadingName('');
      setEditHeadingDescription('');
      setHeadingsAreEmpty(false);
    }
  }

  const removeHeading = (name) => {
    setEditHeadings(editHeadings.filter(heading => heading.header !== name));
  }

  const editForm = async () => {
    const URL = 'api/dvir-form/update-dvir-form';

    const body = {
      dvir_form_key: id,
      organization_key: clientId,
      name: editName,
      brief_description: editDescription,
      header_items: editHeadings
    }

    if (editName.trim() === '') {
      setNameIsEmpty(true);
    }
    else if (editHeadings.length === 0) {
      setHeadingsAreEmpty(true);
    }
    else {
      const res = await instance.post(URL, { ...body });

      history.goBack();
    }
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());
    (window as any).Beacon('destroy')

    fetchDvirForm();

    return () => {
      dispatch(dvirCloseDashboard());
      (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
    }
  }, []);

  const activeUpdateButton = (editName.trim() !== '') && (editHeadings.length !== 0);


  return (
    <DashboardBlock>
      <div className={styles.EditDvirFormHeader}>
        <button className={styles.EditDvirFormHeader__backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <h2>Edit DVIR Form</h2>
        <div></div>
      </div>
      <div className={styles.EditDvirFormContainer}>
        <DvirFormInput
          name={editName}
          description={editDescription}
          onChangeName={(e) => {
            setEditName(e.target.value);
            setNameIsEmpty(false);
          }}
          onChangeDescription={(e) => {
            setEditDescription(e.target.value);
          }}
          nameIsEmpty={nameIsEmpty}
        />
        <DvirFormHeadingInput
          name={editHeadingName}
          description={editHeadingDescription}
          isHeaderNameError={isHeaderNameError}
          headerNameErrorMsg={headerNameErrorMsg}
          onChangeName={(e) => {
            setIsHeaderNameError(false);
            setEditHeadingName(e.target.value);
          }}
          onChangeDescription={(e) => {
            setEditHeadingDescription(e.target.value);
          }}
          onClickErrorBlock={() => setIsHeaderNameError(false)}
          addHeading={addHeading}
        />
        {editHeadings.length > 0 && (
          <DvirFormHeadings
            headings={editHeadings}
            removeHeading={removeHeading}
          />
        )}
        {headingsAreEmpty && <p className={styles.NameErrorMsg}>Headings can't be empty</p>}
      </div>
      <div className={styles.bottomPanel}>
        <button onClick={() => history.goBack()} className={styles.bottomPanel__secondaryButton}>Cancel</button>
        <button
          onClick={editForm}
          className={classNames(styles.bottomPanel__primaryButton, { [styles.bottomPanel__primaryButton_active]: activeUpdateButton })}
        >
          Update
        </button>
      </div>
    </DashboardBlock>
  )
};

export default connect(mapStateToProps)(DvirFormEdit);
