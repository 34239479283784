import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import TagLabel from '../../pages/tags-info/TagLabel';
import { SelectedLabelsListProps } from './interfaces';
import styles from './SelectedLabelsList.module.scss';

const SelectedLabelsList: FunctionComponent<SelectedLabelsListProps> = ({
  title,
  selected,
  onClose,
  className,
}) => {
  return (
    <div className={classNames(styles.selectedItemsSection, className)}>
      {!!title && <p className={styles.sectionTitle}>{title}</p>}
      <div className={styles.selectedItemsList}>
        {selected.map(({ id, name, style }) => (
          <TagLabel
            key={id}
            /* Add 'onClose' dynamically */
            {...(onClose ? { onClose: () => onClose(id) } : {})}
            style={style}
            name={name}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectedLabelsList;
