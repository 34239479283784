import { useSelector } from 'react-redux';
import { iFullStoreState, ItemType } from '../shared/interfaces';
import { useCallback, useMemo } from 'react';
import GeneralActions from '../stores/reducers/general-actions';

type FavoriteSelectType = {
  userId: string;
  isFavorite: boolean;
}

type FavoritePropsType = {
  type: ItemType;
  id: string;
}

type ReturnUseFavorite = {
  isFavorite: boolean,
  toggle: VoidFunction,
}

const useFavorite = ({ type: favoriteType, id: favoriteId }: FavoritePropsType): ReturnUseFavorite => {
  const {
    userId,
    isFavorite
  } = useSelector<iFullStoreState, FavoriteSelectType>((s) => {
    return {
      userId: s.auth.user.uid,
      isFavorite: s.general.favorites?.[favoriteType]?.[favoriteId] ?? false
    }
  });

  const toggle = useCallback(() => {
    GeneralActions.toggleFavorite({ favoriteType, favoriteId, uid: userId, isFavorite: !isFavorite });
  }, [favoriteId, isFavorite]);

  return useMemo(() => ({
    toggle,
    isFavorite,
  }), [toggle, isFavorite]);
};

export default useFavorite;