import {store} from "../stores/store";
import {getOrganizationExtraInfo} from "../shared/db/extra-info-db";
import moment from "moment";

export const isValidEmail = (str) => {
  return (
    !/(\.{2}|-{2}|_{2})/.test(str) &&
    /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(str)
  );
};

export const isValidPhoneNumber = (str) => {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(str);
};


export const getViewBoxByPath = (pathStr: string): string => {
  // create the path element
  const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute('d', pathStr);
  svg.appendChild(path);

  // append to document
  document.body.appendChild(svg);

  // get the bounding box of the path
  const bbox = path.getBBox();

  // remove from document
  svg.remove();

  return `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
}

export const getExtraInfoKeyByName = async (name: string) => {
  const state = store.getState();
  let keys;

  if (Object.keys(state.auth.extraInfoKeys).length > 0) {
    keys = state.auth.extraInfoKeys;
  }
  else {
    keys = await getOrganizationExtraInfo();
  }

  return keys[name] || '';
}

export const getStartAndEndOfDate = () => {
  const nowDate = moment(new Date).set({'hour': 12, 'minute': 0, 'seconds': 0}).format('YYYY-MM-DD');
  const startTime = {
    hour: moment().startOf('day').format('h'),
    minute: moment().startOf('day').minute(),
    ampm: moment().startOf('day').format('a'),
  };
  const endTime = {
    hour:  moment().endOf('day').format('h'),
    minute: moment().endOf('day').minute(),
    ampm: moment().endOf('day').format('a'),
  };

  const startTimeParsed = moment(`${startTime.hour}:${startTime.minute} ${startTime.ampm}`, ["hh:mm A"]);
  const endTimeParsed = moment(`${endTime.hour}:${endTime.minute} ${endTime.ampm}`, ["hh:mm A"]);
  const startDate = moment(nowDate).hour(startTimeParsed.hours()).minute(startTime.minute);
  const endDate = moment(nowDate).hour(endTimeParsed.hours()).minute(endTime.minute);

  return [startDate, endDate];
}

export const groupItemsIntoPages = <T>(items: T[], itemsPerPage: number): (T[])[] => {
  return Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (v, i) =>
    items.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
  );
}