import React from 'react';
import {
  countDateWithUserAndTimezoneOffset, friendlyMilesPer,
  friendlySpeed,
  isMobile,
  speedWholeNumber
} from "../../../../shared/helpers";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import { ReactComponent as NotificationSvg } from "../../../../assets/svg/notification.svg";
import { ReactComponent as MapPointerSvg } from "../../../../assets/svg/map_pointer.svg";
import { ReactComponent as PaginatePrevious } from "../../../../assets/svg/chevron_left.svg";
import { ReactComponent as PaginateNext } from "../../../../assets/svg/chevron_right.svg";
import { ReactComponent as PaginatePreviousWhite } from "../../../../assets/svg/chevron_left_white.svg";
import { ReactComponent as PaginateNextWhite } from "../../../../assets/svg/chevron_right_white.svg";
import {store} from "../../../../stores/store";

import {
  path,
  pipe,
} from "ramda";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReportPointRow from "./ReportPointRow";
import {Units} from "../../../../shared/interfaces";
import {useLocalStorage} from "../../../../stores/reducers/general-reducers";
import {iHiddenReportData} from "../vis-report-data";
import styles from "./RenderStaticReport.module.scss";
import {LabelOrPersonRow} from "./cells/person-label-row";


const RenderStaticReport = ({
  displayName,
  records,
  showAllRoute,
  showAllTrips,
  hideAllTrips,
  showTrip,
  staticNextPage,
  staticPreviousPage,
  staticCurrentPage,
  staticMaxPage,
  staticNextDay,
  staticPreviousDay,
  staticCurrentDay,
  staticMaxDay,
  staticNextDevice,
  staticPreviousDevice,
  staticCurrentDeviceIdx,
  staticDevicesLength
}) => {
  const deviceId = pipe(path([0, "device"]))(records);
  const state = store.getState();
  const ids = state.devicesTripsPoints.getIn([deviceId, "reportTripsIds"]);
  const reportTripsIds = ids ? [...ids.values()] : [];
  const showAllRoutes = reportTripsIds.length
    ? reportTripsIds.some((item) => item)
    : false;
  const deviceUnits = state.devicesData.devicesDetails.getIn([deviceId, "units"], Units.miles);
  const deviceTimezone = state.devicesData.devicesDetails.getIn([deviceId, "timezone"], "EST")
  const pointDate = countDateWithUserAndTimezoneOffset(records[0]?.time, deviceTimezone);
  const [hiddenFields] = useLocalStorage<iHiddenReportData>(
    "hidden-report-data:static",
    {}
  );
  const hiddenFieldsCount = Object.values(hiddenFields).filter(value => value === true).length;
  const shouldShowReportTable = (records || []).length > 0;

  const areas = ["time", "person", "fuel", "voltage", "speed", "posted", "consumption", "location", "coordinates", "label"];
  const widthPerAreas = {
    time: 64,
    person: 142,
    fuel: 64,
    voltage: 72,
    speed: 64,
    posted: 72,
    consumption: 64,
    location: 150,
    coordinates: 150,
    label: 150,
  };
  const gridTemplateAreas = areas.filter((area) => !hiddenFields[area]);
  const gridTemplateColumns = gridTemplateAreas.map((area) => `minmax(${widthPerAreas[area] ?? 0}px, 1fr)`);

  return (
    <div>
      <div
        className={`d-flex justify-content-between ${(isMobile || window["cordova"]) && "align-items-center"
        } ${styles.ReportHeader}`}
      >
        <div className={styles.ReportHeaderDevicePaginate}>
          <button
            disabled={staticCurrentDeviceIdx === 0}
            onClick={staticPreviousDevice}
          >
            <PaginatePrevious
              style={{pointerEvents: 'none'}}
            />
          </button>
          <h3>{displayName}</h3>
          <button
            disabled={(staticCurrentDeviceIdx >= staticDevicesLength - 1) || staticDevicesLength === 0}
            onClick={staticNextDevice}
          >
            <PaginateNext
              style={{pointerEvents: 'none'}}
            />
          </button>
        </div>
        <div className={styles.ReportHeaderBtns}>
          <Button
            className="but but-toggle but-24px"
            styleType={ButtonStyles.GRAY_INACTIVE}
            style={
              isMobile || window["cordova"] ? {height: "max-content"} : {}
            }
            size={ButtonSize.XSM}
          >
            <NotificationSvg
              className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
            />
          </Button>
          <Button
            className={`but but-toggle but-24px ${
              showAllRoutes ? "active" : ""
            }`}
            styleType={
              showAllRoutes
                ? ButtonStyles.BLACK_WHITE
                : ButtonStyles.GRAY_INACTIVE
            }
            style={
              isMobile || window["cordova"] ? {height: "max-content"} : {}
            }
            size={ButtonSize.XSM}
            onClick={showAllRoutes ? hideAllTrips : showAllTrips}
          >
            <MapPointerSvg
              className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
            />
          </Button>
        </div>
      </div>
      {shouldShowReportTable && (
        <>
        <div>
          <PerfectScrollbar className="report-point-table-wrap">
            <div className={styles.ReportTable}>
              <div className={styles.ReportTableDateContainer}>
                <div className={styles.ReportTableDate}>
                  <button
                    onClick={staticPreviousDay}
                    disabled={staticCurrentDay === 1}
                  >
                    <PaginatePreviousWhite style={{height: '16px', width: '16px', cursor: 'pointer'}}/>
                  </button>
                  <span>
                {pointDate.format("MM/DD/YYYY")}
                </span>
                  <button
                    onClick={staticNextDay}
                    disabled={staticCurrentDay >= staticMaxDay}
                  >
                    <PaginateNextWhite style={{height: '16px', width: '16px', cursor: 'pointer'}}/>
                  </button>
                </div>
              </div>

              <div
                className={`${styles.ReportTableRow} ${styles.ReportTableHeader}`}
                style={{
                  gridTemplateColumns: gridTemplateColumns.join(' '),
                }}
              >
                <div className={styles.ReportTableCell}>Time</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["person"] ? "" : "none"}}>Person</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["fuel"] ? "" : "none"}}>Fuel</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["voltage"] ? "" : "none"}}>Voltage</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["speed"] ? "" : "none"}}>mph</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["posted"] ? "" : "none"}}>Posted</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["consumption"] ? "" : "none"}}>mpg</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["location"] ? "" : "none"}}>Location</div>
                <div className={styles.ReportTableCell}>Coordinates</div>
                <div className={styles.ReportTableCell} style={{display: !hiddenFields["label"] ? "" : "none"}}>Label</div>
              </div>

              {records.map((point) => {
                const date = countDateWithUserAndTimezoneOffset(point?.time, deviceTimezone);
                return (
                  <div
                    key={point.pointId}
                    className={styles.ReportTableGridRow}
                    style={{
                      gridTemplateColumns: gridTemplateColumns.join(' '),
                    }}
                  >
                    {!hiddenFields["time"] && <div className={styles.ReportTableGridCell}>{date.format("h:mmA")}</div>}
                    {!hiddenFields["person"] && (
                      <LabelOrPersonRow row={point} type="point" field="personId" isStatic={true}/>
                    )}
                    {!hiddenFields["fuel"] && <div className={styles.ReportTableGridCell}>{point.fuel}</div>}
                    {!hiddenFields["voltage"] && <div className={styles.ReportTableGridCell}>{point.volt}</div>}
                    {!hiddenFields["speed"] && (
                      <div className={styles.ReportTableGridCell}>{(point.speed as any) === "" ? "-" : speedWholeNumber(point.speed, deviceUnits)}</div>
                    )}
                    {!hiddenFields["posted"] && (
                      <div className={styles.ReportTableGridCell}>
                        {point.posted_speed && point.posted_speed !== 999 ? friendlySpeed(point.posted_speed) : "N/A"}
                      </div>
                    )}
                    {!hiddenFields["consumption"] && (
                      <div className={styles.ReportTableGridCell}>
                        {point.mpg ? friendlyMilesPer(point.mpg) : "-"}
                      </div>
                    )}
                    {!hiddenFields["location"] && (
                      <div className={styles.ReportTableGridCell}>
                        {point.address.street}
                        <br/>
                        {`${point.address.city} ${point.address.state} ${point.address.zip}`.replace(/US/gi, '')}
                      </div>
                    )}
                    {!hiddenFields["coordinates"] && (
                      <div className={styles.ReportTableGridCell}>
                        {point.coordinates.location.lat}, {point.coordinates.location.lng}
                      </div>
                    )}
                    {!hiddenFields["label"] && (
                      <LabelOrPersonRow row={point} type="point" field="label" isStatic={true}/>
                    )}
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </div>
        <div className={styles.ReportPagination}>
          <button
            className={styles.ReportPaginationBtn}
            onClick={staticPreviousPage}
            disabled={staticCurrentPage === 1}
          >
            <PaginatePrevious style={{height: '12px', width: '12px'}}/>
            <span>Previous Page</span>
          </button>
          <span className={styles.ReportPaginationPage}>{staticCurrentPage}</span>
          <button
            className={styles.ReportPaginationBtn}
            onClick={staticNextPage}
            disabled={staticCurrentPage >= staticMaxPage}
          >
            <span>Next Page</span>
            <PaginateNext style={{height: '12px', width: '12px'}}/>
          </button>
        </div>
      </>
      )}
    </div>
  )
}

export default RenderStaticReport;
