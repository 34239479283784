import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {pipe, path, fromPairs, toPairs, filter} from 'ramda';

import {
  iFileFormat,
  iFrequency,
  iFullStoreState,
  iList,
  iScheduledReport,
  ItemType,
  UserAuth
} from '../../../../shared/interfaces';
import {iReportDetails, iReportType} from '../../../../stores/reducers/report-reducers';
import Dialog, {DialogConfigSetter} from '../../../Dialog';
import {
  getReportSchedules,
  removeReportSchedule,
  setScheduleLabel,
  updateReportSchedule
} from '../../../../shared/db/report-db';
import {Overlay} from '../../../menus/modal/modal';
import {LabelChooser} from '../../../general/trip-list-chooser';
import DashboardBlock from '../../../DashboardBlock';
import {BottomShadowModal} from '../BottomShadowModal';
import UpdateReport from '../UpdateReport';
import {ReportNavbar} from '../ReportNavbar';
import {idValArr, idValsToList} from '../../../../shared/helpers';
import { ReactComponent as EditSvg } from '../../../../assets/svg/edit.svg';
import { ReactComponent as DeleteOutlineSvg } from '../../../../assets/svg/delete_outline.svg';
import './ScheduledReports.scss';
import {ReportFiltersTable} from '../../../elements/items-list-row';
import CreateReport from "../CreateReport";
import ModalHeaderComponent from '../../../menus/modal/modal-header';

type IPropsFromStore = {
  authUser: UserAuth;
  labels: Array<string>;
  details: iReportDetails;
}

interface iDetails {
  delivery: iFrequency;
  frequency: iFrequency;
  rangeDays: number;
  frequencyDays: { [day: number]: boolean };
  frequencyMonths: { month: number, day: number };
  format: iFileFormat;
}

interface iEditReportData {
  type: iReportType;
  deliverySchedule: {
    schedule: iFrequency;
    frequencyDays: { [day: number]: boolean };
    frequencyMonths: { month: number, day: number };
  };
  format: iFileFormat;
  reportRangeDays: number;
  sendTo: {
    people: string[];
    emails: string[];
  };
}

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
  authUser: state.auth.user!,
  labels: state.general.scheduleLabels || [],
  details: state.report.details
});

const LabelCell = ({label, id, edit}) => {
  return (
    <button className="but but-plaine" onClick={() => edit(id)} >
            <span style={{color: "black"}}>
                {label}&nbsp;
              <sup><EditSvg className="icon-gray icon-xsm"/></sup>
            </span>
    </button>
  )
}

const ScheduledReports = (props) => {
  const [details, setDetails] = useState<iDetails>({
    delivery: 'monthly',
    frequency: 'daily',
    rangeDays: 1,
    frequencyDays: {},
    frequencyMonths: { month: 0, day: 1 },
    format: 'pdf',
  });
  const [editReportData, setEditReportData] = useState<iEditReportData>({
    type: 'travel',
    deliverySchedule: {
      schedule: 'monthly',
      frequencyDays: {},
      frequencyMonths: { month: 0, day: 1 },
    },
    format: 'pdf',
    reportRangeDays: 1,
    sendTo: {
      people: [],
      emails: []
    },
  });
  const [editReportId, setEditReportId] = useState<string | number>('');
  const [editLabel, setEditLabel] = useState<boolean | string>(false);
  const [schedules, setSchedules] = useState<iList<iScheduledReport>>({} as iList<iScheduledReport>);


  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;

  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  const refreshSchedules = async () => {
    const schedules = await getReportSchedules();

    setSchedules(schedules);
  }

  useEffect(() => {
    refreshSchedules();
  }, []);

  const setLabel = (id) => async (label) => {
    setScheduleLabel(props.authUser)(id, label);

    setEditLabel(false);

    refreshSchedules();
  }

  const editLabelHandler = (id) => {
    setEditLabel(id);
  }

  const editReportHandler = (id, report) => {
    setEditReportId(id);
    setEditReportData({
      ...report,
      frequency: 'monthly',
      deliverySchedule: {
        ...(report?.deliverySchedule.frequencyMonths == undefined && {frequencyMonths: { month: 0, day: 1 }}),
        ...(report?.deliverySchedule.frequencyDays == undefined && {frequencyDays: {}}),
        ...report?.deliverySchedule,
      }
    });
  }

  const reportUpdater = updateReportSchedule(props.authUser);
  const reportRemover = removeReportSchedule(props.authUser);

  const deleteHandler = (id) => async () => {
    await reportRemover(id);

    setSchedules(idValsToList(idValArr(schedules).filter(x => x.id != id)));
  }

  const {labels, details: propsDetails} = props;
  const search = props.location.search;
  const generateReport = new URLSearchParams(search).get('generateReport');

  const displaySchedules = pipe(
    toPairs,
    filter(path([1, 'deliverySchedule'])),
    fromPairs as any,
  )(schedules) as iList<iScheduledReport>

  const overlay = !editLabel ? undefined : <Overlay close={() => setEditLabel(false)}>
    <LabelChooser type="schedule" canAdd choose={setLabel(editLabel)} items={labels.map(l => ({id: l, content: l}))} />
  </Overlay>

  return (
    <>
      <DashboardBlock overlay={
        <>
          {overlay}
          {(propsDetails.searchKey || propsDetails.reportTypeFromRedux === 'activity') && generateReport && <CreateReportModal/>}
          {!!editReportId && <BottomShadowModal>
              <UpdateReport
                  reportData={editReportData}
                  id={editReportId}
                  onClose={editReportHandler}
                  onSubmit={reportUpdater}
                  refreshReports={refreshSchedules}
              />
          </BottomShadowModal>
          }
        </>
      } title='Reports'>
        <ModalHeaderComponent title="Reports" />
        <ReportNavbar />
        <div className="report-scheduled-wraper">
          {/* <p style={{maxWidth: 500, textAlign: 'center'}}>
                            To create new scheduled reports choose Static or Travel then add filters and click the Generate Report button.
                        </p> */}
          <div className="report-cheduled-table-wraper">
            <PerfectScrollbar>
              {/* <Table
                            noMeta
                            perPage={5}
                            className="report-table"
                            columns={[
                                { name: 'Label', prop: 'label', cellMapper: ({label}: iScheduledReport, _, id) => <LabelCell label={label} id={id} edit={this.__editLabel} /> },
                                { flex: 2, name: 'Filters', prop: 'filters', cellMapper: (report: iScheduledReport) =>  <DetailsCell report={report} dialog={this.dialog} />},
                                { name: 'Frequency', prop: 'type', cellMapper: ({ deliverySchedule: { schedule } }: iScheduledReport) => schedToString(schedule) },
                                { name: 'Format', prop: 'format', cellMapper: ({format}: iScheduledReport) => formatToString(format) },
                                {
                                    name: 'Remove yes',
                                    prop: 'remove',
                                    cellMapper: (_, __, id) =>
                                        <div
                                            onClick={this.__delete(id)}
                                            className="remove-cell"
                                            >
                                                <DeleteOutlineSvg className="icon-12px"/>
                                        </div>},
                            ]}
                            data={schedules || []}
                        /> */}
              <div className="report-scheduled">
                <div className="report-scheduled__row report-scheduled__row__header">
                  <div className="report-scheduled__col">Label</div>
                  <div className="report-scheduled__col">Filters</div>
                  <div className="report-scheduled__col">Frequency</div>
                  <div className="report-scheduled__col">Format</div>
                  <div className="report-scheduled__col">Edit</div>
                  <div className="report-scheduled__col">Remove</div>
                </div>
                {
                  Object.entries(displaySchedules).map(([key, schedule]) => (
                    <div className="report-scheduled__row">
                      <div className="report-scheduled__col"><LabelCell label={schedule.label} id={key} edit={editLabelHandler} /></div>
                      <div className="report-scheduled__col"><DetailsCell report={schedule} dialog={dialog} /></div>
                      <div className="report-scheduled__col">{schedToString(schedule.deliverySchedule.schedule)}</div>
                      <div className="report-scheduled__col">{formatToString(schedule.format)}</div>
                      <div className="report-scheduled__col"><EditSvg onClick={() => editReportHandler(key, schedule)} className="icon-20px report-scheduled__delete"/></div>
                      <div className="report-scheduled__col"><DeleteOutlineSvg onClick={deleteHandler(key)} className="icon-20px report-scheduled__delete"/></div>
                    </div>
                  ))
                }
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </DashboardBlock>
      <Dialog setupConfig={setupDialog} />
    </>
  )
}

export default connect(mapStateToProps)(ScheduledReports);

const schedToString = (sched: string) => ({
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  once: 'Once',
})[sched.toLowerCase()] || '';

const formatToString = (format: string) => ({
  pdf: 'PDF',
  csv: 'CSV'
})[format.toString()] || ''

type iDetailsProps = {
  report: iScheduledReport;
  className?: string;
  dialog?: DialogConfigSetter;
}

export const DetailsCell = ({report, dialog, className = ""}: iDetailsProps) => {
  const filterStrs = [ItemType.device, ItemType.person, ItemType.tag, 'alerts', 'labels'].map(type => {
    if (!report.filters || !report.filters[type]) return `All ${type}`;

    return `${Object.keys(report.filters[type]).length} ${type}`;
  })

  const notification = () => dialog?.({
    title: 'Filters',
    body: <div style={{width: '100%'}}>
      <table className="table table-sm table-bordered">
        <tbody>
        <ReportFiltersTable report={report} />
        </tbody>
      </table>
      {/* <div style={{textAlign: 'center'}}>
                To change a report remove and re-add a new one.
            </div> */}
    </div>,
    type: 'NOTIFICATION',
  });

  return (
    <span className={`details-cell ${className}`} onClick={notification}>{filterStrs.join(' / ')}</span>
  )
};

export const CreateReportModal = () => {
  return (
    <BottomShadowModal>
      <CreateReport/>
    </BottomShadowModal>
  )
}

