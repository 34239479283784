import styles from "./styles.module.scss";
import React from "react";

const DvirFormHeadings = ({headings, removeHeading}) => {
  return (
    <div className={styles.HeadingsContainer}>
      <h3>Created Headings</h3>
      {headings.map(heading => (
        <div key={heading.header} className={styles.HeadingItem}>
          <span className={styles.HeadingName}>{heading.header}</span>
          <button className={styles.DeleteHeading} onClick={() => removeHeading(heading.header)}>&#x2715;</button>
        </div>
      ))}
    </div>
  )
}

export default DvirFormHeadings;
