import { filter, isNil, keys, pipe, unless } from 'ramda';

import { clientDb, clientStorage, fbapp, globaldb, keyGen } from '../firebase';
import { makeAudit, makeAuditForDevicesList, resetRememberMeIfExists, storePathAwaiterMaker } from '../helpers';
import { iConfig, iDefaultSettings, ItemType, UserAuth } from '../interfaces';
import { localStorage } from '../storage';
import { logout as logoutAPI } from '../../api';

export const userTokenRefreshHandler = async (theStore) => {
    const awaiter = storePathAwaiterMaker(theStore);

    const user = await awaiter<UserAuth>(['auth', 'user']);
    const config = await awaiter<iConfig>(['general', 'config']);

    if (!user || !user.beToken) return;

    // refresh when someone changes site config (eg, strict mode)

    console.log('active-client', localStorage.get('active-client'));

    clientDb().child('config').child('version').on('value', (res) => {
        if (res.val() !== config.version) {
            // clear out old cached config
            localStorage.forget('db-config');

            window.location.reload();
        }
    });

    clientDb().child('people').child('people-details').child(user.uid).child('force-refresh').on('value', async (res) => {
        if (res.val()) {
            // set it to false before we reload else we end up in loop
            await clientDb().child('people').child('people-details').child(user.uid).child('force-refresh').remove();

            user.beToken.role !== 'superAdmin' && window.location.reload();
        }
    });
};

export const getDefaultSettings = async () => (await clientDb().child('default-settings').once('value')).val() || {} as iDefaultSettings

export const getDbConfig = async () => (await clientDb().child('config').once('value')).val() || {} as iConfig;

export const watchLabels = (callback) => clientDb().child('labels').on('value', (res) => {
    callback(res.val());
}, (err) => console.log(err));

export const addTripLabel = (user: UserAuth) => async (newLabel) => clientDb().update(makeAudit(user, {
    [`labels/trip-labels/${newLabel}`]: newLabel
}));

export const addScheduleLabel = (user: UserAuth) => async (newLabel) => clientDb().update(makeAudit(user, {
    [`labels/schedule-labels/${newLabel}`]: newLabel
}));

export const incidentIcons = async () => (await globaldb.child('incident-icons').once('value')).val();

export const saveIcon = async (file: File) => {
    const key = keyGen();
    await clientStorage().child(`icons/${key}/file`).put(file);

    return await clientStorage().child(`icons/${key}/file`).getDownloadURL();
};

export const getPersonAllowedSee = async (personId: string) => {
    return (await clientDb().child('acl').child('items-allowed').child(personId).once('value')).val() || {};
};

export const watchPersonAllowedSee = (personId: string, callback) => {
    const wrapper = (x) => callback(x.val() || {});

    return clientDb().child('acl').child('items-allowed').child(personId).on('value', unless(isNil, wrapper), (err) => console.log(err));
};

export const getDeviceAllowedSee = async (deviceId: string, deviceTags: string[]) => {
    const items = (await clientDb().child('acl').child('items-allowed').once('value')).val() || {};

    return pipe(
        filter(({device = {}, tags = {}}) => device[deviceId] || deviceTags.some((t) => tags[t])),
        keys,
    )(items);
};

export const getEventTypes = async () => (await globaldb.child('events').once('value')).val();

export const setAllowedSee = (user: UserAuth) => async (personId: string, type: ItemType, itemId: string) => {

    if (type === ItemType.device) {
        return clientDb().update(makeAudit(user, {
            [`/acl/items-allowed/${personId}/device/${itemId}/${itemId}`]:'device',
            [`/acl/can/see-fences/${personId}`]: true
        }));
    }

    const taggedDevices = (await clientDb().child('tags').child(itemId).child('instances').child('device').once('value')).val() || {};

    const update = {};

    update[`/tags/${itemId}/instances/allowed-see/person/${personId}`] = true;
    update[`/acl/can/see-fences/${personId}`] = true;
    console.log('taggedDevices', taggedDevices);
    Object.keys(taggedDevices).forEach((deviceId) =>
        update[`/acl/items-allowed/${personId}/device/${deviceId}/${itemId}`] = 'tag'
    );

    const updateForDevicesList = {};

    updateForDevicesList[
      `/tags/${itemId}/details/devices-list`
    ] = {personId: personId, action: 'tag was ADDED to the person'};

    clientDb().update(makeAuditForDevicesList(user, updateForDevicesList));

    await clientDb().update(makeAudit(user, update));

};
export const readOnlyAccessToggle= (user: UserAuth) => async (personId: string,  itemId: string, value: boolean ) => {
    await clientDb().update(makeAudit(user, {
        [`/acl/items-allowed/${personId}/device/${itemId}/readOnly`]: value
    }));

    const isReadOnly = (await (clientDb().child(`/acl/items-allowed/${personId}/device/${itemId}/readOnly`).once('value'))).val();

    if (isReadOnly) {
        await clientDb().update(makeAudit(user, {
            [`/acl/can/see-fences/${personId}`]: false
        }));

        await clientDb().update(makeAudit(user, {
            [`/acl/can/edit-fences/${personId}`]: false
        }));
    }
    else {
        await clientDb().update(makeAudit(user, {
            [`/acl/can/see-fences/${personId}`]: true
        }));
        await clientDb().update(makeAudit(user, {
            [`/acl/can/edit-fences/${personId}`]: true
        }));
    }
};
export const tagReadOnlyToggle= (user: UserAuth) => async (tagId: string,  personId: string, value: boolean ) => {
    await clientDb().update(makeAudit(user, {
        [`/tags/${tagId}/instances/allowed-see/readOnly/${personId}`]: value
    }));
};

export const getReadOnlyStatus = async (personId: string, itemId: string) => {
    const responce = (await (clientDb().child(`/acl/items-allowed/${personId}/device/${itemId}/readOnly`).once('value'))).val()
    return !!responce;
};

// returnі true if status is read only
export const getTagReadOnlyStatus = async (personId: string, tagId: string) => {
    try {
        return (await (clientDb().child(`/tags/${tagId}/instances/allowed-see/readOnly/${personId}`).once('value'))).val() || false;
    } catch (error) {
        if (localStorage.get('active-client', 'non-empty-placeholder') === "non-empty-placeholder") {
            logoutAPI().finally(() => {
                resetRememberMeIfExists();
                document.location.href = '/';
                fbapp.auth().signOut();
            });
        }
    }
};

export const removeAllowedSee = (user: UserAuth) => async (personId: string, type: ItemType, itemId: string) => {

    const info = (await clientDb().child('acl').child('items-allowed').child(personId).child('device').child(itemId).once('value')).val() || {};

    if (type == ItemType.device) return clientDb().update(makeAudit(user, {
        [`acl/items-allowed/${personId}/device/${itemId}/${itemId}`]: null,
        ...(!(Object.keys(info).length > 2) && {[`acl/items-allowed/${personId}/device/${itemId}/readOnly`]: null}),
        [`/acl/can/see-fences/${personId}`]: false
    }));

    const taggedDevices = (await clientDb().child('tags').child(itemId).child('instances').child('device').once('value')).val() || {};

    const update = {};

    update[`/tags/${itemId}/instances/allowed-see/person/${personId}`] = null;
    update[`/tags/${itemId}/instances/allowed-see/readOnly/${personId}`] = null;
    update[`/acl/can/see-fences/${personId}`] = false;

    // we need it to check whether the device is attached via the device, if not - the readOnly flag is also removed
    const infoFor = async () => {
        const info = await clientDb().child('acl').child('items-allowed').child(personId).child('device').once('value');
        return info.val() || {};
    }

    const infoForData = await infoFor()

    const findValueAndCheckForDevice = (data, deviceId) => {
        const entry = Object.entries(data).find(([key, value]) => key === deviceId);
        return entry ? Object.values(entry[1]).some(value => value === "device") : null;
    };

    Object.keys(taggedDevices).forEach( (deviceId) => {
        const thereIsAlsoDevice = findValueAndCheckForDevice(infoForData, deviceId)

        update[`/acl/items-allowed/${personId}/device/${deviceId}/${itemId}`] = null;
        !thereIsAlsoDevice && (update[`/acl/items-allowed/${personId}/device/${deviceId}/readOnly`] = null)
    }
    );

    const updateForDevicesList = {};

    updateForDevicesList[
      `/tags/${itemId}/details/devices-list`
    ] = {personId: personId, action: 'tag was REMOVED for the person'};

    clientDb().update(makeAuditForDevicesList(user, updateForDevicesList));

    await clientDb().update(makeAudit(user, update))
}

export const watchPersonCan = (uid, callback) => {
    const userCanRef = clientDb().child('acl').child('can');
    userCanRef.on("value", (res) => {
        const val = Object.entries(res.val()).filter(([permission, x]) => x[uid]).map(([permission]) => permission);
        callback(val);
    }, err => console.log(err))
    return () => userCanRef.off("value");
}

export enum DeviceInfoSectionOrderItem {
  TAGS = 'tags',
  EXTRA_INFO = 'extra-info',
  VISIBLE_TO = 'visible-to',
  ASSIGNED_DRIVER = 'assigned-driver',
  // NOT DRIVER SECTIONS
  ALERT = 'alert',
  ENGINE_HISTORY = 'engine-history',
  NOTES = 'notes',
}

export const defaultDeviceInfoSectionOrderList: DeviceInfoSectionOrderItem[] = [
    DeviceInfoSectionOrderItem.TAGS,
    DeviceInfoSectionOrderItem.EXTRA_INFO,
    DeviceInfoSectionOrderItem.VISIBLE_TO,
    DeviceInfoSectionOrderItem.ASSIGNED_DRIVER,
    DeviceInfoSectionOrderItem.ALERT,
    DeviceInfoSectionOrderItem.ENGINE_HISTORY,
    DeviceInfoSectionOrderItem.NOTES,
];

const getDeviceInfoSectionOrder = async (): Promise<DeviceInfoSectionOrderItem[]> => (await clientDb().child('default-settings/device-info-section-order').once('value')).val() || [];

export const getDeviceInfoSectionFullOrder = async (): Promise<DeviceInfoSectionOrderItem[]> => {
    const savedOrder = await getDeviceInfoSectionOrder();
    // add all items + remove duplicates
    return [...new Set([...savedOrder, ...defaultDeviceInfoSectionOrderList])];
}

export const setDeviceInfoSectionOrder = (user: UserAuth) => async (newOrder: DeviceInfoSectionOrderItem[]) => (
  clientDb().update(makeAudit(user, {
      [`default-settings/device-info-section-order`]: newOrder,
  }))
);


