import React, { useEffect, useMemo, useRef, useState } from 'react';
import DragSortableList from 'react-drag-sortable';
import C, { UserCan } from '../../shared/constants';
import {faChevronDown, faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Fa} from './fa';
import {changePermission, usersHavePermission} from "../../shared/db/people-db";
import {localStorage} from "../../shared/storage";
import {useRedux} from "../../states/redux-state";
import {getHideExtraInfoValue, getTravelReportFieldVisibility, setHideExtraInfo, setTravelReportFieldVisibility} from "../../shared/db/extra-info-db";
import {getExtraInfoKeyByName} from "../../utils";
import {ExtraInfoFields} from "../../shared/interfaces";
import {
    DeviceInfoSectionOrderItem, getDeviceInfoSectionFullOrder, setDeviceInfoSectionOrder,
} from '../../shared/db/general-db';
import styles from './AllUserFeatures.module.scss';
import { clientDb } from '../../shared/firebase';

const deviceSectionNameByID: {[id in DeviceInfoSectionOrderItem]: string} = {
    [DeviceInfoSectionOrderItem.TAGS]: 'Tags',
    [DeviceInfoSectionOrderItem.EXTRA_INFO]: 'Extra info',
    [DeviceInfoSectionOrderItem.VISIBLE_TO]: 'Visible to',
    [DeviceInfoSectionOrderItem.ASSIGNED_DRIVER]: 'Assigned driver',
    [DeviceInfoSectionOrderItem.ALERT]: 'Alert',
    [DeviceInfoSectionOrderItem.ENGINE_HISTORY]: 'Engine history',
    [DeviceInfoSectionOrderItem.NOTES]: 'Notes',
}

// #region styles
const main: React.CSSProperties = {
    width: '100%',
    margin: '3px 3px 6px',
};
const head: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 8px',
    backgroundColor: C.darkGray,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    cursor: 'pointer',
};
const body: React.CSSProperties = {
    width: '100%',
    padding: '5px 10px',
    border: `1px solid ${C.lightGray}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
};
const toggle: React.CSSProperties = {
    color: '#fff',
    marginRight: 10,
    cursor: 'pointer',
};
const title: React.CSSProperties = {
    flex: '1 1 0%',
    color: '#fff',
    fontWeight: 'bold',
};
const table: React.CSSProperties = {
    width: '200px'
};
const key: React.CSSProperties = {
    fontWeight: 'bold',
    marginBottom: 15
};
const checkboxLabel: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0'
};
const sectionTitle: React.CSSProperties = {...key, marginTop: 15};
// #endregion

const deviceInfo: ({
    name: string;
    permission: UserCan;
})[] = [
    {
        permission: UserCan.HIDE_DEVICE_STATUS,
        name: 'Status',
    },
    {
        permission: UserCan.HIDE_DEVICE_VIEW,
        name: 'View',
    },
    {
        permission: UserCan.HIDE_DEVICE_HISTORY,
        name: 'History',
    },
    {
        permission: UserCan.HIDE_DEVICE_LOCATE,
        name: 'Locate',
    },
    {
        permission: UserCan.HIDE_DEVICE_TODAYS_HISTORY,
        name: 'Today’s History',
    },
    {
        permission: UserCan.HIDE_DEVICE_FUEL,
        name: 'Fuel',
    },
    {
        permission: UserCan.HIDE_DEVICE_BATTERY,
        name: 'Battery',
    },
    {
        permission: UserCan.HIDE_DEVICE_VOLT,
        name: 'Volt',
    },
    {
        permission: UserCan.HIDE_DEVICE_BATTERY_PERCENT,
        name: 'Battery percent',
    },
    {
        permission: UserCan.HIDE_DEVICE_CHECK_ENGINE,
        name: 'Has check engine',
    },
    {
        permission: UserCan.HIDE_DEVICE_SPEED,
        name: 'Speed',
    },
];

enum TravelReportField {
    Duration = 'duration',
    Idle = 'idle',
    Stopped = 'stopped',
    Safety = 'safety',
    Person = 'person',
    MaxSpeed = 'max_speed',
    AvgSpeed = 'avg_speed',
    Distance = 'distance',
    Consumption = 'consumption',
    Label = 'label'
}

export const AllUsersFeatures = () => {
    const user = useRedux((s) => s.auth.user);
    const [collapsed, setCollapsed] = useState(true);
    const [reports, setReports] = useState(false);
    const [tags, setTags] = useState(false);
    const [tappedIn, setTappedIn] = useState(false);
    const [filterReportsByPeople, setFilterReportsByPeople] = useState<boolean>(false);
    const [ maintenance, setMaintenance ] = useState(false);
    const [dvir, setDvir] = useState<boolean>(false);

    // pages setup
    const [hideDashboard, setHideDashboard] = useState(false);

    const pagesSetup = [
        {
            permission: UserCan.HIDE_DASHBOARD_PAGE,
            name: 'Hide dashboard page',
            value: hideDashboard,
            onChange: (newValue: boolean) => {
                setHideDashboard(newValue);
                changePermission(user, UserCan.HIDE_DASHBOARD_PAGE, newValue)();
            }
        },
    ];

    // visibility setup
    const [hideSerial, setHideSerial] = useState<boolean>(false);

    const visibilitySetup = [
        {
            name: 'Hide device serial number',
            label: 'hide_serial',
            value: hideSerial,
            onChange: async (newValue: boolean) => {
                setHideSerial(newValue);

                const key = await getExtraInfoKeyByName(ExtraInfoFields.SERIAL);

                if (key !== '') setHideExtraInfo(user, newValue, key);
            }
        }
    ]

    // travel report field visibility setup
    const defaultFieldVisibility: Record<TravelReportField, boolean> = {
        [TravelReportField.Duration]: false,
        [TravelReportField.Idle]: false,
        [TravelReportField.Stopped]: false,
        [TravelReportField.Safety]: false,
        [TravelReportField.Person]: false,
        [TravelReportField.MaxSpeed]: false,
        [TravelReportField.AvgSpeed]: false,
        [TravelReportField.Distance]: false,
        [TravelReportField.Consumption]: false,
        [TravelReportField.Label]: false,
    };

    const [fieldVisibility, setFieldVisibility] = useState<Record<TravelReportField, boolean>>(defaultFieldVisibility);

    const toggleFieldVisibility = async (key: TravelReportField, newValue: boolean) => {
        setFieldVisibility(prev => ({ ...prev, [key]: newValue }));
        setTravelReportFieldVisibility(user, newValue, key);
    };
    
    const travelReportFieldVisibility = [
        {
            name: 'Hide Duration',
            label: TravelReportField.Duration,
            value: fieldVisibility[TravelReportField.Duration],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Duration, newValue)
        },
        {
            name: 'Hide Idle',
            label: TravelReportField.Idle,
            value: fieldVisibility[TravelReportField.Idle],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Idle, newValue)
        },
        {
            name: 'Hide Stopped',
            label: TravelReportField.Stopped,
            value: fieldVisibility[TravelReportField.Stopped],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Stopped, newValue)
        },
        {
            name: 'Hide Safety',
            label: TravelReportField.Safety,
            value: fieldVisibility[TravelReportField.Safety],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Safety, newValue)
        },
        {
            name: 'Hide Person',
            label: TravelReportField.Person,
            value: fieldVisibility[TravelReportField.Person],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Person, newValue)
        },
        {
            name: 'Hide Max Speed',
            label: TravelReportField.MaxSpeed,
            value: fieldVisibility[TravelReportField.MaxSpeed],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.MaxSpeed, newValue)
        },
        {
            name: 'Hide Avg. Speed',
            label: TravelReportField.AvgSpeed,
            value: fieldVisibility[TravelReportField.AvgSpeed],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.AvgSpeed, newValue)
        },
        {
            name: 'Hide Distance',
            label: TravelReportField.Distance,
            value: fieldVisibility[TravelReportField.Distance],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Distance, newValue)
        },
        {
            name: 'Hide Consumption',
            label: TravelReportField.Consumption,
            value: fieldVisibility[TravelReportField.Consumption],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Consumption, newValue)
        },
        {
            name: 'Hide Label',
            label: TravelReportField.Label,
            value: fieldVisibility[TravelReportField.Label],
            onChange: (newValue: boolean) => toggleFieldVisibility(TravelReportField.Label, newValue)
        },
    ];

    // other setup
    enum ViewMode {
        GRID = 'grid',
        LIST = 'list',
    }
    
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.GRID);
    const userInfo = localStorage.get('login-init-user');
    
    const handleGridOrListView = async (newViewMode: ViewMode) => {
        try {
            setViewMode(newViewMode);

            await clientDb().update({
                [`people/people-details/${userInfo.uid}/viewMode`]: newViewMode,
            });
        } catch (error) {
            console.error('Error updating view mode:', error);
        }
    };

    const otherSetup = [
        {
            name: 'Show list view instead of grid view on Devices and People pages',
            label: 'grid_list_toggle',
            value: viewMode === ViewMode.LIST,
            onChange: async (newValue: boolean) => {
                const newViewMode = newValue ? ViewMode.LIST : ViewMode.GRID;
                await handleGridOrListView(newViewMode);
            },
        }
    ];

    // map setup
    const [showMarkerNameAbove, setShowMarkerNameAbove] = useState(false);
    const [roundedMapMarkerImage, setRoundedMapMarkerImage] = useState(false);

    const mapSetup = [
        {
            permission: UserCan.SHOW_MARKER_NAME_ABOVE,
            name: 'Show marker name above',
            value: showMarkerNameAbove,
            onChange: (newValue: boolean) => {
                setShowMarkerNameAbove(newValue);
                changePermission(user, UserCan.SHOW_MARKER_NAME_ABOVE, newValue)();
            }
        },
        {
            permission: UserCan.ROUNDED_MAP_MARKER_IMAGE,
            name: 'Rounded map marker image',
            value: roundedMapMarkerImage,
            onChange: (newValue: boolean) => {
                setRoundedMapMarkerImage(newValue);
                changePermission(user, UserCan.ROUNDED_MAP_MARKER_IMAGE, newValue)();
            }
        }
    ];

    // device info
    const [deviceInfoConfiguration, setDeviceInfoConfiguration] = useState<Partial<Record<UserCan, boolean>>>({});
    const deviceInfoConfigurationRef = useRef(deviceInfoConfiguration);
    const updateDeviceInfoConfiguration = (newValue: Partial<Record<UserCan, boolean>>) => {
        setDeviceInfoConfiguration(newValue);
        deviceInfoConfigurationRef.current = newValue;
    };
    const updateSingleDeviceInfoConfiguration = (newValue: Partial<Record<UserCan, boolean>>) => {
        updateDeviceInfoConfiguration({...deviceInfoConfigurationRef.current, ...newValue});
    }

    // fetch device info
    useEffect(() => {
        const clientId = localStorage.get('active-client');

        [
            UserCan.HIDE_DEVICE_STATUS,
            UserCan.HIDE_DEVICE_VIEW,
            UserCan.HIDE_DEVICE_HISTORY,
            UserCan.HIDE_DEVICE_LOCATE,
            UserCan.HIDE_DEVICE_TODAYS_HISTORY,
            UserCan.HIDE_DEVICE_FUEL,
            UserCan.HIDE_DEVICE_BATTERY,
            UserCan.HIDE_DEVICE_VOLT,
            UserCan.HIDE_DEVICE_BATTERY_PERCENT,
            UserCan.HIDE_DEVICE_CHECK_ENGINE,
            UserCan.HIDE_DEVICE_SPEED,
        ].forEach((permission) => {
            usersHavePermission(clientId, permission)
              .then((res) => {
                    updateSingleDeviceInfoConfiguration({ [permission]: res });
                }
              )
        });
    }, []);


    const toggleCollapsed = () => setCollapsed(!collapsed);

    const fetchHideSerial = async () => {
        const key = await getExtraInfoKeyByName(ExtraInfoFields.SERIAL);

        const value = await getHideExtraInfoValue(key);

        setHideSerial(value);
    }

    const fetchTravelReportFieldVisibility = async () => {
        const value = await getTravelReportFieldVisibility();
        console.log('vavaluevaluelue', value);

        setFieldVisibility(prev => ({
            ...defaultFieldVisibility, 
            ...value
        }));
    }

    const fetchViewMode = async () => {
        const userFirebaseViewMode = (await clientDb().child(`people/people-details/${user.uid}/viewMode`).once('value')).val();
        if (userFirebaseViewMode) {
            setViewMode(userFirebaseViewMode);
        }
    };

    useEffect(() => {
        const clientId = localStorage.get('active-client');
        usersHavePermission(clientId, 'see-tags').then((res) => setTags(res));
        usersHavePermission(clientId, 'see-all-reports').then((res) => setReports(res));
        usersHavePermission(clientId, 'see-tapped-people').then((res) => setTappedIn(res));
        usersHavePermission(clientId, 'see-maintenance').then(res => setMaintenance(res));
        usersHavePermission(clientId, UserCan.FILTER_REPORTS_BY_PEOPLE).then(res => setFilterReportsByPeople(res));
        usersHavePermission(clientId, 'see-dvir').then(res => setDvir(res));

        // pages setup
        usersHavePermission(clientId, UserCan.HIDE_DASHBOARD_PAGE).then(res => setHideDashboard(res));

        // Map setup
        usersHavePermission(clientId, UserCan.SHOW_MARKER_NAME_ABOVE).then(res => setShowMarkerNameAbove(res));
        usersHavePermission(clientId, UserCan.ROUNDED_MAP_MARKER_IMAGE).then(res => setRoundedMapMarkerImage(res));

        fetchHideSerial();
        fetchTravelReportFieldVisibility();
        fetchViewMode();
    }, []);

    const deviceInfoOptionChange = (permission: UserCan, newOptionValue: boolean) => {
        updateSingleDeviceInfoConfiguration({ [permission]: newOptionValue });
        changePermission(user, permission, newOptionValue)();
    }

    const [deviceInfoSectionOrderState, setDeviceInfoSectionOrderState] = useState<DeviceInfoSectionOrderItem[]>([]);
    useEffect(() => {
        getDeviceInfoSectionFullOrder().then((order) => {
            setDeviceInfoSectionOrderState(order);
        });
    }, []);

    const deviceInfoSectionOrderItems = useMemo(() => (
      deviceInfoSectionOrderState.map(item => ({
          id: item,
          name: deviceSectionNameByID[item],
      }))
    ), [deviceInfoSectionOrderState]);

    const onDeviceSectionReorder = (items: {id: DeviceInfoSectionOrderItem }[]) => {
        const newOrder: DeviceInfoSectionOrderItem[] = items.map(({ id }) => id);
        setDeviceInfoSectionOrderState(newOrder);
        setDeviceInfoSectionOrder(user)(newOrder);
    }

    return (
        <div style={{...main}}>
            <div style={{...head}} onClick={toggleCollapsed}>
                <Fa icon={ collapsed ? faChevronRight : faChevronDown } style={{...toggle}} />
                <span style={{...title}}>All Users Features</span>
            </div>
            <div style={{...body}}>
                {!collapsed && (
                    <div>
                        <h6 style={{...key}}>Show these features for all users:</h6>
                        <div style={{...table}}>
                            <span style={{...checkboxLabel}}>
                                Scheduled Reports
                                <input
                                  type="checkbox"
                                  name='Scheduled reports'
                                  checked={reports}
                                  onChange={() => setReports(!reports)}
                                  onClick={changePermission(user, 'see-all-reports', !reports)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Maintenance
                                <input
                                  type="checkbox"
                                  name='Maintenance'
                                  checked={maintenance}
                                  onChange={() => setMaintenance(!maintenance)}
                                  onClick={changePermission(user, 'see-maintenance', !maintenance)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Tags
                                <input
                                  type="checkbox"
                                  name='Tags'
                                  checked={tags}
                                  onChange={() => setTags(!tags)}
                                  onClick={changePermission(user, 'see-tags', !tags)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Tapped In
                                <input
                                  type="checkbox"
                                  name='Tapped In'
                                  checked={tappedIn}
                                  onChange={() => setTappedIn(!tappedIn)}
                                  onClick={changePermission(user, 'see-tapped-people', !tappedIn)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Filter by people
                                <input
                                  type="checkbox"
                                  name='Filter by people'
                                  checked={filterReportsByPeople}
                                  onChange={() => setFilterReportsByPeople(!filterReportsByPeople)}
                                  onClick={changePermission(user, UserCan.FILTER_REPORTS_BY_PEOPLE, !filterReportsByPeople)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                DVIR
                                <input
                                  type="checkbox"
                                  name='DVIR'
                                  checked={dvir}
                                  onChange={() => setDvir(!dvir)}
                                  onClick={changePermission(user, 'see-dvir', !dvir)}
                                />
                            </span>

                            {/* Pages setup */}
                            <p style={{ ...sectionTitle }}>Pages setup</p>
                            {pagesSetup.map(({ permission, name, value, onChange }) => (
                              <span key={permission} style={{ ...checkboxLabel }}>
                                  {name}
                                  <input
                                    type='checkbox'
                                    name={name}
                                    checked={value}
                                    onChange={() => onChange(!value)}
                                  />
                                </span>
                            ))}

                            {/* Device info */}
                            <p style={{...sectionTitle}}>Device info</p>
                            {deviceInfo.map(({permission, name}) => {
                                const value = deviceInfoConfiguration[permission];
                                return (
                                  <span key={permission} style={{ ...checkboxLabel }}>
                                    {name}
                                      <input
                                        type='checkbox'
                                        name={name}
                                        // value means 'hide' section (eq to 'not show'), so we need to set `!value` as checked param
                                        checked={!value}
                                        onChange={() => deviceInfoOptionChange(permission, !value)}
                                      />
                                </span>
                                );
                            })}

                            {/* Device info section order */}
                            <p style={{...sectionTitle}}>Device info section order</p>
                            <DragSortableList
                              type='vertical'
                              placeholder={<div />}
                              onSort={onDeviceSectionReorder}
                              items={deviceInfoSectionOrderItems.map((orderItem) => ({
                                  id: orderItem.id,
                                  content: (
                                    <div className={styles.deviceOrderItem}>{orderItem.name}</div>
                                  ),
                              }))}
                            />

                            {/* Map setup */}
                            <p style={{ ...sectionTitle }}>Map setup</p>
                            {mapSetup.map(({ permission, name, value, onChange }) => (
                              <span key={permission} style={{ ...checkboxLabel }}>
                                  {name}
                                  <input
                                    type='checkbox'
                                    name={name}
                                    checked={value}
                                    onChange={() => onChange(!value)}
                                  />
                                </span>
                            ))}

                            <p style={{ ...sectionTitle }}>Visibility setup</p>
                            {visibilitySetup.map(({ label, name, value, onChange }) => (
                              <span key={label} style={{ ...checkboxLabel }}>
                                  {name}
                                  <input
                                    type='checkbox'
                                    name={name}
                                    checked={value}
                                    onChange={() => onChange(!value)}
                                  />
                                </span>
                            ))}
                            <p style={{ ...sectionTitle }}>Travel report field visibility setup</p>
                            {travelReportFieldVisibility.map(({ label, name, value, onChange }) => (
                              <span key={label} style={{ ...checkboxLabel }}>
                                  {name}
                                  <input
                                    type='checkbox'
                                    name={name}
                                    checked={value}
                                    onChange={() => onChange(!value)}
                                  />
                                </span>
                            ))}
                            <p style={{ ...sectionTitle }}>Other setup</p>
                            {otherSetup.map(({ label, name, value, onChange }) => (
                              <span key={label} style={{ ...checkboxLabel }}>
                                  {name}
                                  <input
                                    type='checkbox'
                                    name={name}
                                    checked={value}
                                    onChange={() => onChange(!value)}
                                  />
                                </span>
                            ))}
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};
