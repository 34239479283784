import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Set } from 'immutable';
import { Link } from 'react-router-dom';
import useFavorite from '../../../../hooks/useFavorite';
import { useIsReadOnly } from '../../../../hooks/useIsReadOnly';
import { useAuthUserHasPermission } from '../../../../hooks/useAuthUserHasPermission';
import { ALL_DEVICES_TAG } from '../../../../shared/helpers';
import { UserCan } from '../../../../shared/constants';
import { iFullStoreState, ItemType } from '../../../../shared/interfaces';
import Dialog, { DialogConfigSetter } from '../../../Dialog';
import { TagsListItemProps } from './interfaces';
import styles from './TagsListItem.module.scss';
import { ReactComponent as StarFill } from '../../../../assets/svg/star_fill_w16.svg';
import { ReactComponent as StarBorder } from '../../../../assets/svg/star_border_w16.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/svg/visibility_16w.svg';
import { ReactComponent as EyeOffIcon } from '../../../../assets/svg/visibility_off_16w.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/delete_outline.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit_w16.svg';
import { ReactComponent as BellIcon } from '../../../../assets/svg/notification_16w.svg';


const TagsListItem: FunctionComponent<TagsListItemProps> = ({
  details,
  id,
  isViewed,
  isTagType,
  deleteItem,
  toggleTag,
}) => {
  const tagDevicesIdsSet = useSelector<iFullStoreState, Set<string>>((s) => s.tagsDevicesMap.tagsDevices.get(id, Set<string>()));

  // option configuration
  const isAllDeviceTag = (id === ALL_DEVICES_TAG);
  const canEditTags = useAuthUserHasPermission(UserCan.TAG_THINGS);
  const hasPermission = useIsReadOnly(id, ItemType.tag);
  const hideOption = isAllDeviceTag || !canEditTags || hasPermission;

  // tag name
  const displayName = details.name;
  const tagLink= isTagType ? `/tag/${id}` : '';
  const shouldShowLinkForTag = !(!tagLink || hideOption);

  // favorite
  const {
    toggle: handleFavoriteClick,
    isFavorite,
  } = useFavorite({ type: ItemType.tag, id });
  const Star = useMemo(() => isFavorite ? StarFill : StarBorder, [isFavorite])

  // device count
  const deviceCount = tagDevicesIdsSet.size;


  // dialog window
  const dialogRef = React.useRef<DialogConfigSetter>();
  const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

  // Options logic
  // view
  const handleViewTag = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleTag(id);
  };

  // delete
  const shouldShowDelete = !(!deleteItem || hideOption);
  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (deleteItem) {
      const dialog = dialogRef.current;

      // check confirm
      const confirmed = await dialog?.({
        type: 'CONFIRM',
        title: 'Warning!',
        body: (
          <div>
            <p>This will delete the tag <b>{displayName}</b> along with any associated geoFences or settings.</p>
            <p>Click OK to confirm.</p>
          </div>
        ),
      });

      // go out if user cancel confirmation
      if (!confirmed) return;

      deleteItem(id);
    }
  }

  const showBell = details.isAlertType;

  const ItemContainer = useMemo(() => (
    ({ children, ...props}) => (
      shouldShowLinkForTag ? (
        <Link to={tagLink} {...props}>{children}</Link>
      ) : (
        <div {...props}>{children}</div>
      )
    )
  ), [shouldShowLinkForTag]);

  return (
    <>
      <Dialog setupConfig={setupDialog} />

      <ItemContainer className={styles.tagsListItem}>
        <div className={styles.tag}>
          {showBell && <BellIcon className={styles.bellIcon} />}

          <p className={styles.tagName}>
            {
              shouldShowLinkForTag
                ? <Link to={tagLink} onClick={e => e.preventDefault()}>{displayName}</Link>
                : displayName
            }
          </p>

          <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              handleFavoriteClick();
            }}
            className={classNames(styles.favoriteButton, { [styles.favorite]: isFavorite })}
          >
            <Star className={styles.favoriteStar} />
          </button>
        </div>

        {/* Device */}
        {/* Don`t show device count if it's all device tag */}
        <p className={styles.device}>{isAllDeviceTag ? '' : deviceCount}</p>

        <div className={styles.options}>
          <button
            type='button'
            onClick={handleViewTag}
            className={styles.optionItem}
          >
            {!isViewed ? <EyeOffIcon className={styles.optionIcon} /> : <EyeIcon className={styles.optionIcon} />}
          </button>

          {(!!tagLink && !isAllDeviceTag) && (
            <Link
              to={tagLink}
              className={styles.optionItem}
            >
              <EditIcon className={styles.optionIcon} />
            </Link>
          )}

          {
            shouldShowDelete && (
              <button
                type='button'
                onClick={handleDelete}
                className={styles.optionItem}
              >
                <DeleteIcon
                  className={styles.optionIcon}
                />
              </button>
            )
          }
        </div>
      </ItemContainer>
    </>
  );
};

export default TagsListItem;
