import {makeTrip} from "../../../shared/db/report-db";
import {store} from "../../../stores/store";
import {iReportPermissionData} from "../../../shared/interfaces";
import {clientDb} from "../../../shared/firebase";
import {formatWhenLast} from "../devices/device-page";

export const compareArrays = (arr1, arr2) => {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const formattingData = (docs) => {
  const trips = docs?.reduce((acc, curr) => {
    const tripId = curr.id;
    const trip = makeTrip(curr);

    return { ...acc, [tripId]: trip }
  }, {});
  return trips;
}

export const formatPermissions = (permissions) => {
  const state = store.getState();

  const people = state.general.people;
  const devicesDetails = state.devicesData.devicesDetails;

  const updatedPermissions = permissions.map(permission => {
    const personInfo = people[permission.person];

    const deviceDetails = permission.devices.length ? permission.devices.map(device => {
      const deviceInfo = devicesDetails.getIn([device.id]) || {};

      return {
        id: device.id,
        name: deviceInfo.name,
        device: device.device,
        readOnly: device.readOnly,
        tag: device.tag,
        deviceName: deviceInfo.name
      };
    }) : [];

    return {
      displayName: personInfo.displayName,
      devices: deviceDetails,
    };
  });

  return updatedPermissions;
}

export async function getPermissionsForUsers(persons){
  const userPermissionRecords: iReportPermissionData[] = [];

  for (const person of persons) {
    const devices = [];

    const personsDevicesSnapshot = await clientDb().child(`acl/items-allowed/${person}/device`).once('value');
    const personsDevicesSnapshotValue = personsDevicesSnapshot.val();

    if (personsDevicesSnapshotValue) {
      for (const [id, deviceData] of Object.entries(personsDevicesSnapshotValue)) {
        let device;
        let tag;
        let readOnly: unknown = true;

        const personDevice = {};

        personDevice['id'] = id;

        for (const [key, value] of Object.entries(deviceData)){
          if (value === 'tag'){
            tag = key;
          }
          else if (value === 'device'){
            device = key;
          }
          else if (key === 'readOnly'){
            readOnly = value;
          }
        }

        if (tag){
          const tagInfoSnapshot = await clientDb().child(`tags/${tag}/details`).once('value');
          const tagInfoValue = tagInfoSnapshot.val();
          const tagPermissionSnapshot = await clientDb().child(`tags/${tag}/instances/allowed-see/readOnly`).once('value');
          const tagPermissionValue = tagPermissionSnapshot.val();

          if (tagPermissionValue && Object.keys(tagPermissionValue).includes(person)){
            readOnly = true;
          }

          personDevice['tag'] = tagInfoValue.name;
        }

        if (device){
          personDevice['device'] = device;
        }

        personDevice['readOnly'] = readOnly;

        devices.push(personDevice);
      }
    }

    userPermissionRecords.push({
      person,
      devices
    });
  }

  return formatPermissions(userPermissionRecords);
}

export async function getActivityReports(ids){
  const devices = [];

  const state = store.getState();
  const devicesLastPing = state.devicesData.devicesLastPing;
  const devicesDetails = state.devicesData.devicesDetails;


  for (const id of ids) {
    try {
      const data = devicesLastPing.getIn([id]);
      const deviceInfo = devicesDetails.getIn([id]);
      const time = data && data.time ? data.time.unix() : 0;

      const ago = data ? formatWhenLast(data?.time) : 'No info';
      if (deviceInfo) {
        devices.push({
          deviceId: deviceInfo.id,
          deviceName: deviceInfo.name || '',
          time,
          ago
        })
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  const sortedDevices = devices.sort((a, b) => {
    if (a.time === 0 && b.time === 0) return 0;
    if (a.time === 0) return 1;
    if (b.time === 0) return -1;
    return a.time - b.time;
  });

  return sortedDevices;
}

export const formatAlertWithHas = (alert: string) => 'has' + alert.toLowerCase();

export const getDevicesFromTag = async (tag) => {
  const tagDevicesSnapshot = await clientDb().child(`tags/${tag}/instances/device`).once('value');
  const tagDevices = tagDevicesSnapshot.val();

  return Object.keys(tagDevices);
}

export const getDeviceName = async (deviceId) => {
  const deviceSnapshot = await clientDb().child(`devices/device-details/${deviceId}`).once('value');
  const device = deviceSnapshot.val();

  return device.name;
}
