import React, {useEffect, useState} from 'react';
import {faChevronDown, faChevronRight, faPencilAlt} from '@fortawesome/fontawesome-free-solid';

import {ItemType, MenuPinType} from '../../../../shared/interfaces';
import {Fa, FaLink} from '../../../elements/fa';
import {MenuPin} from "../../../elements/MenuPin";
import TagsIcon from '../../../../assets/svg/tags_24px.svg';
import styles from './DeviceTags.module.scss';
import TagLabel from '../../tags-info/TagLabel';

export const DeviceTags = ({deviceId, tags, isPinned, isDriver, readOnly, title, cssOrder}) => {
  const [collapsed, setCollapsed] = useState<boolean>(isPinned);

  useEffect(() => {
    setCollapsed(isPinned);
  }, [isPinned]);

  return (
    <div className={styles.DeviceTags} style={{order: cssOrder}}>
      <div className={styles.DeviceTagsHeader}>
        <div className={styles.DeviceTagsHeaderTitle}>
          <img src={TagsIcon} alt='Tags' style={{width: '16px', height: '16px'}}/>
          <span>{title}</span>
        </div>
        <div className={styles.DeviceTagsHeaderBtns}>
          {deviceId && collapsed && <MenuPin property={MenuPinType.TAGS} isPinnedProp={isPinned}/>}
          {!isDriver && !readOnly && <FaLink icon={faPencilAlt} to={`/${ItemType.device}/${deviceId}/add/tags`}
                                             style={{color: '#111', cursor: 'pointer'}}/>}
          <Fa style={{cursor: 'pointer', width: '20px', height: '20px', color: '#111', opacity: 0.5}} onClick={() => setCollapsed(!collapsed)} icon={!collapsed ? faChevronRight : faChevronDown}/>
        </div>
      </div>
      {collapsed && <div>
          <div className={styles.tagsList}>
            {!tags ? 'None Yet' : (tags as any).map(({val: tag}) => (
              <TagLabel
                key={tag.details.id}
                name={tag.details.name}
              />
            ))}
          </div>
      </div>}
    </div>
  )
};
