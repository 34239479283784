import React, {useState} from "react";
import {useRedux} from '../../../../states/redux-state';
import {
    exportReport,
    exportReportPdf,
    exportSummary,
    exportTimeClockReportCsv,
    exportTimeClockReportPdf,
    exportReportXlsx,
    exportTimeClockReportXlsx,
    exportPermissionsPdf,
    exportPermissionsXlsx,
    exportPermissionsCsv,
    exportTravelReport,
    exportActivityReport,
    exportStaticReport
} from '../report-export-helper';
import "./index.scss";
import {useDispatch} from "react-redux";
import {GMapModalAC} from "../../../../stores/reducers/gMapModal/AC";
import {ReactComponent as PdfSvg} from "../../../../assets/svg/pdf.svg";
import {ReactComponent as CsvSvg} from "../../../../assets/svg/csv.svg";
import {ReactComponent as XlsSvg} from "../../../../assets/svg/xls.svg";
import {Checkbox} from "../../../Checkbox";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import { iDevicePingWithGeofences, iList } from "../../../../shared/interfaces";
import { selectDeviceOrPerson, selectReportsGrouped } from "../../../../stores/reducers/report-selectors";
import { exportGeofenceReportCsv, exportGeofenceReportPdf, exportGeofenceReportXlsx } from "../GeofenceReport/helpers";
import useOneDeviceReportUnits from "../ReportPage/useOneDeviceReportUnits";

export const ExportReport = () => {
    const {
        reportType,
        reportDetails,
        devicesDetails,
        people,
        displayRecordsGrouped,
        displayRecords,
        deviceOrPerson,
        geofenceReportsGrouped,
		    reportDates,
        permissionPersons,
        travelTripIds
    } = useRedux(s => ({
        reportType: s.report.details.reportType,
        reportDetails: s.report.details,
        devicesDetails: s.devicesData.devicesDetails,
        people: s.general.people,
        displayRecordsGrouped: selectReportsGrouped(s),
        displayRecords: s.report.displayRecords,
        deviceOrPerson: selectDeviceOrPerson(s),
        geofenceReportsGrouped: selectReportsGrouped(s),
		    reportDates: s.report.details.dates,
        permissionPersons: s.report.permissionPersons,
        travelTripIds: s.report.travelTripIds
    }));

	const deviceUnits = useOneDeviceReportUnits();

    const handleExport = () => {
        const formats = {
            exportPdf,
            exportXlsx,
            exportCsv
        };

        if (exportCsv) {
            if (reportType === "geofence") exportGeofenceReportCsv({devices: devicesDetails, people, deviceOrPerson, geofenceReportsGrouped: (geofenceReportsGrouped  as iList<iDevicePingWithGeofences[]>)});
            if (reportType === "time-clock") exportTimeClockReportCsv({displayRecords: displayRecords, devicesDetails, people})();
            if (reportType === "summary") exportSummary(displayRecordsGrouped, deviceUnits, devicesDetails, reportDates, 'csv');
            if (reportType === "permissions") exportPermissionsCsv(permissionPersons);
        }
        if (exportXlsx) {
            if (reportType === "geofence") exportGeofenceReportXlsx({devices: devicesDetails, people, deviceOrPerson, geofenceReportsGrouped: (geofenceReportsGrouped  as iList<iDevicePingWithGeofences[]>)});
            if (reportType === "time-clock") exportTimeClockReportXlsx({records: displayRecordsGrouped, reportData: devicesDetails, people});
            if (reportType === "summary") exportSummary(displayRecordsGrouped, deviceUnits, devicesDetails, reportDates, 'xlsx');
            if (reportType === "permissions") exportPermissionsXlsx(permissionPersons);
        }
        if (exportPdf) {
            if (reportType === "geofence") exportGeofenceReportPdf({devices: devicesDetails, people, deviceOrPerson, geofenceReportsGrouped: (geofenceReportsGrouped  as iList<iDevicePingWithGeofences[]>)});
            if (reportType === "time-clock") exportTimeClockReportPdf({records: displayRecordsGrouped, reportData: devicesDetails, people});
            if (reportType === "summary") exportSummary(displayRecordsGrouped, deviceUnits, devicesDetails, reportDates, 'pdf');
            if (reportType === "permissions") exportPermissionsPdf(permissionPersons)
        }

        if (reportType === "travel") {
            exportTravelReport({reportData: devicesDetails, report: reportDetails, people, travelTripIds, formats})
        }

        if (reportType === 'static') {
            exportStaticReport({displayRecords, devicesDetails, people, reportDetails, formats});
        }

        if (reportType === 'activity') {
          exportActivityReport({formats, displayRecords});
        }
    }

    const dispatch = useDispatch();
    const handleCancel = () => dispatch(GMapModalAC.hideModal());

    const [ exportPdf, setExportPdf ] = useState(false);
    const toggleExportPdf = () => setExportPdf(b => !b);
    const [ exportXlsx, setExportXlsx ] = useState(false);
    const toggleExportXlsx = () => setExportXlsx(prevState => !prevState);
    const [ exportCsv, setExportCsv ] = useState(false);
    const toggleExportCsv = () => {
        setExportCsv(b => !b);
    };

    return (
        <div className="export-report-container">
            <div className="export-report-header"><h3>Export report</h3></div>
            <div className="export-report-content">
                <div className="export-report-content-row" onClick={toggleExportPdf}>
                    <label
                        className={`export-report-label ${exportPdf? "active": ""}`}>
                            <PdfSvg />&nbsp;PDF
                    </label>
                    <Checkbox checked={exportPdf}/>
                </div>
                <div className="export-report-content-row" onClick={toggleExportXlsx}>
                    <label
                        className={`export-report-label ${exportXlsx? "active": ""}`}>
                            <XlsSvg />&nbsp;XLS
                    </label>
                    <Checkbox checked={exportXlsx} />
                </div>
                <div className="export-report-content-row" onClick={toggleExportCsv}>
                    <label
                        className={`export-report-label ${exportCsv? "active": ""}`}>
                            <CsvSvg />&nbsp;CSV
                    </label>
                    <Checkbox checked={exportCsv} />
                </div>
            </div>
            <div className="export-report-actions">
                <Button
                  styleType={ButtonStyles.WHITE_GRAY}
                  size={ButtonSize.LG}
                  onClick={handleCancel}>Cancel</Button>
                <Button
                  styleType={ButtonStyles.BLACK_WHITE}
                  size={ButtonSize.LG}
                  onClick={handleExport}>Export</Button>
            </div>
        </div>
    )
}