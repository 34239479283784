import React, {useEffect, useState} from 'react';
import {faChevronDown, faChevronRight, faPencilAlt} from '@fortawesome/fontawesome-free-solid';

import {iList, iPerson, MenuPinType} from '../../../../shared/interfaces';
import {getDeviceAllowedSee} from '../../../../shared/db/general-db';
import TableSimple from '../../../elements/TableSimple';
import {Fa, FaLink} from '../../../elements/fa';
import {MenuPin} from "../../../elements/MenuPin";
import PeopleIcon from '../../../../assets/svg/people.svg';
import styles from './VisibleToPeople.module.scss';

export const DeviceVisibleToPeople = (props: {deviceId: string; deviceTags: string[], people: iList<iPerson>, isPinned: boolean, title: string, cssOrder?: number}) => {
  const [peopleIds, setPeopleIds] = useState([]);
  const {people, isPinned, deviceId} = props;
  const [collapsed, setCollapsed] = useState<boolean>(isPinned);

  useEffect(() => {
    setCollapsed(isPinned);
  }, [isPinned]);

  useEffect(() => {
    const {deviceId, deviceTags} = props;

    const getDevicesAllowedOnMount = async () => {
      const peopleIds = await getDeviceAllowedSee(deviceId, deviceTags);

      setPeopleIds(peopleIds);
    }

    getDevicesAllowedOnMount();
  }, []);

  const peopleIsEmpty = peopleIds.length === 0;

  return (
    <div className={styles.VisibleToPeople} style={{order: props.cssOrder}}>
      <div className={styles.VisibleToPeopleHeader}>
        <div className={styles.VisibleToPeopleHeaderTitle}>
          <img src={PeopleIcon} alt='Visible to...' style={{width: '16px', height: '16px'}}/>
          <span>{props.title}</span>
        </div>
        <div className={styles.VisibleToPeopleHeaderBtns}>
          {deviceId && collapsed && <MenuPin property={MenuPinType.VISIBLE_TO_PEOPLE} isPinnedProp={isPinned}/>}
          <Fa style={{cursor: 'pointer', width: '20px', height: '20px', color: '#111', opacity: 0.5}} onClick={() => setCollapsed(!collapsed)} icon={!collapsed ? faChevronRight : faChevronDown}/>
        </div>
      </div>
      {collapsed && <div>
        {peopleIsEmpty ? null :
          <TableSimple sm striped headers={['Person', '']} style={{fontSize: 11}}>
            {peopleIds
              .filter((id) => people[id])
              .map((id) => people[id])
              .map((person) => <tr key={person.id}>
                <td>{person.displayName}</td>
                <td className="text-right"><FaLink icon={faPencilAlt} to={`/person/${person.id}/visible-devices`}
                                                   style={{fontSize: 13}}/></td>
              </tr>)}
          </TableSimple>
        }
      </div>}
    </div>
  )
};
