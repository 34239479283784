import { iLocation } from '../../../shared/interfaces';

export const SET_SELECTED_POINT = 'SET_SELECTED_POINT';
export const REMOVE_SELECTED_POINT = 'REMOVE_SELECTED_POINT';

export const setSelectedPoint = (deviceId: string, tripId: string, pointId: string, position: iLocation, isClicked: boolean = false, label: string = '') => ({
    type: SET_SELECTED_POINT,
    payload: {
        deviceId,
        tripId,
        pointId,
        position,
        isClicked,
        label
    },
});

export const removeSelectedPoint = () => ({
    type: REMOVE_SELECTED_POINT,
});
