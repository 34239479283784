import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';

import Dashboard from './components/pages/Dashboard';
import DeviceTabPage from './components/pages/devices/device-tab';
import DeviceTabEditPage from './components/pages/devices/DeviceTabPageEdit';
import DeviceAssignmentPage from './components/pages/devices/device-assignment';
import AllTagsInfoPage from './components/pages/tags-info/all-tag-info';
import ExtraInfoPage from './components/pages/tags-info/extra-info-page';
import TagDetailsPage from './components/pages/tags-info/tag-details';
import AllFavoritesPage from './components/pages/favorites/all-favs';
import LabelItem from './components/pages/tags-info/label-item';
import AddNote from './components/pages/notes/add-note';
import SettingsPage from './components/pages/settings';
import Devices from './components/pages/devices/device-page';
import People from './components/pages/PeoplePage/PeoplePage';
import AlertSettingsPage from './components/pages/AlertSettings';
import ReportPage from './components/pages/reports/ReportPage';
import ReportPageFilters from './components/pages/reports/ReportPageFilters';
import ScheduledReports from "./components/pages/reports/ScheduledReports";
import CreateReport from './components/pages/reports/CreateReport';
import Maintenance from './components/pages/reports/maintenance';
import ReportQueue from './components/pages/reports/report-queue';
import DeviceDetailsPage from './components/pages/devices/device-details';
import TimeLine from './components/menus/timeline';
import SearchMobile from './components/pages/SearchMobile';
import MaintenancePage from './components/pages/maintenance/MaintenancePage';
import { UserCan } from './shared/constants';
import { Billing } from './components/pages/Billing/BillingImports';
import AddSubUserPage from './components/pages/AddSubUserPage/AddSubUserPage';
import { PersonCanSee } from './components/pages/PeoplePage/PersonCanSee';
import { PersonEditPage } from './components/pages/PersonEditPage';
import { PersonTabPage } from './components/pages/PersonTabPage';
import { PersonVisibleTags } from './components/pages/PersonVisibleTags';
import { PeopleDefaultSettingsPage } from './components/pages/PeopleDefaultSettingsPage';
import { PersonVisibleRFIDTags } from './components/pages/PersonVisibleRFIDTags';
import GroupsEditPage from './components/pages/PersonTabPage/GroupsEditPage/GroupsEditPage';
import TagItem from './components/pages/tags-info/TagItem';
import Dvir from "./components/pages/Dvir";
import DvirForms from "./components/pages/Dvir/DvirForms";
import CreateDvirForm from "./components/pages/Dvir/CreateDvirForm";
import DvirForm from "./components/pages/Dvir/DvirForm";
import DvirFormEdit from "./components/pages/Dvir/DvirFormEdit";
import CreateDvir from "./components/pages/Dvir/CreateDvir";
import DvirItem from "./components/pages/Dvir/DvirItem";
import DvirUpdate from "./components/pages/Dvir/DvirUpdate";

const getRedirectTo = ({ isDriver }) => (
  <Redirect to={isDriver ? '/device' : '/dashboard'} />
);

interface RoutesMap {
  [key: string]: Array<RouteConfig>;
}

const seeTagsRoutes: Array<RouteConfig> = [
  {
    path: '/tags',
    exact: true,
    component: AllTagsInfoPage,
  },
];

const seeAllReports: Array<RouteConfig> = [
  {
    path: '/reports/scheduled',
    exact: true,
    component: ScheduledReports,
  },
];

const protectedRoutes: RoutesMap = {
  [UserCan.SEE_TAGS]: seeTagsRoutes,
  [UserCan.SEE_ALL_REPORTS]: seeAllReports,
};
export const getRoutes = (userCan) => {
  let routes: Array<RouteConfig> = [...Routes];
  Object.keys(userCan).forEach((permissionKey) => {
    if (userCan[permissionKey]) {
      routes = [...routes, ...(protectedRoutes[permissionKey] || [])];
    }
  });
  return routes;
};

export const Routes: Array<RouteConfig> = [
  {
    path: '/',
    exact: true,
    component: getRedirectTo,
  },
  // {
  //     path: '/dashboardAd',
  //     exact: true,
  //     component: DashboardWithAdModal,
  // },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/settings',
    exact: true,
    component: SettingsPage,
  },
  {
    path: '/billing',
    component: Billing,
  },
  {
    path: '/dvir',
    component: Dvir,
    exact: true
  },
  {
    path: '/dvir-forms/new',
    component: CreateDvirForm,
  },
  {
    path: '/dvir-forms',
    exact: true,
    component: DvirForms,
  },
  {
    path: '/dvir-forms/:id',
    exact: true,
    component: DvirForm,
  },
  {
    path: '/dvir-forms/:id/edit',
    component: DvirFormEdit,
    exact: true
  },
  {
    path: '/dvir/:id',
    component: DvirItem,
    exact: true
  },
  {
    path: '/dvir/:id/update',
    component: DvirUpdate,
    exact: true
  },
  {
    path: '/reports',
    exact: true,
    component: () => <Redirect to='/reports/new/travel' />,
  },
  {
    path: '/reports/create-report',
    exact: true,
    component: CreateReport,
  },
  {
    path: '/reports/new/maintenance',
    exact: true,
    component: Maintenance,
  },
  {
    path: '/reports/scheduled',
    exact: true,
    component: ScheduledReports,
  },
  {
    path: '/reports/queue',
    exact: true,
    component: ReportQueue,
  },
  {
    path: '/reports/new/:type(travel|static|geofence|summary|time-clock|audit-log|video|permissions|activity)?',
    exact: false,
    component: ReportPage,
  },
  {
    path: '/reports/:type(travel|static|geofence|summary|time-clock|audit-log|video|permissions|activity)/filters/:filterItem(device|person|tag|alerts|labels)?',
    exact: true,
    component: ReportPageFilters,
  },
  {
    path: '/device',
    exact: true,
    component: Devices,
  },
  {
    path: '/device/:id',
    exact: false,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/alerts/:eventKey',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/add/extra-info',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/add/tags',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/notes/:action/:noteId',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/person',
    exact: true,
    component: People,
  },
  {
    path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/:itemType/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/tag/:tagId',
    exact: true,
    component: TagDetailsPage,
  },
  {
    path: '/public-view/:tagId',
    exact: true,
    component: TagDetailsPage,
  },
  {
    path: '/favorites',
    exact: true,
    component: AllFavoritesPage,
  },
  {
    path: '/search-mobile',
    exact: true,
    component: SearchMobile,
  },
  {
    path: '/extra-info',
    exact: true,
    component: ExtraInfoPage,
  },
  {
    path: '/:type/:itemId/add/extra-info', // :type(device|person|tag)
    exact: true,
    component: LabelItem,
  },
  {
    path: '/person/:personId/default-settings',
    exact: true,
    component: PeopleDefaultSettingsPage,
  },
  {
    path: '/:type/:itemId/add/tags', // :type(device|person)
    exact: true,
    component: TagItem,
  },
  {
    path: '/person/:id',
    exact: true,
    component: PersonTabPage,
  },
  {
    path: '/person/:id/rfid-tags',
    exact: true,
    component: PersonVisibleRFIDTags,
  },
  {
    path: '/person/:id/visible-devices',
    exact: true,
    component: PersonCanSee,
  },
  {
    path: '/person/:id/visible-tags',
    exact: true,
    component: PersonVisibleTags,
  },
  {
    path: '/person/:id/edit',
    exact: true,
    component: PersonEditPage,
  },
  {
    path: '/person/:id/groups',
    exact: true,
    component: GroupsEditPage,
  },
  {
    path: '/create-sub-user',
    exact: true,
    component: AddSubUserPage,
  },
  {
    path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
    exact: true,
    component: AddNote,
  },
  {
    path: '/maintenance',
    exact: true,
    component: MaintenancePage,
  },
];

export const sharedRoutes: Array<RouteConfig> = [
  {
    path: '/',
    exact: true,
    component: getRedirectTo,
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/settings',
    exact: true,
    component: SettingsPage,
  },
  {
    path: '/device',
    exact: true,
    component: Devices,
  },
  {
    path: '/device/:id',
    exact: false,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/alerts/:eventKey',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/add/extra-info',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/add/tags',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/device/:itemId/notes/:action/:noteId',
    exact: true,
    component: DeviceTabPage,
  },
  {
    path: '/person',
    exact: true,
    component: People,
  },
  {
    path: '/create-sub-user',
    exact: true,
    component: AddSubUserPage,
  },
  {
    path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/:itemType/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/tag/:tagId',
    exact: true,
    component: TagDetailsPage,
  },
  {
    path: '/favorites',
    exact: true,
    component: AllFavoritesPage,
  },
  {
    path: '/search-mobile',
    exact: true,
    component: SearchMobile,
  },
  {
    path: '/extra-info',
    exact: true,
    component: ExtraInfoPage,
  },
  {
    path: '/:type/:itemId/add/extra-info', // :type(device|person|tag)
    exact: true,
    component: LabelItem,
  },
  {
    path: '/:type/:itemId/add/tags', // :type(device|person)
    exact: true,
    component: TagItem,
  },
  {
    path: '/person/:id',
    exact: true,
    component: PersonTabPage,
  },
  {
    path: '/person/:id/edit',
    exact: true,
    component: PersonEditPage,
  },
  {
    path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
    exact: true,
    component: AddNote,
  },
  {
    path: '/reports/new/maintenance',
    exact: true,
    component: Maintenance,
  },
  {
    path: '/maintenance',
    exact: true,
    component: MaintenancePage,
  },
];

export const mobileRoutes: Array<RouteConfig> = Routes;

// export const mobileRoutes: Array<RouteConfig> = [
//     ...sharedRoutes,
//     {
//         path: '/reports',
//         exact: true,
//         component: () => <Redirect to="/reports/travel" />,
//     },
//     {
//         path: '/reports/create-report',
//         exact: true,
//         component: CreateReportMobile,
//     },
//     {
//         path: '/reports/scheduled',
//         exact: true,
//         component: ScheduledReportsMobile,
//     },
//     {
//         path: '/reports/queue',
//         exact: true,
//         component: ReportQueueMobile,
//     },
//     {
//         path: '/reports/:type(travel|static)/filters/:filterItem(device|person|tag|alerts|labels)?',
//         exact: false,
//         component: ReportPageFiltersMobile,
//     },
//     {
//         path: '/reports/:type(travel|static)?',
//         exact: true,
//         component: ReportPageMobile,
//     },
// ];

export const deviceRoutes: Array<RouteConfig> = [
  {
    path: '/device/:id',
    exact: true,
    component: DeviceDetailsPage,
  },
  {
    path: '/device/:deviceId/timeline',
    exact: true,
    component: TimeLine,
  },
  {
    path: '/device/:id/edit',
    exact: true,
    component: DeviceTabEditPage,
  },
  {
    path: '/device/:deviceId/assign',
    exact: true,
    component: DeviceAssignmentPage,
  },
  {
    path: '/:type/:itemId/add/tags', // :type(device|person)
    exact: true,
    component: TagItem,
  },
  {
    path: '/:type(device|person|tag)/:itemId/add/extra-info', // :type(device|person|tag)
    exact: true,
    component: LabelItem,
  },
  {
    path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/:itemType/:itemId/alerts/:eventKey',
    exact: true,
    component: AlertSettingsPage,
  },
  {
    path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
    exact: true,
    component: AddNote,
  },
];
