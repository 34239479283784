import React, { useState } from 'react';
import { compose } from '@bem-react/core';

import { withSentry } from '../../../shared/hoc/withSentry';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import DevicePeopleToggle from './device-people-toggle';
import { ACL, UserCan } from '../../../shared/constants';
import { useSelector } from 'react-redux';
import { iFullStoreState } from '../../../shared/interfaces';
import { Redirect } from 'react-router-dom';

const Dashboard = () => {
    localStorage.removeItem("doNotShowAdModalAgain");

  const userCanDo = useSelector<iFullStoreState, Array<string>>((s) => s.auth.user?.acl?.can);
  const hideDashboard = ACL.check(UserCan.HIDE_DASHBOARD_PAGE, userCanDo, true);

  // https://mastrack.atlassian.net/browse/MAS-1359
  if (hideDashboard) return <Redirect to='/device' />

    return (
        <DashboardBlock>
            <ModalHeader title="Recent" />
            <DevicePeopleToggle />
        </DashboardBlock>
    )
};


export default withSentry(Dashboard);
