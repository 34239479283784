import React from 'react';
import { path } from 'ramda';
import { useDispatch } from 'react-redux';
import { faTrashAlt } from '@fortawesome/fontawesome-free-solid';

import { iTrip, iPerson, iDevicePing } from "../../../../../shared/interfaces";
import { useRedux } from "../../../../../states/redux-state";
import Dialog, { DialogConfigSetter } from '../../../../Dialog';
import { setTripCol, setPointCol } from '../../../../../shared/db/report-db';
import SmartPencil from '../../tables/smart-edit-pencil';
import { Row } from '../../../../elements/flex';
import { Actions as ReportActions } from '../../../../../stores/reducers/report-reducers';
import { Fa } from '../../../../elements/fa';

import { ReactComponent as PersonIcon } from "../../../../../assets/svg/person_report_24px.svg";
import { ReactComponent as BookmarksIcon } from "../../../../../assets/svg/bookmarks_report_24px.svg";

type iProps = {
    field: 'label'|'personId',
    type: 'trip',
    row: iTrip,
    isStatic?: boolean
} |
{
    field: 'label'|'personId',
    type: 'point',
    row: iDevicePing,
    isStatic?: boolean
}

export const LabelOrPersonRow = ({type, row: rowIn, field, isStatic}: iProps) => {
    const idField = type === 'trip' || (type === 'point' && !!rowIn.tripId) ? 'tripId' : 'pointId';
    const rowId = rowIn[idField];

    const isLabel = field === 'label';

    const person = useRedux<iPerson|undefined>(path(['general', 'people', rowIn.personId]));

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): void => {
        dialogRef.current = callBack();
    }

    const dispatch = useDispatch();

    const setterMaker = type === 'trip' || rowIn.tripId ? setTripCol : setPointCol;

    const deleteItem = async () => {
        const dialog = dialogRef.current;
        const confirmed = await dialog?.({
            type: 'CONFIRM',
            title: 'Confirm Delete',
            body: `Are you sure you want to remove this ${isLabel ? 'label' : 'person'}?`
        });
        if (!confirmed) return;

        const setter = (await setterMaker(field, rowId))(null);

        // optimistic update
        const oldVal = rowIn[idField];
        dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING({[idField]: rowId}, field, undefined));

        try {
            await setter;
        } catch (e) {
            dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING({[idField]: rowId}, field, oldVal));
            dialog?.({
                type: 'NOTIFICATION',
                title: 'Problem',
                body: `Problem encountered trying to unassign this ${isLabel ? 'label' : 'person'}`
            })
        }
    }

    const currentVal =
        field === 'label'
            ? rowIn.label || ''
            : person ? person.id : undefined;

    return <>
        {isStatic ? (
          <Row style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4px'
          }}>
              {!currentVal ? <SmartPencil
                currentValue={currentVal}
                style={{marginRight: 5}}
                id={rowId}
                updateType={type}
                pickType={field}
                expandingEdit={type === 'point' && !!rowIn.tripId}
                showIcon={true}
                isStatic={isStatic}
                isStaticAdd={isStatic && !(!!currentVal)}
              /> : (field === 'label' ? <BookmarksIcon/> : <PersonIcon/>)}
              <span style={{
                  color: '#111',
                  fontSize: '11px',
                  fontWeight: 500,
                  textDecoration: 'underline'
              }}>{field === 'label' ? currentVal : person?.displayName}</span>
              {currentVal && <SmartPencil
                currentValue={currentVal}
                style={{marginRight: 5}}
                id={rowId}
                updateType={type}
                pickType={field}
                expandingEdit={type === 'point' && !!rowIn.tripId}
                showIcon={true}
                isStatic={isStatic}
                isStaticAdd={false}
              />}
          </Row>
          ) : (
          <Row>
              {!currentVal
                ? <SmartPencil
                  currentValue={currentVal}
                  style={{marginRight: 5}}
                  id={rowId}
                  updateType={type}
                  pickType={field}
                  expandingEdit={type === 'point' && !!rowIn.tripId}
                  showIcon={false}
                />
                : <Fa
                  icon={faTrashAlt}
                  style={{color: 'darkred', cursor: 'pointer ', marginRight: 5}}
                  onClick={deleteItem}
                />
              }
              {field === 'label' ? currentVal : person?.displayName/*<span onClick={deleteItem}>Delete</span>*/}
          </Row>
        )}
        <Dialog setupConfig={setupDialog} />
    </>;
}
