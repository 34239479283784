import React, {CSSProperties as css, useEffect, useState} from 'react';
import moment from 'moment';
import { MAP } from 'react-google-maps/lib/constants';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import {useDispatch, useSelector} from 'react-redux';
import {
    equals,
    pathOr,
    pipe,
    mapObjIndexed,
    when,
    always,
    keys,
    evolve
} from 'ramda';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {Row} from '../../elements/flex';
import * as devicedb from '../../../shared/db/devices-db';
import C, {ACL, UserCan} from '../../../shared/constants';
import StandardTile from '../../general/StandardTile';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import {idValArr} from '../../../shared/helpers';
import Notepad from '../../general/notepad';
import {AlertToggles} from '../../general/alert-toggle';
import {useRedux} from '../../../states/redux-state';
import {useFirebase} from '../../../states/firebase-state';
import {ExtraInfo} from '../../elements/extra-info';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {history} from '../../../stores/store';
import {useIsReadOnly} from '../../../hooks/useIsReadOnly';
import {Actions as MapActions, defaultBounds} from '../../../stores/reducers/gmap-reducers';
import {
    ItemType,
    iFullStoreState,
    iDeviceDetails,
    iDevicePing,
    iPerson,
    iList,
    iNote,
    MenuPinType, ExtraInfoFields,
} from '../../../shared/interfaces';
import DeviceHeader from './DeviceHeader';
import {EngineCodeTable} from './DeviceDetails/EngineCodeTable';
import {CheckEngineOverlay} from './DeviceDetails/CheckEngineOverlay';
import {clientDb} from '../../../shared/firebase';
import ModalImage from '../../ModalImage';
import HistoryIcon from '../../../assets/svg/history-icon.svg';
import {
    faPencilAlt
} from '@fortawesome/fontawesome-free-solid';
import DeviceEngineHistory from "./DeviceEngineHistory";
import {DeviceVisibleToPeople} from "./VisibleToPeople";
import {DeviceAssignedDriver} from "./AssignedDriver";
import {DeviceTags} from "./DeviceTags";
import {DeviceButtons} from "./DeviceButtons";
import {getExtraInfoKeyByName} from "../../../utils";
import {getHideExtraInfoValue} from "../../../shared/db/extra-info-db";
import {
    DeviceInfoSectionOrderItem,
    getDeviceInfoSectionFullOrder,
} from '../../../shared/db/general-db';

export default ({match: {params: {id: deviceId}}}) => {
    const dispatch = useDispatch();
    const config = useRedux((s) => s.general.config);
    const auth = useRedux((s) => s.auth);
    const {user: authUser, isDriver} = auth;

    const device = useSelector<iFullStoreState, iDeviceDetails | undefined>(
      (state) => state.devicesData.devicesDetails.get(deviceId),
      (l, r) => equals(l, r),
    );
    const currentLabels = useRedux((s) => tagSelectors.getItemLabelNameValMap(s, {type: ItemType.device, itemId: deviceId}));

    const countPerson = useSelector<iFullStoreState, number>((s) => Object.keys(s.general.people).length);

    const assignedPersonId = device?.assignedTo?.personId;
    const assignedPerson = useRedux<iPerson|false>(pathOr(false, ['general', 'people', assignedPersonId]));
    const isDriverDevice = assignedPersonId === authUser.uid && isDriver;

    const people = useRedux((s) => s.general.people);

    const allTags = useRedux((s) => s.general.tags);
    const itemTags = React.useMemo(() => tagSelectors.itemTags(allTags, ItemType.device, deviceId), [allTags, deviceId]);

    const [CEOverlay, setCEOverlay] = React.useState(false);
    const [deviceNotes] = useFirebase<iList<iNote>>(`notes/device/${deviceId}/`, mapObjIndexed(evolve({date: moment})));
    const readOnly = useIsReadOnly(deviceId, ItemType.device)
    const dialogRef = React.useRef<DialogConfigSetter>();
    const [menuPinSettings, setMenuPinSettings] = useState<{[key: string]: boolean}>({});
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [filteredLabels, setFilteredLabels] = useState([]);
    const [wasRedirected, setWasRedirected] = useState<boolean>(false);
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const menuTitlesForDevicePage = {
        tags: process.env.REACT_APP_TAGS_DEVICE_MENU_ITEM_NAME || 'Tags',
        extraInfo: process.env.REACT_APP_EXTRA_INFO_DEVICE_MENU_ITEM_NAME || 'Extra Info',
        assignedDriver: process.env.REACT_APP_ASSIGNED_DRIVER_DEVICE_MENU_ITEM_NAME || 'Assigned Driver',
        alerts: process.env.REACT_APP_ALERTS_DEVICE_MENU_ITEM_NAME || 'Alerts',
        engineCodeHistory: process.env.REACT_APP_ENGINE_CODE_HISTORY_DEVICE_MENU_ITEM_NAME || 'Engine Code History',
        visibleTo: process.env.REACT_APP_VISIBLE_TO_DEVICE_MENU_ITEM_NAME || 'Visible to...',
        notes: process.env.REACT_APP_NOTES_MENU_ITEM_NAME || "Notes",
    }

    // TODO: CONDITIONAL HOOKS
    // there is hooks below that are useing devie as a parameter
    // need to remove that condition and remain the same behaviour
    if (!device) return null;

    const editDevice = () => {
        if (readOnly) {
            const dialog = dialogRef.current;

            dialog?.({
                type: 'NOTIFICATION',
                title: 'Reed only device!',
                body: 'This device is read only',
            });
        } else {
            history.push(`/device/${deviceId}/edit`);
        }

    };

    const overlay = when(Boolean, () => <CheckEngineOverlay
      deviceId={device.id}
      dismiss={pipe(always(device.id), devicedb.dismissCheckEngine, () => setCEOverlay(false))}
      close={() => setCEOverlay(false)}
    />, CEOverlay);

    const tags = idValArr(itemTags || {});
    const isReadOnly = useIsReadOnly(deviceId, ItemType.device);

    const userCanDo = useSelector<iFullStoreState, Array<string>>(
      (s) => s.auth.user?.acl?.can
    );
    const canEditNotes = ACL.check(UserCan.EDIT_NOTES, userCanDo, true);
    const showTappedPeople =  ACL.check(UserCan.SEE_TAPPED_PEOPLE, userCanDo, true);
    const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>(state => state.gmap.mapRef());

    const lastLocation = useRedux((s) => s.devicesData.devicesLastPing.get(deviceId))?.coordinates?.location;
    const currentBounds = useRedux((s) => s.gmap.bounds);

    const imageClickHandler = (e) => {
        e.stopPropagation();
        setImageSrc(null);
    }

    const getFilteredLabels = async () => {
        const combinedValues = {};
        const namesToRemove = [ExtraInfoFields.YEAR, ExtraInfoFields.MAKE, ExtraInfoFields.MODEL];

        const labelEntries = await Promise.all(
          currentLabels.map(async (label) => {
              const [name, value] = label;

              const key = await getExtraInfoKeyByName(name);
              const hideValue = await getHideExtraInfoValue(key);

              if (namesToRemove.includes(name)) {
                  combinedValues[name] = value;

                  return null;
              }

              return hideValue !== true ? label : null;
          })
        );

        const newLabels = labelEntries.filter((label) => label !== null);
        const yearValue = combinedValues[ExtraInfoFields.YEAR] || '';
        const makeValue = combinedValues[ExtraInfoFields.MAKE] || '';
        const modelValue = combinedValues[ExtraInfoFields.MODEL] || '';
        const combinedValue = `${yearValue} ${makeValue} ${modelValue}`;

        if (combinedValue.trim() !== '') {
            newLabels.unshift(['Make', combinedValue]);
        }

        setFilteredLabels(newLabels);
    };

    useEffect(() => {
        if(lastLocation?.lat < currentBounds.latSouth || lastLocation?.lat > currentBounds.latNorth ||
          lastLocation?.lng < currentBounds.lngWest || lastLocation?.lng > currentBounds.lngEast) {
            dispatch(MapActions.SET_BOUNDS(defaultBounds));
        }

    }, [lastLocation]);

    useEffect(() => {
        mapRef && mapRef.current && mapRef.current.context[MAP].setZoom(10);

        const getMenuPins = async () => {
            const fetchedMenuPinSettings = (await clientDb().child(`/people/people-details/${authUser.uid}/menuPin`).once('value')).val();
            if (fetchedMenuPinSettings) setMenuPinSettings(fetchedMenuPinSettings);
        }

        getMenuPins();
        getFilteredLabels();
    }, [])

    const [deviceInfoSectionOrder, setDeviceInfoSectionOrder] = useState<DeviceInfoSectionOrderItem[]>([]);
    useEffect(() => {
        getDeviceInfoSectionFullOrder().then((order) => {
            setDeviceInfoSectionOrder(order);
        });

        const wasRedirectedFromStorage = sessionStorage.getItem('redirectFromPoint') === 'true';
        setWasRedirected(wasRedirectedFromStorage);
        sessionStorage.removeItem('redirectFromPoint');
    }, []);

    const itemsCount = deviceInfoSectionOrder.length;
    const cssOrderValue = {};
    deviceInfoSectionOrder.forEach((curItem, index) => {
        // first item should have the smallest value
        // ans the last one should have '-1' value
        // so css order should calc as index - allCount
        cssOrderValue[curItem] = index - itemsCount;
    });

    const handleImageClick = (src) => {
        setImageSrc(src);
    }

    return <DashboardBlock overlay={overlay}>
        <ModalHeader
          title={device.name}
          action={{fa: faPencilAlt, title: 'Edit Device Information', click: () => editDevice(), isDisabled: isReadOnly}}
          role={isDriver}
        />
        <DeviceLastPingDetails device={device} setCEOverlay={setCEOverlay} isDriver={isDriver} />
        {/*<div style={{ display: 'flex', flexWrap: 'wrap', minHeight: 'min-content' }}>*/}
        {/*    {pipe(*/}
        {/*        pathOr([], ['externalData', 'keyVals']),*/}
        {/*        mapObjIndexed((v, k) => <LocalMinTile key={k} title={k}>{v}</LocalMinTile>),*/}
        {/*        values*/}
        {/*    )(device)}*/}
        {/*</div>*/}

        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'min-content', paddingTop: 10, rowGap: 15 }}>

            {/*<div style={{borderTop: '2px solid' + C.primaryText, marginTop: 4, marginBottom: 6}}/>*/}

            {showTags && <DeviceTags
              deviceId={deviceId}
              tags={tags}
              isPinned={menuPinSettings[MenuPinType.TAGS] || wasRedirected || false}
              isDriver={isDriver}
              readOnly={readOnly}
              title={menuTitlesForDevicePage.tags}
              cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.TAGS]}
            />}
            <ExtraInfo
                editUrl={`/${ItemType.device}/${deviceId}/add/extra-info`}
                labels={filteredLabels}
                role={isDriver}
                readOnly={isReadOnly}
                itemId={device.id}
                itemType={ItemType.device}
                isPinned={menuPinSettings[MenuPinType.EXTRA_INFO] || false}
                property={MenuPinType.EXTRA_INFO}
                imageClick={handleImageClick}
                title={menuTitlesForDevicePage.extraInfo}
                cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.EXTRA_INFO]}
            />
            {(config?.strictAccess && countPerson > 1 && authUser.acl.can.includes(UserCan.DO_ANYTHING)) && <DeviceVisibleToPeople
              deviceId={deviceId}
              deviceTags={keys(itemTags)}
              people={people}
              isPinned={menuPinSettings[MenuPinType.VISIBLE_TO_PEOPLE] || false}
              title={menuTitlesForDevicePage.visibleTo}
              cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.VISIBLE_TO]}
            />}
            {(countPerson > 1 && showTappedPeople) && <DeviceAssignedDriver
              deviceId={deviceId}
              isPinned={menuPinSettings[MenuPinType.ASSIGNED_DRIVER] || false}
              isDriver={isDriver}
              isDriverDevice={isDriverDevice}
              assignedPerson={assignedPerson}
              device={device}
              assignedPersonId={assignedPersonId}
              isReadOnly={isReadOnly}
              authUser={authUser}
              title={menuTitlesForDevicePage.assignedDriver}
              cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.ASSIGNED_DRIVER]}
            />}

            {
                !isDriver && (
                    <>
                        <AlertToggles
                          itemIdProps={deviceId}
                          itemTypeProps={ItemType.device}
                          style={{width: '100%'}}
                          itemId={device.id}
                          itemType={ItemType.device}
                          values={device.eventValues || {}}
                          eventKeys={device.deviceEvents}
                          deviceId={deviceId}
                          property={MenuPinType.ALERTS}
                          isPinned={menuPinSettings[MenuPinType.ALERTS] || false}
                          title={menuTitlesForDevicePage.alerts}
                          cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.ALERT]}
                        />
                        <DeviceEngineHistory
                          body={<EngineCodeTable deviceId={deviceId} />}
                          alwaysOpen={false}
                          title={menuTitlesForDevicePage.engineCodeHistory}
                          cssOrder={cssOrderValue[DeviceInfoSectionOrderItem.ENGINE_HISTORY]}
                        />
                        <div className="col3" style={{width: '100%', minWidth: 200, flex: 1, display: 'flex', flexDirection: 'column', order: cssOrderValue[DeviceInfoSectionOrderItem.NOTES]}}>
                            <Notepad
                                disabled={isReadOnly && !canEditNotes}
                                notes={deviceNotes}
                                deviceOrFence="device"
                                deviceOrFenceId={deviceId}
                                title={menuTitlesForDevicePage.notes}
                            />
                        </div>
                    </>
                )
            }
        </div>
        {imageSrc && <ModalImage onClickHandler={imageClickHandler}>
            <div style={{padding: '25px', backgroundColor: '#fff', borderRadius: '10px'}}>
                <img style={{maxWidth: '80vw', maxHeight: '80vh'}} src={imageSrc}/>
                <button onClick={() => setImageSrc(null)} style={{position: 'absolute', top: '0'}}>&#10006;</button>
            </div>
        </ModalImage>}
        <Dialog setupConfig={setupDialog} />
    </DashboardBlock>;
};

const DeviceLastPingDetails = ({device, setCEOverlay, isDriver}: {device: iDeviceDetails; setCEOverlay: (p) => void; isDriver: boolean}) => {
    const [lastDevicePingsFuel, setLastDevicePingsFuel] = useState<number | null>(null);
    const dispatch = useDispatch();
    const deviceLastPing = useSelector<iFullStoreState, iDevicePing | undefined | null>(
      (state) => state.devicesData.devicesLastPing.get(device.id),
      (l, r) => typeof l === typeof r && l?.pointId === r?.pointId,
    );
    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();
    const availableDeviceLocation = deviceLastPing !== null;
    const [iconUrl, setIconUrl] = useState<string>('');

    const centerOnDevice = () => {
        dispatch(MapActions.RECENTER_MAP_TO_DEVICE(device.id));
    }

    useEffect(() => {
        if (!availableDeviceLocation) {
            const dialog = dialogRef.current;

            dialog?.({
                type: 'NOTIFICATION',
                title: 'No device locations',
                body: 'This device has been activated and will begin to display location information once it is powered on.',
            });
        }
    }, [availableDeviceLocation]);

    useEffect(() => {
        const fetchData = async () => {
            const lastFuel = await devicedb.getDeviceLastFuel(deviceLastPing?.device);
            setLastDevicePingsFuel(lastFuel);
        };

        fetchData();
    }, [deviceLastPing])

    useEffect(() => {
        const {type: iconType, url: iconUrl} = device.icon;

        if (iconType && iconType === 2) {
            setIconUrl(iconUrl);
        }
    }, []);

    return <>
        {!deviceLastPing ? null :
          <Row wrap>
              <DeviceHeader
                name={device.name}
                address={deviceLastPing.address}
                clickHandler={centerOnDevice}
                iconUrl={iconUrl}
              />
          </Row>
        }

        <DeviceButtons device={device} isDriver={isDriver} onCheckEngineClick={setCEOverlay}/>

        <Dialog setupConfig={setupDialog} />
    </>;
};

const localTileStyle: css = {
    margin: 3,
    marginBottom: 6,
};
export const LocalTile = ({
    style = {} as React.CSSProperties,
    title= '',
    children,
    action,
    collapsible = false,
    initialCollapsed=false,
    loading=false,
    deviceId = undefined,
    property = undefined,
    isPinned = false}) => (
    <StandardTile
        style={{...style, ...localTileStyle}}
        title={title}
        action={action}
        collapsible={collapsible}
        initialCollapse={initialCollapsed}
        loading={loading}
        deviceId={deviceId}
        property={property}
        isPinned={isPinned}
    >
        {children}
    </StandardTile>
);
