import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { Actions as GeneralActions } from '../../stores/reducers/general-reducers';
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import SearchModal from '../menus/search';
import { iFullStoreState } from '../../shared/interfaces';
import { closeDashboard, openDashboard } from '../../stores/reducers/dashboardInfo/AC';
import { DashboardSize } from '../../stores/reducers/dashboardInfo';
import ChevronRightIcon from '../SVG-components/chevron_right';
import SearchIcon from '../SVG-components/search';
import BurgerMenuIcon from '../SVG-components/burger_menu';
import CloseMenuIcon from '../SVG-components/close_menu';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import './styles.scss';
import { isMobile } from '../../shared/helpers';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MaintenanceIcon } from '../../assets/svg/maintanance.svg';
import {GMapModalAC} from "../../stores/reducers/gMapModal/AC";
import BookmarksBar from "../BookmarksBar";
import { Fa } from '../elements/fa';
import {useRedux} from "../../states/redux-state";
import {getBookmarkBarState, setBookmarkBarState} from "../../shared/db/extra-info-db";

export const SearchInput = ({visibleInput, extraActions = null}: {visibleInput: boolean, extraActions?: JSX.Element}) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector<iFullStoreState, string>(state => state.general.searchTerm);
  const isSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showSearch);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(GeneralActions.CHANGE_TERM(e.target.value))
  }

  return (
      <div className="search">
          <div className={`search-input${visibleInput ? ' open': ''}`}>
              {!visibleInput && extraActions}
              {visibleInput && <input type="text" id="search-input" value={searchTerm} onChange={handleChange} />}
              <label htmlFor="search-input" onClick={() => dispatch(GeneralActions.SHOW_SEARCH)}><SearchIcon  /></label>
              {isSearchVis && <label className="close-icon" onClick={() => dispatch(GeneralActions.HIDE_SEARCH)}><PlusIcon  /></label>}
          </div>
      </div>
  );
};

export const SearchInputDesktop = ({ visibleInput, isBookmarksCollapsed, toggleBookmarksBar }: { visibleInput: boolean, isBookmarksCollapsed?: boolean, toggleBookmarksBar?: Function }) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector<iFullStoreState, string>(state => state.general.searchTerm);
  const isSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showSearch);

  useEffect(() => {
    if (!isSearchVis) {
      dispatch(GeneralActions.CHANGE_TERM(''))
    }
  }, [isSearchVis])

  const handleChange = (e) => {
    dispatch(GeneralActions.CHANGE_TERM(e.target.value));
  }

  const showSearch = () => {
    if (!isSearchVis) {
      dispatch(GeneralActions.SHOW_SEARCH);
    }
  }

  const hideSearch = () => {
    if (isSearchVis) {
      dispatch(GeneralActions.HIDE_SEARCH)
      dispatch(GeneralActions.CHANGE_TERM(''))
    }
  }

  return (
    <div className={`searchBlock ${isSearchVis ? "isOpen" : ""}`}>
      {isSearchVis && (
        <button 
        className="backIcon"
        onClick={hideSearch}
        >
        <Fa icon={faArrowLeft} />
      </button>
    )}
      <div className="searchContainer">
        <SearchIcon className='searchIcon' />
        <input
          className={`searchInput ${isSearchVis ? "isOpen" : ""}`}
          type="text"
          id="search-input"
          value={searchTerm}
          onChange={handleChange}
          onFocus={showSearch}
          placeholder='Search'
        />
      </div>
      {toggleBookmarksBar && (<ToggleButton isBookmarksCollapsed={isBookmarksCollapsed} clickHandler={toggleBookmarksBar} />)}
    </div>
  );
};

const ToggleButton = ({ isBookmarksCollapsed, clickHandler }) => {
  return (
    <button onClick={clickHandler} className='toggle-bar'>
      {isBookmarksCollapsed ? (<CloseMenuIcon width={24} height={24} />) : (<BurgerMenuIcon width={24} height={24} />)}
    </button>
  )
}

type IProps = {
  overlay?: JSX.Element;
  title?: string;
  searchExtra?: JSX.Element;
}
const DashboardBlock: React.FunctionComponent<IProps> = ({ children, overlay, title = '', searchExtra = null }) => {
  const user = useRedux((s) => s.auth.user);
  const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);
  let open = size !== DashboardSize.CLOSED ? ' open' : '';
  const isSearchVis = useSelector<iFullStoreState, boolean>((s) => s.general.showSearch);
  const isBookmarksSearchVis = useSelector<iFullStoreState, boolean>((s) => s.general.showBookmarksSearch);
  const params = useLocation();
  const dispatch = useDispatch();

  if (size === DashboardSize.OPEN_FULL) {
    open += ' full-size';
  } else if (size === DashboardSize.DVIR_FULL) {
      open += ' dvir-full-size';
      dispatch(GMapModalAC.hideModal());
  }

  const ref = useRef(null);
  const [showScroll, setShowScroll] = useState(false);
  const route = ["/billing/units", "/billing/invoices", "/billing/orders"];
  const billingRoute = route.some((item) => item === params.pathname);
  const dvirRoute = params.pathname.includes('/dvir');
  const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
  const [isBookmarksCollapsed, setIsBookmarksCollapsed] = useState<boolean>(false);

  const fetchBookmarkState = async () => {
    const value = await getBookmarkBarState() || false;

    setIsBookmarksCollapsed(value);
  }

  useEffect(() => {
    if (!IS_PUBLIC_VIEW) {
      ref.current.scrollTop = 0;
      setShowScroll(false);
    }

    fetchBookmarkState();
  }, []);

  // !window['cordova']
  return !IS_PUBLIC_VIEW && (
    <div className={`dashboard-block${open}`} onClick={() => setShowScroll(true)}>
      {((isMobile || window['cordova']) && title != '') && <h1>{title}</h1>}
      {(!billingRoute && !isBookmarksSearchVis && !(isMobile || window['cordova'])) && (
        <SearchInputDesktop
          visibleInput={isSearchVis}
          isBookmarksCollapsed={isBookmarksCollapsed}
          toggleBookmarksBar={() => {
            setBookmarkBarState(user, !isBookmarksCollapsed);
            setIsBookmarksCollapsed(!isBookmarksCollapsed);
          }}
        />
      )}
      <PerfectScrollbar className="scrollable" containerRef={(el) => ref.current = el}>
        {(!billingRoute && (isSearchVis || isBookmarksSearchVis)) ? <SearchModal /> : children}
      </PerfectScrollbar>
      {overlay}
      {!(isMobile) && <DashboardExpanderButtons />}
      <div className='bookmarks-bar-container'>
        {isBookmarksCollapsed && <BookmarksBar />}
      </div>
    </div>
  );
};

export default DashboardBlock;

const DashboardExpanderButtons = () => {
    const dispatch = useDispatch();
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);

    if (size === DashboardSize.DVIR_FULL) return null;

  return (
    <div className="expander-buttons">
      <div className="increase-button" onClick={() => dispatch(openDashboard())}>
        <ChevronRightIcon />
      </div>
      <span />
      <div className="decrease-button" onClick={() => { dispatch(closeDashboard()); dispatch(GeneralActions.HIDE_SEARCH); dispatch(GeneralActions.HIDE_BOOKMARKS_SEARCH) }}>
        <ChevronRightIcon />
      </div>
    </div>
  );
};
