import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { extractDevice } from '../../../../shared/helpers';
import {
  iFullStoreState,
  ItemType,
  iDeviceDetails,
  iDevicePing,
} from '../../../../shared/interfaces';
import { FaDevice, FaFav } from '../../../elements/fa';
import UserImage from '../../../general/UserImage';
import { FormatAddress } from '../../devices/device-list';
import { formatWhenLast } from '../../devices/device-page';
import * as favoritesdb from '../../../../shared/db/favorites-db';
import C from '../../../../shared/constants';
import { ITileProps } from './PersonTile.types';
import HistoryIcon from '../../../../assets/svg/history_24px.svg';

import styles from './styles.module.scss';
import { DashboardSize } from '../../../../stores/reducers/dashboardInfo';
import SvgIcon from '../../../SvgIcon/SvgIcon';
import StarIcon from '../../../../assets/svg/star_border_24px.svg';
import StarIconOrange from '../../../../assets/svg/star_24_orange.svg';

export const PersonListTile = ({ person, style = {} }: ITileProps) => {
  const isFavorite = useSelector<iFullStoreState, boolean>(
    (state) => !!state.general.favorites?.[ItemType.person]?.[person.id]
  );
  const assignedDevice = useSelector<
    iFullStoreState,
    iDeviceDetails | undefined
  >((state) =>
    extractDevice(state.devicesData.devicesDetails, person.hasDevice?.deviceId)
  );
  const assignedDeviceLastPing = useSelector<
    iFullStoreState,
    iDevicePing | undefined
  >((state) =>
    state.devicesData.devicesLastPing.get(person.hasDevice?.deviceId)
  );
  const uid = useSelector<iFullStoreState, string>(
    (state) => state.auth.user?.uid ?? ''
  );


  const ago = formatWhenLast(
    assignedDevice ? assignedDeviceLastPing?.time : undefined
  );

  const toggleFav = (e) => {
    e.preventDefault()
    e.stopPropagation()
    favoritesdb.toggleFavorite(ItemType.person, uid, person.id, !isFavorite)
  }

  const size = useSelector<iFullStoreState, DashboardSize>(
    (s) => s.dashboardInfo.size
  );

  const isNotFullSizeDashboard = !(size === DashboardSize.OPEN_FULL);

  return (
    <Link
      className={classNames(styles.PersonListTile, {
        [styles.PersonTile_showFullCardWidthOnSmallScreens]: isNotFullSizeDashboard,
      })}
      key={person.id}
      to={`/person/${person.id}`}
    >
      <div className={styles.PersonListTile__imageAndNameArea}>
        <UserImage key={person.id} person={person} size={20} />
        <div className={styles.PersonListTile__PersonName}>
          <span>{person.displayName}</span>
        </div>
      </div>
      <img
        className={classNames(styles.PersonListTile__ItemFavorite, { [styles.PersonListTile__ItemFavorite__favorite]: isFavorite })}
        src={isFavorite ? StarIconOrange : StarIcon}
        onClick={toggleFav}
      />
    </Link>
  )
}