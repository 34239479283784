import React, { useEffect, useState } from "react";
import Modal from "../../Modal";
import instance from "../../../api/instance";
import { toast } from "react-toastify";
import { useRedux } from "../../../states/redux-state";
import { MuiThemeProvider, Switch, createTheme } from "@material-ui/core";
import { nanoid } from "nanoid";
import { localStorage } from "../../../shared/storage";
import WarningBlock from '../../core/WarningBlock';
import styles from './PublicLinkModal.module.scss'
import { ReactComponent as CopyIcon } from '../../../assets/svg/content_copy.svg';

const PublicLinkModal = ({ setIsPublicLinkModalOpen, tagId }) => {

    const [isPublicLinkOn, setIsPublicLinkOn] = useState(false);
    const [publicLinkTokenFromDb, setPublicLinkTokenFromDb] = useState(null);
    const [uniqueId, setUniqueId] = useState(null);

    const checkIfPublicLinkEnabled = async () => {
        const URL = `/api/tag/one?firebaseId=${tagId}`;

        await instance.get(URL)
            .then(response => {
                const { data } = response;
                data.share_link_token && (setPublicLinkTokenFromDb(data.share_link_token))
                data?.is_share_link_enabled === 1 && (setIsPublicLinkOn(true))

                return data
            })
            .catch(({ message }) => {
                toast.error(message, { position: toast.POSITION.TOP_RIGHT });
            });
    }

    useEffect(() => {
        checkIfPublicLinkEnabled();
    }, [])

    const clientId = localStorage.get('active-client');
    const uid = useRedux((s) => s.auth.user.uid);

    const generatedLink = `${process.env.REACT_APP_FRONTEND_DOMAIN_URL}/#/public-view/?tag=${tagId}&client=${clientId}&token=${publicLinkTokenFromDb}`;

    if (publicLinkTokenFromDb === null) {
        const uniqueId = nanoid();
        setUniqueId(uniqueId);
        setPublicLinkTokenFromDb(uniqueId)
    }

    const setPublicLinkToDb = async (isEnabled) => {

        setIsPublicLinkOn(isEnabled)
        if (isEnabled === false) {
            setPublicLinkTokenFromDb(null);
        }

        const URL = `/api/tag/store`;

        const body = {
            firebase_id: tagId,
            is_share_link_enabled: isEnabled ? 1 : 0,
            client_id: clientId,
            firebase_user_id: uid,
            share_link_token: isEnabled ? publicLinkTokenFromDb : null,
        }

        await instance.post(URL, body)
            .then(response => {
                const { data } = response;

                return data
            })
            .catch(({ message }) => {
                toast.error(message, { position: toast.POSITION.TOP_RIGHT });
            });
    }

    const theme = createTheme({
        overrides: {
            MuiSwitch: {
                track: {
                    "$checked$checked + &": {
                        opacity: 1.0,
                        backgroundColor: "rgb(255, 124, 2)"
                    },
                    "MuiIconButton-label": {
                        backgroundColor: "rgb(255, 124, 2)"
                    }
                }
            }
        }
    });

    const [showWarnBlock, setShowWarnBlock] = useState(true);
    const [showCopied, setShowCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(generatedLink)
          .then(() => {
              setShowCopied(true);
              setTimeout(() => setShowCopied(false), 1000);
          }).finally(() => {
            setTimeout(() => setShowCopied(false), 3000);
        });
    }

    const closeModal = () => setIsPublicLinkModalOpen(false);
    return (
        <Modal className={styles.modal} onBackdropClick={closeModal}>
            <div className={styles.modalContainer}>
                <h1 className={styles.title}>Public Link</h1>
                {showWarnBlock && (
                  <WarningBlock
                    onClose={() => setShowWarnBlock(false)}
                    message='Enabling this switch will allow anyone with this link to view the location of devices from this tag'
                  />
                )}
                <label className={styles.switcher}>
                    <MuiThemeProvider theme={theme}>
                        Enable public link <Switch checked={isPublicLinkOn} onChange={event => setPublicLinkToDb(event.target.checked)} />
                    </MuiThemeProvider>
                </label>
                {isPublicLinkOn && (
                    <div className={styles.copyLinkSection}>
                        <a href={`https://${generatedLink}`} target='_blank' className={styles.publicLink}>{generatedLink}</a>
                        <button
                          type='button'
                          className={styles.copyButton}
                          onClick={handleCopyClick}
                        >
                            <CopyIcon className={styles.copyIcon} />
                            {showCopied ? 'Copied' : 'Copy Link'}
                        </button>
                    </div>
                )}

                <footer className={styles.footer}>
                    <button
                      type='button'
                      className={styles.closeButton}
                      onClick={closeModal}
                    >
                      close
                    </button>
                </footer>
            </div>
        </Modal>
    )
}

export default PublicLinkModal;
