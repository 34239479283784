import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReportPointRow from "./ReportPointRow";
import ReactPaginate from "react-paginate";

const timeClockData = (points) => {
    const groupedData = {};
    points.map(point => {
        const date = point.current.startDate.format("MM/DD/YYYY");
        const objectKey = point.current.deviceName + date;
        if (!groupedData[objectKey]) {
            //if no such deviceName exists in groupedData,
            // then we add it there and set the initial values
            groupedData[objectKey] = {
                ...point,
                current: {
                    ...point.current,
                    deviceName: point.current.deviceName,
                    startDate: point.current.startDate,
                    endDate: point.current.endDate,
                }
            };
        } else {
            // if deviceName is already in groupedData,
            // then compare the dates and update the values if necessary
            if (point.current.startDate < groupedData[objectKey].current.startDate) {
                groupedData[objectKey].current.startDate = point.current.startDate;
            }
            if (point.current.endDate > groupedData[objectKey].current.endDate) {
                groupedData[objectKey].current.endDate = point.current.endDate;
            }
        }
    });
    return  Object.values(groupedData);
}

const RenderStaticReportData = ({ deviceUnits, isCondensed, points, deviceTimezone, isTimeClock = false, }) => {
    const [pagination, setPagination] = useState({
        data: isTimeClock ? timeClockData(points) : points,
        offset: 0,
        numberPerPage: 50,
        pageCount: 0,
        currentData: [],
    });

    useEffect(() => {
        setPagination((prevState) => ({
            ...prevState,
            pageCount: Math.ceil(prevState.data.length / prevState.numberPerPage),
            currentData: prevState.data.slice(
                pagination.offset,
                pagination.offset + pagination.numberPerPage
            ),
        }));
    }, [pagination.numberPerPage, pagination.offset]);

    // const tags = useRedux(s => s.general.tags);
    // const filters = useRedux(s => s.report.details.filters);
    // const dates = useRedux(s => s.report.details.dates);
    // const devicesDetails = useRedux(s => s.devicesData.devicesDetails);
    // const userToken = useRedux(s => s.auth.user.beToken);
    // const dispatch = useDispatch();
    // const firstOrLastVisible = useRedux(s => s.report.firstOrLastVisible);
    // const filterDisplayRecords = useRedux(s => s.report.filterDisplayRecords);

    const handlePageClick = async (event) => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage;
        setPagination({ ...pagination, offset });
        // let query: iGetReportPropsWrapper = {
        //   allTags: tags,
        //   filters,
        //   dates,
        //   devicesDetails,
        // };
        //
        // let points = await getPoints(query, userToken?.devices || false, dispatch, firstOrLastVisible, next);
        // dispatch(ReportActions.SET_DISPLAY_RECORDS(filterDisplayRecords.length > 0 && validate(filterDisplayRecords) ? filterRecords(filterDisplayRecords, points) : points));
    };

    return (
        <>
            <PerfectScrollbar className="report-point-table-wrap">
                <div className={"report-point-table"}>
                    {pagination.currentData &&
                        pagination.currentData.map((point, index) => {
                            return (
                                <ReportPointRow
                                    key={`${point.current.uniqId}-${point.current.pointId}`}
                                    deviceUnits={deviceUnits}
                                    condensed={isCondensed}
                                    timezone={deviceTimezone}
                                    prevCur={point}
                                    isTimeClock={isTimeClock}
                                />
                            );
                        })}
                </div>
            </PerfectScrollbar>

            {/*<ReactPaginate*/}
            {/*    previousLabel={"<"}*/}
            {/*    nextLabel={">"}*/}
            {/*    breakLabel={"..."}*/}
            {/*    pageCount={pagination.pageCount}*/}
            {/*    marginPagesDisplayed={2}*/}
            {/*    pageRangeDisplayed={5}*/}
            {/*    onPageChange={handlePageClick}*/}
            {/*    containerClassName={"pagination"}*/}
            {/*    activeClassName={"active"}*/}
            {/*/>*/}

            {/*<button onClick={handlePageClick}>{' < '}</button>*/}
            {/*<button onClick={()=>handlePageClick(true)}>{' > '}</button>*/}
        </>
    );
};

export default RenderStaticReportData;
