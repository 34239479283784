import React, {useEffect, useState} from 'react';
import {toPairs} from 'ramda';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';

import TableSimple from '../../../elements/TableSimple';
import {Fa} from '../../../elements/fa';
import * as devicedb from '../../../../shared/db/devices-db';
import {lCount} from '../../../../shared/helpers';
import {iDeviceEngineCode, iList} from '../../../../shared/interfaces';
import {fetchDeviceCodes} from './utils';

export const EngineCodeTable = (props: {deviceId: string}) => {
  const [codes, setCodes] = useState<iList<iDeviceEngineCode>>();

  useEffect(() => {
    const fetchCodes = async () => {
      const deviceCodes = await fetchDeviceCodes(props.deviceId);

      setCodes(deviceCodes);
    };

    fetchCodes();
  }, []);

  if (!codes) return <Fa icon={faSpinner} spin />;

  if (!lCount(codes)) return <p style={{fontSize: 12}}>No Engine code history yet.</p>;

  return (
    <TableSimple dark sm striped headers={['Date', 'Code', 'Desc.']} style={{fontSize: 11}}>
      {toPairs(codes).map(([id, {code, description, date}]) => (
        <tr key={id}>
          <td>{date.format('l LT')}</td>
          <td>{code}</td>
          <td>{description}</td>
        </tr>
      ))}
    </TableSimple>
  )
};