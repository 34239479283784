import React, { CSSProperties as css, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { is, Map } from 'immutable';
import { always, equals, ifElse, isEmpty } from 'ramda';

import {
  iAddress,
  iDeviceDetails,
  iDevicePing,
  iDeviceTap,
  iFullStoreState,
  iList,
  iPerson,
  iTag,
  ItemType,
  UserAuth,
} from '../../../shared/interfaces';
import {
  vals,
  idValArr,
  friendlyDiff,
  isAddress,
  getOrderedDevicesList,
  setShowedDevices,
  dev,
} from '../../../shared/helpers';
import C, { ACL, UserCan } from '../../../shared/constants';
import * as devicedb from '../../../shared/db/devices-db';
import LauncherTile from '../../../shared/item-launch';
import { StandardItem } from '../../general/index';
import { Fa } from '../../elements/fa';
import { Row, Col } from '../../elements/flex';
import { fitText } from '../../../shared/fit-text';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import { withSentry } from '../../../shared/hoc/withSentry';
import { useRedux } from '../../../states/redux-state';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import SvgIcon from '../../SvgIcon/SvgIcon';

export default withSentry(function DeviceList() {
  const userCanDo = useSelector<iFullStoreState, Array<string>>(
    (s) => s.auth.user?.acl?.can
  );
  const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);
  const showTappedPeople = ACL.check(
    UserCan.SEE_TAPPED_PEOPLE,
    userCanDo,
    true
  );

  const dispatch = useDispatch();

  const devicesDetails = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );

  const devicesLastPings = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray();

  const mapRef = useSelector<
    iFullStoreState,
    React.RefObject<GoogleMap> | null
  >((state) => state.gmap.mapRef());
  let isRenderedMap = useRedux((state) => state.gmap.isRendered);
  console.log('map stat', isRenderedMap);

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);

  useEffect(() => {
    let markerBounds = new google.maps.LatLngBounds();
    devicesMarker.forEach((marker) => {
      markerBounds.extend(
        new google.maps.LatLng(
          marker.coordinates.location.lat,
          marker.coordinates.location.lng
        )
      );
    });
    mapRef?.current?.fitBounds(markerBounds);
  }, []);

  useEffect(() => {
    if (!isRenderedMap) {
      let markerBounds = new google.maps.LatLngBounds();
      devicesMarker.forEach((marker) => {
        markerBounds.extend(
          new google.maps.LatLng(
            marker.coordinates.location.lat,
            marker.coordinates.location.lng
          )
        );
      });
      mapRef?.current?.fitBounds(markerBounds);
    }
    setShowedDevices(devicesLastPings, dispatch);
  }, [devicesLastPings, isRenderedMap]);

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className='device-header'
      >
        <Link
          to='/device'
          style={{ fontSize: 12, paddingTop: 7, fontWeight: 'lighter' }}
        >
          View all
        </Link>
      </div>
      <DeviceTileList
        devicesDetails={devicesDetails}
        devicesLastPings={devicesLastPings}
      />
      {/* Tags */}
      {showTags && <TagsAssignedToDevices />}
      {/* Tapped In */}
      {showTappedPeople && <TappedPeople />}
    </div>
  );
});

const DeviceTileList = ({ devicesDetails, devicesLastPings }) => {
  if (!devicesDetails.size || isEmpty(devicesLastPings)) return null;

  const orderedDevicesList = getOrderedDevicesList(devicesLastPings);

  const orderedDevicesDetails = devicesDetails
    .sort(
      (a, b) =>
        orderedDevicesList.indexOf(a.id) - orderedDevicesList.indexOf(b.id)
    )
    .reverse()
    .valueSeq();

  const lastDeviceId = devicesDetails.keySeq().last();

  return (
    <>
      {orderedDevicesDetails
        .take(5)
        .map((device, deviceId) => (
          <DeviceTile
            key={deviceId}
            device={device}
            last={deviceId === lastDeviceId}
          />
        ))
        .valueSeq()}
    </>
  );
};

const DeviceTile = ({
  device,
  last = false,
}: {
  device: iDeviceDetails;
  last?: boolean;
}) => {
  const lastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
    (s) => s.devicesData.devicesLastPing.get(device.id),
    (l, r) => l?.pointId === r?.pointId
  );

  return (
    <div>
      <LauncherTile
        id={device.id}
        link={`/device/${device.id}`}
        type={ItemType.device}
        strikeBottom={!last}
      >
        <div
          style={{
            display: 'flex',
            fontSize: 20,
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <SvgIcon color={device.color} icon={device.icon} size='xs' />
          <span style={{ marginLeft: 5, maxWidth: '100%' }}>{device.name}</span>
        </div>
        {lastPing?.address && <FormatAddress address={lastPing.address} row />}
        <AssignedTo device={device} lastPing={lastPing} />
      </LauncherTile>
    </div>
  );
};

const TagsAssignedToDevices = () => {
  const tagsWithDevices = useSelector<iFullStoreState, Array<iTag>>(
    (state) => {
      const devicesDetails = state.devicesData.devicesDetails;

      return vals(state.general.tags || {}).filter(
        (tag) =>
          tag.instances &&
          tag.instances.device &&
          idValArr(tag.instances.device).some(({ id }) =>
            devicesDetails.has(id)
          )
      );
    },
    (left, right) => equals(left, right)
  );

  return (
    <div>
      <div style={headerStyle}>
        Tags <HeaderLink link='/tags' text='View all' />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tagsWithDevices.map(({ details: tag }) => (
          <StandardItem
            key={tag.id}
            style={{ margin: 4 }}
            itemId={tag.id}
            displayName={tag.name}
          />
        ))}
      </div>
    </div>
  );
};

const TappedPeople = () => {
  const people = useSelector<iFullStoreState, iList<iPerson>>(
    (s) => s.general.people,
    (l, r) => equals(l, r)
  );

  const devicesWithTappedPerson = useSelector<
    iFullStoreState,
    Map<string, iDeviceTap>
  >(
    // @ts-ignore
    (s) =>
      s.devicesData.devicesDetails
        .filter(
          (device) => device.assignedTo && people[device.assignedTo.personId]
        )
        .map((device) => device.assignedTo),
    (l, r) => is(l, r)
  );
  const userAuth = useSelector<iFullStoreState, UserAuth>(
    (state) => state.auth.user!,
    (l, r) => equals(l, r)
  );

  return (
    <div>
      <div style={headerStyle}>Tapped In</div>
      {devicesWithTappedPerson
        .map((deviceTap) => {
          const currentPerson = people[deviceTap.personId];

          return (
            <div
              key={deviceTap.deviceId}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 5,
              }}
            >
              <span>{currentPerson.displayName} </span>
              <span>{friendlyDiff(moment(), deviceTap.time)}</span>
              <button
                className='btn btn-primary btn-xs'
                onClick={(_) =>
                  devicedb.unAssignDevice(userAuth)(
                    deviceTap.deviceId,
                    deviceTap.personId
                  )
                }
              >
                Tap Out
              </button>
            </div>
          );
        })
        .valueSeq()}
    </div>
  );
};

const headerStyle: css = {
  marginTop: 10,
  marginBottom: 12,
  fontWeight: 'bold',
  fontSize: 20,
  paddingTop: 8,
  borderTop: `1px solid ${C.mediumGray}`,
};

const HeaderLink = ({ link, text }) => (
  <Link
    to={link}
    style={{
      float: 'right',
      fontSize: 12,
      paddingTop: 7,
      fontWeight: 'lighter',
    }}
  >
    {text}
  </Link>
);

/** ********** Assigned to Section */
type IAssignedToProps = {
  device: iDeviceDetails;
  lastPing?: iDevicePing;
};

const AssignedTo = (props: IAssignedToProps) => {
  const { device, lastPing } = props;

  const person = useSelector<iFullStoreState, iPerson | undefined>(
    (state) => {
      const personId = device.assignedTo?.personId;

      return personId ? state.general.people?.[personId] : undefined;
    },
    (l, r) => equals(l, r)
  );

  if (!device.assignedTo) return null; // nobody assigned

  let name = '';

  if (!!person) name = person.displayName;

  if (!device.assignedTo) name = 'Unassigned';

  const status = lastPing?.msg ?? 'No status yet';

  return (
    <div>
      <Fa
        icon={C.faIcos.person}
        style={{ color: C.mediumGray, fontSize: 'larger', paddingRight: 8 }}
      />
      <span>{name}</span>

      <Fa
        icon={C.faIcos.device}
        style={{ color: C.mediumGray, fontSize: 'larger', padding: '0 8px' }}
      />
      <span>{status}</span>
    </div>
  );
};

/** ****** Address Section */
interface iFormatAddressProps {
  row?: boolean;
  address?: iAddress;
  style?: React.CSSProperties;
  noWrap?: boolean;
}
export const FormatAddress = React.memo(
  ({
    address,
    style = {},
    row = false,
    noWrap = false,
  }: iFormatAddressProps) => {
    const addressKey = address ? JSON.stringify(address) : null;
    const add1 = React.createRef();
    const add2 = React.createRef();
    const add3 = React.createRef();

    React.useEffect(
      () =>
        fitText(
          [
            [add1.current, `formatted-address-${addressKey}-add1`],
            [add2.current, `formatted-address-${addressKey}-add2`],
            [add3.current, `formatted-address-${addressKey}-add3`],
          ],
          { maxFontSize: 13 }
        ),
      [add1, add2, add3]
    );

    return ifElse(
      isAddress,
      ({ street, city, state, zip }) =>
        row ? (
          <Row style={style}>
            {!street ? null : (
              <span style={{ whiteSpace: 'nowrap' }} ref={add1 as any}>
                {street}, &nbsp;
              </span>
            )}
            {!city ? null : (
              <span style={{ whiteSpace: 'nowrap' }} ref={add2 as any}>
                {city} &nbsp;
              </span>
            )}
            {!state ? null : (
              <span style={{ whiteSpace: 'nowrap' }} ref={add3 as any}>
                {state} {zip}
              </span>
            )}
          </Row>
        ) : (
          <Col style={style}>
            <span
              style={{ whiteSpace: noWrap ? 'initial' : 'nowrap' }}
              ref={add1 as any}
            >
              {street}{' '}
            </span>
            <span style={{ whiteSpace: 'nowrap' }} ref={add2 as any}>
              {!city ? null : city}
            </span>
            <span
              style={{ whiteSpace: 'nowrap' }}
              ref={add2 as any}
            >{`${state} ${zip}`}</span>
          </Col>
        ),
      always(null)
    )(address);
  }
);
