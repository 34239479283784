import React, { useEffect, useState } from 'react';
import DashboardBlock from "../../../DashboardBlock";
import { Fa } from "../../../elements/fa";
import {
  faArrowLeft,
  faEye,
  faPencilAlt,
  faPlus,
  faSpinner,
  faTrashAlt
} from "@fortawesome/fontawesome-free-solid";
import { useHistory } from "react-router-dom";
import styles from './styles.module.scss';
import C from "../../../../shared/constants";
import { dvirCloseDashboard, dvirOpenDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import { useDispatch } from "react-redux";
import instance from "../../../../api/instance";
import { localStorage } from "../../../../shared/storage";
import { DvirPagination } from "../DvirPagination";
import { Row } from "../../../elements/flex";
import Modal from "../../../Modal";
import { HttpStatus, iDvirFormsItem } from "../../../../shared/interfaces";
import { fetchDvirFormsByPage } from "../utils";
import { ReactComponent as ArrowBackIcon } from '../../../../assets/svg/arrow-back.svg';
import { ReactComponent as AddPlusThinIcon } from '../../../../assets/svg/add-plus-thin.svg';
import { ReactComponent as PreviewIcon } from '../../../../assets/svg/preview.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit.svg';
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-outline.svg";



interface iDvirForms {
  id: number,
  firebase_key: string;
  name: string;
  brief_description: string;
  created_at: string;
  person_key: string;
}

const DvirForms = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [forms, setForms] = useState<iDvirFormsItem[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldDeleteModalDisplay, setShouldDeleteModalDisplay] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const fetchDvirForms = async (page: number = 1, limit: number = 10) => {
    try {
      setIsLoading(true);

      const data = await fetchDvirFormsByPage({ page, limit, clientId });

      const { data: forms, total_pages: totalPages } = data;

      setTotalPages(totalPages);
      setForms(forms);

      setIsLoading(false);
    } catch ({ message }) {
      console.log(message)
    }
  }

  const deleteDvirForm = async () => {
    const URL = 'api/dvir-form/delete-dvir-form';
    const body = {
      dvir_form_key: idToDelete,
      organization_key: clientId
    };

    const res = await instance.delete(URL, { data: body });

    if (res.status === HttpStatus.OK) {
      fetchDvirForms();
      setCurrentPage(1);
    }
    else {
      setErrorMsg('We couldn\'t delete the item due to an error. Please try again later.');
      setIsError(true);
      setTimeout(() => setIsError(false), 3000);
    }

    setIdToDelete(null);
  }

  useEffect(() => {
    (window as any).Beacon('destroy')
    dispatch(dvirOpenDashboard());

    fetchDvirForms();

    return () => {
      (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
      dispatch(dvirCloseDashboard());
    }
  }, []);

  const deleteFormHandler = (id) => {
    setIdToDelete(id);
    setShouldDeleteModalDisplay(true);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchDvirForms(page);
  }

  const deleteModalCancel = () => {
    setShouldDeleteModalDisplay(false);
  }

  const deleteModalDelete = async () => {
    setShouldDeleteModalDisplay(true);

    await deleteDvirForm();

    setShouldDeleteModalDisplay(false);
  }

  return (
    <DashboardBlock>
      <div className={styles.DvirFormHeader}>
        <button className={styles.DvirFormHeader__backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <h2>DVIR Forms</h2>
        <button className={styles.DvirFormHeader__addButton} onClick={() => history.push('/dvir-forms/new')}>
          <AddPlusThinIcon />
        </button>
      </div>
      {isError && (
        <div className={styles.DvirFormsError}>
          <p>{errorMsg}</p>
          <span onClick={() => setIsError(false)}>&#x2715;</span>
        </div>
      )}
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }} />
        </Row>
      )}
      {forms && forms.length > 0 && !isLoading && (
        <div className={styles.DvirTableWrapper}>
          <table className={styles.DvirFormTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {forms.map((form, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{form.name}</td>
                  <td>{form['brief_description']}</td>
                  <td className={styles.DvirFormItemActions}>
                    <button
                      className={styles.DvirFormItemActions__button}
                      onClick={() => history.push(`/dvir-forms/${form.firebase_key}`)}
                    >
                      <PreviewIcon />
                    </button>
                    <button
                      className={styles.DvirFormItemActions__button}
                      onClick={() => history.push(`/dvir-forms/${form.firebase_key}/edit`)}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className={styles.DvirFormItemActions__button}
                      onClick={() => deleteFormHandler(form.firebase_key)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>)}
      {!isLoading && <DvirPagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />}
      {shouldDeleteModalDisplay && <Modal>
        <div className={styles.DeleteDvirModal}>
          <p>Are you sure you want to delete this form?</p>
          <div className={styles.DeleteDvirBtns}>
            <button onClick={deleteModalDelete} className={styles.DeleteBtn}>Delete</button>
            <button onClick={deleteModalCancel} className={styles.CancelBtn}>Cancel</button>
          </div>
        </div>
      </Modal>}
    </DashboardBlock>
  )
};

export default DvirForms;
