import './style.scss';

import C, {ACL, UserCan} from '../../../shared/constants';
import {useDispatch, useSelector} from 'react-redux';

import {NavLink as Link} from 'react-router-dom';
import React from 'react';
import {fbapp} from '../../../shared/firebase';
import {fullOpenDashboard} from '../../../stores/reducers/dashboardInfo/AC';
import {iFullStoreState} from '../../../shared/interfaces';
import {localStorage} from '../../../shared/storage';
import { toggleBeacon } from '../../../shared/helpers';
import {useRedux} from '../../../states/redux-state';

const MainMenuMobile = (props) => {
    const {mainMenuToggle} = props;
    const dispatch = useDispatch();

    const uid = useRedux((s) => s.auth.user.uid);
    const userData = useRedux((s) => s.general.people[uid]);

    const pageToggle = () => {
        dispatch(fullOpenDashboard());
        mainMenuToggle();
    };
    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        s => s.auth.user?.acl?.can
    )
    const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);

    const logout = () => {
        localStorage.forget('login-init-be-token');
        localStorage.forget('login-init-user');
        localStorage.forget('cached-user');
        localStorage.forget('active-client');
        fetch(`${process.env.REACT_APP_API_URL}/site/api-logout`)
            .finally(() => {
                document.location.href = '/';
                fbapp.auth().signOut();
        });
    }
    return (
        <div className='MainContainer'>
            {/* <LogoMobile className='MainLogo'/> */}
            <div className="MainLogo">
                <img src={process.env.REACT_APP_LOGIN_LOGO_MOBILE} className="MainLogoImg" alt="" />
            </div>

            <div className='MainLinks'>
                <Link
                    onClick={() => pageToggle()}
                    to="/dashboard"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">Dashboard</span>
                </Link>

                <Link
                    onClick={() => pageToggle()}
                    to="/device"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >

                    <span className="description">Devices</span>
                </Link>

                <Link
                    onClick={() => pageToggle()}
                    to="/billing"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">Billing</span>
                </Link>

                <Link
                    onClick={() => pageToggle()}
                    to="/person"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">People</span>
                </Link>

                <Link
                    onClick={() => pageToggle()}
                    to="/reports"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">Reports</span>
                </Link>
                {
                    showTags
                        &&
                        (<Link
                            onClick={() => pageToggle()}
                            to="/tags"
                            className="MainNavLink"
                            activeClassName={'active-row'}
                        >
                            <span className="description">Tags</span>
                        </Link>)
                }
                <Link
                    onClick={() => pageToggle()}
                    to="/dvir"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">DVIR</span>
                </Link>
                <Link
                    onClick={() => pageToggle()}
                    to="/favorites"
                    className="MainNavLink"
                    activeClassName={'active-row'}
                >
                    <span className="description">Favorites</span>
                </Link>
                <button className="MainNavLink" onClick={toggleBeacon}>Help</button>

            </div>

            <div className='MainUserInfo'>
                <h3>{userData.displayName}</h3>
                <p>{userData.email}</p>
                <span className="btn-link" onClick={() => logout()} style={{color: C.blackMain}}>Log Out</span>
            </div>
        </div>
    );
};

export default MainMenuMobile;
