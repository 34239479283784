import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome';
import moment from 'moment';
import { UserInfo } from 'firebase';
import { iReducersState } from '../stores/reducers';
import { UserCan } from './constants';
import { iReportDetails, iReportType } from '../stores/reducers/report-reducers';
import { DevicesDetailsContainer } from '../stores/reducers/devicesData';
import { Map } from 'immutable';
import {CSSProperties} from "react";

export interface AppConfig {
    "firebase-app": {
        apiKey: string,
        authDomain: string,
        databaseURL: string,
        projectId: string,
        storageBucket: string,
        messagingSenderId: string
    },
    "gmap-api-key": string,
    "raven-target"?: string,
    "captcha-v3-key": string,
    "captcha-v2-key": string,
}

export interface iClient {
    "client-details": {
        details: {
            name: String
        }
    },
    devices: {
        "device-details": iList<iDeviceDetails>;

        /** Keyed by nothing */
        "tap-history": iList<iDeviceTap>;
    },
    favorites: {
        /** user that is favoriting */
        [userId: string]: {
            [bucketType: string]: { [itemId: string]: number };
        }
    },
    people: {
        "people-details": iList<iPerson>,

        /** Keyed by person id */
        "tap-history": iList<iDeviceTap>
    },
    tags: iList<iTag>
    "extra-info": iList<iExtraInfo>,
    fences: {
        "instances": {[itemType: string]: {[itemId: string]: iList<iFence>}}
    }
}


export interface iFenceDetails extends iTagInfoDetailsBase {
    [x: string]: any;
    toLocaleLowerCase: any;
    id: string,
    itemId: string,
    fenceType: FenceType,
    fenceGroup: FenceGroup,
    icon: iIcon,
    itemType: ItemType,
    isWaypoint: boolean,
    description: string,
    region: iRegion,
    color?: string,
    eventValues: {[alertType: string]: iEventInstance}
}


export interface iTagInfoDetailsBase {
    id: string,
    name: string,
    icon: iIcon,
    color?: string,
    isAlertType?: boolean,
    eventValues: {[alertType: string]: iEventInstance}
    isHidden?: boolean,
    author?: string
}

export interface iTagInfoBase<T> extends iAttacherGroup<T, iTagInfoDetailsBase> {
    // todo, move this to tag and info type maybe
}

interface iAttacherGroup<T,T2> {
    details: T2,
    instances?: {
        device?: { [itemId: string]: T }
        people?: { [itemId: string]: T }
        tag?: { [itemId: string]: T }
        'allowed-see'?: {
            'person': { [personId: string]: boolean},
            readOnly: { [personId: string]: boolean }
        }
    }
}

export interface iExtraInfoDetails {
    name: string,
    id: string,
}

export interface iExtraInfo {
    details: {
        name: string,
        readOnly?: boolean,
    }
}
// export interface iExtraInfo extends iAttacherGroup<string, iExtraInfoDetails> {}

export interface iTag extends iAttacherGroup<true, iTagInfoDetailsBase> {}

export interface iFence extends iAttacherGroup<true, iFenceDetails> {

}

export interface iPerson {
    id: string,
    displayName: string,
    email: string,
    hasDevice?: iDeviceTap,
    name: string,
    photoURL: string,
    mainNumber?: string,
    secondaryNumber?: string,
    acl: string[],
    fences?: iFenceDetails[];
    disabled?: boolean;
    suppressContinueStreamModal?: boolean;
}

export interface iItemsAllowed {
    device: {
        [deviceId: string]: {
            [source: string]: 'tag'|'device'
        }
    }
}
export interface iDeviceTap {
    deviceId: string,
    event: "IN"|"OUT",
    personId: string,
    time: moment.Moment;

}

export interface iDeviceExternal {
    keyVals?: {[key: string]: string}[]
}

export interface iEventInstance {
    settings?: any,
    on: boolean,
    people?: {[personId: string]: true},
    custom?: iList<string>,
    dnd?: {
        on: boolean,
        days: {
            [day: string]: {
                [scheduleId: string]: {from: number, to: number}
            }
        }
    }
}

export interface iScheduledReport {
    // delivery: 'once'|'weekly'|'monthly'|'anually',
    type: iReportType,
    deliverySchedule: {
        schedule: 'daily'|'weekly'|'monthly'|'anually',
        frequencyDays: { [day: number]: boolean },
        frequencyMonths?: { month: number, day: number }
    },
    reportRangeDays: number,
    format: 'pdf'|'csv',
    label?: string,
    filters: {[key: string]: {[key: string]: true}},
    sendTo: {
        emails: string[],
        people: string[]
    }
}

export interface iGeneratedReport {
    startDate: moment.Moment;
    scheduleId: string;
    url?: string,
}

export enum Units {
    miles = 'miles', knots = 'knots', kilo = 'kilo'
}

export interface iDefaultSettings {
    timezone: string;
}

export interface iDeviceEvent {
    key: string,
    type: 'speed'|'range'|'no-setting',
    itemType: ItemType,
    name: string,
    description?: string,
    min?: number,
    max?: number,
    step?: number,
    precision?: number,
    unitString?: string,
    src?: string,
    showForFence?: boolean
}

export interface iDeviceDetails {
    id: string,
    units?: Units,
    premiumRequested?: boolean,
    premiumUntil?: moment.Moment,
    externalData: iDeviceExternal;
    assignedTo?: iDeviceTap;
    fences?: iFenceDetails[];
    name: string;
    icon: iIcon;
    color?: string;
    deviceEvents?: string[];
    possibleAlerts: AlertTypes[];
    eventValues: {[eventKey: string]: iEventInstance}
    extraInfo?: {
        [infoId: string]: string
    }

    earliestTime?: moment.Moment,
    timezone: string,
    hasCheckEngine?: boolean,
    batteryPercent?: number,
    newMaintenance?: iList<iMaintenanceTask>;
    visible?: boolean,
    canPremium?: boolean,
    isCamera?: boolean,
}

export interface iConfig {
    version?: string
    strictAccess?: boolean
}

export interface iNote {
    id: string,
    date: moment.Moment,
    note: string,
}
export interface iList<T> {
    [key: string]: T
}

export type iAlertActivity = {
    /**
     * all lower case has + alert eg hasspeed
     */
    [hasthingkey in AlertTypes]: boolean;
}

export interface iTripAlertActivity extends iAlertActivity {
    /**
     * lower case alert + Count so eg speedCount
     */

    // [thingcount:string]: number
    // enterCount: number,
    // exitCount: number,
    // harshCount: number,
    // poweroffCount: number,
    // poweronCount: number;
    // sosCount: number;
    // speedingpostedCount: number;
    // speedingcappedCount: number;
    // rapidCount: number;
}

export interface iDevicePing {
    deviceName?: string;
    pointId: string;
    address: iAddress;
    /**
     * Fixed group of coordinates
     * Use the appropriately tagged coordinate for actual
     */
    coordinates: iCoordinate;
    device: string;
    fuel: number;
    miles: number;
    mpg?: number;
    msg: string;
    speed: number;
    time: moment.Moment;
    volt: number;
    posted_speed: number;
    tripId: string;
    personId?: string;
    label?: string;
    alertActivity: iAlertActivity;
    hidden?: boolean;
    stopMinutes?: number;
    endDate?: moment.Moment;
    startDate?: moment.Moment;
    averageSpeed?: number;
    bat_percent: number;
    alarmId: string;
}

export interface iDevicePingEager extends Omit<iDevicePing, "person" | "device"> {
    device: iDeviceDetails,
    person?: iPerson
}

export interface iGeofences {
    [geofenceName: string]: 0 | 1
};

export interface iDevicePingWithGeofences extends iDevicePing {
    geofences: iGeofences
}

export interface iDevicePingWithGeofencesEager extends Omit<iDevicePing, "person" | "device"> {
    device: iDeviceDetails,
    person?: iPerson
}

export interface iDeviceEngineCode {
    date: moment.Moment;
    code: string;
    description: string;
}

export interface iLocation {
    lat: number, lng: number
}
export interface iLongLocation {
    latitude: number, longitude: number
}
/**
 * point on map
 */
export interface iCoordinate {
    location: iLocation
    snappedLocations: { location: iLocation, order: number, }[]
}
export interface iAddress {
    city: string,
    state: string;
    street: string;
    zip: string;
}


export enum FenceType {
    RECTANGLE, OVAL, PATH
}

export enum FenceGroup {
    PATH, FENCE
}

export interface iRegion {
    shape: FenceType,
    editable: boolean,
    center: iLocation,
    height: number,
    width: number,
    name: string,
    hideName?: boolean,
    hideShadows?: boolean,
    hideIcon?: boolean
}

export interface iPath {
    points: iLocation[],
    ffPoints: iLocation[],
    alertValues: {[alertType: string]: iEventInstance}
    width: number,
    color: string,
    ico: false|iIcon,
    editable?: boolean
    recording?: boolean,
}

export enum IconTypes {
    FaIcon,
    SvgIcon,
    UserSvgIcon,
}

export interface iIcon {
    type: IconTypes
    url?: string
    fa?: IconDefinition
    svgIconName?: string
}

export enum AlertTypes {
    /** Ignition On Interval Report */
    IgnOnInt='IgnOnInt',

    /** Ignition On Report */
    IgnOn='IgnOn',

    /** Ignition Off Report */
    IgnOff='IgnOff',

    /** On Demand Locate */
    Poll='Poll',

    /** Speed Threshold */
    Speed='Speed',

    /** Speed Posted */
    // SpeedPosted='SpeedPosted',

    /** Acceleration Threshold */
    Accel='Accel',

    /** Deceleration Threshold */
    Decel='Decel',

    /** Low Voltage */
    LowVolt='LowVolt',

    /** Power Up/Reset */
    PowerUp='PowerUp',

    /** Idle Threshold */
    Idle='Idle',

    /** Tow Start */
    TowStart='TowStart',

    /** Tow Stop */
    TowStop='TowStop',

    /** Heartbeat */
    Heartbeat='Heartbeat',

    /** Ignition Off Interval Report */
    IgnOffInt='IgnOffInt',

    /** Direction Change */
    DirChange='DirChange',

    /** Mileage Threshold */
    Mileage='Mileage',

    /** Virtual Ignition On Interval Report */
    VIgnOnInt='VIgnOnInt',

    /** Virtual Ignition On Report */
    VIgnOn='VIgnOn',

    /** Virtual Ignition Off Report */
    VIgnOff='VIgnOff',

    /** Main Battery Disconnect Interval */
    BatDCInt='BatDCInt',

    /** Main Battery Disconnect */
    BatDC='BatDC',

    /** Main Battery Reconnect */
    BatRC='BatRC',

    /** Move Start */
    MvStart='MvStart',

    /** Move Stop */
    MvStop='MvStop',

    /** Engine Codes */
    ECodes='ECodes',

    /** Input High */
    InHi='InHi',

    /** Input Low */
    InLo='InLo',

    /** Idle Stop */
    IdleStop='IdleStop',

    IdleStart='IdleStart',

    /** SOS Alert */
    SOS='SOS',

    /** Power On */
    PowerOn='PowerOn',

    /** Power Off */
    PowerOff='PowerOff',

    /** GL300 Interval */
    Int='Int',

    /** Device Crossed Into Fence */
    FenceEnter='FenceEnter',

    /** Device Crossed Out of Fence */
    FenceExit='FenceExit',

    /** Trip Start */
    TripStart='TripStart',

    /** Trip Stop */
    TripStop='TripStop',

    /** Unfastened safety belt */
    UnfastenedSafetyBelt='UnfastenedSafetyBelt',

    /** Driver making phone calls */
    DriverMakingPhoneCalls='DriverMakingPhoneCalls',

    /** Harsh left turn */
    HarshLeftTurn='HarshLeftTurn',

    /** Harsh right turn */
    HarshRightTurn='HarshRightTurn',

    /** Tailgating */
    Tailgating='Tailgating',

    /** Front collision */
    FrontCollision='FrontCollision',

    /** lens cover */
    LensCover='LensCover',

    /** Driver smoking */
    DriverSmoking='DriverSmoking',

    /** Stop sign*/
    StopSignAlarm='StopSignAlarm',

    // harsh='harsh',
    // poweroff = 'poweroff',
    // poweron = 'poweron',
    // sos = 'sos',
    // speedPosted = 'speedingposted',
    // speedCapped = 'speedingcapped',
    // rapid = 'rapid',
    // enter = 'enter',
    // exit = 'exit',
}

export enum AlertConfigTypes {
    increment = 'increment', float = 'float'
}

// export interface AlertConfig {
//     type: AlertConfigTypes,
//     setting: any,
//     desc: string,
// }
export interface iTrip {
    deviceName?: string,
    device: string,
    personId?: string,
    tripId: string,
    fuel?: number;
    startDate: moment.Moment,
    endDate: moment.Moment,
    startAddress: iAddress,
    endAddress?: iAddress,
    maxSpeed?: number,
    safetyPercent: number,
    averageSpeed: number,
    label?: string,
    alertActivity: iTripAlertActivity;
    miles: number,
    pointCount: number,
    mpg?: number,
    stopMinutes: number,
    idleMinutes: number,
    time?: moment.Moment,
    minFuels?: any,
}

export interface iTripEager extends Omit<iTrip, "device" | "person"> {
    device: iDeviceDetails,
    person: iPerson
}

export enum ReportType {
    STATIC = "static",
    TRAVEL = "travel",
    GEOFENCE = "geofence"
}

export interface UserAuth {
    uid: string;
    displayName: string;
    photoURL: string;
    beToken?: beToken;
    email: string;
    acl?: {
        can: Array<UserCan>;
    }
    firstName?: string;
    lastName?: string;
    company?: string;
    organizationId?: string;
}

export interface iFullStoreState extends iReducersState {
    visible?: boolean,
}

export interface beToken {
    claimsInitialized?: boolean,
    realId?: string,
    clientId?: string,
    devices?: string[],
    role?: string
}

export interface MyUserInfo extends UserInfo {
    acl?: {
        can: string[]
    }
    firstName?: string;
    lastName?: string;
    company?: string;
    organizationId?: string;
}

export type AuthReducer = {
    user?: null | UserAuth;
    fbToken?: string;
    isFingerPrint?: boolean;
    isDriver?: boolean;
    mapApiToken?: string;
    logoutTime?: string | number,
    username: string;
    isPublic?: boolean;
    extraInfoKeys?: { [extraInfoKey: string]: string };
}

interface iFlashSuccess {
    success: boolean
}

interface iFlashFail {
    fail: boolean
}

type iFlashMessageHelper<T> = T & {
    message: string
}
export type iFlashMessage = iFlashMessageHelper<iFlashFail|iFlashSuccess>

export interface iDisplayRegion {
    shape: FenceType,
    editable: boolean,
    center: iLocation,
    height: number,
    width: number,
    color: string,
    ico: false|iIcon,
    itemId: string,
    name: string,
    hideIcon?: boolean,
    hideName?: boolean,
    hideShadows?: boolean
}

export enum ItemType {
    // !!!! any changes here need to be reflected in functions
    device = 'device',
    person = 'person',
    tag = 'tag',
    fence = 'fence',
    values = 'values',
}

export type iFavorites = {
    [ItemType.device]: iList<boolean>,
    [ItemType.person]: iList<boolean>,
    [ItemType.tag]: iList<boolean>
};

export const tagAvailailableAlerts = [
    AlertTypes.Decel,
    AlertTypes.PowerOff,
    AlertTypes.PowerOn,
    AlertTypes.SOS,
    AlertTypes.Speed,
];

export const AlertTypeTitle = (alertType) =>  {
    const raw = {
        [AlertTypes.IgnOnInt]: 'Ignition On Interval Report',
        [AlertTypes.IgnOn]: 'Ignition On Report',
        [AlertTypes.IgnOffInt]: 'Ignition Off Interval Report',
        [AlertTypes.IgnOff]: 'Ignition Off Report',
        [AlertTypes.Poll]: 'On Demand Locate',
        [AlertTypes.Speed]: 'Speed Threshold',
        [AlertTypes.Accel]: 'Acceleration Threshold',
        [AlertTypes.Decel]: 'Deceleration Threshold',
        [AlertTypes.LowVolt]: 'Low Voltage',
        [AlertTypes.PowerUp]: 'Power Up/Reset',
        [AlertTypes.Idle]: 'Idle Threshold',
        [AlertTypes.TowStart]: 'Tow Start',
        [AlertTypes.TowStop]: 'Tow Stop',
        [AlertTypes.TripStart]: 'Trip Start',
        [AlertTypes.TripStop]: 'Trip Stop',
        [AlertTypes.Heartbeat]: 'Heartbeat',
        [AlertTypes.DirChange]: 'Direction Change',
        [AlertTypes.Mileage]: 'Mileage Threshold',
        [AlertTypes.VIgnOnInt]: 'Virtual Ignition On Interval Report',
        [AlertTypes.VIgnOn]: 'Virtual Ignition On Report',
        [AlertTypes.VIgnOff]: 'Virtual Ignition Off Report',
        [AlertTypes.BatDCInt]: 'Main Battery Disconnect Interval',
        [AlertTypes.BatDC]: 'Main Battery Disconnect',
        [AlertTypes.BatRC]: 'Main Battery Reconnect',
        [AlertTypes.MvStart]: 'Move Start',
        [AlertTypes.MvStop]: 'Move Stop',
        [AlertTypes.ECodes]: 'Engine Codes',
        [AlertTypes.InHi]: 'Input High',
        [AlertTypes.InLo]: 'Input Low',
        [AlertTypes.IdleStop]: 'Idle Stop',
        [AlertTypes.SOS]: 'SOS Alert',
        [AlertTypes.PowerOn]: 'Power On',
        [AlertTypes.PowerOff]: 'Power Off',
        [AlertTypes.Int]: 'GL300 Interval',
    }

    const lowerSafe = {};
    Object.keys(raw).forEach(k => lowerSafe[k.toLowerCase()] = raw[k]);

    return lowerSafe[alertType.toLowerCase()] || alertType;
}

export enum TimelineRangeType {
    CUSTOM = 0, MONTH = 1, WEEK = 2, DAY = 3
}

export enum ActiveTab {
    home, device, person, reports, settings, tag, favorites
}

export type DevicePingContainer = iList<iDevicePing>;
export type ReportContainer = iList<iDevicePing | iTrip>

export interface iReportTravelData {
    group: iDevicePing[] | iTrip[];
    device: iDeviceDetails;
    people: iList<iPerson>
}

export interface iReportStaticData {
    group: iDevicePing[] | iTrip[];
    device: iDeviceDetails;
    people?: iList<iPerson>
}

export interface iPermissionDevice {
    id: string;
    device?: string;
    tag?: string;
    readOnly?: boolean;
    name?: string;
}

export interface iPermissionTag {
    tag: {
       id: string;
       name: string;
       color: string;
       isAlertType: boolean;
       "devices-list": {
           action: string;
           deviceId: string;
       }[];
       icon: iIcon;
    };
    devices: iPermissionDevice[];
}

export interface iReportPermissionData {
    person: string;
    devices: iPermissionDevice[];
}

export interface iFormattedReportPermissionData {
    displayName: string;
    devices: iPermissionDevice[];
}

export interface iActivityReportData {
    deviceId: string;
    deviceName: string;
    ago: string;
    time: moment.Moment | string;
}

export interface iFullTravelData {
    records: iList<iDevicePing[] | iTrip[]>,
    reportData: Map<string, iDeviceDetails>,
    people: iList<iPerson>
}

export interface iFullStaticData {
    records: iList<iDevicePing[] | iTrip[]>,
    reportData: Map<string, iDeviceDetails>,
    people?: iList<iPerson>
}

export interface iSummaryItemResults {
	device: string;
	safetyPercent: string;
	driveTime: number;
	distanceTraveled: number;
	averageSpeed: number;
	stoppedTime: number;
}

export interface iExportSettings {
	fileName: string;
	deviceUnits: Units;
}

export interface iExportReportProps {
	displayRecords: iList<iDevicePing | iTrip>;
	reportType: iReportType;
	devicesDetails: DevicesDetailsContainer;
	people: iList<iPerson>;
}

export interface iExportReportPdfProps {
	report: iReportDetails;
	records: iList<iDevicePing[] | iTrip[]>;
	reportData: Map<string, iDeviceDetails>;
	people: iList<iPerson>;
}

export interface DateTimeFormatOptions {
	day: '2-digit',
	month: '2-digit'
}

export type AclFb = {
    [permission in keyof UserCan]: {
        [uid: string]: boolean;
    }
}

export type DefaultAclFb = Partial<Record<UserCan, boolean>>

export type tagObj = {
    name: string,
    id: string,
}

export type CSVArray = string[][][];

export type CSVObjectUnit = {
    [key: string]: string[]
};

export type CSVObject = Array<CSVObjectUnit>;

export interface iMaintenanceUpcomingTask {
    deviceName: string;
    taskName: string;
    upcomingHours: string;
    upcomingMiles: string;
    status: number; // when is equal to "2" it moves from maintenance needed to maintenance required
}

export interface iMaintenanceTask {
    name: string;
    date: number;
    miles_needed: string;
    miles_offset: string;
    hours_needed: string;
    hours_offset: string;
    alertEndpoint: iList<string>;
    mainUpcoming?: iList<iMaintenanceUpcomingTask>;
    contacts?: Array<string>;
}

export interface iCreateMaintenance extends iMaintenanceTask {
    clientKey: string;
    deviceKey: string;
    deviceName: string;
}

export enum LoadDocumentType {
    NONE,
    UPLOAD,
    DOWNLOAD,
}

export enum enumActionButton {
    _,
    DELETE,
    CANCEL,
}

export interface iFileIconProps {
    isLoaded?: boolean;
    inLoad?: boolean;
    loaded: number;
    sizeInBytes: number;
    loadType?: LoadDocumentType;
}

export interface iLoadedFileInfo extends iFileIconProps {
    name: string;
    storageName: string;
    file?: File;
    onDelete?: () => void;
    isCancel?: boolean;
    fullPath?: string;
    errorText?: string;
    actionButtonType?: enumActionButton;
}

export interface iCompleteMaintenanceTask {
    deviceKey: string;
    maintenanceKey?: string;
    upcomingMaintenanceKey?: string;
    taskInfo?: {
        deviceName: string;
        taskName: string;
        date: number;
        milesNeeded: string;
        milesOffset: string;
        hoursNeeded: string;
        hoursOffset: string;
        upcomingHours: string;
        upcomingMiles: string;
    }
    extraTask?: boolean;
    taskCompleteInfo: {
        date: number;
        odometer: number;
        labourHours: number;
        cost: number;
    }
    documents: iLoadedFileInfo[];
}

export type iCompleteMaintenanceTaskContainer = iList<iCompleteMaintenanceTask>;

export interface iEditMaintenanceTask {
    deviceKey: string;
    maintenanceKey: string;
    taskName: string;
    date: string;
    miles_needed: string;
    miles_offset: string;
    hours_needed: string;
    hours_offset: string;
    contacts?: Array<string>;
}

export interface iDownloadFileInfo extends iLoadedFileInfo {
    xhr?: XMLHttpRequest;
    isDocumentDisable?: boolean;
}

export interface iDownloadDocumentBlock {
    documents: iDownloadFileInfo[];
    updateDocuments?: any;
    needCancel?: boolean;
    className?: string;
}

export interface iOnDownloadFileClickProps extends iLoadedFileInfo{
    url: string;
}

export interface iUploadDocumentBlock {
    onSuccess?: (itm) => void;
    onChange?: (itm) => void;
    onReset?: () => void;
    fileSizeLimit?: number;
    filesMaxCount?: number;
    setInLoad?: (itm) => void;
    needReset?: boolean;
    loadType: LoadDocumentType;
}

export interface iDocumentsList {
    documents: iLoadedFileInfo[];
    loadType: LoadDocumentType;
    columns?: number;
    hasDeleteButton?: boolean;
    style?: CSSProperties;
    className?: string;
    onDownloadFileClick?: (itm: iLoadedFileInfo) => void;
}

export interface iDocumentItem extends iLoadedFileInfo {
    loadType?: LoadDocumentType;
    hasDeleteButton?: boolean;
    onDownloadFileClick?: (itm: iLoadedFileInfo) => void;
    xhr?: XMLHttpRequest;
    isDocumentDisable?: boolean;
}



export interface iMaintenanceTableData {
    columns: any;
    data: any;
    hasDeleteButton?: boolean;
    removeCallBack?: (itm) => void;
    deleteText?: string;
    className?: string;
    hasPagination?: boolean;
    paginationClass?: string;
    pageSize?: number;
    hasScroll?: boolean;
}

export interface iFirebaseError extends Error {
    code?: string,
    message: string,
    name: string,
    serverResponse?: any;
}

export interface iRemoveItemFromStorageResponse {
    isSuccess: boolean;
    error?: iFirebaseError;
}

export interface iFileName {
    name: string;
    className?: string;
}

export enum HttpStatus {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404
}

export interface iVisibleDevicesTags {
    userCanDo: string[];
    personId: string;
    tags: iList<iTag>;
    devicesDetails: DevicesDetailsContainer;
}

export type iFrequency = 'weekly' | 'monthly' | 'daily' | 'anually';

export type iFileFormat = 'pdf' | 'csv';

export enum MenuPinType {
    EXTRA_INFO = 'extra-info',
    TAGS = 'tags',
    ASSIGNED_DRIVER = 'assigned-driver',
    VISIBLE_TO_PEOPLE = 'visible-to-people',
    ALERTS = 'alerts'
}

export enum DvirStatuses {
    PRE_TRIP = 'Needs pre-trip inspection',
    POST_TRIP = 'Needs post-trip inspection',
    MAINTENANCE = 'Needs maintenance',
    COMPLETED = 'Completed'
}

export interface iDvirForm {
    name: string;
    description: string;
    id: string;
    headings: {
        id: string;
        firebase_key: string;
        header: string;
        description: string;
        sort_order: number;
    }[]
}

export interface iDvirFormsItem {
    id: number,
    firebase_key: string;
    name: string;
    brief_description: string;
    created_at: string;
    person_key: string;
}

export interface iDvirInfo {
    id: string,
    vehicle_name: string,
    vehicle_vin: string,
    firebase_key: string,
    device_key: string,
    person_key: string,
    status: string,
    created_at: string
}

export interface iHeaderItem {
    id: string,
    firebase_key: string,
    header: string,
    description: string,
    sort_order: string,
    pre_status: string,
    post_status: string,
    maintenance_status: string
}

export interface iDvirInspection {
    id: string,
    person_key: string,
    type: string,
    ip_address: string,
    browser_type: string,
    start_date: string,
    end_date: string,
    odometer: string,
    notes: string
}

export enum ExtraInfoFields {
    MAKE = 'Make',
    MODEL = 'Model',
    SERIAL = 'Serial Number',
    YEAR = 'Year'
}

export interface ExtraInfoEntry {
    details?: {
        name?: string;
    };
}
