import React, { useEffect, useState } from 'react';
import DashboardBlock from "../../../DashboardBlock";
import { useDispatch } from "react-redux";
import { dvirCloseDashboard, dvirOpenDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import { useHistory } from "react-router-dom";
import styles from './styles.module.scss';
import { localStorage } from "../../../../shared/storage";
import { useRedux } from "../../../../states/redux-state";
import classNames from "classnames";
import instance from "../../../../api/instance";
import DvirFormInput from "../DvirFormInput";
import DvirFormHeadingInput from "../DvirFormHeadingInput";
import DvirFormHeadings from "../DvirFormHeadings";
import { ReactComponent as ArrowBackIcon } from '../../../../assets/svg/arrow-back.svg';

const CreateDvirForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [headingName, setHeadingName] = useState<string>('');
  const [headingDescriptionName, setHeadingDescriptionName] = useState<string>('');
  const [headings, setHeadings] = useState<{ header: string, description: string, sort_order: number }[]>([]);
  const [nameIsEmpty, setNameIsEmpty] = useState<boolean>(false);
  const [isHeaderNameError, setIsHeaderNameError] = useState<boolean>(false);
  const [headerNameErrorMsg, setHeaderNameErrorMsg] = useState<string>('');
  const [headingsAreEmpty, setHeadingsAreEmpty] = useState<boolean>(false);
  const clientId = localStorage.get('active-client');
  const uid = useRedux((s) => s.auth.user.uid);

  useEffect(() => {
    dispatch(dvirOpenDashboard());
    (window as any).Beacon('destroy')

    return () => {
      dispatch(dvirCloseDashboard());
      (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
    }
  }, []);

  const addHeading = async () => {
    if (headingName.trim() === '') {
      setIsHeaderNameError(true);
      setHeaderNameErrorMsg('Heading Name can\'t be empty');
    }
    else if (!headings.every(heading => heading.header != headingName)) {
      setIsHeaderNameError(true);
      setHeaderNameErrorMsg('Duplicate');
      setHeadingName('');
    }
    else {
      setHeadings([...headings, {
        header: headingName,
        description: headingDescriptionName,
        sort_order: headings.length + 1
      }]);

      setHeadingName('');
      setHeadingDescriptionName('');
      setHeadingsAreEmpty(false);
    }
  }

  const removeHeading = (name) => {
    setHeadings(headings.filter(heading => heading.header !== name));
  }

  const createDvirForm = async () => {
    const URL = 'api/dvir-form/create-dvir-form';
    const body = {
      organization_key: clientId,
      name,
      brief_description: description,
      person_key: uid,
      created_at: Math.round(new Date().getTime() / 1000),
      header_items: headings
    }

    if (name.trim() === '') {
      setNameIsEmpty(true);
    }
    else if (headings.length === 0) {
      setHeadingsAreEmpty(true);
    }
    else {
      const res = await instance.post(URL, { ...body });

      setName('');
      setDescription('');
      setHeadings([]);
      history.goBack();
    }
  }

  const activeCreateButton = (name.trim() !== '') && (headings.length !== 0);

  return (
    <DashboardBlock>
      <div className={styles.CreateDvirFormHeader}>
        <button className={styles.CreateDvirFormHeader__backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <h2>Create DVIR Form</h2>
        <div></div>
      </div>
      <div className={styles.CreateDvirFormContainer}>
        <DvirFormInput
          name={name}
          description={description}
          onChangeName={(e) => {
            setName(e.target.value);
            setNameIsEmpty(false);
          }}
          onChangeDescription={(e) => {
            setDescription(e.target.value);
          }}
          nameIsEmpty={nameIsEmpty}
        />
        <DvirFormHeadingInput
          name={headingName}
          description={headingDescriptionName}
          isHeaderNameError={isHeaderNameError}
          headerNameErrorMsg={headerNameErrorMsg}
          onChangeName={(e) => {
            setIsHeaderNameError(false);
            setHeadingName(e.target.value);
          }}
          onChangeDescription={(e) => {
            setHeadingDescriptionName(e.target.value);
          }}
          onClickErrorBlock={() => setIsHeaderNameError(false)}
          addHeading={addHeading}
        />
        {headings.length > 0 && (
          <DvirFormHeadings
            headings={headings}
            removeHeading={removeHeading}
          />
        )}
        {headingsAreEmpty && <p className={styles.NameErrorMsg}>Headings can't be empty</p>}
      </div>
      <div className={styles.bottomPanel}>
        <button onClick={() => history.goBack()} className={styles.bottomPanel__secondaryButton}>Cancel</button>
        <button
          onClick={createDvirForm}
          className={classNames(styles.bottomPanel__primaryButton, { [styles.bottomPanel__primaryButton_active]: activeCreateButton })}
        >
          Create
        </button>
      </div>
    </DashboardBlock>
  )
};

export default CreateDvirForm;
