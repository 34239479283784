import React, { useEffect, useState } from 'react';
import styles from './DeviceButtons.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import InfoIcon from '../../../../assets/svg/info_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg';
import ViewIcon from '../../../../assets/svg/play_circle_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg';
import HistoryIcon from '../../../../assets/svg/history_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg';
import LocateIcon from '../../../../assets/svg/gps_fixed_24px.svg';
import ReportsIcon from '../../../../assets/svg/history-icon.svg';
import {checkStatusCamera} from "../../../../api/openApi";
import {cameraAvailableTime, openModal, statusCameraOnline} from "../../../../stores/reducers/videoCameras";
import {iReducersState} from "../../../../stores/reducers";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "../../../Modal";
import Portal from "../../../Portal";
import instance from "../../../../api/instance";
import DeviceStatuses from "../deviceStatuses";
import * as devicedb from "../../../../shared/db/devices-db";
import {LOCATION_STATUS, useIsLocating} from "../../../../stores/reducers/general-reducers";
import {useReduxPath} from "../../../../states/redux-state";
import Dialog, {DialogConfigSetter} from "../../../Dialog";
import {Col, Row} from "../../../elements/flex";
import {Fa} from "../../../elements/fa";
import {
  faBatteryEmpty,
  faBatteryFull, faBatteryHalf, faBatteryQuarter,
  faBatteryThreeQuarters,
  faCircleNotch,
  faToggleOff,
  faToggleOn
} from "@fortawesome/fontawesome-free-solid";
import {useHistory} from "react-router-dom";
import {removeReportTrips} from "../../../../stores/reducers/devicesTripsPoints/AC";
import moment from "moment/moment";
import {ReportAC} from "../../../../stores/reducers/report-reducers";
import {useFirebase} from "../../../../states/firebase-state";
import {iDevicePing, iFullStoreState} from "../../../../shared/interfaces";
import Captcha from "../../../Captcha/Captcha";
import C, { ACL, UserCan } from '../../../../shared/constants';
import {
  unless,
  not,
  always,
  when,
  prop,
  cond,
  lte,
  lt,
  T
} from 'ramda';
import GaugeChart from "react-gauge-chart";
import {safeHas} from "../../../../shared/ramda";
import {friendlySpeed} from "../../../../shared/helpers";
import {getStartAndEndOfDate} from "../../../../utils";

const INTERVAL_TIME: number = 30000;

export const DeviceButtons = ({device, isDriver, onCheckEngineClick}) => {
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isCamera, 'extra-info': serialNumber } = device || {};
  const serial = Object.values(serialNumber)[0].toString();
  const [availableTime, setAvailableTime] = useState<number>(null);
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);
  const [lastDevicePingsFuel, setLastDevicePingsFuel] = useState<number | null>(null);

  const { openLiveViewPlayer, openHistoryPlayer }: any = useSelector(
    (state: iReducersState) => state.videoCameras.modals
  );
  const { cameraOnline } = useSelector(
    (state: iReducersState) => state.videoCameras
  );
  const deviceLastPing = useSelector<iFullStoreState, iDevicePing | undefined | null>(
    (state) => state.devicesData.devicesLastPing.get(device.id),
    (l, r) => typeof l === typeof r && l?.pointId === r?.pointId,
  );
  const dispatch = useDispatch();

  const CAMERA_ONLINE = cameraOnline === 1;
  const SAVE_MODE = cameraOnline === 2;
  const hasFuel = lastDevicePingsFuel;
  const hasEnergy = !!deviceLastPing?.bat_percent;
  const hasVolt = !!deviceLastPing?.volt;

  const userCanDo = useSelector<iFullStoreState, Array<string>>(
    (s) => s.auth.user?.acl?.can
  );

  // device info show rules
  const showDeviceStatus = !ACL.check(UserCan.HIDE_DEVICE_STATUS, userCanDo, true);
  const showDeviceView = !ACL.check(UserCan.HIDE_DEVICE_VIEW, userCanDo, true);
  const showDeviceHistory = !ACL.check(UserCan.HIDE_DEVICE_HISTORY, userCanDo, true);
  const showDeviceLocate = !ACL.check(UserCan.HIDE_DEVICE_LOCATE, userCanDo, true);
  const showDeviceTodaysHistory = !ACL.check(UserCan.HIDE_DEVICE_TODAYS_HISTORY, userCanDo, true);
  const showDeviceFuel = !ACL.check(UserCan.HIDE_DEVICE_FUEL, userCanDo, true);
  const showDeviceBattery = !ACL.check(UserCan.HIDE_DEVICE_BATTERY, userCanDo, true);
  const showDeviceVolt = !ACL.check(UserCan.HIDE_DEVICE_VOLT, userCanDo, true);
  const showDeviceBatteryPercent = !ACL.check(UserCan.HIDE_DEVICE_BATTERY_PERCENT, userCanDo, true);
  const showDeviceCheckEngine = !ACL.check(UserCan.HIDE_DEVICE_CHECK_ENGINE, userCanDo, true);
  const showDeviceSpeed = !ACL.check(UserCan.HIDE_DEVICE_SPEED, userCanDo, true);

  const getStatus = async (): Promise<void> => {
    setIsLoading(true);
    if (isCamera && device.id) {
      const { onlineStatus, channels, cameraTime } = await checkStatusCamera({
        firebaseDeviceId: device.id,
      });
      dispatch(statusCameraOnline(onlineStatus));
      dispatch(cameraAvailableTime(cameraTime?.available_time));
      setAvailableTime(cameraTime?.available_time);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStatus();
    let interval;
    if (openHistoryPlayer || openLiveViewPlayer) {
      clearInterval(interval);
    } else {
      interval = setInterval(getStatus, INTERVAL_TIME);
    }

    return () => clearInterval(interval);
  }, [openHistoryPlayer, openLiveViewPlayer]);

  useEffect(() => {
    const fetchData = async () => {
      const lastFuel = await devicedb.getDeviceLastFuel(deviceLastPing?.device);
      setLastDevicePingsFuel(lastFuel);
    };

    fetchData();
  }, [deviceLastPing])

  const checkIfOnline = () => {
    if (!CAMERA_ONLINE) setShowOfflineModal(true);
  }

  const liveViewClick = (): void => {
    checkIfOnline();

    if (SAVE_MODE) {
      setIsModalShow(true);
    } else {
      if (CAMERA_ONLINE)
        dispatch(openModal({ key: 'openLiveViewModal', value: true }));
      if (CAMERA_ONLINE && availableTime === 0)
        dispatch(openModal({ key: 'openBuyMoreModal', value: true }));
    }
  };

  const onCloseModal = () => {
    setIsModalShow(false);
  };

  const onAcceptModal = () => {
    setIsLoading(true);
    setIsModalShow(false);

    instance
      .get(`/api/device/change-cameras-state?uniqueId=${serial}&onlineState=2`)
      .then(() => {
        getStatus();
      });
  };

  const history = () => {
    checkIfOnline();

    if (SAVE_MODE) {
      setIsModalShow(true);
    } else {
      if (CAMERA_ONLINE)
      dispatch(openModal({ key: 'openHistoryModal', value: true }));
      if (CAMERA_ONLINE && availableTime === 0)
        dispatch(openModal({ key: 'openBuyMoreModal', value: true }));
    }
  };

  return (
    <PerfectScrollbar
      className={styles.DeviceButtonsHorizontalScroll}
      options={{ useBothWheelAxes: false, suppressScrollY: true }}
    >
    <div className={styles.DeviceButtons}>
      {(showOfflineModal && !SAVE_MODE) && <Modal>
          <div style={{
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
            position: 'relative'
          }}>
              <h3 style={{textAlign: 'center'}}>Device is offline</h3>
              <p>This camera is currently not connected to the network. The camera may be powered off, or out of
                  cellular coverage. Please wait for the camera to connect before trying to view any video.</p>
              <button onClick={() => setShowOfflineModal(false)} style={{
                fontSize: '25px',
                position: 'absolute',
                top: '10px',
                right: '10px'
              }}>&#x2715;</button>
          </div>
      </Modal>}
      {showDeviceStatus && isCamera && <div className={styles.DeviceButton} onClick={checkIfOnline}>
        {isLoading ? <CircularProgress size={25}/> : <img src={InfoIcon}/>}
          <span>Status</span>
      </div>}
      {showDeviceView && isCamera && <div className={styles.DeviceButton} onClick={liveViewClick}>
        {isLoading ? <CircularProgress size={25}/> : <img src={ViewIcon}/>}
          <span>View</span>
      </div>}
      {showDeviceHistory && isCamera && <div className={styles.DeviceButton} onClick={history}>
        {isLoading ? <CircularProgress size={25}/> : <img src={HistoryIcon}/>}
          <span>History</span>
      </div>}
      {showDeviceStatus && <DeviceStatuses device={device}/>}
      {showDeviceLocate && !isDriver && <LocateDevice deviceId={device.id}/>}
      {showDeviceTodaysHistory && !isDriver && <DeviceHistoryLink deviceId={device.id}/>}
      {!isDriver && <GoPremiumBtn deviceId={device.id}/>}
      {showDeviceFuel && hasFuel && unless(not, always(
          <div className={styles.DeviceButton}>
            <Gauge
              height={25}
              width={25}
              percent={lastDevicePingsFuel}
              title="Fuel"/>
          </div>
        ), deviceLastPing
      )}
      {showDeviceBattery && hasEnergy && unless(not, always(
          <div className={styles.DeviceButton}>
            <Gauge
              height={25}
              width={25}
              percent={deviceLastPing?.bat_percent}
              title="Internal Battery"
            />
          </div>
        ), deviceLastPing
      )}
      {showDeviceVolt && hasVolt && when(safeHas('volt'), (p) => <VoltageIndicator volt={prop('volt', p)}/>, deviceLastPing)}
      {showDeviceBatteryPercent && unless(not, always(<BatteryIndicator percent={device.batteryPercent}/>), 'batteryPercent' in device)}
      {showDeviceCheckEngine && unless(not, always(<CheckEngine onClick={() => onCheckEngineClick(true)}/>), device.hasCheckEngine)}
      {showDeviceSpeed && deviceLastPing && deviceLastPing.speed && (
        <div className={styles.DeviceButton} style={{height: '59px'}}><span style={{fontSize: '20px'}}>{friendlySpeed(deviceLastPing.speed, device.units)}</span></div>)}
      {isModalShow && (
        <Portal>
          <div
            className='device-status__modal-wrapper'
            style={{
              zIndex: 99,
              position: 'absolute',
              top: 0,
              left: 0,

              width: '100vw',
              height: '100vh',
              backdropFilter: 'brightness(0.3)',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              className='device-status__modal'
              style={{
                minHeight: '200px',
                maxHeight: '20%',

                minWidth: '300px',
                maxWidth: '50%',

                backgroundColor: '#fff',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <p
                style={{
                  padding: '20px',
                }}
              >
                This unit is asleep in power save mode. This is done to preserve
                battery life. To wake the unit up select yes. It may take up to
                2 minutes for the unit to wake up.
              </p>

              <div
                className='device-status__modal-footer'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',

                  width: '100%',
                  height: '50px',

                  borderTop: '1px solid black',

                  padding: '0 30px',
                }}
              >
                <button
                  className='but  but-white-gray but-md'
                  onClick={onCloseModal}
                >
                  No
                </button>
                <button
                  className='but  but-orange-white but-md'
                  style={{
                    marginLeft: '5px',
                  }}
                  onClick={onAcceptModal}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
</PerfectScrollbar>
  )
}

export const LocateDevice = React.memo(({deviceId}: { deviceId: string }) => {
  const [remainingLocateCount] = devicedb.useMonthlyCounter('location', deviceId);
  const [locationStatus, {locate: requestLocate, clear: clearLocate}] = useIsLocating(deviceId);
  const [, setFlash] = useReduxPath((s) => s.general.flashMessage, ['general', 'flashMessage']);

  const dialogRef = React.useRef<DialogConfigSetter>();
  const setupDialog = (callBack: () => DialogConfigSetter): void => {
    dialogRef.current = callBack();
  };

  useEffect(() => {
    if (locationStatus === LOCATION_STATUS.FAIL) {
      setFlash({
        message: 'Unable to locate your device. The map will automatically update once your device is able to report its location',
        fail: true,
      });
      clearLocate();
    } else if (locationStatus === LOCATION_STATUS.SUCCESS) {
      setFlash({
        message: 'Location Successful',
        success: true,
      });
      clearLocate();
    }
  }, [locationStatus]);

  const doLocate = async () => {
    if (locationStatus !== LOCATION_STATUS.INACTIVE) {
      return;
    }

    const dialog = dialogRef.current;

    // warn that no counts are available
    if (remainingLocateCount < 1) {
      dialog?.({
        type: 'NOTIFICATION',
        title: 'Exceeded Maximum Locates',
        body: 'Your account has exceeded the maximum locates for 24 hour period',
      });

      return;
    }

    const confirmLocation = await dialog?.({
      type: 'CONFIRM',
      title: 'Confirm Location Request',
      body: <div>
        <p>Your device automatically updates information as per your service plan. On Demand will attempt to locate your device regardless of report interval.</p>
        <p>This will deduct one On Demand Locate from your account.</p>
        <p>You have {remainingLocateCount} On Demand Locates left for this month.</p>
        <p>Click OK to locate.</p>
      </div>
    });

    if (!confirmLocation) return;

    requestLocate();
  };

  return (
    <div className={styles.DeviceButton} onClick={doLocate}>
      <img src={LocateIcon}/>
      <span>Locate</span>
      <Dialog setupConfig={setupDialog} />
    </div>
  )
});

const DeviceHistoryLink = React.memo(({deviceId}: {deviceId: string}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToReports = () => {
    dispatch(removeReportTrips());
    dispatch({ type: 'REPORT_RESET_ALL_FILTERS' });

    const [startDate, endDate] = getStartAndEndOfDate();

    dispatch(ReportAC.TOGGLE_ITEM_FILTER('device', deviceId));
    dispatch(ReportAC.UPSERT_DATE_GROUP_IN_DATES({ startDate, endDate }));

    history.push('/reports');
  }

  return (
    <div className={styles.DeviceButton} onClick={() => goToReports()}>
      <img src={ReportsIcon}/>
      <span>Today’s History</span>
    </div>
  )
});

const GoPremiumBtn = ({deviceId}: {deviceId: string}) => {
  const [premiumRequested, setPremiumRequested] = useFirebase(`devices/device-details/${deviceId}/premiumRequested`);
  const [premiumCount, setPremiumCount] = useFirebase(`devices/premium-count/${deviceId}`)
  const [remainingPremiumCount] = devicedb.useMonthlyCounter('premium', deviceId);
  const premiumUntil = useSelector<iFullStoreState, moment.Moment | undefined>((s) => s.devicesData.devicesDetails.getIn([deviceId, 'premiumUntil']));
  const canPremium = useSelector<iFullStoreState, Boolean>((s) => s.devicesData.devicesDetails.getIn([deviceId, 'canPremium']))
  const isPremium = premiumUntil && premiumUntil.isAfter(moment());
  const [isShowCaptcha, setIsShowCaptcha] = useState(false)

  if(isPremium && premiumRequested && premiumCount){

    const [name, temp] = Object.entries(premiumCount)[0]
    const startOfMonth = moment().startOf('month')

    if(moment(temp.lastUpdated).isBefore(startOfMonth)){
      temp.remaining = temp.max
    }

    temp.lastUpdated = moment().format('YYYY-MM-DDThh:mm:ss')
    temp.remaining--

    setPremiumCount({[name]: temp})
    setPremiumRequested(false)
  }

  const dialogRef = React.useRef<DialogConfigSetter>();
  const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

  const doPremiumRequest = async () => {
    if (isPremium) return;

    const dialog = dialogRef.current;

    if (!remainingPremiumCount) {
      dialog?.({
        type: 'NOTIFICATION',
        title: 'Premium unavailable',
        body: 'You have used all of your premium activations for the month',
      });

      return;
    }

    const confirmed = await dialog?.({
      type: 'CONFIRM',
      title: 'Activate Premium Service',
      body:'You are about to activate 24 hours of premium service.',
    });

    if (!confirmed) return;

    setIsShowCaptcha(true)

  };

  if (!!canPremium) {
    return (
      <div className={styles.DeviceButton} onClick={doPremiumRequest}>
        {isShowCaptcha&&<Captcha
            onVerify={() => setPremiumRequested(true)}
            closeCaptcha={() => setIsShowCaptcha(false)}/>}
        {premiumRequested && !isPremium ?
          <Fa size={20} icon={faCircleNotch} spin title="Premium upgrade is being processed" /> :
          <Fa
            size={18}
            icon={isPremium ? faToggleOn : faToggleOff}
            style={{
              fontSize: 16,
              color: isPremium ? C.primaryColor : 'initial',
              cursor: !isPremium ? 'pointer' : 'default'}}
          />
        }
        <span>Theft Recovery</span>
        <Dialog setupConfig={setupDialog} />
      </div>
    )
  }

  return null;
};

const Gauge = ({percent, height, width, title}) => {

  const id = title.split(' ').join('_')

  return(
    <Col
      align="center"
      style={{zIndex: 1}}
      justify="flex-end"
    >
      <Row justify="center" align="center" style={{height, width}}>
        <div style={{width: 65, marginBottom: -10}}>
          <GaugeChart id={id}
                      arcWidth={0.2}
                      cornerRadius={1}
                      hideText
                      arcsLength={[0.1, 0.2, 0.7]}
                      colors={['#ee3e3e', '#fddc00', '#30b22d']}
                      percent={percent === 'N/A' ? 0 : percent / 100}
          />
        </div>
      </Row>
      <span style={{fontSize: 10}}>{title}: {percent === 'N/A' ? 'N/A' : `${percent}%`}</span>
    </Col>
  )
};

const VoltageIndicator = ({volt}) => (
  <div className={styles.DeviceButton} style={{height: '59px'}}>
    <span style={{fontSize: '20px', height: '23px'}}>{volt}</span>
    <span>Volts</span>
  </div>
);

const BatteryIndicator = ({percent}) => (
  <div className={styles.DeviceButton} style={{height: '59px'}}>
    <Fa
      title={`${percent}%`}
      icon={cond([
        [lte(75), always(faBatteryFull)],
        [lte(50), always(faBatteryThreeQuarters)],
        [lte(25), always(faBatteryHalf)],
        [lt(0), always(faBatteryQuarter)],
        [T, always(faBatteryEmpty)],
      ])(percent)}
      style={{color: 'orangered', fontSize: 30, height: '30px'}}
    />
    <span>Battery {percent}%</span>
  </div>
);

const CheckEngine = ({onClick}) => (
  <div className={styles.DeviceButton} style={{height: '59px'}} onClick={onClick}>
    <img src="images/engine.svg" alt='engine image' width={30} height={30}  />
    <span title="Click to dismiss">Check Engine</span>
  </div>
);
