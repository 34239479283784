import React from 'react';

import {DashboardSize} from '../../../../stores/reducers/dashboardInfo';
import {ReactComponent as FilterListSvg} from "../../../../assets/svg/filter_list.svg";
import {Link} from 'react-router-dom';
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import {DevicesDetailsContainer} from '../../../../stores/reducers/devicesData';
import StaticTotalTable from './StaticTotalTable';
import TravelTotalTable from './TravelTotalTable';
import {BottomShadowModal} from "../BottomShadowModal";
import {ACL, UserCan} from '../../../../shared/constants';
import {
    isMobile,
    vals
} from '../../../../shared/helpers';
import {GMapModalAC, GMapModalContent} from '../../../../stores/reducers/gMapModal/AC';
import {groupBy} from 'ramda';
import {useRedux} from '../../../../states/redux-state';
import {useDispatch, useSelector} from 'react-redux';
import {
    iFullStoreState,
    ItemType,
} from '../../../../shared/interfaces';
import {
    Actions as ReportActions,
    iReportDetails,
    iState as iReportState,
} from '../../../../stores/reducers/report-reducers';

const LocalModalActions = (props) => {
    const report = useSelector<iFullStoreState, iReportDetails>(s => s.report.details);
    const dashboardInfo = useRedux(s => s.dashboardInfo);
    const reportData = useSelector<iFullStoreState, DevicesDetailsContainer>(s => s.devicesData.devicesDetails);
    const reportType = useSelector<iFullStoreState, string>(s => s.report.details.reportType);
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));

    const dispatch = useDispatch();
    const handleExport = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.EXPORT_REPORT, params: {report, records, reportData}}))
    };
    const handleFilters = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS}));
    const handleCreateReport = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.GENERATE_REPORT}));
    const {userCanDo} = props;
    const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo, true);
    const exportButtonName = process.env.REACT_APP_REPORTS_EXPORT_BUTTON_NAME || 'Export';
    return (
        <BottomShadowModal>
            <div className={`report-summary ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""} ${reportType === "geofence" || reportType === "travel"? "report-summary--end": ""}`}>
                {
                    reportType === "static"
                        ? <StaticTotalTable />
                        : reportType === 'summary'
                            ? <TravelTotalTable />
                            : null
                }
                <div className="report-summary-actions" >
                    {seeAllReports && (<Link className={"generate-report-btn link"} to={(isMobile || window['cordova']) ? "/reports" : "/reports/scheduled?generateReport=true"}>
                        <Button
                            styleType={ButtonStyles.ORANGE_WHITE}
                            size={(isMobile || window['cordova']) && ButtonSize.LG}
                            onClick={(isMobile || window['cordova']) && handleCreateReport}
                        >
                            Generate Report
                        </Button>
                    </Link>)}
                    {(!isMobile) &&
                    <Button
                        className={"filters-btn"}
                        styleType={ButtonStyles.BLACK_WHITE}
                        onClick={handleFilters}
                    >
                        <FilterListSvg className="icon-16px"/>
                    </Button>
                    }
                    <Button
                        className={"export-btn"}
                        styleType={ButtonStyles.WHITE_GRAY}
                        onClick={handleExport}
                        size={(isMobile || window['cordova']) && ButtonSize.LG}
                    >
                        {exportButtonName}
                    </Button>
                </div>
            </div>
        </BottomShadowModal>
    )
}

export default LocalModalActions;