import React, { FC } from "react";
import { ItemType } from '../../../shared/interfaces';
import { FaFav } from "../../elements/fa";
import useFavorite from '../../../hooks/useFavorite';

type FavoritePropsType = {
    favoriteType: ItemType;
    favoriteId: string;
}

const FavoriteBLL: FC<FavoritePropsType> = ({favoriteType, favoriteId}) => {
    const {
        toggle: handleFavoriteClick,
        isFavorite,
    } = useFavorite({ type: favoriteType, id: favoriteId });

    return (
        <button onClick={handleFavoriteClick}>
            <FaFav isFav={isFavorite} style={isFavorite ? {color: "rgb(247, 216, 45)"} : {color: "black"}}/>
        </button>
    )
}

export default FavoriteBLL;
