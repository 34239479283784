import React, {useState} from 'react';
import {Fa} from "../../../elements/fa";
import {faChevronDown, faChevronRight} from "@fortawesome/fontawesome-free-solid";
import EngineIcon from '../../../../assets/svg/engine.svg';
import styles from './DeviceEngineHistory.module.scss';

const DeviceEngineHistory = ({body, alwaysOpen, title, cssOrder}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  if (alwaysOpen) {
    setCollapsed(false);
  }

  return (
    <div className={styles.DeviceEngineHistory} style={{order: cssOrder}}>
      <div className={styles.DeviceEngineBar}>
        <div className={styles.DeviceEngineHeader}>
          <img src={EngineIcon} alt='Engine Image'/>
          <span>{title}</span>
        </div>
        {alwaysOpen ? null : <Fa icon={collapsed ? faChevronRight : faChevronDown} style={{width: '20px', height: '20px', color: '#000', opacity: '0.5'}} onClick={() => setCollapsed(!collapsed)}/>}
      </div>
      {collapsed ? null : <div className={styles.DeviceEngineBody}>{body}</div>}
    </div>
  )
}

export default DeviceEngineHistory;
