import React from 'react';
import {useSelector} from "react-redux";
import {iDeviceDetails, iDevicePing, iFullStoreState, iPerson, ItemType} from "../../../../shared/interfaces";
import { equals } from 'ramda';
import styles from "./DeviceListItem.module.scss";
import StarIconOrange from "../../../../assets/svg/star_24_orange.svg";
import StarIcon from "../../../../assets/svg/star_border_24px.svg";
import {useRedux} from "../../../../states/redux-state";
import {useFirebase} from "../../../../states/firebase-state";
import { ReactComponent as PersonThinIcon } from '../../../../assets/svg/personThin.svg';
import classNames from 'classnames';


const DeviceListItem = ({deviceId}) => {
  const device = useSelector<iFullStoreState, iDeviceDetails>(
    (state) => state.devicesData.devicesDetails.get(deviceId)!,
    (left, right) => equals(left, right)
  );
  const lastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
    (state) => state.devicesData.devicesLastPing.get(deviceId),
    (left, right) => left?.pointId === right?.pointId
  );
  const pingMsg = lastPing?.msg ?? 'N/A';
  const hasSpeed = Boolean(lastPing?.speed);
  const uid = useRedux((s) => s.auth.user.uid);
  const [isFavorite, setFavorite] = useFirebase(
    `favorites/${uid}/${ItemType.device}/${deviceId}`
  );

  const pingMsgCheck = (pingMsg === 'Ignition on' ||
    pingMsg === 'Acceleration Threshold' ||
    pingMsg === 'Acceleration threshold' ||
    pingMsg === 'Deceleration Threshold' ||
    pingMsg === 'Deceleration threshold' ||
    pingMsg === 'Idle Threshold' ||
    pingMsg === 'Idle threshold' ||
    pingMsg === 'Ignition On Report' ||
    pingMsg === 'Ignition On Interval Report' ||
    pingMsg === 'Direction Change' ||
    pingMsg === 'Virtual Ignition On Interval Report' ||
    pingMsg === 'Virtual Ignition On Report' ||
    pingMsg === 'Move Start' ||
    pingMsg === 'Move Stop' ||
    pingMsg === 'Trip Start');

  const toggleFav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFavorite(isFavorite ? null : 999999);
  };

  const keysOfMakeAndModelValue = {
    productionFirebase: {
      keyOfMakeValue: '-OGBeZKg_SPUQLY3Mu71',
      keyOfModelValue: '-OGBe_aAE47sOw4OKqy9',
    },
    stagingFirebase: {
      keyOfMakeValue: '-O3DOuQg0W35h0XkWmZl',
      keyOfModelValue: '-O3DRQ92aQOrLLi6glci',
    },
  };

  const keyOfMakeValue = keysOfMakeAndModelValue.productionFirebase.keyOfMakeValue;
  const makeNameOfDevice = device["extra-info"]?.[keyOfMakeValue] ?? null;

  const keyOfModelValue = keysOfMakeAndModelValue.productionFirebase.keyOfModelValue;
  const modelNameOfDevice = device["extra-info"]?.[keyOfModelValue] ?? null;

  const assignedDriverId = device?.assignedTo?.personId;
  const assignedDriver = useSelector<iFullStoreState, iPerson>(state => assignedDriverId ? state.general.people[assignedDriverId] : null);

  return (
    <div className={styles.DeviceListItem}>
      <div className={styles.DeviceListItemInfoContainer}>
        <div className={styles.DeviceListItemInfo}>
          {pingMsg === 'Heartbeat' && (
            <div className={styles.DeviceListItemIndicator} style={{backgroundColor: 'pink'}}/>
          )}

          {(pingMsg === 'Ignition off' ||
            pingMsg === 'Ignition Off Report' ||
            pingMsg === 'Ignition off Report' ||
            pingMsg === 'Ignition Off Interval Report' ||
            pingMsg === 'Virtual Ignition Off Report' ||
            pingMsg === 'Trip Stop') && (
            <div className={styles.DeviceListItemIndicator} style={{backgroundColor: 'red'}}/>
          )}

          {pingMsgCheck && !hasSpeed && (
            <div className={styles.DeviceListItemIndicator} style={{backgroundColor: 'yellow'}}/>
          )}

          {pingMsgCheck && hasSpeed && (
            <div className={styles.DeviceListItemIndicator} style={{backgroundColor: 'green'}}/>
          )}
          <span>
            {device.name}
            {(makeNameOfDevice && modelNameOfDevice) ? ` - ${makeNameOfDevice} ${modelNameOfDevice}` :
              (makeNameOfDevice || modelNameOfDevice) ? ` - ${makeNameOfDevice || modelNameOfDevice}` : ''}
          </span>
          {(device?.assignedTo && assignedDriver) && (
            <span className={styles.AssignedDriver}>
              <PersonThinIcon className={styles.AssignedDriver__icon} />
              <span>{assignedDriver?.displayName}</span>
            </span>
          )}
        </div>
      </div>
      <img
        className={classNames(styles.DeviceListItemFavorite, { [styles.DeviceListItemFavorite__favorite]: isFavorite })}
        src={isFavorite ? StarIconOrange : StarIcon}
        onClick={toggleFav}
      />
    </div>
  )
}

export default DeviceListItem;
