import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import * as color from 'color';
import {compose} from 'redux';
import classNames from 'classnames';

import {IconTypes, iFullStoreState} from '../../../../shared/interfaces';
import { ACL, getIconTabByName, UserCan } from '../../../../shared/constants';
import {IInfoMarkerProps, IInfoMarkerPropsFromState} from './types';
import {removeSelectedPoint, setSelectedPoint} from '../../../../stores/reducers/selectedPoint/AC';
import {Actions} from '../../../../stores/reducers/gmap-reducers';
import {FaVector} from '../../../elements/fa';

import styles from './index.module.scss';
import { getViewBoxByPath } from '../../../../utils';
import {store} from "../../../../stores/store";

const mapStateToProps = (state: iFullStoreState, o: IInfoMarkerProps) => ({
  deviceDetails: state.devicesData.devicesDetails.get(o.deviceId),
  deviceLastPing: state.devicesData.devicesLastPing.get(o.deviceId),
  bounds: state.gmap.bounds,
});

const loadIcon = async (
  url: string,
  iconColor: string = '#000'
): Promise<string> => {
  const response = await fetch(url);
  const content = await response.text();

  const isSvg = content.includes('svg');

  if (isSvg) {
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(content, 'image/svg+xml');

    svgDocument
      .getElementsByTagName('svg')
      .item(0)
      ?.setAttribute('fill', iconColor.toUpperCase());

    const svgString = new XMLSerializer().serializeToString(svgDocument);
    const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

    return URL.createObjectURL(blob);
  } else {
    return url;
  }
};

const DeviceMarker = (props) => {
  const prevPropsRef = useRef<IInfoMarkerPropsFromState>();
  const { deviceDetails, deviceLastPing, dispatch, bounds } = props;
  const [markerIcon, setMarkerIcon] = useState<any>(null as any);
  const pingMsg = deviceLastPing?.msg ?? 'N/A';
  const hasSpeed = Boolean(deviceLastPing?.speed);

  const recenterToDevice = () => {
    const { dispatch, deviceId, doPan = false } = props;

    if (doPan) {
      dispatch(Actions.RECENTER_MAP_TO_DEVICE(deviceId, true));
    }
  };

  const updateMarker = async () => {
   if (!props.deviceDetails) return;

    const { size } = props;
    const { icon, color } = props.deviceDetails;

    let iconType = icon?.type;
    let url: string;

    if (!iconType) {
      if ('fa' in icon) {
        iconType = IconTypes.FaIcon;
      } else if ('svgIconName' in icon) {
        iconType = IconTypes.SvgIcon;
      } else {
        iconType = IconTypes.UserSvgIcon;
      }
    }

    switch (iconType) {
      case IconTypes.FaIcon:
        url = FaVector(icon.fa);
        break;
      case IconTypes.SvgIcon:
        const iconTab = getIconTabByName(icon.svgIconName);
        url = require(`../../../../assets/svg/${iconTab}/${icon.svgIconName}.svg`);
        break;
      case IconTypes.UserSvgIcon:
        url = icon?.url;
        break;
    }

    if (iconType === IconTypes.SvgIcon) {
      setMarkerIcon(null);
      url = await loadIcon(url, color);
    }

    const isIcon = 'url' in icon;
    const isFa = 'fa' in icon;
    const isSvg = !isIcon && !isFa;
    const fixedSize = 13;

    const marker = {
      [isFa ? 'path' : 'url']: url,
      type: iconType,

      size: new google.maps.Size(size, size),
      origin: new google.maps.Point(0, 0),
      strokeWeight: 0,

      ...(isIcon && {
        size: new google.maps.Size(fixedSize * 3, fixedSize * 3),
        anchor: new google.maps.Point(fixedSize, fixedSize * 3),
        scaledSize: new google.maps.Size(fixedSize * 3, fixedSize * 3),
      }),

      ...(isFa && {
        anchor: new google.maps.Point(100, 500),
        scale: 0.06,
        scaledSize: new google.maps.Size(size / 2, size / 2),
        fillColor: color || '#000',
        fillOpacity: 0.95,
      }),

      ...(isSvg && {
        size: new google.maps.Size(fixedSize * 4, fixedSize * 4),
        anchor: new google.maps.Point(fixedSize, fixedSize * 2.5),
        scaledSize: new google.maps.Size(fixedSize * 4, fixedSize * 4),
        fillColor: color || '#000',
        fillOpacity: 0.95,
      }),
    };

    setMarkerIcon(marker);
  };

  useLayoutEffect(() => {
    updateMarker();
  }, []);


  useEffect(() => {
    recenterToDevice();
  }, []);

  useEffect(() => {
    const prevProps = prevPropsRef.current;

    if (prevProps) {
      const prevLastLocation = prevProps.deviceLastPing?.coordinates.location;
      const lastLocation = props.deviceLastPing?.coordinates.location;

      if (!prevLastLocation && !!lastLocation) {
        recenterToDevice();
      }

      if (prevProps.deviceDetails?.icon !== props.deviceDetails?.icon) {
        updateMarker();
      }
    }

    prevPropsRef.current = props;
  }, [props]);

  const userCanDo = useSelector<iFullStoreState, Array<string>>((s) => s.auth.user?.acl?.can);
  const showMarkerNameAbove = ACL.check(UserCan.SHOW_MARKER_NAME_ABOVE, userCanDo, true);
  const roundedMapMarkerImage = ACL.check(UserCan.ROUNDED_MAP_MARKER_IMAGE, userCanDo, true);


  const labelStyle = useMemo(() => {
    if (!deviceDetails) return ({});
    return (
      showMarkerNameAbove ? {
        backgroundColor: 'transparent',
        color: '#fff',
        fontWeight: 600,
        textShadow: '0 0 2px #000, 0 0 5px #999, 0 0 8px #000',
      } : {
        backgroundColor: deviceDetails.color,
        color: color(deviceDetails.color).light() ? '#000' : '#fff',
        border: `1px solid ${color(deviceDetails.color).darken(
          0.5,
        )}`,
      });
  }, [showMarkerNameAbove, deviceDetails]);

  // calculate viewBox for path
  const viewBox = useMemo(() => {
    if (!(markerIcon && markerIcon.path)) return;

    return getViewBoxByPath(markerIcon.path);
  }, [markerIcon && markerIcon.path]);

  if (!deviceLastPing || !deviceDetails) return null;

  const {
    device: deviceId,
    tripId,
    pointId,
    coordinates: { location },
  } = deviceLastPing;

  const renderDevice =
    location.lat > bounds.latSouth &&
    location.lng > bounds.lngWest &&
    location.lat < bounds.latNorth &&
    location.lng < bounds.lngEast;

  if (!renderDevice) return null;

  const pingMsgCheck = (pingMsg === 'Ignition on' ||
    pingMsg === 'Acceleration Threshold' ||
    pingMsg === 'Acceleration threshold' ||
    pingMsg === 'Deceleration Threshold' ||
    pingMsg === 'Deceleration threshold' ||
    pingMsg === 'Idle Threshold' ||
    pingMsg === 'Idle threshold' ||
    pingMsg === 'Ignition On Report' ||
    pingMsg === 'Ignition On Interval Report' ||
    pingMsg === 'Direction Change' ||
    pingMsg === 'Virtual Ignition On Interval Report' ||
    pingMsg === 'Virtual Ignition On Report' ||
    pingMsg === 'Move Start' ||
    pingMsg === 'Move Stop' ||
    pingMsg === 'Trip Start');

  const onMarkerClick = () => {
    dispatch(removeSelectedPoint());
    dispatch(setSelectedPoint(deviceId, tripId, pointId, location, true));
  };

  const onHoverIn = () => {
    const selectedPoint = store.getState().selectedPoint;

    if (selectedPoint === null) {
      dispatch(setSelectedPoint(deviceId, tripId, pointId, location, false));
    }
  }

  const onHoverOut = () => {
    const selectedPoint = store.getState().selectedPoint;

    if (!selectedPoint.isClicked) {
      dispatch(removeSelectedPoint());
    }
  }

  const displayAsSvgPath = markerIcon && !!markerIcon.path;

  return (
    <>
      {markerIcon && (
        <>
          <InfoBox
            options={{
              disableAutoPan: true,
              zIndex: 4,
              boxStyle: {
                zIndex: 4,
                width: '1px',
                overflow: 'visible',
                padding: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: '.9',
              },
              alignBottom: true,
              closeBoxURL: '',
              pixelOffset: new google.maps.Size(9, showMarkerNameAbove ? 0 : 29),
              enableEventPropagation: true, // to allow handle events in children
            }}
            position={new google.maps.LatLng(location.lat, location.lng)}
          >
            <button
              type='button'
              className={styles.markerInfoContainer}
              onClick={onMarkerClick}
              onMouseOver={onHoverIn}
              onMouseOut={onHoverOut}
            >
              {displayAsSvgPath ? (
                <svg width={50} height={50} viewBox={viewBox} style={{position: 'relative', top: '30px'}}>
                  <path d={markerIcon.path} fill={markerIcon.fillColor} />
                </svg>
              ) : (
                <img
                  src={markerIcon.url}
                  alt={deviceDetails.name}
                  width={40}
                  height={40}
                  className={styles.markerImage}
                  style={{
                    borderRadius: roundedMapMarkerImage ? 6 : 0,
                    position: 'relative',
                    top: '30px'
                  }}
                />
              )}

              <div
                className={classNames(styles.messageBlock, {[styles.onTheMarker]: showMarkerNameAbove})}
                style={labelStyle}
              >
                {deviceDetails.name}

                {pingMsg === 'Heartbeat' && (
                  <div className={`${styles.indicatorDot} ${styles.pink}`} />
                )}

                {(pingMsg === 'Ignition off' ||
                  pingMsg === 'Ignition Off Report' ||
                  pingMsg === 'Ignition off Report' ||
                  pingMsg === 'Ignition Off Interval Report' ||
                  pingMsg === 'Virtual Ignition Off Report' ||
                  pingMsg === 'Trip Stop') && (
                  <div className={`${styles.indicatorDot} ${styles.red}`} />
                )}

                {pingMsgCheck && !hasSpeed && (
                  <div
                    className={`${styles.indicatorDot} ${styles.yellow}`}
                  />
                )}

                {pingMsgCheck && hasSpeed && (
                  <div
                    className={`${styles.indicatorDot} ${styles.green}`}
                  />
                )}
              </div>
            </button>
          </InfoBox>
        </>
      )}
    </>
  )
}

export default compose(connect(mapStateToProps), React.memo)(DeviceMarker);