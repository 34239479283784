import React from 'react';
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import Marker from 'react-google-maps/lib/components/Marker';
import Polygon from 'react-google-maps/lib/components/Polygon';
import {path} from 'ramda';

import {default as C} from '../../shared/constants';
import {
    icoSize,
    getRadiusHeight,
    getRadiusWidth,
    shortLocation,
    unitShort,
    userSpeed,
    alertDotIcon,
} from '../../shared/helpers';
import {iLocation, iFullStoreState, iDevicePing, Units, iDisplayRegion} from '../../shared/interfaces';
import {Actions as RegionActions} from '../../stores/reducers/map-region-reducers';
import {BaseComponent} from '../../shared/BaseComponent';
import {FaVector} from '../elements/fa';
import {getPathsSelector, topMarkerSelector, rightMarkerSelector} from '../../stores/reducers/gmap-selectors';
import {getMapZoom as getMapZoomFromStore} from '../../stores/reducers/gmap-reducers';
import {removeSelectedPoint, setSelectedPoint} from '../../stores/reducers/selectedPoint/AC';
import {IPointsContainer} from '../../stores/reducers/devicesTripsPoints';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import {MouseTooltip} from '../MouseTooltip';
import {store} from "../../stores/store";

type IPointProps = {
    point: iDevicePing;
    tripIdx: number;
    color: string;
    icoSizeDiff?: number;
    units: Units;
    isLastPoint: boolean;
    idx: number;
}

const anchor = new google.maps.Point(13.5, 13.5);

export const Point = React.memo((props: IPointProps) => {
    const dispatch = useDispatch();
    const {point, tripIdx, isLastPoint, icoSizeDiff = 0, color: inColor, units, idx: pointIdx} = props;
    const hiddenPoint = useSelector<iFullStoreState, IPointsContainer>(
        (s) => s.devicesTripsPoints.getIn([point.device, 'tripsPoints', point.tripId, point.pointId]));
    const isHidden = hiddenPoint && hiddenPoint['hidden'];
    const {coordinates: {location}} = point;
    const pointLatLng = new google.maps.LatLng(location.lat, location.lng);
    const alertIcon = alertDotIcon(point.alertActivity);

    /* d
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>(state => state.gmap.mapRef());

    const outOfBounds = !mapRef?.current || !mapRef.current.getBounds().contains(pointLatLng);

    if (outOfBounds) return null;
    */

    // @ts-ignore
    const isSpeed = point.alertActivity.hasspeed || point.alertActivity.hasspeedposted || point.alertActivity.hasspeedingcapped;
    const color = isSpeed ? '#f00' : inColor;
    const label = isSpeed ? `${userSpeed(point.speed, units)}${unitShort(units)}/h` : `T${tripIdx + 1}-${pointIdx}`;
    const icon = React.useMemo(() =>
        `data:image/svg+xml,
        %3Csvg ${isHidden ? 'style=\'opacity: 0.2;\'' : 'style=\'opacity: 1;\''} width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"%3E
            %3Cg%3E
                %3Ccircle cx="25" cy="25" r="20" stroke-width="2"  fill="%23F9FAFB" stroke="${escape(color)}"/%3E
                %3Ctext x="50%25" y="50%25" text-anchor="middle" stroke="%23222" font-size="11" font-stretch="ultra-condensed" font-family="Roboto, Arial, sans-serif" dy=".34em"%3E${label}%3C/text%3E
            %3C/g%3E
        %3C/svg%3E
    `, [color, label, isHidden]);

    const selectCurrentPoint = () => {
        dispatch(removeSelectedPoint());
        dispatch(setSelectedPoint(point.device, point.tripId, point.pointId, location, true, label));
    };

    const handleMouseEnter = () => {
        const selectedPoint = store.getState().selectedPoint;

        if (selectedPoint === null) {
            dispatch(setSelectedPoint(point.device, point.tripId, point.pointId, location, false, label));
        }
    };

    const handleMouseLeave = () => {
        const selectedPoint = store.getState().selectedPoint;

        if (!selectedPoint.isClicked) {
            dispatch(removeSelectedPoint());
        }
    };

    return (
        <div>
            {/* show the dot or icon */}
            {
                alertIcon ?
                    <ResizingImage
                      floatRight={isLastPoint}
                      sizeDiff={icoSizeDiff}
                      click={selectCurrentPoint}
                      position={pointLatLng}
                      url={alertIcon}
                      onHoverIn={handleMouseEnter}
                      onHoverOut={handleMouseLeave}
                    /> :
                    !isLastPoint &&
                 <Marker
                    onClick={selectCurrentPoint}
                    position={pointLatLng}
                    icon={{
                        url: icon,
                        anchor: anchor,
                    }}
                    onMouseOver={handleMouseEnter}
                    onMouseOut={handleMouseLeave}
                />
            }
        </div>
    );
});

type IResizingImageProps = {
    position: google.maps.LatLngLiteral | google.maps.LatLng;
    url: string;
    click: () => void;
    sizeDiff: number;
    floatRight?: boolean;
    onHoverIn?: () => void;
    onHoverOut?: () => void;
}

function ResizingImage (props: IResizingImageProps) {
    const {position, url, click, floatRight = false, onHoverIn, onHoverOut} = props;

    const getZoom = useSelector<iFullStoreState, () => number>((state) => () => getMapZoomFromStore(state.gmap));

    const size = getZoom() + props.sizeDiff;

    const anchor = floatRight ?
        new google.maps.Point(size / 2 - 25, size / 2 + 25) :
        new google.maps.Point(size / 2, size / 2);

    return (
        <Marker
            onClick={floatRight ? () => {} : click}
            clickable={!floatRight}
            position={position}
            zIndex={2}
            icon={{
                url,
                anchor,
                size: new google.maps.Size(size, size),
                origin: new google.maps.Point(0, 0),
                scaledSize: new google.maps.Size(size, size),
            }}
            onMouseOver={onHoverIn}
            onMouseOut={onHoverOut}
        />
    );
}

type iOvalPolygonProps = {
    id: string;
}

type iOvalPolygonPropsFromStore = {
    region?: iDisplayRegion;
    getMapZoom: () => number;
    paths: any;
    // calculated
    topHandle?: iLocation;
    rightHandle?: iLocation;
}

type iFullOvalPolygonProps = iOvalPolygonProps & iOvalPolygonPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState, ownProps: iOvalPolygonProps): iOvalPolygonPropsFromStore => {
    const getMapZoom = (): number => getMapZoomFromStore(state.gmap);
    try {
        const region = path(['regions', 'vis', ownProps.id], state);
        let ret: iOvalPolygonPropsFromStore = {
            paths: getPathsSelector(state, ownProps),
            region,
            getMapZoom, 
        } as any;
        if (ret.region.editable) {
            ret = {
                ...ret,
                topHandle: shortLocation(topMarkerSelector(state, ownProps) as any),
                rightHandle: shortLocation(rightMarkerSelector(state, ownProps) as any),
            };
        }

        return ret;
    } catch (e) {
        return {} as any;
    }

};

type iFullState = {
    isTooltipVisible: boolean;
}

export const OvalPolygon = connect(mapStateToProps)
(class extends BaseComponent<iFullOvalPolygonProps, iFullState> {

    state = {
        isTooltipVisible: false,
        imgSize: 0,
    }

    showTooltip = () => {
        this.setState({isTooltipVisible: true});
    };
    hideTooltip = () => {
        this.setState({isTooltipVisible: false});
    };

    getNameBlock = (lat, lng, title) => {
        return (
            <InfoBox
                options={{
                    disableAutoPan: true,
                    zIndex: 4,
                    boxStyle: {
                        zIndex: 4,
                        padding: '4px 6px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        backgroundColor: '#800',
                        color: '#fff',
                        opacity: '.9',
                        fontSize: '16px',
                    },
                    alignBottom: true,
                    closeBoxURL: '',
                    pixelOffset: new google.maps.Size(45, -12),
                }}
                defaultPosition={new google.maps.LatLng(lat, lng)}
            >
                <div>{title}</div>
            </InfoBox>
        );
    }

    debugRender = () => {
        const {id, rightHandle, dispatch, getMapZoom, topHandle, paths,
            region: {
                name,
                color,
                editable,
                center,
                ico,
                hideIcon,
                hideName,
                hideShadows,
            }} = this.props;

        if (!center) return null;

        let size = icoSize(getMapZoom());

        // ignore hide properties in edit mode;
        const showPolygon = editable || !hideShadows;
        const showIcon = !editable && !hideIcon;
        const showName = name && (editable || !hideName);

        const showTooltip = this.props.region.hideName && !this.props.region.editable;
        const poligonHandlers = showTooltip? {
            onMouseOver: this.showTooltip,
            onMouseOut: this.hideTooltip
        }: {};
        const iconHandlers = showTooltip && hideShadows? {
            onMouseOver: this.showTooltip,
            onMouseOut: this.hideTooltip
        }: {};

        const getImageParams = () => {
            if (ico && !!ico.url) {
                const img = new Image();
                img.src = ico.url;
                const svgSize = 70;
                return {width: !!img.width ? img.width : svgSize};
            }
        }

        const iconSize = size < getImageParams()?.width ? getImageParams().width : size;
        return (
            <div>
                {
                    showTooltip && (
                        <MouseTooltip
                            title={name}
                            isVisible={this.state.isTooltipVisible}
                        />
                    )
                }
                {
                    showPolygon && (
                        <Polygon
                            {...poligonHandlers}
                            paths={paths}
                            options={{strokeWeight: 1.5, strokeColor: color || '#000000', fillColor: color, fillOpacity: C.fenceFillOpacity}}
                        />
                    )
                }

                {/* Top Handle */}
                {!editable ? null :
                    <>
                        <Marker
                            cursor='pointer'
                            icon={{
                                url: 'images/h-bars.png',
                                size: new google.maps.Size(70, 70),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(size/2, size/2),
                                scaledSize: new google.maps.Size(size, size)
                            }}
                            draggable={true}
                            options={{position: {lat: topHandle!.lat, lng: center.lng}}}
                            onDragEnd={function(x) {
                                // lock it on axis
                                this.setPosition(new google.maps.LatLng(
                                    Math.max(x.latLng.lat(), center.lat),
                                    center.lng
                                ));
                            }}
                            onDrag={function(x) {
                                // lock it on axis
                                this.setPosition(new google.maps.LatLng(
                                    Math.max(x.latLng.lat(), center.lat),
                                    center.lng
                                ));
                                dispatch(RegionActions.UPDATE_REGION_HEIGHT(
                                    id,
                                    getRadiusHeight(center, Math.max(x.latLng.lat(), center.lat))
                                ));
                            }}
                        />
                        {name && this.getNameBlock(center.lat, center.lng, name)}
                    </>
                }
                {
                    // FIX ICO
                    showIcon && ico && (
                        <Marker
                            {...iconHandlers}
                            position={center}
                            icon={{
                            [ico.fa ? 'path' : 'url']: ico.url || FaVector(ico.fa),

                            size: new google.maps.Size(iconSize, iconSize),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(iconSize/2, iconSize/2),

                            ...(ico.url ? {
                                anchor: new google.maps.Point(iconSize/6, iconSize/6),
                                scaledSize: new google.maps.Size(iconSize/3, iconSize/3),
                            } : {
                                strokeColor: '#000',
                                strokeOpacity: 1,
                                strokeWeight: .25,
                                fillOpacity: .95,
                                fillColor: color,
                                anchor: new google.maps.Point(215, 300),
                                scale: .05 - ((26 - iconSize) / 1000),
                            }),
                            } as any}
                        />                        
                    )
                }

                {
                    showName && this.getNameBlock(center.lat, center.lng, name)
                }
                    
                {/* Move Handle */}
                {!editable ? null :
                    <>
                        <Marker
                            position={center}
                            draggable
                            onDrag={({latLng}) => { dispatch(RegionActions.UPDATE_REGION_CENTER(id, {lat: latLng.lat(), lng: latLng.lng()}));
                            }}
                            cursor='pointer'
                            icon={{
                                url: 'images/circled_dot.png',
                                size: new google.maps.Size(70, 70),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(size/2, size/2),
                                scaledSize: new google.maps.Size(size, size)
                            }}
                        />
                        {name && this.getNameBlock(center.lat, center.lng, name)}
                    </>
                }

                {/* Right Handle */}
                {!editable ? null :
                    <Marker
                        position={rightHandle!}
                        icon={{
                            url: 'images/v-bars.png',
                            size: new google.maps.Size(70, 70),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(size/2, size/2),
                            scaledSize: new google.maps.Size(size, size),
                        }}
                        cursor='pointer'
                        draggable
                        options={{position: {lat: center.lat, lng: rightHandle!.lng}}}
                        onDragEnd={function(x) {
                            // lock it on axis
                            this.setPosition(new google.maps.LatLng(
                                rightHandle!.lat,
                                Math.max(x.latLng.lng(), center.lng)
                            ));
                        }}
                        onDrag={function(x) {
                            // lock it on axis and prevent inverting
                            this.setPosition(new google.maps.LatLng(
                                rightHandle!.lat,
                                Math.max(x.latLng.lng(), center.lng)
                            ));
                            dispatch(RegionActions.UPDATE_REGION_WIDTH(
                                id,
                                getRadiusWidth(center, Math.max(x.latLng.lng(), center.lng))
                            ));
                        }}
                    />
                }
            </div>
        );
    }
});
