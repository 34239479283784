import React, { CSSProperties as css } from 'react';
import {RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import Select from 'react-select';
import type { Props as ReactSelectProps } from 'react-select';
import { IconDefinition, faQuestionCircle } from '@fortawesome/fontawesome-free-solid';
import { ReactComponent as FilterListSvg } from '../../../assets/svg/filter_list.svg';
import { ReactComponent as ChevronDownBlackSvg } from '../../../assets/svg/chevron_down_black.svg';
import { ReactComponent as ArrowBackSvg } from '../../../assets/svg/arrow_back.svg';
import classNames from 'classnames';
import styles from './modal-header.module.scss';

import Constants from '../../../shared/constants';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Fa } from '../../elements/fa';
import MinimalTile from '../../general/MinimalTile';
import { customSelectStyles } from '../../general/selections-grid/constants';
import GridViewIcon from '../../../assets/svg/grid_view_24px.svg';
import ListViewIcon from '../../../assets/svg/list_view.svg';
import { customSelectStylesWithWhiteBackground } from '../../general/selections-grid/constants';
import { sortBySelectStyles } from '../../general/selections-grid/constants';
import { components } from "react-select";
import {useDispatch} from "react-redux";
import {Actions as ReportActions} from "../../../stores/reducers/report-reducers";


interface IHeaderAction {
    fa: IconDefinition,
    click: () => void,
    title: string,
    disabled?: string | false,
    faPdf?: IconDefinition,
    clickPdf?: () => void,
    titlePdf?: string,
    isDisabled?: boolean,
}

interface iProps {
    action?: IHeaderAction,
    help?: string,
    title: string | JSX.Element,
    style?: React.CSSProperties,
    role?: boolean,
    goBackCallback?: () => void;
    showSelect?: boolean;
    options?: ReactSelectProps,
    handleChange?: (option: ReactSelectProps) => void,
    option?: ReactSelectProps,
    className?: string;
}

type GoBackProps = {
    children: React.ReactNode;
    goBackCallback?: () => void;
} & RouteComponentProps;

const GoBack = withRouter(({ history, children, goBackCallback }: GoBackProps) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const pathRedirects = [
        { regex: /^\/device\/[^/]+$/, redirect: '/device' },
        { regex: /^\/person\/[^/]+$/, redirect: '/person' },
        { regex: /^\/tag\/[^/]+$/, redirect: '/tags' },
    ];

    const goBackHandler = () => {
        if (goBackCallback) {
            goBackCallback();
        }

        for (const { regex, redirect } of pathRedirects) {
            if (regex.test(location.pathname)) {
                history.replace(redirect);

                return;
            }
        }

        if (/^\/reports\/new\/[^/]+$/.test(location.pathname)) {
            dispatch(ReportActions.RESET_ALL_FILTERS());
            dispatch(ReportActions.RESET_DATES());

            return;
        }

        history.goBack();
    }

    return (
        <button onClick={goBackHandler} className={styles.goBackButton}>
            {children}
        </button>
    )
});

class ModalHeaderComponent extends BaseComponent<iProps, { showHelp: boolean }> {
    state = { showHelp: false };

    debugRender = () => {
        const { action, help, style: userStyle = {}, role, goBackCallback, showSelect, options, handleChange, option, className } = this.props;
        const { showHelp } = this.state;

        const CustomSingleValue = (props) => {
            const { selectProps } = props;
            const isOpen = selectProps.menuIsOpen;

            return (
            <components.SingleValue {...props}>
                <div className={styles.singleValueBlock}>
                    <FilterListSvg className={styles.singleValueBlock__icon} />
                    <span className={styles.singleValueBlock__title}>Sort By</span>
                    <ChevronDownBlackSvg
                        className={classNames(styles.singleValueBlock__icon, { [styles.singleValueBlock__icon_focused]: isOpen })}
                    />
                </div>
            </components.SingleValue>
        )};
        return (
            <>
                <div className={styles.header} style={{ ...style, ...userStyle }} >
                    <GoBack goBackCallback={goBackCallback}>
                        <ArrowBackSvg style={{position: 'absolute', top: 0, left: 0}} />
                    </GoBack>
                    <h3
                        className={classNames(styles.heading, { [styles.heading_leftPosition]: showSelect })}
                    >
                        {this.props.title}
                    </h3>
                    {showSelect && (
                        <Select
                            onChange={handleChange}
                            options={options}
                            value={option}
                            className={className}
                            styles={sortBySelectStyles}
                            components={{ SingleValue: CustomSingleValue, DropdownIndicator: () => null }}
                            isSearchable={false}
                            menuPlacement="auto"
                        />
                    )}
                    <div className={styles.buttons}>
                        {
                            !role && (
                                <div>
                                    {!action
                                        ? null
                                        : ActionDisplay(action)
                                    }
                                    {
                                        !help
                                            ? null
                                            : <Fa icon={faQuestionCircle}
                                                style={{ cursor: 'pointer' }}
                                                className="modal-help"
                                                title="Show instructions"
                                                onClick={() => this.setState(s => ({ showHelp: !s.showHelp }))}
                                            />
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                {showHelp && (<div className={`help-body no-print ${showHelp ? 'active' : ''}`}>
                    {this.state.showHelp &&
                        <MinimalTile>
                            <div style={{ maxWidth: 325 }}>{help}</div>
                        </MinimalTile>
                    }
                </div>)}
            </>
        );
    }
}

export default ModalHeaderComponent;

const style: css = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    color: Constants.primaryText,
    minHeight: 33
}

const ActionDisplay = ({ fa, click, title = '', disabled = false, faPdf, clickPdf, titlePdf = '', isDisabled }: IHeaderAction) => (
    <>
        {faPdf && <span title={disabled || titlePdf} className="btn-link" onClick={disabled ? () => { } : clickPdf} style={{ fontSize: 20, color: disabled ? Constants.lightGray : "red", marginRight: 10 }}>
            <Fa icon={faPdf} title={titlePdf} />
        </span>}
        {(
            <span title={disabled || title} className="btn-link" onClick={disabled ? () => { } : click} style={{ fontSize: 20, color: disabled ? Constants.lightGray : 'inherit' }}>
                <Fa style={isDisabled ? { color: "white" } : {}} icon={fa} title={title} />
            </span>
        )}
    </>
);
