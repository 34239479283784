import { select, takeEvery } from 'redux-saga/effects';
import getDistance from 'geolib/es/getDistance';

import { iFullStoreState } from '../../shared/interfaces';
import { SET_SELECTED_POINT, setSelectedPoint } from '../reducers/selectedPoint/AC';
import { fullLocation } from '../../shared/helpers';


function* worker (action: ReturnType<typeof setSelectedPoint>) {
    const {position, isClicked} = action.payload;
    const mapRef = yield select((state: iFullStoreState) => state.gmap.mapRef());

    if (mapRef && mapRef.current && isClicked) {
        const mapCenter = mapRef.current.getCenter();
        const dist = getDistance({latitude: mapCenter.lat(), longitude: mapCenter.lng()}, fullLocation(position));
        if (dist > 1000) {
            mapRef.current.panTo(position);
        }
    }
}

export default function* () {
    yield takeEvery(SET_SELECTED_POINT, worker);
}
