import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../../Modal';
import { NewTagFormValues, NewTagModalProps } from './interfaces';
import styles from './NewTagModal.module.scss';
import * as yup from 'yup';

const schema = yup.object().shape({
  tagName: yup.string().required('This field is required'),
});

const NewTagModal: FunctionComponent<NewTagModalProps> = ({ onClose, handleSubmit: onModalSubmit }) => {
  const { register, handleSubmit, errors} = useForm<NewTagFormValues>({
    resolver: yupResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values: NewTagFormValues) => {
    setIsSubmitting(true);
    onModalSubmit(values)
      .then(onClose)
      .catch(() => setIsSubmitting(false));
  };

  return (
    <Modal
      onBackdropClick={onClose}
      className={styles.modalContent}
    >
      <h1 className={styles.title}>Create New Tag</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <label className={styles.label}>
          Tag Name
          <input
            type='text'
            id='tagName'
            name='tagName'
            ref={register()}
            className={styles.input}
            placeholder='Enter tag name'
          />
          {!!errors.tagName && <p className={styles.errorMessage}>{errors.tagName.message}</p>}
        </label>

        <div className={styles.buttons}>
          <button
            type='button'
            onClick={onClose}
            className={styles.cancelButton}
          >
            Cancel
          </button>
          <button
            type='submit'
            className={styles.createButton}
            disabled={isSubmitting}
          >
            Create
          </button>
        </div>
      </form>
    </Modal>
);
};

export default NewTagModal;
