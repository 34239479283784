import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CheckboxListProps } from './interfaces';
import styles from './CheckboxList.module.scss';
import { ReactComponent as CheckboxIcon } from '../../../assets/svg/check_box_16w.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../../assets/svg/check_box_outline_blank_16w.svg';
import { ReactComponent as RestartIcon } from '../../../assets/svg/restart_alt_12w.svg';

const CheckboxList: FunctionComponent<CheckboxListProps> = ({
  sections,
  selected,
  changeAllItems,
  isAllSelected,
  containerClassName,
}) => {
  const anySectionExists = sections.length > 0;
  const withSelectAll = !!changeAllItems;

  return (
    <div className={classNames(styles.checkboxList, containerClassName)}>
      <PerfectScrollbar className={styles.scrollBar}>
        <div className={classNames(styles.list, { [styles.notFound]: !anySectionExists })}>
          {sections.map(({ id: sectionId, value: section }) => (
            <div key={sectionId} className={styles.section}>
              {section.map(item => {
                const isChecked = selected.includes(item.id);
                return (
                  <button
                    key={item.id}
                    onClick={item.onChange}
                    className={classNames(styles.sectionItem, { [styles.checked]: isChecked })}
                  >
                    {isChecked
                      ? <CheckboxIcon className={styles.sectionItemCheckbox} />
                      : <CheckboxBlankIcon className={styles.sectionItemCheckbox} />
                    }
                    <span
                      className={styles.sectionItemName}
                      title={item.name}
                    >
                      {item.name}
                    </span>
                  </button>
                );
              })}
            </div>
          ))}
          {!anySectionExists && (
            <p className={styles.notFoundMessage}>Nothing found</p>
          )}
        </div>
      </PerfectScrollbar>
      {withSelectAll && anySectionExists && (
        <div className={styles.toggleSelectAllButtonWrapper}>
          <button
            type='button'
            onClick={() => changeAllItems(!isAllSelected)}
            className={styles.toggleSelectAllButton}
          >
            {isAllSelected && <RestartIcon className={styles.resetIcon} />}
            <span>{isAllSelected ? 'Deselect All' : 'Select All'}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckboxList;
