import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { WarningBlockProps } from './interfaces';
import styles from './WarningBlock.module.scss';
import { ReactComponent as WarningAmberIcon } from '../../../assets/svg/warning_amber.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close_cur.svg';

const WarningBlock: FunctionComponent<WarningBlockProps> = ({
  title = 'Warning!',
  message,
  onClose,
  className,
}) => (
  <div className={classNames(styles.content, className)}>
    <header className={styles.warnHeader}>
      <div className={styles.warnTitleBlock}>
        <WarningAmberIcon className={styles.warnIcon} />
        <h1 className={styles.warnTitle}>{title}</h1>
      </div>

      {!!onClose && (
        <button
          type='button'
          onClick={onClose}
          className={styles.closeButton}
        >
          <CloseIcon className={styles.closeIcon} />
        </button>
      )}
    </header>
    <p className={styles.message}>{message}</p>
  </div>
);

export default WarningBlock;
