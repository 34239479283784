import React, { CSSProperties as css } from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { faPlusSquare } from '@fortawesome/fontawesome-free-regular';
import C from '../../shared/constants';
import { vals } from '../../shared/helpers';
import { iList, iNote } from '../../shared/interfaces';
import { keyGen } from '../../shared/firebase';
import { Fa } from "../elements/fa";

type IProps = {
    ownerTitle?: string;
    notes: iList<iNote>;
    deviceOrFence: 'device' | 'fence';
    deviceOrFenceId: string;
    style?: css;
    disabled?: boolean;
    title?: string;
    cssOrder?: number;
};

export default ({ notes, deviceOrFence, deviceOrFenceId, style = {}, ownerTitle, disabled = false, title, cssOrder }: IProps) => {
    return (
      <div style={{ ...style, width: '100%', order: cssOrder }}>
          <div style={{ position: 'relative', ...notePadCss }}>
              {/* Header with title and "Add Note" button */}
              <div style={headerCss}>
                  <span style={titleCss}>{title || 'Notes'}</span>
                  <Link
                    style={disabled ? addNoteDisabledCss : addNoteCss}
                    to={`/${deviceOrFence}/${deviceOrFenceId}/notes/add/${keyGen()}`}
                  >
                      <Fa icon={faPlusSquare} style={disabled ? { color: "gray" } : { color: '#ff7c02' }} />
                      <span style={addNoteTextCss}>Add Note</span>
                  </Link>
              </div>

              {/* Notes list */}
              <div style={{ marginTop: '12px' }}>
                  {vals(notes).map(note => (
                    <div key={note.id} style={noteContainerCss}>
                        <span style={noteTextCss} title={note.note}>
                                {note.note}
                        </span>
                        <span style={noteDateCss}>{note.date.format(C.simpleDateFormat)}</span>
                        <Link
                          to={{
                              pathname: `/${deviceOrFence}/${deviceOrFenceId}/notes/edit/${note.id}`,
                              state: {ownerTitle}
                          }}
                          style={disabled ? editDisabledCss : editCss}
                        >
                            <Fa icon={faPencilAlt}/>
                        </Link>
                    </div>
                  ))}
              </div>
          </div>
      </div>
    );
};

/** Styles **/
const notePadCss: css = {
    backgroundColor: '#FCF5AD',
    overflow: 'hidden',
    minHeight: 200,
    padding: 12,
    borderRadius: '4px',
    border: '1px solid #f9dc4b'
};

const headerCss: css = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
};

const titleCss: css = {
    fontSize: '14px',
    fontWeight: 500,
    color: '#111',
};

const addNoteCss: css = {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
};

const addNoteDisabledCss: css = {
    ...addNoteCss,
    color: "gray",
    pointerEvents: "none"
};

const addNoteTextCss: css = {
    fontSize: '11px',
    color: '#ff7c02',
    textDecoration: 'underline',
    fontWeight: 'normal'
};

const noteContainerCss: css = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #111',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginBottom: '8px',
};

const noteDateCss: css = {
    fontSize: '12px',
    minWidth: '100px',
    fontWeight: 300,
    color: '#878889',
};

const noteTextCss: css = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    fontSize: '12px',
    color: '#000',
    fontWeight: 400
};

const editCss: css = {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#000',
    cursor: 'pointer',
};

const editDisabledCss: css = {
    ...editCss,
    color: 'gray',
    pointerEvents: 'none',
};
