import React, { useEffect } from 'react';
import { InfoWindow } from 'react-google-maps';
import './InfoWindowStyles.scss';
import { Actions } from '../../../stores/reducers/gmap-reducers';
import {PointDetails} from "../../gmap/pois";
import { ReactComponent as CloseIcon } from '../../../assets/svg/close_24px.svg';

const CustomInfoWindow = ({
  position,
  onCloseClick,
  selectedPoint,
  dispatch,
  hasExtraInfo,
  point,
  deviceLastPing,
  units,
  timezone,
  existsPrevPoint,
  existsNextPoint,
  selectPrevPoint,
  selectNextPoint
}) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const closeButtons = document.querySelectorAll('.gm-ui-hover-effect');
          closeButtons.forEach((btn) => {
            (btn as HTMLElement).style.display = 'none';
          });

          const infoWindows = document.querySelectorAll('.gm-style-iw, .gm-style-iw-c, .gm-style-iw-d');
          infoWindows.forEach((el) => {
            (el as HTMLElement).style.padding = '0';
            (el as HTMLElement).style.margin = '0';
            (el as HTMLElement).style.overflow = 'visible';
          });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <InfoWindow
      position={position}
      onCloseClick={onCloseClick}
      zIndex={5}
      options={{
        disableAutoPan: true,
        pixelOffset: new window.google.maps.Size(0, 0)
      }}
    >
      <div className="custom-info-window" style={{
        position: 'relative',
        padding: 0,
        margin: 0
      }}>
        <CloseIcon
          style={{
            position: 'absolute',
            right: '12px',
            width: '12px',
            height: '12px',
            cursor: 'pointer',
            zIndex: 10,
            fill: 'rgb(17, 17, 17)',
          }}
          onClick={onCloseClick}
        />

        <div style={{ width: '100%', padding: 0 }}>
          {hasExtraInfo ? (
            <PointDetails
              gotoStreet={() => dispatch(Actions.TOGGLE_STREET_VIEW(selectedPoint.position))}
              point={point ?? deviceLastPing}
              units={units}
              timezone={timezone}
              existsPrevPoint={existsPrevPoint}
              existsNextPoint={existsNextPoint}
              selectPrevPoint={selectPrevPoint}
              selectNextPoint={selectNextPoint}
            />
          ) : (
            <p>There is no extra info about this device</p>
          )}
        </div>
      </div>
    </InfoWindow>
  );
};

export default CustomInfoWindow;
