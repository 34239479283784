import React, { useEffect, useState } from 'react';
import {Fa, FaLink} from './fa';
import {
    faChevronDown,
    faChevronRight,
    faPencilAlt,
} from '@fortawesome/fontawesome-free-solid';
import C, { ACL, UserCan } from '../../shared/constants';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {iFullStoreState, ItemType, MenuPinType, UserAuth} from '../../shared/interfaces';
import { useIsReadOnly } from '../../hooks/useIsReadOnly';
import {MenuPin} from './MenuPin';
import MoreInfoIcon from '../../assets/svg/more_info_24px.svg';
import { ReactComponent as PdfIcon } from '../../assets/svg/pdf.svg';

// #region styles
const head: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px',
    backgroundColor: '#fff',
    borderRadius: '4px'
};
const body: React.CSSProperties = {
    width: '100%',
    padding: '3px 8px',
    border: `1px solid ${C.lightGray}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 12,
};
const toggle: React.CSSProperties = {
    color: '#111',
    opacity: 0.5,
    cursor: 'pointer',
    width: '20px',
    height: '20px'
};
const titleStyle: React.CSSProperties = {
    color: '#111',
    fontSize: '14px',
    fontWeight: 500,
};
const edit: React.CSSProperties = {
    color: '#111',
    cursor: 'pointer',
};
const item: React.CSSProperties = {
    marginBottom: 3,
};
const key: React.CSSProperties = {
    fontWeight: 'bold',
};
// #endregion

interface iProps {
    editUrl: string;
    labels: [string, string | object][];
    role?: boolean;
    readOnly?: boolean;
    itemId?: string;
    itemType?: ItemType;
    isPinned?: boolean;
    property?: string;
    imageClick?: (src) => any;
    title?: string;
    cssOrder?: number,
}

export const ExtraInfo = ({editUrl, labels, role, itemId, itemType, isPinned, property, imageClick, title, cssOrder}: iProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(() => {
        return property ? !isPinned : true;
    });

    useEffect(() => {
        const value = property ? !isPinned : true;
        setCollapsed(value);
    }, [isPinned]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const readOnly = useIsReadOnly(itemId, itemType);

    return (
        <div style={{order: cssOrder}}>
            <div style={{...head}} >
                <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    <img src={MoreInfoIcon} alt='Extra Info'/>
                    <span style={{...titleStyle}}>{title || 'Extra Info'}</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    {!collapsed &&
                      <MenuPin
                        property={property}
                        isPinnedProp={isPinned}
                      />
                    }
                    {!role && !readOnly && (
                      <FaLink icon={faPencilAlt} to={editUrl} style={{...edit}}/>
                    )}
                    <Fa icon={collapsed ? faChevronRight : faChevronDown} onClick={toggleCollapsed} style={{...toggle}}/>
                </div>
            </div>
            {!collapsed && <div style={{...body}}>
                {!collapsed ?
                    !labels.length ? 'None Yet' : labels.map(([name, value]) => {
                        const isPhoto = typeof(value) === 'object' && value.hasOwnProperty('isPhoto') && value['isPhoto'];
                        const isPdf = typeof(value) === 'object' && value.hasOwnProperty('isPdf') && value['isPdf'];
                        const photoUrl = isPhoto && value.hasOwnProperty('url') ? value['url'] : '';
                        const pdfUrl = isPdf && value.hasOwnProperty('url') ? value['url'] : '';

                        return (
                            <div key={name} style={{...item}}>
                                <span style={{...key}}>{name}</span>
                                <span> : </span>
                                {isPhoto ? (
                                  <img
                                    src={photoUrl}
                                    style={{ height: '30px', cursor: 'pointer' }}
                                    onClick={() => imageClick(photoUrl)}
                                  />
                                ) : isPdf ? (
                                  <a href={pdfUrl} target='_blank' rel='noopener noreferrer'>
                                      <PdfIcon/>
                                  </a>
                                ) : (
                                  <span>{value}</span>
                                )}
                            </div>
                        )
                    }) :
                    ''
                }
            </div>}
        </div>
    );
};
