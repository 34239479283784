import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

import {
    alertDotIcon,
    friendlySpeed,
    isDev,
    countDateWithUserAndTimezoneOffset,
    clearTimezone,
} from '../../shared/helpers';
import {
    ExtraInfoFields,
    HttpStatus,
    iDeviceDetails,
    iDevicePing,
    iFullStoreState,
    iPerson,
    Units
} from '../../shared/interfaces';
import { Point } from './oval-polygon';
import { Col } from '../elements/flex';
import { Fa } from '../elements/fa';
import { useRedux } from '../../states/redux-state';
import {IS_MOBILE_APP, UserCan} from '../../shared/constants';
import { clientStore, FieldValue } from '../../shared/firebase';
import {checkStatusCamera, dayChooseByTimestamp, getAvailableTime, getDownloadPoint} from '../../api/openApi';
import { IReducerSelectedPoint } from '../../stores/reducers/selectedPoint';
import {hidePoint, removeReportTrips, showPoint} from '../../stores/reducers/devicesTripsPoints/AC';
import {getTimezoneByValue} from '../pages/devices/DeviceTabPageEdit/Timezones/Timezones.helper';
import { cameraAvailableTime, openModal, statusCameraOnline, takeDeviceInfo } from '../../stores/reducers/videoCameras';
import { iReducersState } from '../../stores/reducers';
import { getDownloadAlert } from '../../api/openApi';
import { ReactComponent as VisibilityOffIcon } from "../../assets/svg/visibility_off_24px.svg";
import { ReactComponent as VisibilityIcon } from "../../assets/svg/visibility_24px.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/warning_amber_24px.svg";
import { ReactComponent as InfoSignIcon } from "../../assets/svg/info_24px.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close_24px.svg";
import { ReactComponent as ChevronLeft } from '../../assets/svg/chevron_left_balloon.svg';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron_right.svg';
import { ReactComponent as KeepIcon } from '../../assets/svg/keep_24px.svg';
import { ReactComponent as MapPinIcon } from '../../assets/svg/place_24px.svg';

import styles from './NewPointDetails.module.scss';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';
import {getExtraInfoKeyByName, getStartAndEndOfDate} from "../../utils";
import {ReportAC} from "../../stores/reducers/report-reducers";
import AddAlarmIdModalPrompt from "./AddAlarmIdModalPrompt/AddAlarmIdModalPrompt";
import AddAlarmIdModalForm from "./AddAlarmIdModalForm/AddAlarmIdModalForm";

export const shouldShowPoint = ({showPOI, showDots}) => (point: iDevicePing) => {
    const ico = showPOI ? alertDotIcon(point.alertActivity) : false;
    // @ts-ignore
    const isSpeed = point.alertActivity.hasspeedingcapped || point.alertActivity.hasspeedingposted;
    const isHidden = point.hidden;

    if (!showPOI && isHidden && (ico || isSpeed)) return false;
    return !(!showDots && !ico && !isSpeed);
}

enum DownloadPointResponseStatuses {
    ADD_POINT = 'Add_point',
    UNKNOWN = 'Unknown',
    DOWNLOADING = 'Downloading',
    WAITING = 'Waiting',
    FAILURE = 'Failure',
    COMPLETED = 'Completed'
}

type IPoisProps = {
  lastPointId?: string;
  tripIdx: number;
  points: Array<iDevicePing>;
  color: string;
  units: Units;
  tripId: string;
};

type VideoTypeName = 'Event' | 'Point';

enum VideoTypeNameEnum {
    EVENT = 'Event',
    POINT = 'Point'
}

export const Pois = (props: IPoisProps) => {
    const { lastPointId, points, color, units, tripIdx, tripId } = props;
    const user = useRedux(s => s.auth.user.acl);
    const showPOI = useRedux(s => s.gmap.showPOI);
    const showDots = useRedux(s => s.gmap.showDots);
    let filteredPois = points;
    if (!showDots || !showPOI) {
        filteredPois = points.filter(shouldShowPoint({showDots, showPOI}));
    }
    if (showDots && !user.can.includes(UserCan.HIDE_POINTS)) {
        filteredPois = points.filter(point => {
            return !point.hidden;

        })
    }

    const checkedPoints = tripId === "pointsWithoutTripId" ? points : filteredPois;

    return <>{checkedPoints.map((point, idx) => {
        return <Point
                key={point.pointId}
                point={point}
                tripIdx={tripIdx}
                color={color}
                isLastPoint={lastPointId === point.pointId}
                units={units}
                idx={idx+1}
                icoSizeDiff={22} // sos slightly bigger cause of text
            />
        }
    )}</>;
}

type iPointDetails = {
    gotoStreet: () => any,
    point: iDevicePing,
    units: Units,
    timezone?: string,
    existsPrevPoint?: boolean,
    existsNextPoint?: boolean,
    selectPrevPoint?: () => any,
    selectNextPoint?: () => any,
};

export const PointDetails = ({gotoStreet, point, units, timezone = 'EST', existsPrevPoint, existsNextPoint, selectPrevPoint, selectNextPoint}: iPointDetails) => {
    const { address = false, posted_speed, time, msg, alertActivity, device, alarmId } = point;
    const [loading, setLoading] = useState(false);
    const [isDownloadingInProcess, setIsDownloadingInProcess] = useState(false);
    const [isDownloadStarted, setIsDownloadStarted] = useState(false);
    const [isFailureWhileDownloadingPoint, setIsFailureWhileDownloadingPoint] = useState(false);
    const [isFailureWhileDownloadingEvent, setIsFailureWhileDownloadingEvent] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [failurePointId, setFailurePointId] = useState();
    const [isAddAlarmIdPromptModalOpen, setIsAddAlarmIdPromptModalOpen] = useState(false);
    const [isAddAlarmIdFormModalOpen, setIsAddAlarmIdFormModalOpen] = useState(false);
    const [IsUserHasNotEnteredAllData, setIsUserHasNotEnteredAllData] = useState(false);
    const [makeModel, setMakeModel] = useState<string>('');
    const [imagePath, setImagePath] = useState<string>('assets/incidents/gps_fixed.svg');
    const [statusMsg, setStatusMsg] = useState<string>('');
    const [isNotification, setIsNotification] = useState<boolean>(false);

    const [formData, setFormData] = useState({ alarmId: alarmId, pointId: point.pointId, deviceId: point.device, time: point.time, channel: "", file_type: "1", stream_type: "2", before: "", after: "" });
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPoint = useSelector<iFullStoreState, IReducerSelectedPoint>(state => state.selectedPoint);
    const [isButtonHidden, setIsButtonHidden] = useState(point.hidden)

    const deviceData = useSelector<iFullStoreState, iDeviceDetails>(
        (state) => state.devicesData.devicesDetails.get(device)
    );
    const { isCamera, id: deviceId, 'extra-info': serialNumber }:any = deviceData;
    const serial = Object.values(serialNumber)[0].toString();
    const isEvent = !!Object.keys(point.alertActivity).length

    const auth = useRedux(s => s.auth);
    const { user: { acl: user }, isDriver } = auth;

    const driverId = deviceData.assignedTo?.personId;
    const assignedDriver = useSelector<iFullStoreState, iPerson>(state => driverId ? state.general.people[driverId] : null);

    const checkingStatus = async () => {
        setLoading(true)
        const {onlineStatus, channels, cameraTime} = await checkStatusCamera({firebaseDeviceId: deviceData.id});

        dispatch(statusCameraOnline(onlineStatus));
        dispatch(cameraAvailableTime(cameraTime?.available_time));
        setLoading(false);
    }

    const { cameraOnline, deviceInfo, availableTime } = useSelector((state: iReducersState) => state.videoCameras);
    const CAMERA_ONLINE = cameraOnline == 1;
    const cameraStatus = CAMERA_ONLINE ? 'Camera is online' : 'Camera is offline';

    useEffect(() => {
        formatMakeModel();
        getImagePath();
    }, []);

    useEffect(() => {
        setImagePath('assets/incidents/gps_fixed.svg');
        getImagePath();
    }, [point.pointId]);

    useEffect(() => {
        setIsButtonHidden(point.hidden);
        const failurePoints = JSON.parse(sessionStorage.getItem('failurePoints')) || [];
        const isCurrentPointFailure = failurePoints.includes(point.pointId);

        if(!isEvent) {
            setFormData({
                ...formData,
                stream_type: '0'
            });
        }

        if (failurePointId && failurePointId !== point.pointId) {
            setIsFailure(false);
        }
        else if (isCurrentPointFailure) {
            setIsFailure(true);
            setIsNotification(false);
            setStatusMsg('An error occurred');
        }

        setIsFailureWhileDownloadingEvent(false);
        setIsFailureWhileDownloadingPoint(false);
    }, [point])

    const hidePointHandle = async () => {
        setIsButtonHidden(true)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': true});

        await dispatch(hidePoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }

    const showPointHandle = async () => {
        setIsButtonHidden(false)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': FieldValue.delete()});

        await dispatch(showPoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }
    const showAlertTitle = Object.keys(alertActivity).length > 0

    const timezoneData = getTimezoneByValue(timezone);
    const clearedTimezone = clearTimezone(timezone);
    const [timezoneTime, ...countries] = timezoneData.split(' ');
    const offSetTime = countDateWithUserAndTimezoneOffset(time, clearedTimezone)
    const alertDate = moment(time).format('YYYY-MM-DD'); // offSetTime.format("HH:mm:ss");
    const alertTime = moment(time).format("HH:mm:ss"); // offSetTime.format('YYYY-MM-DD');
    const checkingDateAndSetAvailableVideo = async () => {
        setLoading(true);
        const data = await dayChooseByTimestamp({ firebaseDeviceId: deviceId, convertDate: alertDate, alertTime });
        if(data?.length) {
            dispatch(takeDeviceInfo({id: deviceId, alertDate, alertTime}));
            dispatch(openModal({key: "openHistoryModal", value: true}));
        }
        setLoading(false);
        return data
    }

    const check30DaysLeft  = isMoreThan30DaysAgo(new Date(offSetTime.format('YYYY-MM-DD')));

    useEffect(() => {
        if(isCamera && !check30DaysLeft) {
            checkingStatus();
        }
    }, [alertDate, point])

    function isMoreThan30DaysAgo(date) {
        //                   days  hours min  sec  ms
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

        return timestampThirtyDaysAgo > date ? true : false;
    }

    const showAlertHistoryVideo = () => {
        if (CAMERA_ONLINE && availableTime > 0) {
            checkingDateAndSetAvailableVideo();
        }
        if(CAMERA_ONLINE && availableTime === 0) {
            dispatch(openModal({key: "openBuyMoreModal", value: true}));
        }
    }

    const handleLink = (link) => {
        window['cordova'].InAppBrowser.open(link, '_system', 'location=yes');
    }

    const downloadVideoFromUrl = async (url: string, name: VideoTypeName, channel: number | string = 1) => {
        try {
            const response = await axios({
                url,
                method: 'GET',
                responseType: 'blob'
            })

            if (response.status === HttpStatus.OK) {
                const urlObject = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urlObject;
                link.setAttribute('download', `${name}_${point.pointId ?? ''}_${offSetTime.format('MM.DD.YY h mm A') ?? ''}_${channel}.mp4`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (e) {
            if (name === VideoTypeNameEnum.EVENT) {
                setIsFailureWhileDownloadingEvent(true);
            }
            else {
                setIsFailureWhileDownloadingPoint(true);
            }

            setStatusMsg('An error occurred while downloading');
            setIsNotification(false);
        }
    }

    const downloadAlert = async (alarmId) => {
        const downloadedAlert = await getDownloadAlert(alarmId);

        if (downloadedAlert.success && downloadedAlert.link) {
            await downloadVideoFromUrl(downloadedAlert.link, 'Event');
        }
        if (downloadedAlert.downloading_in_process) {
            setIsDownloadingInProcess(true);
            setStatusMsg('Download started. Check back soon.');
            setIsNotification(true);
        }
        if (downloadedAlert.add_alarm) {
            setIsAddAlarmIdPromptModalOpen(true);
        }
        if (downloadedAlert.update_alarm) {
            // filtering alarmTask object to get alarmTask object without falsy value ""
            const asArray = Object.entries(downloadedAlert.alarmTask);
            const filtered = asArray.filter(([key, value]) => value !== "");
            const alarmTaskObjWithoutFalsyValue = Object.fromEntries(filtered);

            setFormData({...formData, ...alarmTaskObjWithoutFalsyValue })
            setIsUserHasNotEnteredAllData(true);
            setIsAddAlarmIdPromptModalOpen(true);
        }
    }

    const downloadPoint = async (pointId) => {
        const downloadedPoint = await getDownloadPoint(pointId);

        if (downloadedPoint.status === DownloadPointResponseStatuses.COMPLETED) {
            setIsDownloadStarted(true);

            for (const res of downloadedPoint.data) {
                await downloadVideoFromUrl(res.bucket_url, 'Point', res.channel);
            }

            setIsDownloadStarted(false);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.WAITING || downloadedPoint.status === DownloadPointResponseStatuses.DOWNLOADING){
            setIsDownloadingInProcess(true);
            setStatusMsg('Downloading in process');
            setIsNotification(true);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.ADD_POINT || downloadedPoint.status === DownloadPointResponseStatuses.UNKNOWN){
            setIsAddAlarmIdPromptModalOpen(true);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.FAILURE){
            setIsFailure(true);
            setFailurePointId(pointId);
            setIsNotification(false);
            setStatusMsg('An error occurred');
            const failurePoints = new Set(JSON.parse(sessionStorage.getItem('failurePoints')) || []);
            failurePoints.add(pointId);
            sessionStorage.setItem('failurePoints', JSON.stringify([...failurePoints]));
        }
    }

    const formatAddress = (address) => {
        const addressEntries: string[] = [];

        if (address) {
            addressEntries.push(address.street.replace(/\S*\+[^ ]*/g, ''));
        }
        if (address.city) addressEntries.push(address.city);
        if (address.state) addressEntries.push(address.state);
        if (address.zip) addressEntries.push(address.zip);

        return addressEntries.map(entry => entry.replace(/US/gi, '')).filter(entry => entry !== '').join(', ');
    }

    const formatMakeModel = async () => {
        const keys = await Promise.all([
            getExtraInfoKeyByName(ExtraInfoFields.MAKE),
            getExtraInfoKeyByName(ExtraInfoFields.MODEL),
            getExtraInfoKeyByName(ExtraInfoFields.YEAR)
        ]);

        const value = keys.map(key => deviceData['extra-info'][key]).filter(e => e !== undefined).join(' ');

        setMakeModel(value);
    }

    const getImagePath = async () => {
        const event = Object.keys(point.alertActivity)[0] || '';

        if (event !== '') {
            const path = await alertDotIcon(point.alertActivity);
            if (typeof(path) === 'string') setImagePath(path);
        }
    }

    const generateDirections = (address) => {
        if (!address) return null;

        return (
          <div className={styles.PointDetailsButton}>
              <span>
                  {IS_MOBILE_APP ? (
                    <button
                      type='button'
                      onClick={() => handleLink(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address.street},${address.city}`)}`)}
                    >
                        Directions
                    </button>
                  ) : (
                    <a
                       href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address.street},${address.city}`)}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >Directions</a>
                  )}
              </span>
          </div>
        )
    }

    const handleTodaysHistory = () => {
        dispatch(removeReportTrips());
        dispatch({ type: 'REPORT_RESET_ALL_FILTERS' });

        const [startDate, endDate] = getStartAndEndOfDate();

        dispatch(ReportAC.TOGGLE_ITEM_FILTER('device', deviceId));
        dispatch(ReportAC.UPSERT_DATE_GROUP_IN_DATES({ startDate, endDate }));

        history.push('/reports');
    }

    const clearStatusBar = () => {
        setStatusMsg('');
    }

    const tagsClickHandler = () => {
        sessionStorage.setItem('redirectFromPoint', 'true');
        history.push(`/device/${deviceId}`);
    }

    const formattedAddress = formatAddress(address);

    return (
      <div className={styles.PointDetails}>
          <div className={styles.PointDetailsHeaderCointainer}>
              <div className={styles.PointDetailsHeader}>
                  <img className={styles.PointDetailsImage} src={imagePath}/>
                  <div className={styles.PointDetailsData}>
                      <div className={styles.PointDetailsInfoContainer}>
                          {existsPrevPoint && <div className={styles.PointDetailsBtnContainer}>
                              <ChevronLeft style={{height: '16px', width: '16px'}} onClick={() => {
                                  setStatusMsg('');
                                  selectPrevPoint();
                              }}/>
                          </div>}
                          <span className={styles.PointDetailsName}>{deviceData.name}</span>
                          {existsNextPoint && <div className={styles.PointDetailsBtnContainer}>
                              <ChevronRight style={{height: '16px', width: '16px'}} onClick={() => {
                                  setStatusMsg('');
                                  selectNextPoint();
                              }}/>
                          </div>}
                      </div>
                      {isEvent ?
                        (<span className={styles.PointDetailsMessage}>
                            Event:<span style={{fontWeight: 500}}>{point.msg}</span>
                        </span>) :
                        (<span className={styles.PointDetailsMessage}><MapPinIcon style={{width: '12px', height: '12px'}}/> {formattedAddress}</span>)}
                  </div>
              </div>
              {selectedPoint.isClicked === true && <div className={styles.PointDetailsPinnedContainer}>
                  <KeepIcon style={{width: '12px', height: '12px'}}/>
                  <span>Pinned</span>
              </div>}
          </div>
          <div className={styles.PointDetailsBorder}></div>
          {statusMsg !== '' && <div className={styles.PointDetailsStatusBar}
                                    style={isNotification ? {backgroundColor: 'rgba(255, 125, 2, 0.2)'} : {backgroundColor: 'rgba(176, 8, 8, 0.2)'}}>
              <div style={{display: 'flex', gap: '8px'}}>
                  {isNotification ? <InfoSignIcon/> : <WarningIcon/>}
                  <span style={isNotification ? {color: '#ff7c02'} : {color: '#b00808'}}>{statusMsg}</span>
              </div>
              <CloseIcon style={{cursor: 'pointer', fill: 'rgb(17, 17, 17)'}} onClick={clearStatusBar}/>
          </div>}
          <div className={styles.PointDetailsInfo}>
              {isEvent && <div className={styles.InfoEntry}>
                  <span className={styles.Label}>Current Location:</span>
                  <span className={styles.InfoData}>{formattedAddress}</span>
              </div>}
              <div className={styles.InfoEntry}>
                  <span className={styles.Label}>Date & Time:</span>
                  <span className={styles.InfoData}>{offSetTime.format('MM/DD/YY h:mm A')} {timezoneTime}</span>
              </div>
              <div className={styles.InfoEntry}>
                  <span className={styles.Label}>Speed:</span>
                  <span className={styles.InfoData}>{friendlySpeed(point.speed, units)}</span>
              </div>
              {makeModel !== '' && <div className={styles.InfoEntry}>
                  <span className={styles.Label}>Make & Model:</span>
                  <span className={styles.InfoData}>{makeModel}</span>
              </div>}
              {posted_speed ? (
                <div className={styles.InfoEntry}>
                    <span className={styles.Label}>Posted Speed:</span>
                    <span className={styles.InfoData}>{posted_speed}</span>
                </div>
              ) : null}
              {assignedDriver && <div className={styles.InfoEntry}>
                  <span className={styles.Label}>Driver:</span>
                  <span className={styles.InfoData}>{assignedDriver.displayName}</span>
              </div>}
          </div>
          <div className={styles.PointDetailsBtns}>
              <div className={styles.PointDetailsButton} onClick={gotoStreet}>
                  <span>Go To Street</span>
              </div>
              <div className={styles.PointDetailsButton} onClick={() => history.push(`/device/${deviceId}`)}>
                  <span>Go To Device</span>
              </div>
              {assignedDriver &&
                <div className={styles.PointDetailsButton} onClick={() => history.push(`/person/${assignedDriver.id}`)}>
                    <span>Go To Driver</span>
                </div>}
              {generateDirections(address)}
              <div className={styles.PointDetailsButton} onClick={tagsClickHandler}>
                  <span>Tags</span>
              </div>
              <div className={styles.PointDetailsButton} onClick={handleTodaysHistory}>
                  <span>Today's History</span>
              </div>
              {(isCamera && !check30DaysLeft) &&
                <Tooltip title={cameraStatus} placement='top'>
                    <button className={styles.PointDetailsButton} onClick={() => showAlertHistoryVideo()}>
                        {loading ? <Fa icon={faSpinner} spin/> : <span>Play Video</span>}
                    </button>
                </Tooltip>
              }
              {(isEvent && isCamera && !check30DaysLeft && alarmId) &&
                <Tooltip title={'Download event'} placement='top'>
                    <button
                      className={styles.PointDetailsButton}
                      onClick={() => downloadAlert(alarmId)}
                    >
                        {isDownloadingInProcess ? <Fa icon={faSpinner} spin/> : <span>Download event</span>}
                    </button>
                </Tooltip>
              }
              {(!isEvent && isCamera && !check30DaysLeft) &&
                <Tooltip title={'Download video'} placement='top'>
                    <button
                      className={styles.PointDetailsButton}
                      onClick={() => downloadPoint(point.pointId)}
                      disabled={isFailure}
                    >
                        {(isDownloadingInProcess || isDownloadStarted) ? <Fa icon={faSpinner} spin/> : <span>Download video</span>}
                    </button>
                </Tooltip>
              }
              {(user.can.includes(UserCan.HIDE_POINTS) && !isButtonHidden) && <div className={styles.PointDetailsRedBtn}>
                  <span onClick={hidePointHandle} style={{display: 'flex', alignItems: 'center', gap: '2px'}}><VisibilityOffIcon/> Hide</span>
              </div>}
              {(user.can.includes(UserCan.HIDE_POINTS) && isButtonHidden) && <div className={styles.PointDetailsButton}>
                  <span onClick={showPointHandle} style={{display: 'flex', alignItems: 'center', gap: '2px'}}><VisibilityIcon/> Show</span>
              </div>}
          </div>
          {isAddAlarmIdPromptModalOpen
            && <AddAlarmIdModalPrompt
              IsUserHasNotEnteredAllData={IsUserHasNotEnteredAllData}
              onClosePrompt={() => setIsAddAlarmIdPromptModalOpen(false)}
              openAddAlarmIdFormModal={() => setIsAddAlarmIdFormModalOpen(true)}
              isEvent={isEvent}
              handleLiveStream={showAlertHistoryVideo}
            />}
          {isAddAlarmIdFormModalOpen
            && <AddAlarmIdModalForm
              formData={formData}
              serial={serial}
              firebaseDeviceId={deviceId}
              setFormData={setFormData}
              onCloseForm={() => setIsAddAlarmIdFormModalOpen(false)}
              isEvent={isEvent}
            />}
      </div>
    )
}
