import React, { useEffect, useState } from 'react';
import DashboardBlock from '../../DashboardBlock';
import { Fa } from '../../elements/fa';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';
import { useHistory } from 'react-router-dom';
import { dvirCloseDashboard, dvirOpenDashboard } from '../../../stores/reducers/dashboardInfo/AC';
import { useDispatch, useSelector } from 'react-redux';
import { localStorage } from '../../../shared/storage';
import instance from '../../../api/instance';
import { DvirPagination } from './DvirPagination';
import { iFullStoreState, iList, iPerson } from '../../../shared/interfaces';
import { Row } from '../../elements/flex';
import { dvirStatusMapping, formatDate } from './utils';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import { is } from 'immutable';
import { equals } from 'ramda';
import { customSelectStyles } from '../../general/selections-grid/constants';
import Select, { components } from 'react-select';
import DateInput from '../../elements/DateInput';
import CreateDvirModal from './CreateDvir';
import { ReactComponent as ArrowBackIcon } from '../../../assets/svg/arrow-back.svg';
import { ReactComponent as InsertDriveFileIcon } from '../../../assets/svg/insert-drive-file.svg';
import { ReactComponent as AddPlusIcon } from '../../../assets/svg/add-plus.svg';
import { ReactComponent as PreviewIcon } from '../../../assets/svg/preview.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';
import { ReactComponent as CheckSvg } from '../../../assets/svg/check_24px_black.svg';
import styles from './styles.module.scss';

interface iDvir {
  id: string,
  device_key: string,
  firebase_key: string,
  created_at: string,
  person_key: string,
  status: string,
  vehicle_name: string,
  vehicle_vin: string
}

interface iDvirFilters {
  status: { label: string, value: string },
  vehicle: { label: string, value: string },
  driver: { label: string, value: string },
  date: string
}

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <span>{props.data.label}</span>
      {props.isSelected && (
        <CheckSvg />
      )}
    </components.Option>
  );
};

const Dvir = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const [page, setPage] = useState<number>(1);
  // const [limit, setLimit] = useState<number>(10);
  const [dvirs, setDvirs] = useState<iDvir[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<iDvirFilters>({
    status: { label: '', value: '' },
    vehicle: { label: '', value: '' },
    driver: { label: '', value: '' },
    date: ''
  });

  const [isCreateDVIRModalVisible, setIsCreateDVIRModalVisible] = useState<boolean>(false);

  const people = useSelector<iFullStoreState>(state => state.general.people);

  const peopleArray = [
    { label: 'All', value: 'all' },
    ...Object.values(useSelector<iFullStoreState, iList<iPerson>>(
      (s) => s.general.people,
      (l, r) => equals(l, r)
    )).map((person) => {
      return { label: person.displayName, value: person.id };
    })
  ];

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );
  const devicesArray = devices.valueSeq().toArray();

  const transformedForSelectDevices = [
    { label: 'All', value: 'all' },
    ...devicesArray.map(device => ({
      label: device.name,
      value: device.id
    }))
  ];

  const statusSelectOptions = [
    { label: 'All', value: 'all' },
    { label: 'Completed', value: 'completed' },
    { label: 'Needs Maintenance', value: 'maintenance-inspection' },
    { label: 'Needs Post-Trip Inspection', value: 'post-trip-inspection' },
    { label: 'Needs Pre-Trip Inspection', value: 'pre-trip-inspection' },
  ];

  const dvirStatusStyle: React.CSSProperties = {
    color: '#B00808'
  }

  const fetchDvirs = async (page: number = 1, limit: number = 10) => {
    const URL = 'api/dvir/get-dvirs';
    const body = {
      organization_key: clientId,
      page,
      limit
    }

    if (filters.status.value !== '' && filters.status.value !== 'all') {
      body['dvir_status'] = [filters.status.value];
    }

    if (filters.vehicle.value !== '' && filters.vehicle.value !== 'all') {
      body['device_name'] = [filters.vehicle.label];
    }

    if (filters.driver.value !== '' && filters.driver.value !== 'all') {
      body['person_key'] = [filters.driver.value];
    }

    if (filters.date !== '') {
      const date = new Date(filters.date);

      const startOfDay = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
      const endOfDay = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999);

      body['start_date'] = Math.round(startOfDay / 1000);
      body['end_date'] = Math.round(endOfDay / 1000);
    }

    try {
      setIsLoading(true);

      const { data } = await instance.post(URL, { ...body });

      const { data: dvirs, total_pages: totalPages } = data;

      setTotalPages(totalPages);
      setDvirs(dvirs);

      setIsLoading(false);

      const dvir = dvirs[0];
    } catch ({ message }) {
      console.log(message)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchDvirs(page);
  }

  const handleStatusFilter = (selectedOption) => {
    setFilters({ ...filters, status: selectedOption });
  }

  const handleDriverFilter = (selectedOption) => {
    setFilters({ ...filters, driver: selectedOption });
  }

  const handleVehicleFilter = (selectedOption) => {
    setFilters({ ...filters, vehicle: selectedOption });
  }

  const handleDateFilter = (momentDate) => {
    if (momentDate === undefined) {
      setFilters({ ...filters, date: '' })
    } else {
      const formattedDate: string = momentDate?.format('YYYY-MM-DD');
      setFilters({ ...filters, date: formattedDate });
    }
  }

  useEffect(() => {
    setCurrentPage(1);
    fetchDvirs(1)
  }, [filters]);

  useEffect(() => {
    (window as any).Beacon('destroy')
    dispatch(dvirOpenDashboard());

    fetchDvirs();

    return () => {
      (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirHeader}>
        <button className="no-print btn-link" onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <h2>DVIR Overview</h2>
        <div></div>
      </div>
      <div className={styles.DvirButtons}>
        <button className={styles.DvirButtons__secondary} onClick={() => history.push("/dvir-forms")}>
          <InsertDriveFileIcon className={styles.DvirButtons__secondary__icon} />
          <span>Forms</span>
        </button>
        <button className={styles.DvirButtons__primary} onClick={() => setIsCreateDVIRModalVisible(true)}>
          <AddPlusIcon />
          <span>New DVIR</span>
        </button>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }} />
        </Row>
      )}
      <div className={styles.DvirTableWrapper}>
        <table className={styles.DvirTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>DVIR ID</th>
              <th>Driver</th>
              <th>Date</th>
              <th>Vehicle Name</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.FiltersRow}>
              <td></td>
              <td></td>
              <td className={styles.FiltersRow__dropdownWrapper}>
                <Select
                  key='driver'
                  onChange={handleDriverFilter}
                  value={filters.driver.value && filters.driver}
                  placeholder='Select Driver'
                  styles={customSelectStyles}
                  options={peopleArray}
                  components={{ Option: CustomOption }}
                />
              </td>
              <td className={styles.FiltersRow__datepickerWrapper}>
                <DateInput
                  className='date-input-dvir'
                  onDateChange={handleDateFilter}
                  closeOnDateSelect={true}
                />
              </td>
              <td className={styles.FiltersRow__dropdownWrapper}>
                <Select
                  key='vehicleName'
                  onChange={handleVehicleFilter}
                  value={filters.vehicle.value && filters.vehicle}
                  placeholder='Select Vehicle'
                  styles={customSelectStyles}
                  options={transformedForSelectDevices}
                  components={{ Option: CustomOption }}
                />
              </td>
              <td className={styles.FiltersRow__dropdownWrapper}>
                <Select
                  key='status'
                  onChange={handleStatusFilter}
                  value={filters.status.value && filters.status}
                  placeholder='Select Status'
                  styles={customSelectStyles}
                  options={statusSelectOptions}
                  components={{ Option: CustomOption }}
                />
              </td>
              <td></td>
            </tr>
            {!isLoading && dvirs && dvirs.length > 0 && (
              <>
                {dvirs.map((dvir, index) => {
                  const person = people[dvir.person_key];

                  const pencilStyles: React.CSSProperties = {
                    color: dvir.status === 'completed' ? 'gray' : '#FF7C02',
                    cursor:
                      dvir.status === 'completed' ? 'not-allowed' : 'pointer',
                  };

                  return (
                    <tr key={dvir.firebase_key}>
                      <td>{index + 1}</td>
                      <td>{dvir.id}</td>
                      <td>{person?.displayName || ''}</td>
                      <td>{formatDate(dvir.created_at)}</td>
                      <td>{dvir.vehicle_name}</td>
                      <td
                        style={dvir.status !== 'completed' ? dvirStatusStyle : {}}
                      >
                        {dvirStatusMapping[dvir.status]}
                      </td>
                      <td className={styles.DvirItemActions}>
                        <button
                          className={styles.DvirItemActions__button}
                          onClick={() =>
                            history.push(`/dvir/${dvir.firebase_key}`)
                          }
                        >
                          <PreviewIcon />
                        </button>
                        <button
                          className={styles.DvirItemActions__button}
                          onClick={() => {
                            if (dvir.status !== 'completed')
                              history.push(`/dvir/${dvir.firebase_key}/update`);
                          }}
                        >
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && dvirs && dvirs.length > 0 && (
        <DvirPagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      <CreateDvirModal
        isCreateDVIRModalVisible={isCreateDVIRModalVisible}
        setIsCreateDVIRModalVisible={setIsCreateDVIRModalVisible}
      />
    </DashboardBlock>
  );
};

export default Dvir;
