import React from 'react';
import { useSelector } from "react-redux";
import { iDeviceDetails, iDevicePing, iFullStoreState, ItemType } from "../../../../shared/interfaces";
import { equals, pathOr } from 'ramda';
import { formatWhenLast } from "../device-page";
import { useRedux } from "../../../../states/redux-state";
import PersonIcon from '../../../../assets/svg/person_24px.svg';
import PersonIconOrange from '../../../../assets/svg/person_24px_orange.svg';
import StarIcon from '../../../../assets/svg/star_border_24px.svg';
import StarIconOrange from '../../../../assets/svg/star_24_orange.svg';
import HistoryIcon from '../../../../assets/svg/history_24px.svg';
import styles from './styles.module.scss';
import SvgIcon from "../../../SvgIcon/SvgIcon";
import { FormatAddress } from "../device-list";
import { useFirebase } from "../../../../states/firebase-state";
import { DashboardSize } from '../../../../stores/reducers/dashboardInfo';
import classNames from 'classnames';

const getPersonFromState = (personId, state) =>
  pathOr(false, ['general', 'people', personId], state);

const NewDeviceItem = ({ deviceId }) => {
  const device = useSelector<iFullStoreState, iDeviceDetails>(
    (state) => state.devicesData.devicesDetails.get(deviceId)!,
    (left, right) => equals(left, right)
  );
  const lastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
    (state) => state.devicesData.devicesLastPing.get(deviceId),
    (left, right) => left?.pointId === right?.pointId
  );
  const pingMsg = lastPing?.msg ?? 'N/A';
  const assignedPersonId = device?.assignedTo?.personId;
  const assignedTo = useRedux((s) => getPersonFromState(assignedPersonId, s));
  const [ago, setAgo] = React.useState(formatWhenLast(lastPing?.time));
  const uid = useRedux((s) => s.auth.user.uid);
  const [isFavorite, setFavorite] = useFirebase(
    `favorites/${uid}/${ItemType.device}/${deviceId}`
  );

  const toggleFav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFavorite(isFavorite ? null : 999999);
  };

  const size = useSelector<iFullStoreState, DashboardSize>(
    (s) => s.dashboardInfo.size
  );

  const isNotFullSizeDashboard = !(size === DashboardSize.OPEN_FULL);


  return (
    <div
      className={classNames(styles.DeviceItem, {
        [styles.DeviceItem_showFullCardWidthOnSmallScreens]: isNotFullSizeDashboard,
      })}
    >
      <div className={styles.DeviceHeader}>
        <div className={styles.DeviceHeaderTop}>
          <div style={{ position: 'relative', height: '12px', width: '12px' }}>
            {assignedTo !== false && <span style={{
              position: 'absolute',
              bottom: '30px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#6e6e6e',
              color: '#fff',
              whiteSpace: 'nowrap',
              borderRadius: '4px',
              fontSize: '12px',
              opacity: 0,
              visibility: 'hidden',
              transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
            }}>Assigned to {assignedTo.displayName}</span>}
            <img
              style={{ height: '12px', width: '12px', position: 'absolute' }}
              src={assignedTo === false ? PersonIcon : PersonIconOrange}
              onMouseEnter={(e) => {
                const tooltip = e.currentTarget.previousSibling as HTMLElement | null;
                if (tooltip) {
                  tooltip.style.opacity = '1';
                  tooltip.style.visibility = 'visible';
                }
              }}
              onMouseLeave={(e) => {
                const tooltip = e.currentTarget.previousSibling as HTMLElement | null;
                if (tooltip) {
                  tooltip.style.opacity = '0';
                  tooltip.style.visibility = 'hidden';
                }
              }}
            />
          </div>
          <div className={styles.DeviceHeaderImageContainer}>
            <SvgIcon
              style={{ flex: 1, textAlign: 'center' }}
              color={device.color}
              icon={device.icon}
              size='xs'
            />
          </div>
          <img src={isFavorite ? StarIconOrange : StarIcon} onClick={toggleFav} />
        </div>
        <span>{device.name}</span>
      </div>
      <div className={styles.DevicePingDetails}>
        {ago && (
          <div className={styles.DeviceLastPing}>
            <img src={HistoryIcon} />
            <span title={ago.toString()}>{ago}</span>
          </div>
        )}

        {pingMsg && (
          <div className={styles.DevicePingMsg}>
            <span>{pingMsg}</span>
          </div>
        )}

        <FormatAddress
          style={{
            fontSize: 10,
            fontWeight: 400,
            color: '#878889',
            textAlign: 'center'
          }}
          address={lastPing?.address}
          noWrap
        />
      </div>
    </div>
  )
}

export default NewDeviceItem;
