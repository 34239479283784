import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { SearchInputProps } from './interfaces';
import styles from './SearchInput.module.scss';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search_to_right.svg';


const SearchInput: FunctionComponent<SearchInputProps> = ({ value, onChange, placeholder, className }) => {
  const handleChange = ({ target }) => onChange(target.value)

  return (
    <label className={classNames(styles.searchContainer, className)}>
      <SearchIcon className={styles.searchIcon} />
      <input
        className={styles.searchInput}
        type='text'
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </label>
  );
};

export default SearchInput;
