import { useRef, useState, useEffect, useMemo, useCallback } from 'react';

type UseItemsListParams = {
  initialSelected?: string[],
  allItemsIds: string[];
  canItemBeSelected?: (id: string, allSelected: string[]) => boolean;
  onCannotSelect?: VoidFunction;
};

export type ToggleSelectionParams =  { id: string; isSelected?: boolean }

type UseItemsListReturn = {
  selected: string[];
  toggleSelection: ({ id, isSelected }: ToggleSelectionParams) => void;
  isAllSelected: boolean;
  toggleSelectAll: (isSelected: boolean) => void;
  resetAllSelection: VoidFunction;
}

const useItemsList = ({
  initialSelected = [],
  allItemsIds,
  canItemBeSelected = () => true,
  onCannotSelect,
}: UseItemsListParams): UseItemsListReturn => {
  // this is a record with selected ids as keys
  const [selected, updateSelectedState] = useState<string[]>(initialSelected);
  const selectedRef = useRef(selected);
  const setSelected = (v: string[]): void => {
    updateSelectedState(v);
    selectedRef.current = v;
  };

  const toggleSelection = useCallback(({
    id,
    // new value
    isSelected = !selectedRef.current.includes(id),
  } : {
    id: string,
    isSelected?: boolean,
  }): void => {
    // we can change item if new value is false or it can be selected
    const canChangeItemSelection = !isSelected || canItemBeSelected(id, selectedRef.current);

    if (canChangeItemSelection) {
      const selectedWithoutKey = selectedRef.current.filter(v => v !== id);
      const newSelected = isSelected
        ? [...selectedWithoutKey, id]
        : selectedWithoutKey;
      setSelected(newSelected);
    } else {
      onCannotSelect?.();
    }

    // reset selected all value
    // INFO: setting to true is implemented in a useEffect hook
    setIsAllSelected(false);
  }, [canItemBeSelected, onCannotSelect, selected]);

  // SELECT/DESELECT ALL FUNCTIONALITY
  const calculateIsAllSelected = () => {
    // we use `some` and reverted logic, as `every` returns true for empty array
    const doesOneNotSelectedExist = allItemsIds.some((id) => !selected.includes(id));
    // if no [one element not selected] => all items are selected
    return !doesOneNotSelectedExist;
  };

  // we handle it manually, as we have cases where we cannot calculate it dynamically due the limit:
  // if an item cannot be selected for all selected items
  const [isAllSelected, setIsAllSelected] = useState<boolean>(calculateIsAllSelected());

  const resetAllSelection = useCallback(() => setIsAllSelected(false), [setIsAllSelected]);

  // setting isAllSelected to true
  useEffect(() => {
    const curIsAllSelected = calculateIsAllSelected();
    // update only if true
    curIsAllSelected && setIsAllSelected(true);
  }, [allItemsIds, selected]);

  const toggleSelectAll = useCallback((isSelected: boolean) => {
    allItemsIds.forEach((id) => {
      toggleSelection({ id, isSelected });
    });

    // change selected all value
    // we handle it manually, as we have cases where we cannot calculate it dynamically due the limit:
    // if an item cannot be selected for all selected items
    setIsAllSelected(isSelected);
  }, [allItemsIds, toggleSelection]);

  return useMemo(() => ({
    selected,
    toggleSelection,
    isAllSelected,
    toggleSelectAll,
    resetAllSelection,
  }), [
    selected,
    toggleSelection,
    isAllSelected,
    toggleSelectAll,
    resetAllSelection,
  ]);
};

export default useItemsList;