import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HttpStatus, iDvirFormsItem, iFullStoreState } from "../../../../shared/interfaces";
import styles from "./styles.module.scss";
import { dvirCloseDashboard, dvirOpenDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import { DevicesDetailsContainer } from "../../../../stores/reducers/devicesData";
import { is } from "immutable";
import { localStorage } from "../../../../shared/storage";
import { useRedux } from "../../../../states/redux-state";
import { fetchDvirFormsByPage } from "../utils";
import instance from "../../../../api/instance";
import Modal from '../../../Modal';
import Select, { components } from 'react-select';
import classNames from "classnames";
import { ReactComponent as CheckSvg } from '../../../../assets/svg/check_24px_black.svg';
import { ReactComponent as InfoSvg } from '../../../../assets/svg/info.svg';

import { customSelectStyles } from '../../../general/selections-grid/constants';

interface iSelectElement {
  label: string,
  value: string,
}

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <span>{props.data.label}</span>
      {props.isSelected && (
        <CheckSvg />
      )}
    </components.Option>
  );
};

const CreateDvirModal = ({ isCreateDVIRModalVisible, setIsCreateDVIRModalVisible }: { isCreateDVIRModalVisible: boolean, setIsCreateDVIRModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState<iSelectElement>({ label: '', value: '' });
  const [selectedForm, setSelectedForm] = useState<iSelectElement>({ label: '', value: '' });
  const clientId = localStorage.get('active-client');
  const uid = useRedux((s) => s.auth.user.uid);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forms, setForms] = useState<iDvirFormsItem[]>();
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [errorsShouldDisplay, setErrorsShouldDisplay] = useState<boolean>(false);

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );
  const devicesArray = devices.valueSeq().toArray();

  const transformedForSelectDevices = [
    ...devicesArray.map(device => ({
      label: device.name,
      value: device.id
    }))
  ];

  const transformedForSelectForms = forms && forms.map(item => ({
    label: item.name,
    value: item.firebase_key
  }));

  const handleDeviceChange = (selectedOption) => {
    setErrorsShouldDisplay(false);
    setSelectedDevice(selectedOption);
  }

  const handleFormChange = (selectedOption) => {
    setErrorsShouldDisplay(false);
    setSelectedForm(selectedOption);
  }

  const fetchDvirForms = async (page: number = 1, limit: number = 100) => {
    try {
      setIsLoading(true);

      const data = await fetchDvirFormsByPage({ page, limit, clientId });

      const { data: forms, total_pages: totalPages } = data;

      setForms(forms);

      setIsLoading(false);
    } catch ({ message }) {
      console.log(message)
    }
  }

  const createDvir = async () => {
    const URL = 'api/dvir/create-dvir';
    const body = {
      organization_key: clientId,
      device_key: selectedDevice.value,
      dvir_form_key: selectedForm.value,
      person_key: uid,
      created_at: Math.round(new Date().getTime() / 1000)
    }
    const errors = [];

    let hasErrors = false;

    if (selectedDevice.value === '') {
      errors.push('Device can\'t be empty.');
      hasErrors = true;
    }

    if (selectedForm.value === '') {
      errors.push('Form can\'t be empty.');
      hasErrors = true;
    }

    if (!hasErrors) {
      try {
        const res = await instance.post(URL, { ...body });

        if (res.data.status === 'unclosed-dvir') {
          errors.push('Unable to create a new DVIR inspection. All existing inspections must have a status of \'Completed\' or \'Needs Maintenance.\' Please close any open inspections before proceeding.');
        }
        else if (res.status === HttpStatus.OK) {
          handleCancelClick();
        }
      }
      catch (e) {
        console.log(e);
        errors.push('Error while creating a DVIR, please try again later or select a different device.')
      }
    }

    if (errors.length) {
      setErrorsList(errors);
      setErrorsShouldDisplay(true);
    }
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    fetchDvirForms();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  const activeCreateButton = (selectedDevice.value !== '') && (selectedForm.value !== '')

  const handleCancelClick = () => {
    setSelectedDevice({ label: '', value: '' });
    setSelectedForm({ label: '', value: '' });
    setErrorsList([]);
    setErrorsShouldDisplay(false);
    setIsCreateDVIRModalVisible(false);
  }

  return (
    <>
      {isCreateDVIRModalVisible && (
        <Modal>
          <div className={styles.CreateDvirModal}>
            <div className={styles.contentPanel}>
              <h3>Create DVIR</h3>
              {errorsShouldDisplay && (
                <div className={styles.CreateDvirErrors}>
                  <div>
                    <div className={styles.CreateDvirErrors__header}>
                      <InfoSvg />
                      <p>DVIR Creation Failed</p>
                    </div>
                    <div className={styles.CreateDvirErrors__ErrorList}>
                      {errorsList.map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                    </div>
                  </div>
                  <button className={styles.CloseBtn} onClick={() => setErrorsShouldDisplay(false)}>&#x2715;</button>
                </div>)}
              <p className={styles.CreateDvirModal__selectLabel}>Device</p>
              <Select
                key='device'
                onChange={handleDeviceChange}
                value={selectedDevice.value && selectedDevice}
                placeholder='Select device...'
                styles={customSelectStyles}
                options={transformedForSelectDevices}
                components={{ Option: CustomOption }}
              />
              {forms && forms.length > 0 && !isLoading && (
                <div className={styles.formPart}>
                  <p className={styles.CreateDvirModal__selectLabel}>Form</p>
                  <Select
                    key='form'
                    onChange={handleFormChange}
                    value={selectedForm.value && selectedForm}
                    placeholder='Select form...'
                    styles={customSelectStyles}
                    options={transformedForSelectForms}
                    components={{ Option: CustomOption }}
                  />
                </div>
              )}
            </div>
            <div className={styles.bottomPanel}>
              <button onClick={handleCancelClick} className={styles.bottomPanel__secondaryButton}>
                Cancel
              </button>
              <button
                onClick={createDvir}
                className={classNames(styles.bottomPanel__primaryButton, { [styles.bottomPanel__primaryButton_active]: activeCreateButton })}
              >
                Create
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );

};

export default CreateDvirModal;
