import React from 'react';
import {iPerson} from "../../shared/interfaces";

interface iProps {
    size?: number;
    person: iPerson;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const style = (size, url): React.CSSProperties => ({
  width: size, height: size, borderRadius: size, backgroundImage: `url(${url})`, backgroundSize: 'cover', border: `1px solid #E8E8E8`, backgroundColor: '#F8F8F8', position: 'relative'
})

const UserImage = (props: iProps) => {
  const { person, size = 90, children = null, style: extraStyle = {} } = props;

  return (
    <div style={{...style(size, person.photoURL), ...extraStyle}}>
      {children}
    </div>
  )
}

export default UserImage;
