import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import DayPicker from "../DayPicker";
import moment, { Moment } from "moment";
import { ReactComponent as CalendarToday } from "../../../assets/svg/calendar_today.svg";
import useClickOutside from "../../../hooks/useClickOutside";


interface DateInput {
    name?: string;
    onDateChange?: (date: Moment | undefined) => void;
    className?: string;
    defaultValue?: Moment | null;
    format?: string;
    closeOnDateSelect?: boolean;
    onClose?: (date: Moment | undefined) => void;
    shouldClear?: boolean;
}

const DateInput: FC<DateInput> = ({name, onClose, onDateChange, className = '', defaultValue = null, format = "MM : DD : YYYY", closeOnDateSelect = false, shouldClear = false}) => {
    const [isSelecting, setIsSelecting] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [date, setDate] = useState(defaultValue);
    const pickerRef = useRef(null);
    const inputRef = useRef(null);
    useClickOutside({targetRef: pickerRef, omitRefs: [inputRef], callback: () => {setIsSelecting(false)}})

    const handleInputClick = () => {
        setIsSelecting(!isSelecting);
    }

    useEffect(() => {
        setFirstRender(false);
    }, [])

    useEffect(() => {
        if (!isSelecting && !firstRender) {
            onClose?.(date)
        }
    }, [isSelecting])

    useEffect(() => {
        if (!firstRender && shouldClear) {
            setDate(defaultValue);
            // handleDateChange(undefined, {selected: false})
            setFirstRender(true); // test
            setTimeout(() => {
                setFirstRender(false); // test
            }, 0)
        }
    }, [shouldClear]);

    const handleDateChange = (newDate: Date, {selected}) => {
        setDate(selected? undefined : moment(newDate));
        onDateChange?.(selected? undefined : moment(newDate));
        closeOnDateSelect && !selected && setIsSelecting(false);
    }
    const isUserHasEnteredDate = date?.isValid();

    return (
        <div className={`date-input ${className ? className : null}`}>
            <div ref={inputRef} className={`date-input__input ${className ? `${className}__input` : null}`} onClick={handleInputClick}>
                {isUserHasEnteredDate ? date.format(format): format}&nbsp;<CalendarToday className="icon-16px" />
            </div>
            <div ref={pickerRef} className={`date-input__picker date-input__picker${isSelecting? "--opened": ""} ${className ? `${className}__picker${isSelecting? "--opened": ""}` : null}`}><DayPicker defaultValue={defaultValue?.toDate()} onDayChange={handleDateChange} value={date?.toDate()} /></div>
        </div>
    )
};

export default DateInput;