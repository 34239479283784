import React, { FunctionComponent, useCallback } from 'react';
import classNames from 'classnames';
import { TagLabelProps } from './interfaces';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close_cur.svg';
import styles from './TagLabel.module.scss';

const TagLabel: FunctionComponent<TagLabelProps> = ({ name, onClose, style }) => {
  const Wrapper = useCallback(({ children, ...props }) => (
    onClose
      ? (
        <button
          type='button'
          onClick={onClose}
          {...props}
        >
          {children}
        </button>
      ) : <div {...props}>{children}</div>
  ), [onClose]);

  return (
    <Wrapper
      className={classNames(styles.selectedTagItem, { [styles.withClose]: onClose })}
      style={style}
    >
      <span className={styles.selectedTagName}>
        {name}
      </span>
      {!!onClose && (<CloseIcon className={styles.selectedTagItemIcon} />)}
    </Wrapper>
  );
};

export default TagLabel;
