import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {iDvirForm, iFullStoreState} from "../../../../shared/interfaces";
import DashboardBlock from "../../../DashboardBlock";
import {Fa} from "../../../elements/fa";
import {faArrowLeft, faSpinner} from "@fortawesome/fontawesome-free-solid";
import styles from './styles.module.scss';
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../../stores/reducers/dashboardInfo/AC";
import {Row} from "../../../elements/flex";
import {fetchDvirFormById} from "../utils";

interface DvirFormProps extends RouteComponentProps<{ id: string }> {}

const mapStateToProps = (state: iFullStoreState, ownProps: DvirFormProps) => ({
  dvirId: ownProps.match.params.id,
});

const DvirForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = props.dvirId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<iDvirForm>();

  const fetchDvirForm = async () => {
      try {
        setIsLoading(true);

        const data = await fetchDvirFormById(id);

        const dvirForm = data.data['dvir_form'];
        const headings = data.data['header_items'];

        setForm({
          name: dvirForm.name,
          description: dvirForm.brief_description,
          id: dvirForm.firebase_key,
          headings
        });

        setIsLoading(false);
      }
      catch (e) {
        console.log(e)
      }
  };

  useEffect(() => {
    (window as any).Beacon('destroy')
    dispatch(dvirOpenDashboard());

    fetchDvirForm();

    return () => {
      (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <span
        className="no-print btn-link"
        style={{paddingLeft: 10, marginRight: 20}}
        onClick={() => history.goBack()}
      >
        <Fa style={{color: '#FF7C02'}} icon={faArrowLeft}/>
      </span>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      {!isLoading && form && (
      <div className={styles.DvirForm}>
        <table className={styles.DvirFormTable}>
          <tr>
            <td>Name</td>
            <td>{form.name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{form.description}</td>
          </tr>
        </table>
        <h3>Headings</h3>
        <table className={styles.DvirFormHeadingsTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {form.headings.map(heading => (
              <tr key={heading.firebase_key}>
                <td>{heading.header}</td>
                <td>{heading.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>)}
    </DashboardBlock>
  )
};

export default connect(mapStateToProps)(DvirForm);
