import React from "react";
import { ReactComponent as AddPlusIcon } from '../../../assets/svg/add-plus.svg';
import styles from './styles.module.scss';

const DvirFormHeadingInput = ({ name, description, isHeaderNameError, headerNameErrorMsg, onChangeName, onChangeDescription, onClickErrorBlock, addHeading }) => {
  return (
    <div className={styles.CreateDvirFormHeadersContainer}>
      <h3>Add New Heading</h3>
      {isHeaderNameError && (
        <div className={styles.CreateDvirFormError}>
          <p>{headerNameErrorMsg}</p>
          <span onClick={onClickErrorBlock}>&#x2715;</span>
        </div>
      )}
      <div className={styles.CreateDvirFormName}>
        <label htmlFor='heading-name'>Heading Name</label>
        <input
          type='text'
          id='heading-name'
          placeholder='Enter heading name'
          value={name}
          onChange={onChangeName}
        />
        <label>Heading Brief Description</label>
        <textarea
          id='form-description'
          placeholder='Enter heading description'
          value={description}
          onChange={onChangeDescription}
        />
        <div className={styles.AddHeadingBtnWrapper}>
          <button onClick={addHeading} className={styles.AddHeadingBtn}>
            <AddPlusIcon />
            <span>Add Heading</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DvirFormHeadingInput;
