import * as color from 'color';
import classNames from 'classnames';
import * as generaldb from '../../../shared/db/general-db';
import * as tagsdb from '../../../shared/db/tags-db';

import Dialog, { DialogConfig, DialogConfigSetter } from '../../Dialog';
import { DispatchProp, connect, useSelector } from 'react-redux';
import {
  IconTypes,
  ItemType,
  UserAuth,
  iFullStoreState,
  iIcon,
  iList,
  iTag,
  iPerson,
} from '../../../shared/interfaces';
import React, { CSSProperties as css, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  assoc,
  dissoc,
  keys,
  path,
  pathOr,
} from 'ramda';
import {
  hideAllTags,
  hideSelectedDevices,
  showSelectedDevices,
  showTagDevices,
  toggleShowSelectedTagDevice,
} from '../../../stores/reducers/tagsDevicesMap/AC';
import { Actions as GmapActions } from '../../../stores/reducers/gmap-reducers';
import { MAP } from 'react-google-maps/lib/constants';
import { makeAudit, vals } from '../../../shared/helpers';

import C, {ICON_NAMES} from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import { ExtraInfo } from '../../elements/extra-info';
import MinimalTile from '../../general/MinimalTile';
import ModalHeader from '../../menus/modal/modal-header';
import { UiActions, iReducersState } from '../../../stores/reducers';
import { clientDb } from '../../../shared/firebase';
import { getItemLabelNameValMap } from '../../../shared/db/tags-labels-selectors';
import { getTagReadOnlyStatus } from '../../../shared/db/general-db';
import { selectDevices } from '../../../stores/reducers/devicesData/selector';
import { selectIconContext } from '../../../shared/contexts/selectIcon.context';
import { withSentry } from '../../../shared/hoc/withSentry';
import { useRedux } from '../../../states/redux-state';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import SvgIcon from '../../SvgIcon/SvgIcon';
import './tag-details.scss';

import {
  openModal,
} from '../../../stores/reducers/videoCameras';
import { getChannelsMore } from '../../../api/openApi';
import styles from './tag-details.module.scss';
import DeviceStatusesForTag from '../devices/deviceStatuses/ForTag/DeviceStatusesForTag/DeviceStatusesForTag';
import ModalCamerasForTag from '../devices/deviceStatuses/ForTag/ModalCamerasForTag/ModalCamerasForTag';
import SelectChannelModal from '../devices/deviceStatuses/ForTag/SelectChannelModal/SelectChannelModal';
import ContinueStreamModalForTag from '../devices/deviceStatuses/ForTag/ContinueStreamModalForTag/ContinueStreamModalForTag';
import PublicLinkModal from './PublicLinkModal';
import {localStorage} from "../../../shared/storage";
import SearchInput from '../../core/SearchInput';
import CheckboxList from '../../core/CheckboxList';
import { groupItemsIntoPages } from '../../../utils';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close_cur.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check_cur.svg';
import { ReactComponent as PlayIcon } from '../../../assets/svg/play_circle_cur.svg';
import { ReactComponent as PublicIcon } from '../../../assets/svg/public_cur.svg';
import { ReactComponent as NotificationsOnIcon } from '../../../assets/svg/notification_16w.svg';
import { ReactComponent as NotificationsOffIcon } from '../../../assets/svg/notifications_off_cur.svg';


const taggedDeviceIdsSelector = (
  tagId: string
): ((state: iFullStoreState) => ITaggedDeviceIds) =>
  pathOr({}, ['general', 'tags', tagId, 'instances', 'device']);
type ITaggedDeviceIds = { [deviceId: string]: true };

const mapStateToProps = (
  state: iFullStoreState,
  ownProps: IProps
): IPropsFromStore => ({
  authUser: state.auth.user,
  tags: state.general.tags,
  currentLabels: getItemLabelNameValMap(state, {
    type: ItemType.tag,
    itemId: ownProps.match.params.tagId,
  }),
  deviceFilterStr: state.ui.tagDeetsDeviceFilter,
});

type IProps = RouteComponentProps<{ tagId: string }>;

type IPropsFromStore = {
  authUser: UserAuth;
  tags: iList<iTag>;
  currentLabels: [string, string][];
  deviceFilterStr: string;
};
type IFullProps = IProps & IPropsFromStore & DispatchProp;

const confirmModalConfig: DialogConfig = {
  title: 'Alert Confirm Modal',
  body: 'Only one alert type can be attached to device. Confirm to continue',
  type: 'CONFIRM',
};

function TagDetailsPage(props: IFullProps) {
  const {
    tags,
    dispatch,
    currentLabels,
    deviceFilterStr,
    authUser,
  } = props;
  const { tagId } = props.match.params;
  const tag = tags[tagId];
  const taggedDeviceIdsList = useSelector(taggedDeviceIdsSelector(tagId));
  const person = useSelector<iFullStoreState, iPerson>(state => state.general.people[state.auth.user?.uid]);
  const taggedDeviceIds = Object.keys(taggedDeviceIdsList);
  const taggedDeviceCount = taggedDeviceIds.length;
  const loginToken = localStorage.get('login-init-be-token');
  let isRenderedMap = useSelector<iFullStoreState, boolean>(
      (state) => state.gmap.isRendered
  );

  const devicesDetails = useSelector(selectDevices);
  // devices data as objects, not react elements
  const allDevicesArray = useMemo(() => [...devicesDetails.values()], [devicesDetails]);
  const filteredDevices = useMemo(() => (
    allDevicesArray
      .filter(t => deviceFilterStr
        ? !!t.name && t.name.toLowerCase().indexOf(deviceFilterStr.toLowerCase()) !== -1
        : true)
  ), [allDevicesArray, deviceFilterStr]);
  const filteredDevicesIds = useMemo(() => filteredDevices.map(({ id }) => id), [filteredDevices]);

  const calculateIsAllSelected = () => {
    // we use `some` and reverted logic, as `every` returns true for empty array
    const doesOneNotSelectedExist = filteredDevicesIds.some((id) => !taggedDeviceIds.includes(id));
    // if no [one element not selected] => all items are selected
    return !doesOneNotSelectedExist;
  };

  const [isAllDeviceSelected, setIsAllDeviceSelected] = useState(() => {
    if (calculateIsAllSelected()) {
      return true;
    }
    if (taggedDeviceIds.length === 0) {
      return false;
    }

    return false;
  });

  // update select all to true if all selected
  useEffect(() => {
    const isAllSelected = calculateIsAllSelected();
    (isAllSelected) && setIsAllDeviceSelected(true);
  }, [taggedDeviceIds.length, filteredDevicesIds.length]);

  const [isSvg, setIsSvg] = useState(false);
  const [svgIcon, setSvgIcon] = useState<any>(null);

  const mapRef = useSelector<
    iFullStoreState,
    React.RefObject<GoogleMap> | null
  >((state) => state.gmap.mapRef());

  useEffect(() => {
    const saveIcon = async () =>
      await tagsdb.update(authUser)(tagId, { svgIcon });

    if (svgIcon !== null) {
      saveIcon();
    } else if (!isSvg) setSvgIcon(null);
  }, [svgIcon, isSvg]);

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);

  const { selectIcon } = React.useContext(selectIconContext);

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  const showDevices = () => {
    dispatch(showTagDevices(tagId));
  };

  const removeAllTags = () => {
    dispatch(hideAllTags());
  };

  React.useEffect(() => {
    removeAllTags();
    showDevices();

    return () => {
      removeAllTags();
    };
  }, [tagId]);

  const sortByIds = (arrayToSort, orderArray) => orderArray
      .map(id => arrayToSort
          .find(item => item.device === id)
      );

  const publicViewArr = sortByIds(devicesMarker, taggedDeviceIds)
      .filter((device) => device !== undefined);

  const IS_PUBLIC_VIEW = window.location.href.includes('public-view');

  React.useEffect(() => {
    if (IS_PUBLIC_VIEW && isRenderedMap) {
        if (publicViewArr.length) {
          dispatch(GmapActions.RECENTER_MAP_TO_DEVICE(publicViewArr[0]?.device, true));
          dispatch(GmapActions.RECENTER_MAP(publicViewArr[0]?.coordinates?.location, true));
        } else {
          mapRef && mapRef.current.context[MAP].setZoom(4);
        }
    }
  }, [IS_PUBLIC_VIEW, isRenderedMap]);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const readOnlyTag = await getTagReadOnlyStatus(authUser.uid, tagId);
      if (readOnlyTag) {
        await dialog?.({
          title: 'Permission error',
          body: 'This tag is read only',
          type: 'NOTIFICATION',
          afterClose: () => history.goBack(),
        });
      }
    })();
  });

  useEffect(() => {
    findSvg(tag.details.icon);
  }, []);

  const changeImage = async () => {
    try {
      let selectedIcon = await selectIcon?.();
      let icon: iIcon

      if (!selectedIcon || !(typeof selectedIcon == 'object')) {
        return;
      }
      if ('icon' in selectedIcon) {
        icon = {
          type: IconTypes.FaIcon,
          fa: selectedIcon,
        };
      } else if ('name' in selectedIcon && ICON_NAMES[selectedIcon.name]) {
        icon = {
          type: IconTypes.SvgIcon,
          svgIconName: selectedIcon.name as string,
        };
      } else {
        icon = {
          type: IconTypes.UserSvgIcon,
          url: await generaldb.saveIcon(selectedIcon as File),
        };
      }

      findSvg(icon);

      await tagsdb.update(authUser)(tagId, { icon });
    } catch (e) {
      console.log('change image error', e);
      dialog?.({
        title: 'Permission error',
        body: "You don't have permission to edit tags",
        type: 'NOTIFICATION',
      });
    }
  };

  const findSvg = async (icon) => {
    const res = await fetch((icon as any)?.url);
    const index = res.headers.get('content-type').indexOf('svg');
    setIsSvg(index === -1 ? false : true);
  };

  const setToAlert = (on: boolean) => async (_) => {
    // prevent changing if already tagging something
    // if this changes we will need to handle case where tag is shared on device or is on a person etc

    if (on && vals(tag.instances).length) {
      dialog?.({
        title: 'Ineligible',
        body: 'This tag is assigned to a device or person and is ineligible for conversion into an alert type tag.',
        type: 'NOTIFICATION',
      });
      return;
    }

    if (on && !(await dialog?.(confirmModalConfig))) return;

    try {
      tagsdb.update(authUser)(tagId, {
        isAlertType: on,
        eventValues: null,
      });
    } catch (e) {
      console.log('set to alert error', e);
      dialog?.({
        title: 'Permission error',
        body: "You don't have permission to edit tags",
        type: 'NOTIFICATION',
      });
    }
  };

  const setColor = async ({ hex: color }) => {
    try {
      await tagsdb.update(authUser)(tagId, { color });
    } catch (e) {
      console.log('e', e);
      dialog?.({
        title: 'Permission error',
        body: "You don't have permission to edit tags",
        type: 'NOTIFICATION',
      });
    }
  };

  const updateName = async (name: string) => {
    try {
      await tagsdb.update(authUser)(tagId, { name });
    } catch (e) {
      console.log('e', e);
      dialog?.({
        title: 'Permission error',
        body: "You don't have permission to edit tags",
        type: 'NOTIFICATION',
      });
    }
  };

  const toggleSelectedDevice = async (deviceId: string) => {
    if (!tag.details.isAlertType) {
      // not items are selected -> change this state
      setIsAllDeviceSelected(false);

      try {
        await toggleDeviceTag(deviceId);
        dispatch(toggleShowSelectedTagDevice(tagId, deviceId));
      } catch (e) {
        dialog?.({
          title: 'Permission error',
          body: "You don't have permission to edit tags",
          type: 'NOTIFICATION',
        });
      }
    }
  };

  const toggleDeviceTag = async (deviceId: string) => {
    /**TODO in next line there is something wrong
     * deviceTags - has function type (string) => any,
     * but in code below deviceTags uses as object
     */
    let deviceTags = pathOr(
      {},
      path(['tag', 'instances', 'device', deviceId])(deviceId)
    );
    const update = {};
    const peopleAllowedSee =
      (
        await clientDb()
          .child('tags')
          .child(tagId)
          .child('instances')
          .child('allowed-see')
          .child('person')
          .once('value')
      ).val() || {};

    if (path(['instances', 'device', deviceId], tag)) {
      deviceTags = dissoc(tagId, deviceTags) as any;
      Object.keys(peopleAllowedSee).forEach(
        (personId) =>
          (update[
            `/acl/items-allowed/${personId}/device/${deviceId}/${tagId}`
          ] = null)
      );
    } else {
      deviceTags = assoc(tagId, true, deviceTags);
    }

    try {
      const isComeFromDevicePage = false;
      await tagsdb.setItemTags(authUser)(
        isComeFromDevicePage,
        ItemType.device,
        deviceId,
        keys(deviceTags),
        tagId
      );

      const taggedDevices =
        (
          await clientDb()
            .child('tags')
            .child(tagId)
            .child('instances')
            .child('device')
            .once('value')
        ).val() || {};

      Object.keys(peopleAllowedSee).forEach((personId) =>
        Object.keys(taggedDevices).forEach(
          (deviceId) =>
            (update[
              `/acl/items-allowed/${personId}/device/${deviceId}/${tagId}`
            ] = 'tag')
        )
      );
      clientDb().update(makeAudit(authUser, update));
    } catch (e) {
      console.log('toggle device tag error', e);
      dialog?.({
        title: 'Permission error',
        body: "You don't have permission to edit tags",
        type: 'NOTIFICATION',
      });
    }
    return true;
  };

  const tagDetails = tag.details;

  const handleSelectAllClick = () => {
    setIsAllDeviceSelected((prev) => !prev);

    const toggleValue = !isAllDeviceSelected;
    tagsdb
      .toggleDevicesForTag(filteredDevicesIds, tagId, toggleValue)
      .then(() => {
        if (isAllDeviceSelected) {
          dispatch(hideSelectedDevices(filteredDevicesIds));
        } else {
          let markerBounds = new google.maps.LatLngBounds();

          devicesMarker.forEach((marker) => {
            markerBounds.extend(
              new google.maps.LatLng(
                marker.coordinates.location.lat,
                marker.coordinates.location.lng
              )
            );
          });

          mapRef.current.fitBounds(markerBounds);
          dispatch(showSelectedDevices(filteredDevicesIds));
        }
      })
      .catch((e) => {
        console.error('toggle device tag error', e);
        dialog?.({
          title: 'Permission error',
          body: "You don't have permission to edit tags",
          type: 'NOTIFICATION',
        });
      });
  };

  const handleDeviceSearch = (search: string) => {
    // reset select all on search
    setIsAllDeviceSelected(false);

    return dispatch(UiActions.SET_TAG_DEETS_DEVICE_FILTER(search));
  };

  // DEVICE SELECTION DISPLAY
  const deviceSections = useMemo(() => {
    const devicesInSection = 6;
    const allDevices = filteredDevices.map((device) => ({
      id: device.id,
      name: device.name,
      onChange: () => toggleSelectedDevice(device.id),
    }));

    return groupItemsIntoPages(allDevices, devicesInSection)
      .map((v, i) => ({id: `device-section-${i}`, value: v}));
  }, [filteredDevices, toggleSelectedDevice]);

  const AllSerialNumbersWithExtraInfo = Array.from(devicesDetails).map((el: any) => {
    const serialNumber = el[0];
    const extraInfo = el?.[1]?.['extra-info']?.[0];
    if (el?.[1]?.isCamera) {
      return { [serialNumber]: extraInfo }
    }
  });

  const objectWithDeviceIdAndSerial = Object.assign({}, ...AllSerialNumbersWithExtraInfo);

  const objectWithDeviceIdAndSerialThatTagHas = {};
  taggedDeviceIds.forEach((key) => {
    if (objectWithDeviceIdAndSerial.hasOwnProperty(key)) {
      objectWithDeviceIdAndSerialThatTagHas[key] = objectWithDeviceIdAndSerial[key];
    }
  });

  const arrayOfSerialsThatTagHas = Object.values(objectWithDeviceIdAndSerialThatTagHas);

  const filterDevicesInfoOnlyThatTagHas = (arr, idArray) => {
    const result = arr.filter(item => {
      const extraInfo = item[1]["extra-info"];
      return extraInfo && idArray.includes(extraInfo["0"]);
    });

    return result;
  }

  const arrayOfAllDevicesInfo = Array.from(devicesDetails);

  const deviceData = filterDevicesInfoOnlyThatTagHas(arrayOfAllDevicesInfo, arrayOfSerialsThatTagHas);

  const { openLiveViewPlayer, openLiveViewModal, openContinueLiveStreamingModal }: any = useSelector(
    (state: iReducersState) => state.videoCameras.modals
  );


  const liveViewClick = async () => {
    dispatch(openModal({ key: 'openLiveViewModal', value: true }));
  }

  const REMINDER_INTERVAL: number = 60000;

  const [reminderIntervalTime, setReminderIntervalTime] = useState<number>(REMINDER_INTERVAL);
  const [triggerCloseModal, setTriggerCloseModal] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState({ channel_id: null, name: null });
  const [toCloseModalNow, setToCloseModalNow] = useState(false);
  const [channelLinksForCameras, setChannelLinksForCameras] = useState([]);

  const getChannelLinksForCameras = async () => {
    try {
      const res = await getChannelsMore({ firebaseTagId: tagId, orgKey: loginToken['clientId'] });
      setChannelLinksForCameras(res.data?.streams);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (openContinueLiveStreamingModal === false && !IS_PUBLIC_VIEW) {
      getChannelLinksForCameras();
    }
  }, [selectedChannels, openContinueLiveStreamingModal])

  const [isPublicLinkModalOpen, setIsPublicLinkModalOpen] = useState(false);

  const findDeviceBySerial = (serial: string) => {
    if(!serial){
      return null;
    }

    for (const device of arrayOfAllDevicesInfo) {
      const extraInfo = device[1]['extra-info'];
      if(!extraInfo){
        return null;
      }

      let foundSerial;

      if (Array.isArray(extraInfo) && extraInfo.length === 1 && typeof extraInfo[0] === 'string') {
        foundSerial = extraInfo[0];
      }
      else if (typeof extraInfo === 'object' && extraInfo !== null && extraInfo.hasOwnProperty('0')) {
        foundSerial = extraInfo['0'];
      }

      if(foundSerial === serial){
        return device[1].name;
      }
    }
  }

  const [isTagNameEditing, setIsTagNameEditing] = useState(false);
  const [tagName, setTagName] = useState(tagDetails.name);
  const saveName = () => {
    updateName(tagName);
    setIsTagNameEditing(false);
  };

  const cancelNameEditing = () => {
    setTagName(tagDetails.name);
    setIsTagNameEditing(false);
  };

  const hasSerialsToView = arrayOfSerialsThatTagHas.length > 0;
  const areAlertsEnabled = tagDetails.isAlertType;

  return (
    <>
      <DashboardBlock>
        <ModalHeader title='Edit Tag' style={{ marginBottom: 16 }} />

        {/* TAG'S NAME */}
        <div className={classNames(styles.tagNameSection, { [styles.editMode]: isTagNameEditing })}>
          <div className={styles.tagNameSectionLabel}>
            <p className={styles.sectionLabel}>Tag Name</p>
            <button
              type='button'
              onClick={saveName}
              className={styles.saveChangesButton}
            >
              Save Changes
            </button>
          </div>

          <div className={styles.tagNameInput}>
            <input
              value={tagName}
              onChange={({ target: { value } }) => setTagName(value)}
              onFocus={() => setIsTagNameEditing(true)}
            />
            <button
              onClick={cancelNameEditing}
              className={styles.closeButton}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>

        {/* TAG'S ICON + COLOR */}
        <div className={styles.tagIconSection}>
          <p className={styles.sectionLabel}>Tag Icon</p>

          <div className={styles.tagIconContent}>
            <div
              className={styles.tagIcon}
              onClick={changeImage}
            >
              {tagDetails.icon ? (
                <SvgIcon
                  size='lg'
                  color={tagDetails.color}
                  icon={tagDetails.icon}
                />
              ) : (
                <SvgIcon
                  size='lg'
                  color={'#fff'}
                  icon={{
                    svgIconName: 'micro',
                    type: 1,
                  }}
                />
              )}
            </div>

            {/* Custom colors list picker */}
            <div className={styles.colorsPalette}>
              {
                C.chooserColors.map(colorElement => (
                  <button
                    key={colorElement}
                    type='button'
                    onClick={() => setColor({ hex: colorElement })}
                    className={styles.colorItem}
                    style={{ backgroundColor: colorElement }}
                  >
                    {(tagDetails.color ?? '').toLowerCase() === colorElement.toLowerCase() && (
                      <CheckIcon
                        className={styles.colorItemIcon}
                        style={{ color: color(colorElement).light() ? '#000' : '#fff' }}
                      />
                    )}
                  </button>
                ))
              }
            </div>
          </div>
        </div>

        <div className={styles.actionsSection}>
          <p className={styles.sectionLabel}>Actions</p>

          <div className={styles.actionsList}>
            <button
              type='button'
              className={styles.actionItem}
              title={hasSerialsToView ? 'Live View' : 'No camera devices selected for live View'}
              onClick={hasSerialsToView ? liveViewClick : () => {}}
              disabled={!hasSerialsToView}
            >
              <PlayIcon className={styles.actionItemIcon} />
              <span>View</span>
            </button>

            <button
              onClick={() => setIsPublicLinkModalOpen(true)}
              className={styles.actionItem}
            >
              <PublicIcon className={styles.actionItemIcon} />
              <span>Public</span>
            </button>

            <button
              onClick={setToAlert(!areAlertsEnabled)}
              className={classNames(styles.actionItem, {[styles.actionOn]: areAlertsEnabled})}
            >
              {
                areAlertsEnabled
                  ? <NotificationsOnIcon className={styles.actionItemIcon} />
                  : <NotificationsOffIcon className={styles.actionItemIcon} />
              }
              <span>{areAlertsEnabled ? 'Alerts Enabled' : 'Alerts Disabled'}</span>
            </button>
          </div>
        </div>

        {/* selected count */}
        <div className={styles.selectedDeviceContainer}>
          <p>Select Devices:</p>
          <p className={styles.selectedCount}>{taggedDeviceCount} Items Selected</p>
        </div>

        <SearchInput
          value={deviceFilterStr}
          onChange={handleDeviceSearch}
          placeholder='Filter Devices'
          className={styles.searchInput}
        />

        <CheckboxList
          sections={deviceSections}
          selected={taggedDeviceIds}
          changeAllItems={handleSelectAllClick}
          isAllSelected={isAllDeviceSelected}
          containerClassName={styles.checkboxesList}
        />

        <div style={{ position: 'relative', marginTop: 16 }}>
          <div
            style={{
              ...(tagDetails.isAlertType ? { display: 'none' } : noAlertsStyle),
            }}
          >
            <MinimalTile
              style={{
                border: `1px solid ${C.primaryColor}`,
                backgroundColor: '#fff',
                margin: 10,
              }}
            >
              Enable Alerts to Edit
            </MinimalTile>
          </div>

          <ExtraInfo
            editUrl={`/${ItemType.tag}/${tagDetails.id}/add/extra-info`}
            labels={currentLabels}
          />

          <div style={{ marginTop: 10, textAlign: 'center' }}>
          </div>
        </div>
      </DashboardBlock>
      <Dialog setupConfig={setupDialog} />
      <ModalCamerasForTag toCloseModalNow={toCloseModalNow} setToCloseModalNow={setToCloseModalNow}>
        {
          openLiveViewModal && (
            <SelectChannelModal
              setSelectedChannels={setSelectedChannels}
              selectedChannels={selectedChannels}
            />)
        }
        <div className={styles.gridCameras}>
          {openLiveViewPlayer &&
          deviceData.map(([firebaseDeviceId, deviceInfo], index) => {
            const channelLinks = channelLinksForCameras[index];
            const deviceName = findDeviceBySerial(channelLinks?.uniqueId);

            return (
              <DeviceStatusesForTag
                deviceName={deviceName}
                device={deviceInfo}
                arrayOfSerialsThatTagHas={arrayOfSerialsThatTagHas}
                index={index}
                channelLinksForCameras={channelLinks}
                uniqueId={channelLinks?.uniqueId}
                channelProp={selectedChannels.channel_id}
                triggerCloseModal={triggerCloseModal}
                reminderIntervalTime={reminderIntervalTime}
              />)
          })
        }
        </div>
      </ModalCamerasForTag>

      {(isPublicLinkModalOpen) && (
        <PublicLinkModal
          setIsPublicLinkModalOpen={setIsPublicLinkModalOpen}
          tagId={tagId}
        />
      )}

      {(!person.suppressContinueStreamModal && openContinueLiveStreamingModal) &&
        (<ContinueStreamModalForTag
          closeContinueLiveStreamingModal={() => dispatch(openModal({ key: "openContinueLiveStreamingModal", value: false }))}
          setReminderIntervalTime={setReminderIntervalTime}
          setTriggerCloseModal={setTriggerCloseModal}
        />)
      }
    </>
  );
}

export default withSentry(connect(mapStateToProps)(TagDetailsPage));

const noAlertsStyle: css = {
  backgroundColor: color('#333').fade(0.7),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};